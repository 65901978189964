import { handle } from 'redux-pack';
import { initialState } from '../../selectors';
import * as types from '../../../actionTypes';

export default (state = initialState, action) => {
  // NOSONAR
  const { type, payload } = action;

  switch (type) {
    case types.SET_INVESTMENT_DATA: {
      return {
        ...state,
        ...payload,
      };
    }
    case types.FETCH_CUSTOMER_INVESTMENT_HISTORY:
      return handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            history: {
              ...payload.data,
            },
          };
        },
      });
    case types.FETCH_CUSTOMER_INVESTMENT_DOCUMENTS:
      return handle(state, action, {
        start: (prevState) => {
          return {
            ...prevState,
            documents: {},
          };
        },
        success: (prevState) => {
          return {
            ...prevState,
            documents: payload.data,
          };
        },
      });
    case types.FETCH_CUSTOMER_INVESTMENT:
      return handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            ...payload.data,
          };
        },
      });
    case types.FETCH_CUSTOMER_TIMELINE_ORDERS:
      return handle(state, action, {
        start: (prevState) => {
          return {
            ...prevState,
            orders: {},
          };
        },
        success: (prevState) => {
          return {
            ...prevState,
            orders: payload.data,
          };
        },
      });
    case types.FETCH_CUSTOMER_TIMELINE_FEES_TRANSACTIONS:
      return handle(state, action, {
        start: (prevState) => {
          return {
            ...prevState,
            fees: {},
          };
        },
        success: (prevState) => {
          return {
            ...prevState,
            fees: payload.data,
          };
        },
      });
    case types.FETCH_CUSTOMER_TIMELINE_REBALANCING_EVENTS:
      return handle(state, action, {
        start: (prevState) => {
          return {
            ...prevState,
            rebalancing: {},
          };
        },
        success: (prevState) => {
          return {
            ...prevState,
            rebalancing: payload.data,
          };
        },
      });
    case types.FETCH_CUSTOMER_TIMELINE_DISTRIBUTION_EVENTS:
      return handle(state, action, {
        start: (prevState) => {
          return {
            ...prevState,
            dividends: {},
          };
        },
        success: (prevState) => {
          return {
            ...prevState,
            dividends: payload.data,
          };
        },
      });
    default:
      return state;
  }
};
