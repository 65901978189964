import {
  OTP_EXPIRED_ERROR_CODE,
  OTP_INVALID_ERROR_CODE,
  OTP_STATE_NEW,
  OTP_STATE_VALIDATED,
  OTP_USER_BLOCKED_ERROR_CODE,
  OTP_VERIFICATION_MESSAGE_DE,
  OTP_VERIFICATION_MESSAGE_EN,
} from './constants';

export const isUserBlockedError = (error) =>
  error?.frontend_error_code === OTP_USER_BLOCKED_ERROR_CODE;

export const isInvalidOTPError = (error) => error?.frontend_error_code === OTP_INVALID_ERROR_CODE;

export const isExpiredOTPError = (error) => error?.frontend_error_code === OTP_EXPIRED_ERROR_CODE;

export const isVerifiedOTPResponse = (response) => response?.state === OTP_STATE_VALIDATED;

export const isNewOTPResponse = (response) => response?.state === OTP_STATE_NEW;

export const getVerificationMessage = () => {
  if (
    window.location.host.endsWith('weltsparen.de') ||
    window.location.host.endsWith('weltsparen.at')
  ) {
    return OTP_VERIFICATION_MESSAGE_DE;
  }

  return OTP_VERIFICATION_MESSAGE_EN;
};

export const bearerToken = (authToken) => {
  return `Bearer ${authToken}`;
};
