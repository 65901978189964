import { getUrl } from '../utils';

export const HAS_FIXED_DEPOSIT_ACCESS = 'HAS_FIXED_DEPOSIT_ACCESS';
export const HAS_FIXED_DEPOSIT_ACCOUNT = 'HAS_FIXED_DEPOSIT_ACCOUNT';
export const HAS_FLEX_DEPOSIT_ACCESS = 'HAS_FLEX_DEPOSIT_ACCESS';
export const HAS_FLEX_DEPOSIT_ACCOUNT = 'HAS_FLEX_DEPOSIT_ACCOUNT';
export const HAS_INVESTMENT_PRODUCT_ACCESS = 'HAS_INVESTMENT_PRODUCT_ACCESS';
export const HAS_INVESTMENT_PRODUCT_ACCOUNT = 'HAS_INVESTMENT_PRODUCT_ACCOUNT';
export const HAS_NOTICE_DEPOSIT_ACCESS = 'HAS_NOTICE_DEPOSIT_ACCESS';
export const HAS_NOTICE_DEPOSIT_ACCOUNT = 'HAS_NOTICE_DEPOSIT_ACCOUNT';
export const HAS_OVERNIGHT_DEPOSIT_ACCESS = 'HAS_OVERNIGHT_DEPOSIT_ACCESS';
export const HAS_OVERNIGHT_DEPOSIT_ACCOUNT = 'HAS_OVERNIGHT_DEPOSIT_ACCOUNT';

export const getNlMenus = ({
  customer,
  shouldShowUpdatedProductURLs,
  hasB2cDeprecateObsFeatureFlag,
}) => {
  const { productAccess } = customer;

  const myInvestments = {
    id: 'myInvestments',
    title: 'Mijn spaaroverzicht',
    href: '',
    subMenus: {
      layout: 'even',
      top: [
        {
          id: 'dashboard-myInvestments',
          type: 'basic',
          title: `Dashboard`,
          href: getUrl(`/#/Dashboard`),
          icon: 'overviewIcon',
          iconColor: '#0E6CC5',
        },
      ],
      main: [
        (productAccess.includes(HAS_FIXED_DEPOSIT_ACCOUNT) ||
          productAccess.includes(HAS_FLEX_DEPOSIT_ACCOUNT)) && {
          id: 'fixed-deposit',
          type: 'basic',
          title: `Deposito sparen`,
          href: getUrl(`/#/MyInvestments/TermDeposit`),
          icon: 'fixedDepositIcon',
          iconColor: '#61AFD2',
        },
        productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCOUNT) && {
          id: 'overnight-deposit',
          type: 'basic',
          title: `Spaarrekening`,
          href: getUrl(`/#/MyInvestments/Overnight`),
          icon: 'overnightMoneyIcon',
          iconColor: '#9CD3E4',
        },
      ],
    },
  };

  const offers = {
    id: 'offers',
    title: `Producten`,
    href: '',
    subMenus: {
      layout: 'even',
      main: [
        (productAccess.includes(HAS_FIXED_DEPOSIT_ACCESS) ||
          productAccess.includes(HAS_FLEX_DEPOSIT_ACCESS) ||
          hasB2cDeprecateObsFeatureFlag) && {
          id: 'fixedDeposit-offers',
          type: 'basic',
          title: `Deposito sparen`,
          href: shouldShowUpdatedProductURLs ? '/products/' : getUrl(`/#/Products`),
          icon: 'fixedDepositIcon',
          iconColor: '#61AFD2',
        },
        (productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCESS) || hasB2cDeprecateObsFeatureFlag) && {
          id: 'overnightDeposit-offers',
          type: 'basic',
          title: `Spaarrekening`,
          href: shouldShowUpdatedProductURLs
            ? '/products/overnight/'
            : getUrl(`/#/Products/overnight`),
          icon: 'overnightMoneyIcon',
          iconColor: '#9CD3E4',
        },
      ],
    },
  };

  const information = {
    id: 'information',
    title: `Informatie`,
    href: '',
    subMenus: {
      main: [
        {
          id: 'd71e82d5-84ea-4457-8cfc-762927bd8f21',
          type: 'advanced',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt8e96e15c429c928b/Einlagensicherung.svg',
          iconColor: '#0E6CC5',
          title: 'Depositogarantie',
          href: '/depositogarantie/',
        },
        {
          id: '4a17825e-a704-457c-945f-f1b45c50b29e',
          type: 'advanced',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt30ff10945458f646/Belasting.svg',
          iconColor: '#0E6CC5',
          title: 'Belasting',
          href: '/belasting/',
        },
        {
          id: '8ec5d1d0-2452-4cad-acce-f8875f8b8277',
          type: 'advanced',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt99996be20b268bc3/Sparen_algemeen.svg',
          iconColor: '#0E6CC5',
          title: 'Sparen algemeen',
          href: '/sparen/',
        },
        {
          id: '023386ad-c47a-44cd-be66-4ea80d25ca22',
          type: 'advanced',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blte084bc080dd2c186/Sparen_in_de_EU.svg',
          iconColor: '#0E6CC5',
          title: 'Sparen in de EU',
          href: '/spaarrente/buitenland/',
        },
      ],
    },
  };

  const overRaisin = {
    id: 'dad415d9-8434-47fd-8ae4-fbb9e1a64a85',
    title: 'Over Raisin',
    href: '',
    subMenus: {
      main: [
        {
          id: 'e78780a1-cc15-45d9-95ca-53c52e905cc5',
          type: 'advanced',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt8a5863614d18d6bc/Zo_werkt_het.svg',
          iconColor: '#0E6CC5',
          title: 'Zo werkt het',
          href: '/zo-werkt-het/',
        },
        {
          id: 'e78780a1-cc15-45d9-95ca-53c52e905cc2',
          type: 'advanced',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/bltb30f24c72431a3ba/Partnerbanken.svg',
          iconColor: '#0E6CC5',
          title: 'Partnerbanken',
          href: '/banken/',
        },
        {
          id: 'e78780a1-cc15-45d9-95ca-53c52e905cc1',
          type: 'advanced',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt7306ae38b600c7e6/Over_ons.svg',
          iconColor: '#0E6CC5',
          title: 'Over ons',
          href: '/over-raisin/',
        },
        {
          id: 'e78780a1-cc15-45d9-95ca-53c52e905cc7',
          type: 'advanced',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt3fda0eed0225803a/Raisin_Bank.svg',
          iconColor: '#0E6CC5',
          title: 'Raisin Bank',
          href: '/raisin-bank/',
        },
      ],
    },
  };

  const serviceAndContact = {
    id: 'dad415d9-8434-47fd-8ae4-fbb9e1a74a85',
    title: 'Service & Contact',
    href: '/service-en-contact/',
  };

  const navItems = [myInvestments, offers, information, overRaisin, serviceAndContact];

  return navItems;
};
