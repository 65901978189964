import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import constants from 'ui-lib/src/constants';
import Alert from 'ui-lib/src/components/Alert';
import Paragraph from 'ui-lib/src/components/Paragraph';
import { Translate } from 'components/I18n';
import styles from './styles.scss';

export default class FileUploadErrorNotice extends PureComponent {
  static propTypes = {
    notices: PropTypes.object,
  };

  render() {
    const { notices } = this.props;
    const { alerts } = constants;

    if (
      notices &&
      (notices['file.upload.duplicate'] ||
        notices['file.exceeds.size'] ||
        notices['file.upload.limit.exceeded'])
    ) {
      return (
        <Alert type={alerts.danger} key="alert-fileUploadError" showDefault>
          {notices['file.upload.duplicate']
            ? notices['file.upload.duplicate'].duplicateFiles.map((fileName) => (
                <Paragraph key={fileName} className={styles.alert}>
                  <Translate
                    id="error.fileUpload.duplicate"
                    values={{
                      fileName,
                    }}
                  />
                </Paragraph>
              ))
            : null}
          {notices['file.exceeds.size']
            ? notices['file.exceeds.size'].exceedingSizeFiles.map((file) => (
                <Paragraph key={file.name} className={styles.alert}>
                  <Translate
                    id="error.fileUpload.sizeExceeded"
                    values={{
                      fileName: file.name,
                      maxFileSize: notices['file.exceeds.size'].maxFileSize,
                    }}
                  />
                </Paragraph>
              ))
            : null}
          {notices['file.upload.limit.exceeded'] ? (
            <Paragraph className={styles.alert}>
              <Translate
                id="error.fileUpload.uploadLimitExceeded"
                values={{ fileUploadLimit: notices['file.upload.limit.exceeded'].fileUploadLimit }}
              />
            </Paragraph>
          ) : null}
        </Alert>
      );
    }

    return null;
  }
}
