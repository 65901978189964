import {
  DEVELOPMENT_ENV_DOMAIN_REGION_REGEX,
  getNamespaceName,
  getRegionName,
} from 'utils/IPUtils';
import store from 'store2';
import { AUTH_TOKEN_KEY } from '../CustomerUtils';

export const isRunningLocally = () => {
  return process.env.NODE_ENV === 'local';
};

export const KEYCLOAK_CLIENT_ID = 'login';
export const KEYCLOAK_REALM = 'global';

export const getAuthBaseUrl = (hostname) => {
  const DEFAULT_AUTH_BASE_URL = 'https://auth.weltsparen.de';

  const config = {
    GBR: {
      develop: 'https://auth-gbr-%s.raisin-dev.network',
      staging: 'https://auth.staging-raisin.co.uk',
      onboarding: 'https://auth.onboarding-raisin.co.uk',
      production: 'https://auth.raisin.co.uk',
    },
    DEU: {
      develop: 'https://auth-deu-%s.raisin-dev.network',
      staging: 'https://auth.staging-weltsparen.de',
      onboarding: 'https://auth.onboarding-weltsparen.de',
      production: 'https://auth.weltsparen.de',
    },
  };

  const namespace = getNamespaceName();

  const developmentAuthBaseUrl = (hostname) => {
    const name = DEVELOPMENT_ENV_DOMAIN_REGION_REGEX.exec(hostname);

    return name ? config[name[1].toUpperCase()].develop.replace('%s', getNamespaceName()) : false;
  };

  const regionName = getRegionName(hostname);

  return developmentAuthBaseUrl(hostname) || config[regionName][namespace] || DEFAULT_AUTH_BASE_URL;
};
export const getRefreshToken = () => {
  const authToken = store(AUTH_TOKEN_KEY);

  return authToken?.refresh_token;
};
