import * as types from '../../actionTypes';
import { createImprovedAsyncAction } from '../../actionCreators';

export const fetchDashboardData = () => {
  return createImprovedAsyncAction({
    type: types.FETCH_DASHBOARD_DATA,
    path: 'dashboard.data',
  });
};

export const fetchPensionProductsDashboardData = () => {
  return createImprovedAsyncAction({
    type: types.FETCH_PENSION_PRODUCTS_DASHBOARD_DATA,
    path: 'dashboard.pensionProducts',
    settings: {
      validateStatus: () => true,
    },
  });
};

export const fetchPensionProductsDashboardDataMigrated = () =>
  createImprovedAsyncAction({
    type: types.FETCH_PENSION_PRODUCTS_DASHBOARD_DATA,
    path: 'dashboard.pensionProductsMigrated',
    settings: {
      validateStatus: () => true,
      useJwt: true,
    },
  });

/**
 * Fetches the invested amounts of BIPSified products.
 * @param {*} bac customer ID
 */
export const fetchDepositsBalanceFromDAS = (bac) => {
  return createImprovedAsyncAction({
    type: types.DAS_FETCH_DEPOSITS_BALANCE,
    path: 'das.depositsBalance',
    settings: {
      params: {
        customer_id: bac,
      },
      useJwt: true,
    },
  });
};

/**
 *  Fetchs only the non re-platformed banks data.
 */
export const fetchNonReplatformedDashboardData = () => {
  return createImprovedAsyncAction({
    type: types.FETCH_DASHBOARD_DATA,
    path: 'dashboard.nonReplatformedData',
  });
};
