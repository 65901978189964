import React, { Component } from 'react';
import PropTypes from 'prop-types';
import constants from 'ui-lib/src/constants';
import Alert from 'ui-lib/src/components/Alert';
import Paragraph from 'ui-lib/src/components/Paragraph';
import Text from 'ui-lib/src/components/Text';
import { Translate } from 'components/I18n';
import { responseStatus } from '../../config';
import styles from './styles.scss';

export default class BlockedAccountErrorNotice extends Component {
  static propTypes = {
    requestError: PropTypes.object,
    isBlocked: PropTypes.bool,
  };

  render() {
    const { requestError, isBlocked } = this.props;
    const { alerts } = constants;

    const deeplink = localStorage.getItem('obs-deepLink');
    const isBlockedUrlParam = deeplink?.includes('is_blocked=true');

    if (
      (requestError &&
        requestError.status === responseStatus.forbidden &&
        requestError.title_i18n &&
        requestError.title_i18n === 'errors.account.locked') ||
      isBlocked ||
      isBlockedUrlParam
    ) {
      return (
        <Alert
          id="blockedAccountErrorNotice"
          type={alerts.danger}
          key="alert-blockedAccountError"
          className={styles.authorizationErrorNotice}
          showDefault
        >
          <Paragraph>
            <strong>
              <Translate id="locked.caption" />
            </strong>
          </Paragraph>
          <Text>
            <Translate id="locked.info" />
          </Text>
        </Alert>
      );
    }

    return null;
  }
}
