import { handle } from 'redux-pack';
import * as types from '../../../actionTypes';
import { ajaxStatus } from '../../../../config';

export default (state, action) => {
  // NOSONAR
  const { type, payload } = action;

  switch (type) {
    case types.POM_FETCH_ETFC_CONSENT_ORDERS:
      return handle(state, action, {
        success: (prevState) => ({
          ...prevState,
          etfcConsentOrders: payload.data,
        }),
      });
    case types.POM_FETCH_CRYPTO_CONSENT_ORDERS:
      return handle(state, action, {
        success: (prevState) => ({
          ...prevState,
          cryptoConsentOrders: payload.data,
        }),
      });
    case types.POM_POST_CRYPTO_DRAFT_ORDER: {
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          pomPostCryptoDraftOrder: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          pomPostCryptoDraftOrder: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          pomPostCryptoDraftOrder: {
            data: payload.data,
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });
    }
    case types.POM_POST_CRYPTO_VERIFY_ORDER: {
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          pomPostCryptoVerifyOrder: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          pomPostCryptoVerifyOrder: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          pomPostCryptoVerifyOrder: {
            data: payload.data,
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });
    }

    case types.POM_POST_ETFC_DRAFT_ORDER: {
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          pomPostETFCDraftOrder: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          pomPostETFCDraftOrder: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          pomPostETFCDraftOrder: {
            data: payload.data,
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });
    }
    case types.POM_POST_ETFC_VERIFY_ORDER: {
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          pomPostETFCVerifyOrder: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          pomPostETFCVerifyOrder: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          pomPostETFCVerifyOrder: {
            data: payload.data,
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });
    }

    case types.POM_FETCH_CUSTOMER_ORDERS:
      return handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            customerOrders: payload.data,
          };
        },
      });
    case types.POM_FETCH_PORTFOLIO_ORDERS:
      return handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            portfolioOrders: payload.data,
          };
        },
      });
    default:
      return state;
  }
};
