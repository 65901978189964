import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Alert from 'ui-lib/src/components/Alert';
import Button from 'ui-lib/src/components/Button';
import ButtonLink from 'ui-lib/src/components/ButtonLink';
import Heading from 'ui-lib/src/components/Heading';
import Input from 'ui-lib/src/components/Input';
import Label from 'ui-lib/src/components/Label';
import Link from 'ui-lib/src/components/Link';
import Text from 'ui-lib/src/components/Text';
import constants from 'ui-lib/src/constants';

import { Translate, TranslationsProvider } from 'components/I18n';
import Notices from 'components/Notices';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import { noticesConfig } from 'components/Notices/config';
import { OTP, getVerificationMessage } from 'components/OTP';
import {
  authenticateWithKeycloak,
  fetchAuthConfiguration,
  fetchCustomerProductAccess,
  postCustomerLoginCredentials,
  setCustomerData,
  toggleLoginOTPVerification,
} from 'store/customer/actions';
import { getCountryByLocalStorageLocale, isLoggedIn } from 'utils/CustomerUtils';
import { reportError } from 'utils/ErrorHandling';
import { getLocaleFromLocalStorage } from 'utils/Locale';
import { trackEvent } from '@raisin/events-tracking';
import {
  getFeatureFlagClient,
  getFeatureFlagClientBy,
  getFeatureFlagUserKey,
} from '../../utils/FeatureFlag';

import {
  LOGIN_MTAN_BLOCKED,
  LOGIN_MTAN_EXPIRED,
  LOGIN_MTAN_INVALID,
  LOGIN_MTAN_REQUESTED,
  LOGIN_MTAN_RESENT,
  LOGIN_MTAN_SUBMITTED,
  LOGIN_MTAN_VERIFIED,
  LOGIN_MTAN_FAILED,
} from '../../eventsTracking/login';
import { EVENTS } from '../../eventsTracking';
import ResetYourPassword from './ResetYourPassword';
import styles from './styles.scss';
import { withLoginForm } from './withLoginForm';
import { getBasePath } from '../../components/Header/data/utils';
import { EXTERNAL_LINKS } from '../../components/Header/Navigation/menuLinks';

export class Login extends Component {
  static propTypes = {
    login: PropTypes.shape({
      username: PropTypes.string.isRequired,
      password: PropTypes.string.isRequired,
    }).isRequired,
    loginForm: PropTypes.shape({
      status: PropTypes.string.isRequired,
      error: PropTypes.object,
    }).isRequired,
    errors: PropTypes.shape({
      username: PropTypes.shape({
        type: PropTypes.string,
        path: PropTypes.string,
      }),
      password: PropTypes.shape({
        type: PropTypes.string,
        path: PropTypes.string,
      }),
    }),
    isPosting: PropTypes.bool,
    isOtpVerified: PropTypes.bool,
    setCustomerData: PropTypes.func,
    postCustomerLoginCredentials: PropTypes.func,
    fetchCognitoConfiguration: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    values: PropTypes.shape({
      username: PropTypes.string.isRequired,
      password: PropTypes.string.isRequired,
      verificationId: PropTypes.string,
    }).isRequired,
    touched: PropTypes.shape({
      username: PropTypes.bool,
      password: PropTypes.bool,
    }).isRequired,
    toggleLoginOTPVerification: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.getBottomComponentByLocale = this.getBottomComponentByLocale.bind(this);
    this.renderLoginBottom = this.renderLoginBottom.bind(this);
    this.renderLoginBottomDeu = this.renderLoginBottomDeu.bind(this);
    this.renderInputAndLabel = this.renderInputAndLabel.bind(this);
    this.renderCBNotice = this.renderCBNotice.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderOTP = this.renderOTP.bind(this);

    this.isOtpVerified = false;

    this.state = {
      featureFlagClient: null,
    };
  }

  componentDidMount() {
    this.props.setCustomerData({ hasJustLoggedOut: false });

    const featureFlagIdentifier = getFeatureFlagUserKey();

    Promise.all([getFeatureFlagClientBy(featureFlagIdentifier, true), getFeatureFlagClient()])
      .then(([clientByRandomUserId, client]) => {
        // TODO: delete the below code after Login migration to CP becomes stable
        if (clientByRandomUserId?.allFlags()?.userManagementLoginRedirectionInRaisinFrontend) {
          window.location.assign(`${getBasePath()}${EXTERNAL_LINKS.GLOBAL.LOGIN}?sr=0`);

          return;
        }

        if (client) {
          this.setState({ featureFlagClient: client });
        }
      })
      .catch((err) => reportError(`Failed initializing LaunchDarkly client.`, err));

    window?.ReactNativeWebView?.postMessage(JSON.stringify({ action: 'logout' }));
  }

  componentDidUpdate() {
    if (isLoggedIn()) {
      this.props.history.push('/');
    }
  }

  formatError = (msg) => {
    return <Translate id={msg} raw />;
  };

  renderLoginBottom(linkToOffers) {
    return (
      <Text>
        <Translate id="loginBottom.part2" />{' '}
        <Link href={linkToOffers}>
          <Translate id="loginBottom.part3" />
        </Link>
      </Text>
    );
  }

  renderLoginBottomDeu() {
    return (
      <ButtonLink
        onClick={() => window.location.assign('https://registrierung.weltsparen.de/registrierung')}
        text={<Translate id="client" />}
        suffixIcon="chevron-right"
      />
    );
  }

  renderLoginBottomPol() {
    return (
      <ButtonLink
        onClick={() => window.location.assign('https://www.raisin.com/pl-pl/register')}
        text={<Translate id="client" />}
        suffixIcon="chevron-right"
      />
    );
  }

  renderLoginBottomAut(linkToReg) {
    return (
      <Link href={linkToReg} suffixIcon="chevron-right">
        <Translate id="privateClient" />
      </Link>
    );
  }

  renderInputAndLabel(inputParams) {
    return (
      <div className={`row ${styles.loginForm}`}>
        <Label
          htmlFor={inputParams.label.name}
          label={<Translate id={inputParams.label.labelContent} />}
          className={`${styles.inputLabel}`}
        />
        <div>
          <Input {...inputParams.input} />
        </div>
      </div>
    );
  }

  getBottomComponentByLocale(locale) {
    const COMPONENT_FOR_LOCALE = {
      EUR: {
        link: 'https://www.raisin.com/our-offers/',
        component: this.renderLoginBottom,
      },
      FRA: {
        link: 'https://www.raisin.fr/offres/',
        component: this.renderLoginBottom,
      },
      ESP: {
        link: 'https://www.raisin.es/productos',
        component: this.renderLoginBottom,
      },
      NLD: {
        link: 'https://www.raisin.nl/deposito/',
        component: this.renderLoginBottom,
      },
      GBR: {
        link: 'https://www.raisin.co.uk/savings-accounts/',
        component: this.renderLoginBottom,
      },
      DEU: {
        link: '/',
        component: this.renderLoginBottomDeu,
      },
      AUT: {
        link: 'https://www.weltsparen.at/register',
        component: this.renderLoginBottomAut,
      },
      IRL: {
        link: 'https://www.raisin.ie/savings-accounts/',
        component: this.renderLoginBottom,
      },
      POL: {
        link: '/',
        component: this.renderLoginBottomPol,
      },
    };

    const relevantLink = COMPONENT_FOR_LOCALE[locale];

    if (!relevantLink) {
      throw new Error('Unexpected LOCALE see COMPONENT_FOR_LOCALE');
    }

    return relevantLink;
  }

  renderCBNotice() {
    const flags = this.state.featureFlagClient?.allFlags();

    if (!flags) {
      return null;
    }

    if (flags.userManagementCBNotice) {
      return (
        <Alert type={constants.alerts.info}>
          <Translate id="cbNotice" />
        </Alert>
      );
    }

    return null;
  }

  renderForm() {
    const {
      isPosting,
      handleSubmit,
      handleChange,
      handleBlur,
      errors,
      values,
      touched,
      loginForm,
    } = this.props;

    if (this.isOtpVerified) return <LoadingPlaceholder height={300} width="100%" />;

    const usernameParams = {
      input: {
        id: 'emailInput',
        inputType: 'text',
        name: 'username',
        key: 'username',
        type: 'input',
        required: true,
        value: values.username,
        formatError: this.formatError,
        onChange: handleChange,
        onBlur: handleBlur,
        error: touched.username ? errors.username : {},
        autoComplete: 'off',
      },
      label: {
        name: 'emailInput',
        labelContent: 'emailLabel',
      },
    };

    const passParams = {
      input: {
        id: 'passInput',
        inputType: 'password',
        name: 'password',
        key: 'password',
        type: 'input',
        required: true,
        value: values.password,
        formatError: this.formatError,
        onChange: handleChange,
        onBlur: handleBlur,
        error: touched.password ? errors.password : {},
      },
      label: {
        name: 'passInput',
        labelContent: 'passwordLabel',
      },
    };

    return (
      <form
        className={styles.loginForm}
        onSubmit={handleSubmit}
        style={{ display: loginForm?.verificationId ? 'none' : 'block' }}
      >
        <Notices page={noticesConfig.pages.LOGIN.name} />
        <div className={styles.login}>
          <div className="row">
            <div className="col-lg-4 col-sm-6 col-lg-offset-4 col-sm-offset-3">
              {this.renderInputAndLabel(usernameParams)}
              {this.renderInputAndLabel(passParams)}
              <ResetYourPassword />
              <div className="row">
                <div className={`${styles.loginButton}`}>
                  <Button
                    id="loginSubmitButton"
                    isLoading={isPosting}
                    isDisabled={isPosting}
                    isPrimary
                    type="submit"
                  >
                    <Translate id="submitButton" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }

  renderOTP() {
    const { handleSubmit, loginForm, setFieldValue } = this.props;

    if (!loginForm?.verificationId) return null;

    return (
      <OTP
        verificationMessage={getVerificationMessage()}
        verificationId={loginForm.verificationId}
        guestToken={loginForm.guestToken}
        locale={getLocaleFromLocalStorage()}
        customerId={loginForm.customerId}
        userId={loginForm.userId}
        onUserVerified={() => {
          this.isOtpVerified = true;
          toggleLoginOTPVerification({});
          setFieldValue('verificationId', loginForm.verificationId);
          handleSubmit();
        }}
        onUserVerificationFailed={() => {
          trackEvent({ ...EVENTS.LOGIN_FAILED, customAttribute1: loginForm.customerId });
        }}
        trackedEvents={{
          requested: LOGIN_MTAN_REQUESTED,
          resent: LOGIN_MTAN_RESENT,
          submitted: LOGIN_MTAN_SUBMITTED,
          verified: LOGIN_MTAN_VERIFIED,
          invalid: LOGIN_MTAN_INVALID,
          expired: LOGIN_MTAN_EXPIRED,
          blocked: LOGIN_MTAN_BLOCKED,
          failed: LOGIN_MTAN_FAILED,
        }}
      />
    );
  }

  renderHeader() {
    return (
      <div className={`text-center ${styles.loginHeader}`}>
        <Heading level={2}>
          <Translate id="heading" />
        </Heading>
      </div>
    );
  }

  renderFooter() {
    const locale = getCountryByLocalStorageLocale();
    const relevantLink = this.getBottomComponentByLocale(locale);

    return (
      <div className={styles.loginBottom}>
        <Text>
          <b>
            <Translate id="loginBottom" />
          </b>
        </Text>
        <br />
        {relevantLink.component(relevantLink.link, this.props)}
      </div>
    );
  }

  render() {
    return (
      <TranslationsProvider
        translations={{
          de: () => import('./__translations__/de.json'),
          es: () => import('./__translations__/es.json'),
          fr: () => import('./__translations__/fr.json'),
          nl: () => import('./__translations__/nl.json'),
          'en-US': () => import('./__translations__/en-US.json'),
          'en-GB': () => import('./__translations__/en-GB.json'),
          'en-IE': () => import('./__translations__/en-IE.json'),
          'pl-PL': () => import('./__translations__/pl-PL.json'),
          'fi-FI': () => import('./__translations__/fi-FI.json'),
        }}
        id="login-page"
      >
        <div className={styles.loginPageWrapper}>
          {this.renderHeader()}

          {this.renderCBNotice()}

          <hr className={styles.lineBreak} />

          {this.renderForm()}

          {this.renderOTP()}

          <hr className={styles.lineBreak} />

          {this.renderFooter()}
        </div>
      </TranslationsProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.customer.login,
  loginForm: state.customer.loginForm,
  isPosting: state.ui.isPosting,
  featureFlags: state.global.featureFlags,
});

const mapDispatchToProps = (dispatch) => ({
  postCustomerLoginCredentials: (data) => dispatch(postCustomerLoginCredentials(data)),
  authenticateWithKeycloak: (data) => dispatch(authenticateWithKeycloak(data)),
  fetchAuthConfiguration: (base64Email) => dispatch(fetchAuthConfiguration(base64Email)),
  fetchCustomerProductAccess: () => dispatch(fetchCustomerProductAccess()),
  setCustomerData: (data) => dispatch(setCustomerData(data)),
  toggleLoginOTPVerification: (data) => dispatch(toggleLoginOTPVerification(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLoginForm(Login)));
