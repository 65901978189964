import React, { Component } from 'react';
import PropTypes from 'prop-types';
import constants from 'ui-lib/src/constants';
import Alert from 'ui-lib/src/components/Alert';
import Paragraph from 'ui-lib/src/components/Paragraph';
import { Translate } from 'components/I18n';
import styles from './styles.scss';

export default class OrderFreezeExplain extends Component {
  static propTypes = {
    history: PropTypes.object,
  };

  render() {
    const { isDWMLegacyMigrationInProgress } = this.props;
    const { alerts } = constants;

    if (isDWMLegacyMigrationInProgress) {
      return (
        <Alert
          type={alerts.info}
          key="alert-orderFreezeExplain"
          className="orderFreezeExplainNotice"
          fullWidth
          showDefault
        >
          <Paragraph>
            <strong>
              <Translate id="alert.orderFreezeExplain.heading" />
            </strong>
          </Paragraph>
          <Paragraph className={styles.alert}>
            <Translate id="alert.orderFreezeExplain.body" />
          </Paragraph>
        </Alert>
      );
    }

    return null;
  }
}
