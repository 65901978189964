import jwtDecode from 'jwt-decode';
import store from 'store2';

import { getJwtForMobile, AUTH_TOKEN_KEY } from 'utils/CustomerUtils';

export const AUTH_CONFIG_KEY = 'auth_config';
export const AUTH_TIME_DIFF_KEY = 'auth_time_diff';
export const AUTH_CONFIG_DONE_KEY = 'auth_config_done';

export const storeAuthConfig = (config) => {
  if (config === null || config === 0) {
    store.remove(AUTH_CONFIG_KEY);

    return;
  }
  store(AUTH_CONFIG_KEY, config);
};

export const storeAuthToken = (token) => {
  store(AUTH_TOKEN_KEY, token);
  if (token) {
    try {
      const { iat } = jwtDecode(token.access_token);
      const timeDiff = Date.now() / 1000 - iat;

      store(AUTH_TIME_DIFF_KEY, timeDiff);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('unable to get jwt issue time to calculate time diff');
    }
  }
};

export const hasAuthConfig = () => {
  return store.has(AUTH_CONFIG_KEY);
};

export const hasAuthToken = () => {
  return store.has(AUTH_TOKEN_KEY);
};

export const retrieveAuthConfig = () => {
  return store(AUTH_CONFIG_KEY);
};

export const retrieveAuthToken = () => {
  return store(AUTH_TOKEN_KEY);
};

export const isAuthConfigDone = () => {
  return !!getJwtForMobile() || store(AUTH_CONFIG_DONE_KEY);
};

export const storeAuthConfigDone = (isDone) => {
  return store(AUTH_CONFIG_DONE_KEY, isDone);
};

export const trimUrl = (str) => {
  if (!str) return '';

  return str.endsWith('/') ? str.slice(0, -1) : str;
};
