import React, { useContext } from 'react';

import Alert from 'ui-lib/src/components/Alert';

import { Translate } from 'components/I18n';

import * as TRANSLATION_KEYS from './__translations__';
import { OTPContext } from './OTPProvider';

export const OTPAlert = () => {
  const { error, info, isBlocked, isLoading, isValidated } = useContext(OTPContext);

  if (isValidated || isLoading) return null;

  if (isBlocked)
    return (
      <Alert showDefault showIcon type="warning">
        <Translate id={TRANSLATION_KEYS.USER_BLOCKED_ERR_DESC} />
      </Alert>
    );

  if (error)
    return (
      <Alert showDefault showIcon type="danger">
        {error}
      </Alert>
    );

  if (info)
    return (
      <Alert showDefault showIcon type="info">
        {info}
      </Alert>
    );

  return null;
};
