import { getBasePath, getUrl } from '../utils';

export const HAS_FIXED_DEPOSIT_ACCESS = 'HAS_FIXED_DEPOSIT_ACCESS';
export const HAS_FIXED_DEPOSIT_ACCOUNT = 'HAS_FIXED_DEPOSIT_ACCOUNT';
export const HAS_FLEX_DEPOSIT_ACCESS = 'HAS_FLEX_DEPOSIT_ACCESS';
export const HAS_FLEX_DEPOSIT_ACCOUNT = 'HAS_FLEX_DEPOSIT_ACCOUNT';
export const HAS_OVERNIGHT_DEPOSIT_ACCESS = 'HAS_OVERNIGHT_DEPOSIT_ACCESS';
export const HAS_OVERNIGHT_DEPOSIT_ACCOUNT = 'HAS_OVERNIGHT_DEPOSIT_ACCOUNT';

export const getFinMenus = ({
  customer,
  shouldShowUpdatedProductURLs,
  hasB2cDeprecateObsFeatureFlag,
}) => {
  const { productAccess } = customer;

  const basePath = getBasePath();

  const myInvestments = {
    id: 'myInvestments',
    title: 'Omat sijoitukseni',
    href: '',
    subMenus: {
      layout: 'even',
      top: [
        {
          id: 'dashboard-myInvestments',
          type: 'basic',
          title: 'Yleiskatsaus',
          href: getUrl(`/#/Dashboard`),
          icon: 'overviewIcon',
          iconColor: '#0E6CC5',
        },
      ],
      main: [
        (productAccess.includes(HAS_FIXED_DEPOSIT_ACCOUNT) ||
          productAccess.includes(HAS_FLEX_DEPOSIT_ACCOUNT)) && {
          id: 'fixed-deposit',
          type: 'basic',
          title: 'Määräaikaistalletukset',
          href: getUrl(`/#/MyInvestments/TermDeposit`),
          icon: 'fixedDepositIcon',
          iconColor: '#61AFD2',
        },
        productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCOUNT) && {
          id: 'overnight-deposit',
          type: 'basic',
          title: 'Päiväraha',
          href: getUrl('/#/MyInvestments/Overnight'),
          icon: 'overnightMoneyIcon',
          iconColor: '#9CD3E4',
        },
      ],
    },
  };

  const offers = {
    id: 'offers',
    title: 'Meidän tarjoukset',
    href: '',
    subMenus: {
      main: [
        (productAccess.includes(HAS_FIXED_DEPOSIT_ACCESS) ||
          productAccess.includes(HAS_FLEX_DEPOSIT_ACCESS) ||
          hasB2cDeprecateObsFeatureFlag) && {
          id: 'fixedDeposit-offers',
          type: 'advanced',
          title: 'Määräaikaistalletukset',
          description: 'Hyödynnä kilpailukykyinen kiinteä korko valitulle ajanjaksolle',
          href: shouldShowUpdatedProductURLs ?  '/fi-fi/products/' : getUrl(`/#/Products`),
          icon: 'fixedDepositIcon',
          iconColor: '#61AFD2',
        },
        productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCESS) && {
          id: 'overnightDeposit-offers',
          type: 'advanced',
          title: 'Päiväraha',
          href: shouldShowUpdatedProductURLs
            ? '/products/overnight/'
            : getUrl('/#/Products/overnight'),
          description: 'Joustava sijoitus ilman määräaikaa ja vaihtuvakorkoisella',
          icon: 'overnightMoneyIcon',
          iconColor: '#9CD3E4',
          groupName: 'Sparen',
        },
      ],
    },
  };

  const information = {
    id: '3f11a01f-4216-4fea-b05f-2fd46cf3b8b4',
    title: 'O Raisin',
    href: '',
    subMenus: {
      main: [
        {
          id: '9d4v781e-7180-4618-b581-38951a8b5f547',
          type: 'text',
          title: 'Meistä',
          href: `${basePath}/o-nas/`,
        },
        {
          id: '9d4g882x-b191-46s8-b581-42551an8uf824',
          type: 'text',
          title: 'Tulla kumppaniksi',
          href: `${basePath}/zostan-partnerem/`,
        },
        {
          id: '9d4g882x-b191-47s8-b581-426561an7uf824',
          type: 'text',
          title: 'Ota yhteyttä',
          href: `${basePath}/kontakt/`,
        },
      ],
    },
  };

  const help = {
    id: '7d61gcdc-4e33-4fe2-9c58-878a2r7534c6',
    title: 'Tietopohja',
    href: '',
    subMenus: {
      main: [
        {
          id: '9d7v781e-728o-46i8-bs81-38951a1b2547',
          type: 'advanced',
          title: 'FAQ',
          description: 'Etsi vastauksia usein kysyttyihin kysymyksiin',
          href: 'https://pomoc.raisin.com/hc/fi-fi',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt68453dc5db1e4d3a/FAQs.svg',
          iconColor: '#0E6CC5',
        },
        {
          id: '9d6h992y-2i91-56s8-b591-42721a7uf824',
          type: 'advanced',
          title: 'Talletussuojajärjestelmä',
          description: 'Kaikki Euroopan talletussuojajärjestelmästä',
          href: `${basePath}/system-gwarantowania-depozytow/`,
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt8e96e15c429c928b/Einlagensicherung.svg',
          iconColor: '#0E6CC5',
        },
        {
          id: '9d6g8810z-b100-4s78-b581-42551anvuf824',
          type: 'advanced',
          title: 'Verotiedot',
          description: 'Tietoja talletusten verotuksesta',
          href: `${basePath}/informacje-podatkowe/`,
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt36b69e61c2b0a979/Steuerinformation.svg',
          iconColor: '#0E6CC5',
        },
      ],
    },
  };

  return [myInvestments, offers, information, help];
};



