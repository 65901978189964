import React, { Component } from 'react';
import PropTypes from 'prop-types';
import constants from 'ui-lib/src/constants';
import Alert from 'ui-lib/src/components/Alert';
import Paragraph from 'ui-lib/src/components/Paragraph';
import { Translate } from 'components/I18n';
import styles from './styles.scss';

export default class WaitingTimeNotice extends Component {
  static propTypes = {
    history: PropTypes.object,
  };

  render() {
    const {
      history: {
        location: { pathname },
      },
    } = this.props;
    const { alerts } = constants;

    if (pathname.endsWith('confirm')) {
      let flowName = null;

      if (pathname.startsWith('/InvestmentProducts/buy')) {
        flowName = 'buy';
      } else if (pathname.startsWith('/InvestmentProducts/sell')) {
        flowName = 'sell';
      }
      if (flowName) {
        return (
          <Alert
            type={alerts.info}
            key="alert-waitingTime"
            className="waitingTimeNotice"
            showDefault
          >
            <Paragraph className={styles.alert}>
              <Translate id={`alert.${flowName}.waitingTime`} />
            </Paragraph>
          </Alert>
        );
      }
    }

    return null;
  }
}
