import { getUrl } from '../utils';

export const HAS_FIXED_DEPOSIT_ACCESS = 'HAS_FIXED_DEPOSIT_ACCESS';
export const HAS_FIXED_DEPOSIT_ACCOUNT = 'HAS_FIXED_DEPOSIT_ACCOUNT';
export const HAS_FLEX_DEPOSIT_ACCESS = 'HAS_FLEX_DEPOSIT_ACCESS';
export const HAS_FLEX_DEPOSIT_ACCOUNT = 'HAS_FLEX_DEPOSIT_ACCOUNT';
export const HAS_INVESTMENT_PRODUCT_ACCESS = 'HAS_INVESTMENT_PRODUCT_ACCESS';
export const HAS_INVESTMENT_PRODUCT_ACCOUNT = 'HAS_INVESTMENT_PRODUCT_ACCOUNT';
export const HAS_NOTICE_DEPOSIT_ACCESS = 'HAS_NOTICE_DEPOSIT_ACCESS';
export const HAS_NOTICE_DEPOSIT_ACCOUNT = 'HAS_NOTICE_DEPOSIT_ACCOUNT';
export const HAS_OVERNIGHT_DEPOSIT_ACCESS = 'HAS_OVERNIGHT_DEPOSIT_ACCESS';
export const HAS_OVERNIGHT_DEPOSIT_ACCOUNT = 'HAS_OVERNIGHT_DEPOSIT_ACCOUNT';

export const getFrMenus = ({
  customer,
  shouldShowUpdatedProductURLs,
  hasB2cDeprecateObsFeatureFlag,
}) => {
  const { productAccess } = customer;

  const myInvestments = {
    id: 'myInvestments',
    title: 'Mes comptes',
    href: '',
    subMenus: {
      layout: 'even',
      top: [
        {
          id: 'dashboard-myInvestments',
          type: 'basic',
          title: 'Tableau de bord',
          href: getUrl(`/#/dashboard`),
          icon: 'overviewIcon',
          iconColor: '#0E6CC5',
        },
      ],
      main: [
        (productAccess.includes(HAS_FIXED_DEPOSIT_ACCOUNT) ||
          productAccess.includes(HAS_FLEX_DEPOSIT_ACCOUNT)) && {
          id: 'fixed-deposit',
          type: 'basic',
          title: 'Mes comptes à terme',
          href: getUrl(`/#/MyInvestments/TermDeposit`),
          icon: 'fixedDepositIcon',
          iconColor: '#61AFD2',
        },
        productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCOUNT) && {
          id: 'overnight-deposit',
          type: 'basic',
          title: `Mes livrets d'épargne`,
          href: getUrl(`/#/MyInvestments/Overnight`),
          icon: 'overnightMoneyIcon',
          iconColor: '#9CD3E4',
        },
      ],
    },
  };

  const offers = {
    id: 'offers',
    title: 'Les offres',
    href: '',
    subMenus: {
      layout: 'even',
      main: [
        (productAccess.includes(HAS_FIXED_DEPOSIT_ACCESS) ||
          productAccess.includes(HAS_FLEX_DEPOSIT_ACCESS) ||
          hasB2cDeprecateObsFeatureFlag) && {
          id: 'fixedDeposit-offers',
          type: 'advanced',
          title: 'Comptes à terme',
          href: shouldShowUpdatedProductURLs ? '/products/' : getUrl(`/#/Products`),
          description: `Placement à durée déterminée et à un taux d'intérêt fixe`,
          icon: 'fixedDepositIcon',
          iconColor: '#61AFD2',
        },
        (productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCESS) || hasB2cDeprecateObsFeatureFlag) && {
          id: 'overnightDeposit-offers',
          type: 'advanced',
          title: `Livrets d'épargne`,
          href: shouldShowUpdatedProductURLs
            ? '/products/overnight/'
            : getUrl(`/#/Products/overnight`),
          description: `Placement flexible à durée indéterminée et à un taux d'intérêt variable`,
          icon: 'overnightMoneyIcon',
          iconColor: '#9CD3E4',
        },
      ],
    },
  };

  const information = {
    id: 'information',
    title: `En savoir plus`,
    href: '',
    subMenus: {
      main: [
        {
          id: 'savings-finance',
          type: 'advanced',
          title: "Guide de l'épargne",
          description: 'Un guide intégral pour vous aider à mieux gérer votre épargne',
          href: '/epargne/',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/bltf0001a4dd44c52d4/Guide_de_l_Épargne.svg',
          iconColor: '#0E6CC5',
        },
        {
          id: 'investments-finance',
          type: 'advanced',
          title: 'Garantie des dépôts',
          description: 'Comprendre le système de garantie des dépôts au sein de l’Union Européenne',
          href: '/garantie-des-depots/',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/bltec92767c887b11af/Garantie_des_dépôts.svg',
          iconColor: '#0E6CC5',
        },
        {
          id: '4a17825e-a704-457c-945f-f1b45c50b29e',
          type: 'advanced',
          title: 'Banques partenaires',
          description: 'En savoir plus sur nos banques partenaires',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt017f3eb0e4c93396/Bancos.svg',
          iconColor: '#0E6CC5',
          href: '/banque/',
        },
        {
          id: 'pension-finance',
          type: 'advanced',
          title: 'Fiscalité',
          description:
            'Comment vos placements auprès de nos différentes banques partenaires sont-ils imposés',
          href: '/fiscalite/',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt21cf5223c1caa2fe/Fiscalité.svg',
          iconColor: '#0E6CC5',
        },
      ],
    },
  };

  const help = {
    id: '2e11a01f-4216-4fea-b05f-2fd46cf3a8b4',
    title: 'Aide',
    href: '',
    subMenus: {
      main: [
        {
          id: 'a9cbf2ad-81bc-4f83-8196-b67b414f9b0f',
          type: 'text',
          title: 'Questions fréquentes',
          href: 'https://assistance.raisin.fr/hc/fr/',
        },
        {
          id: '9a91930e-db2a-45be-8eab-30a081e40e03',
          type: 'text',
          title: 'Contact',
          href: '/contactez-nous/',
        },
      ],
    },
  };

  return [myInvestments, offers, information, help];
};
