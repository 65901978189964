import { handle } from 'redux-pack';
import { initialState } from '../selectors';
import * as types from '../../actionTypes';

export default (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case types.FETCH_PENSION_CUSTOMER_TOKEN:
      return handle(state, action, {
        success: (prevState) => ({
          ...prevState,
          ...payload.data,
        }),
      });
    case types.CHANGE_PENSION_PRODUCT_TYPE:
      return {
        ...state,
        type: payload,
      };
    case types.CHANGE_PP_TARGET_LINK:
      return {
        ...state,
        targetLink: payload,
      };
    case types.POST_PENSION_CUSTOMER_SHARE_SESSION:
      return handle(state, action, {
        success: (prevState) => ({
          ...prevState,
          raisid: payload.data.token,
        }),
      });
    default:
      return state;
  }
};
