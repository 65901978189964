import { handle } from 'redux-pack';
import { initialState } from '../selectors';
import * as types from '../../actionTypes';

export default (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case types.FETCH_CMS_PAGE_CONTENT:
      return handle(state, action, {
        success: (prevState) => ({
          ...prevState,
          cmsContent: {
            ...payload.data.cms_content,
          },
        }),
      });
    default:
      return state;
  }
};
