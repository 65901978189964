import React, { Component } from 'react';
import PropTypes from 'prop-types';
import constants from 'ui-lib/src/constants';
import Alert from 'ui-lib/src/components/Alert';
import Paragraph from 'ui-lib/src/components/Paragraph';
import { Translate, LocalizeConsumer } from 'components/I18n';

export default class SavingsPlanDebitDayNotice extends Component {
  static propTypes = {
    notices: PropTypes.object.isRequired,
  };

  render() {
    const { notices } = this.props;
    const { alerts } = constants;

    if (notices && notices.savings_plan) {
      const {
        debit_day,
        amount: { value, currency },
      } = notices.savings_plan;

      return (
        <LocalizeConsumer>
          {(ctx) => (
            <Alert
              type={alerts.info}
              key="alert-savingsPlanDebitDayNotice"
              className="savingsPlanDebitDayNotice"
              showDefault
            >
              <Paragraph>
                <Translate
                  id="alert.savingsPlanDebitDay.text"
                  values={{
                    amount: ctx.localizeMoney(value, {
                      currencyDisplay: 'code',
                      minimumFractionDigits: 2,
                      currency,
                    }),
                    day: debit_day,
                  }}
                />
              </Paragraph>
            </Alert>
          )}
        </LocalizeConsumer>
      );
    }

    return null;
  }
}
