import Keycloak from 'keycloak-js';

import { storeAuthToken, trimUrl } from 'utils/Auth/index';
import { getLocaleFromLocalStorage } from 'utils/Locale';
import { reportError } from 'utils/ErrorHandling';

import { getRefreshToken, isRunningLocally } from './utils';

export class KeycloakAuth {
  config = {};

  Keycloak = null;

  setConfig(config) {
    const { clientId, realm, scope, keycloakUrl, realmPath, tokenEndpoint, logoutEndpoint } =
      config;

    const runningLocally = isRunningLocally();
    const localKeycloakUrl = process.env.KEYCLOAK_ENDPOINT_URI || '/__mocks__';
    const localKeycloakTokenEndpoint = process.env.KEYCLOAK_ENDPOINT_URI
      ? `${trimUrl(localKeycloakUrl)}/auth/realms/${realm}/protocol/openid-connect/token`
      : `${localKeycloakUrl}/keycloakToken.json`;
    const localKeycloakLogoutEndpoint = process.env.KEYCLOAK_ENDPOINT_URI
      ? `${trimUrl(localKeycloakUrl)}/auth/realms/${realm}/protocol/openid-connect/logout`
      : `${localKeycloakUrl}/keycloakLogout.json`;

    const tokenEndpointToSet = runningLocally ? localKeycloakTokenEndpoint : tokenEndpoint;
    const keycloakUrlToSet = runningLocally ? localKeycloakUrl : keycloakUrl;
    const scopeToSet = runningLocally ? { scope: scope || 'openid' } : {};
    const logoutEndpointToSet = runningLocally ? localKeycloakLogoutEndpoint : logoutEndpoint;

    this.config = {
      keycloakUrl: keycloakUrlToSet,
      clientId,
      realm,
      realmPath,
      tokenEndpoint: tokenEndpointToSet,
      logoutEndpoint: logoutEndpointToSet,
      ...{ scopeToSet },
    };
  }

  requestOptions(verificationId) {
    const request = {};

    request.headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Locale: getLocaleFromLocalStorage(),
    };
    request.method = 'POST';
    request.mode = 'cors';

    if (verificationId) {
      request.headers['Verification-ID'] = verificationId;
    }

    return request;
  }

  async directGrant({ username, password, verificationId }) {
    const body = {
      username,
      password,
      grant_type: 'password',
      client_id: this.config.clientId,
    };
    const formBody = Object.keys(body)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`)
      .join('&');

    let request;

    if (isRunningLocally() && !process.env.KEYCLOAK_ENDPOINT_URI) {
      request = fetch(this.config.tokenEndpoint, {
        method: 'GET',
      });
    } else {
      request = fetch(this.config.tokenEndpoint, {
        ...this.requestOptions(verificationId),
        body: formBody,
        credentials: 'same-origin',
      });
    }

    return request
      .then(async (response) => {
        let token;

        if (response.status === 200) {
          token = await response.json();
        } else if (response.status === 202) {
          return {
            headers: response.headers,
            status: response.status,
          };
        } else {
          const errorJson = await response.json();

          return Promise.reject(
            new Error(errorJson.error_description, { cause: { code: errorJson.error } }),
          );
        }

        this.persistToken(token);
        this.Keycloak = new Keycloak({
          url: `${trimUrl(this.config.keycloakUrl)}/auth/`,
          clientId: this.config.clientId,
          realm: this.config.realm,
        });

        return {
          token,
          headers: response.headers,
          status: response.status,
        };
      })
      .catch((err) => {
        reportError(err.message, err);

        return Promise.reject(new Error(err.message, err));
      });
  }

  keycloakLogout = async (refreshToken) => {
    let refreshTokenToUse = refreshToken;

    if (!refreshTokenToUse) {
      refreshTokenToUse = getRefreshToken();
    }
    const body = {
      client_id: this.config.clientId,
      refresh_token: refreshTokenToUse,
    };
    const formBody = Object.entries(body)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');

    try {
      if (isRunningLocally() && !process.env.KEYCLOAK_ENDPOINT_URI) {
        return await fetch(this.config.logoutEndpoint, {
          method: 'GET',
        });
      }

      const response = await fetch(this.config.logoutEndpoint, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'POST',
        mode: 'cors',
        body: formBody,
      });

      if (!response.ok) {
        const errorJson = await response.json();

        return Promise.reject(
          new Error(errorJson.error_description, { cause: { code: errorJson.error } }),
        );
      }

      return response;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Logout with KC failed', err);
      reportError(err.message, err);

      return Promise.reject(new Error(err.message, err));
    }
  };

  persistToken(token) {
    storeAuthToken(token);
  }

  refreshToken() {
    this.Keycloak && this.Keycloak.refreshToken(30);
  }
}

export const KeycloakAuthFactory = (() => {
  let instance;

  return {
    getInstance() {
      if (instance == null) {
        instance = new KeycloakAuth();
        // Hide the constructor so the returned object can't be new'd...
        instance.constructor = null;
      }

      return instance;
    },
  };
})();
