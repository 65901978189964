import { ajaxStatus } from '../../../config';

export const initialState = {
  currency: 'EUR',
  academic_title: '',
  bac_number: '',
  account_holder_name: '',
  email: '',
  first_name: '',
  last_name: '',
  display_name: '',
  company_customer_name: '',
  phone_number_anonymized: '',
  is_company_customer: false,
  isEligibleForInvestmentProducts: true,
  tax_id_number: undefined,
  transactional_emails_enabled: false,
  mail_consents: {
    savings: false,
    investments: false,
    pensions: false,
    cs: false,
  },
  isMailSubscribed: true,
  signature_method: 'M_TAN',
  region: '',
  identification_date: '',
  state: '',
  default_address: {
    country: '',
    street: '',
    additional_info: '',
    street_no: '',
    city: '',
    postal_code: '',
  },
  referAFriendForm: {
    status: ajaxStatus.notPosted,
    error: null,
  },
  contactDataForm: {
    status: ajaxStatus.notPosted,
    error: null,
  },
  passwordForm: {
    status: ajaxStatus.notPosted,
    error: null,
  },
  login: {
    username: '',
    password: '',
  },
  loginForm: {
    status: ajaxStatus.notPosted,
    error: null,
  },
  mTanForm: {
    status: ajaxStatus.notPosted,
    error: null,
  },
  referenceAccountMTanForm: {
    status: ajaxStatus.notPosted,
    error: null,
  },
  blockAccountForm: {
    status: ajaxStatus.notPosted,
    error: null,
  },
  customerContactDataMtanForm: {
    status: ajaxStatus.notPosted,
    error: null,
  },
  customerPasswordMtanForm: {
    status: ajaxStatus.notPosted,
    error: null,
  },
  customerMTanBlockForm: {
    status: ajaxStatus.notPosted,
    error: null,
  },
  referenceAccountInitialForm: {
    status: ajaxStatus.notPosted,
    error: null,
  },
  idnowSms: {
    status: ajaxStatus.notPosted,
    mobileNumber: '',
  },
  depositAccountOrderRevokeForm: {
    status: ajaxStatus.notPosted,
    error: null,
  },
  mailDataForm: {
    status: ajaxStatus.notPosted,
    error: null,
  },
  nationalIdentifiersForm: {
    status: ajaxStatus.notPosted,
    error: null,
  },
  customerPayoutMtanForm: {
    status: ajaxStatus.notPosted,
    error: null,
  },
  applicationDocumentForm: {
    status: ajaxStatus.notPosted,
    error: null,
  },
  session: {
    error: null,
    status: null,
    nonce: null,
    mtan: null,
  },
  referenceAccount: {
    iban: '',
    account_holder: '',
    bic_bank_code: '',
    bank_name: '',
    sort_code: '',
    account_number: '',
  },
  tamsTransactionAccount: {
    iban: '',
    accountNumber: '',
  },
  tamsError: null,
  documents: {
    identification: {
      state: '',
      upload_time: '',
    },
    post_ident_document: {
      state: '',
      upload_time: '',
    },
    proof_of_address: {
      state: '',
      upload_time: '',
    },
  },
  deposit: {
    deposit_number: '',
    partner_bank_bic: '',
    balance: '',
    partner_bank_name: '',
    partner_bank_withholding_tax_process_type: '',
    tax_info: {
      tax_rate: 0,
      tax_reduced_rate: 0,
      tax_template_path: '',
      tax_requirements: [
        {
          status: '',
          earliest_submission_date: '',
          lastest_submission_date: '',
          document: {
            sent_date: '',
            document_link: '',
          },
        },
      ],
    },
    product_offer: {
      currency_code: '',
    },
    parameters: {
      savings_plan_enabled: false,
    },
  },
  revoke: {
    deposit_number: '',
    deposit_state: '',
    partner_bank_country_name: '',
    partner_bank_name: '',
    partner_bank_withholding_tax_process_type: '',
  },
  investments: {
    term_deposits: {
      current_deposits: {
        deposits: [],
        deposited_amounts: {},
      },
      historical_deposits: {
        deposits: [],
        deposited_amounts: {},
      },
      pending_deposits: {
        deposits: [],
        deposited_amounts: {},
      },
      notices: {},
    },
    overnight_deposits: {
      current_deposits: {
        deposits: [],
        deposited_amounts: {},
      },
      historical_deposits: {
        deposits: [],
        deposited_amounts: {},
      },
      pending_deposits: {
        deposits: [],
        deposited_amounts: {},
      },
      notices: {},
    },
    notice_deposits: {
      current_deposits: {
        deposits: [],
        deposited_amounts: {},
      },
      historical_deposits: {
        deposits: [],
        deposited_amounts: {},
      },
      pending_deposits: {
        deposits: [],
        deposited_amounts: {},
      },
      notices: {},
    },
    ip_accounts: [],
  },
  authConfiguration: null,
  available_countries: [],
  // Any temporary data supposed to be displayed before confirmation is sent,
  // using mTan for instance.
  identification: {
    brief_report_exists: false,
    idnow_live: false,
    idnow_office_open: false,
    mobile_number: '',
    process_id: '',
    webcam_session_url: '',
  },
  transient_data: {
    withdrawalFormInput: {
      amount: 0,
      purpose: '',
      execution_date: '',
    },
  },
  mailbox: {
    all_data: {},
    documents: {},
    inbox: {},
    sent: {},
    current_message: {},
    unreadMessagesCount: 0,
  },
  branding: {
    is_header_active: false,
  },
  nationalIdentifiers: [],
  productAccess: [],
  productTypes: null,
  pomProducts: {},
  isLoggedIn: false,
  setPasswordForm: {
    status: ajaxStatus.notPosted,
    error: null,
  },
  validity: {
    status: '',
    lastValidDate: '',
    resubmissionDate: '',
  },
  businessCustomerValidityPost: {
    status: ajaxStatus.notPosted,
    error: null,
  },
  casCustomerData: null,
  kciCustomerKYCStatus: null,
  kssCustomerKYCStatus: null,
  mergedWithBIPS: false,
  mergedWithDTS: false,
  dasEndpointFailed: false,
  dtsTaxRequirements: [],
};

export const isMtan = (state) => state.customer.signature_method === 'M_TAN';

export const isVoiceCallEnabled = (state) => {
  return state.customer.enabled_features.includes('voice_call_active');
};

export const selectDbsDepositProduct = (state, depositNumber) => {
  return state.customer.dbsDeposits?.[depositNumber]?.product || {};
};

export const selectDbsDepositAvailableActions = (state, depositNumber) => {
  return state.customer.dbsDeposits?.[depositNumber]?.available_actions || {};
};

export const selectDbsDepositCustomerJobs = (state, depositNumber) => {
  return state.customer.dbsDeposits?.[depositNumber]?.customer_jobs || {};
};
