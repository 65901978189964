import {
  isAUTCustomer,
  isDEUCustomer,
  isESPCustomer,
  isFRACustomer,
  isGBRCustomer,
  isIRLCustomer,
  isNLDCustomer,
  isPOLCustomer,
  isFINCustomer,
} from 'utils/CustomerUtils';

import {
  getAuMenus,
  getComMenus,
  getDeMenus,
  getEsMenus,
  getFrMenus,
  getGbrMenus,
  getIrlMenus,
  getNlMenus,
  getPolMenus,
  getFinMenus,
} from './countryMenus';

export const getNavItems = (props) => {
  const { customer } = props;

  switch (true) {
    case isDEUCustomer(customer):
      return getDeMenus(props);
    case isAUTCustomer(customer):
      return getAuMenus(props);
    case isNLDCustomer(customer):
      return getNlMenus(props);
    case isGBRCustomer(customer):
      return getGbrMenus(props);
    case isESPCustomer(customer):
      return getEsMenus(props);
    case isIRLCustomer(customer):
      return getIrlMenus(props);
    case isFRACustomer(customer):
      return getFrMenus(props);
    case isPOLCustomer(customer):
      return getPolMenus(props);
    case isFINCustomer(customer):
      return getFinMenus(props);
    default:
      return getComMenus(props);
  }
};
