import PropTypes from 'prop-types';

export const TrackingEventPropTypes = PropTypes.shape({
  eventName: PropTypes.string,
  object: PropTypes.string,
  action: PropTypes.string,
  portal: PropTypes.string,
  description: PropTypes.string,
  boundedContext: PropTypes.string,
  zone: PropTypes.string,
  context: PropTypes.string,
  component: PropTypes.string,
  customerGroup: PropTypes.string,
  customAttribute1: PropTypes.string,
  customAttribute2: PropTypes.string,
  customAttribute3: PropTypes.string,
  customAttribute4: PropTypes.string,
  customAttribute5: PropTypes.string,
  customAttribute6: PropTypes.string,
  customAttribute7: PropTypes.string,
  customAttribute8: PropTypes.string,
  customAttribute9: PropTypes.string,
});

export const OTPTrackingEventsPropTypes = PropTypes.shape({
  requested: TrackingEventPropTypes,
  resent: TrackingEventPropTypes,
  submitted: TrackingEventPropTypes,
  verified: TrackingEventPropTypes,
  expired: TrackingEventPropTypes,
  invalid: TrackingEventPropTypes,
  blocked: TrackingEventPropTypes,
});

export const OTPPropTypes = {
  verificationMessage: PropTypes.string.isRequired,
  verificationId: PropTypes.string.isRequired,
  guestToken: PropTypes.string.isRequired,
  description: PropTypes.string,
  locale: PropTypes.string.isRequired,
  onUserVerified: PropTypes.func.isRequired,
  onUserVerificationFailed: PropTypes.func,
  trackedEvents: OTPTrackingEventsPropTypes,
};
