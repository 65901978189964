export const PHONE_AND_EMAIL = {
  AUT: {
    business: {
      email: 'geschaeftskunden@weltsparen.at',
      phone: '0720 205 047',
    },
    retail: {
      email: 'kundenservice@weltsparen.at',
      phone: '0720 205 047',
    },
  },
  DEU: {
    business: {
      email: 'geschaeftskunden@weltsparen.de',
      phone: '030 21 784 002',
    },
    retail: {
      email: 'kundenservice@weltsparen.de',
      phone: '030 21 784 002',
    },
  },
  ESP: {
    business: {
      email: 'servicio@raisin.es',
      phone: '91 769 37 80',
    },
    retail: {
      email: 'servicio@raisin.es',
      phone: '91 769 37 80',
    },
  },
  EUR: {
    business: {
      email: 'geschaeftskunden@weltsparen.de',
      phone: '+49 30 770 191 295',
    },
    retail: {
      email: 'service@raisin.com',
      phone: '+49 30 770 191 295',
    },
  },
  FRA: {
    business: {
      email: 'service@raisin.fr',
      phone: '01 85 65 36 94',
    },
    retail: {
      email: 'service@raisin.fr',
      phone: '01 85 65 36 94',
    },
  },
  GBR: {
    business: {
      email: 'service@raisin.co.uk',
      phone: '0161 6010000',
    },
    retail: {
      email: 'service@raisin.co.uk',
      phone: '0161 6010000',
    },
  },
  IRL: {
    business: {
      email: 'service@raisin.ie',
      phone: '+353 1 68 62 65 1',
    },
    retail: {
      email: 'service@raisin.ie',
      phone: '+353 1 68 62 65 1',
    },
  },
  NLD: {
    business: {
      email: 'klantenservice@raisin.nl',
      phone: '020 715 9296',
    },
    retail: {
      email: 'klantenservice@raisin.nl',
      phone: '020 715 9296',
    },
  },
  POL: {
    business: {
      email: '',
      phone: '',
    },
    retail: {
      email: '',
      phone: '',
    },
  },
  FIN: {
    business: {
      email: '',
      phone: '',
    },
    retail: {
      email: '',
      phone: '',
    },
  },
};
