import { ajaxStatus } from '../../../../config';

export const setBuyData = (state, payload) => {
  if (payload.mTanForm) {
    return {
      ...state,
      ...payload,
    };
  }

  return {
    ...state,
    ...payload,
    form: {
      status: ajaxStatus.notPosted,
      error: null,
    },
    mTanForm: {
      status: ajaxStatus.notPosted,
      error: null,
    },
  };
};
