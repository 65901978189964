import { stringify } from 'query-string';
import { getHost } from 'utils/PensionProducts';

export const openConfiguratorPage = (portfolio, utm_source) => {
  const params = stringify({ portfolio, utm_source });
  const baseUrl = `${getHost('invest')}/produkte/etf-configurator/rechner`;
  const fullUrl = params ? `${baseUrl}?${params}` : baseUrl;

  return window.location.assign(fullUrl);
};

export const configuratorNamespace = 'IP-Configurator';
