import { isDEURegion, isGBRCustomer, states } from 'utils/CustomerUtils';

import { getLocalizedMoney, getTranslation } from './utils';

export const getAccount = ({ customer, isCustomerActive, tamsTransactionAccount = {} }) => {
  const { iban, accountNumber, sortCode } = tamsTransactionAccount;

  if (!isCustomerActive) return [];

  return isGBRCustomer(customer)
    ? [
        {
          label: getTranslation('header.account.information.accountNumber'),
          id: accountNumber,
          size: 'small',
        },
        {
          label: getTranslation('header.account.information.sortCode'),
          id: sortCode,
          size: 'small',
        },
      ]
    : [
        {
          id: iban ?? undefined,
        },
      ];
};

export const getTotalBalance = (customer, transactionAccount) => {
  const isPendingActivation = customer?.state === states.ACTIVATION_PENDING;
  const { currencyCode = 'EUR', balance = { current: 0 } } = transactionAccount;
  const position = isDEURegion(customer) ? 'back' : 'front';
  const label = getTranslation('header.account.information.balance');

  if (isPendingActivation) {
    return {
      label,
      amount: getLocalizedMoney(0, currencyCode, position),
    };
  }

  return {
    label,
    amount: getLocalizedMoney(balance?.current, currencyCode, position),
    isNegative: balance?.current < 0,
  };
};

export const getAvailableBalance = (customer, transactionAccount) => {
  const isPendingActivation = customer?.state === states.ACTIVATION_PENDING;
  const { currencyCode = 'EUR', balance = { current: 0, available: 0 } } = transactionAccount;
  const position = isDEURegion(customer) ? 'back' : 'front';
  const label = getTranslation('header.account.information.onlineBalance');

  if (isPendingActivation) {
    return {
      label,
      amount: getLocalizedMoney(balance?.current, currencyCode, position),
    };
  }

  return {
    label,
    amount: getLocalizedMoney(balance?.available, currencyCode, position),
  };
};
