import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import LazyImage from 'ui-lib/src/components/Image';
import CircledImage from 'ui-lib/src/components/CircledImage';
import SVG from 'react-inlinesvg';
import config from '../../config';

export default class Image extends PureComponent {
  static propTypes = {
    src: PropTypes.string.isRequired,
    tinySrc: PropTypes.string,
    isCircled: PropTypes.bool,
    isBackgroundImage: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const { isCircled, isBackgroundImage, children, className } = this.props;
    const isSVG = this.props.src.includes('.svg');
    let src;
    let tinySrc;

    if (process.env.NODE_ENV === 'local') {
      src = require(`../../../assets/img/${this.props.src}`).default;
    }

    if (process.env.NODE_ENV === 'production') {
      src = `${config.imageBasePath}${this.props.src}`;
    }

    if (this.props.tinySrc && process.env.NODE_ENV === 'local') {
      tinySrc = require(`../../../assets/img/${this.props.tinySrc}`).default;
    }

    if (this.props.tinySrc && process.env.NODE_ENV === 'production') {
      tinySrc = `${config.imageBasePath}${this.props.tinySrc}`;
    }
    if (isSVG) {
      return <SVG {...this.props} src={src} />;
    } else if (isBackgroundImage) {
      return (
        <div className={className} style={{ backgroundImage: `url(${src})` }}>
          {children}
        </div>
      );
    } else if (isCircled) {
      return <CircledImage {...this.props} src={src} tinySrc={tinySrc} />;
    }

    return <LazyImage {...this.props} src={src} tinySrc={tinySrc} />;
  }
}
