import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import PublicLayout from 'components/PublicLayout';

export default class PublicRoute extends Component {
  render() {
    return (
      <PublicLayout>
        <Route {...this.props} />
      </PublicLayout>
    );
  }
}
