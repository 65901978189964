import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { TranslationsProvider, Translate } from 'components/I18n';
import List from 'ui-lib/src/components/List';
import Paragraph from 'ui-lib/src/components/Paragraph';
import Icon from 'ui-lib/src/components/Icon';
import Button from 'ui-lib/src/components/Button';
import ButtonLink from 'ui-lib/src/components/ButtonLink';
import { sendGtmTrackingEvent } from 'ui-lib/src/utils/GtmTracking';
import { changeUIState } from 'store/ui/actions';
import {
  fetchPensionCustomerToken,
  postPensionCustomerShareData,
} from 'store/pensionProducts/actions';
import Image from 'components/Image';
import { LINKS as MENU_LINKS } from 'components/Layout/menu';
import { LINKS } from 'utils/PensionProducts';
import styles from '../styles.scss';

export class ConnectAccountsModal extends PureComponent {
  static propTypes = {
    fetchPensionCustomerToken: PropTypes.func.isRequired,
    postPensionCustomerShareData: PropTypes.func.isRequired,
    token: PropTypes.string,
    changeUIState: PropTypes.func.isRequired,
    bacNumber: PropTypes.string.isRequired,
    location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  };

  state = {
    isLoading: false,
  };

  componentWillUnmount() {
    window.removeEventListener('message', this.handlePopup);
  }

  sendGtmEvent = (eventAction, eventLabel) => {
    const {
      bacNumber,
      location: { pathname },
    } = this.props;

    const eventCategories = {
      [MENU_LINKS.DASHBOARD]: 'obs-dashboard',
    };

    sendGtmTrackingEvent({
      eventCategory: eventCategories[pathname],
      eventAction,
      eventLabel,
      eventProperty: bacNumber,
      eventNonInteraction: false,
    });
  };

  closeModal = (e) => {
    if (e) {
      e.preventDefault();
      this.sendGtmEvent('close-connect-accounts-modal', 'connect-accounts-cancel');
    }
    this.props.changeUIState({ isPensionProductsConnectAccountsModalOpen: false });
  };

  onDataProtectionLinkClick = () => {
    this.sendGtmEvent('linkto-obs-about-privacy', 'data-sharing-privacy');
    window.open(`#/${MENU_LINKS.ABOUT.PRIVACY}`, '_blank');
  };

  handlePopup = (e) => {
    if (e && e.origin && e.origin.includes(LINKS.general)) {
      const response = JSON.parse(e.data);

      if (response.success) {
        this.handleSuccessfulResponse();
      }
    }
  };

  handleSuccessfulResponse = () => {
    const {
      location: { pathname },
      history,
    } = this.props;

    this.sendGtmEvent('linkto-external-fairr-connect-accounts', 'success-message');
    if (pathname === MENU_LINKS.DASHBOARD) {
      window.location.reload();
    } else {
      history.push(MENU_LINKS.DASHBOARD);
    }
    this.closeModal();
  };

  connectAccounts = (e) => {
    if (e) {
      e.preventDefault();
    }

    const { postPensionCustomerShareData, fetchPensionCustomerToken } = this.props;

    this.setState({ isLoading: true });
    this.sendGtmEvent('linkto-external-fairr-connect-accounts', 'connect-accounts-cta');

    return postPensionCustomerShareData().then(() => {
      return fetchPensionCustomerToken().then(() => {
        const { token } = this.props;

        if (token) {
          window.open(
            `${LINKS.connectAccounts}?token=${token}`,
            'FairrConnectAccount',
            'menubar=no,location=no,resizable=no,scrollbars=no,status=no,left=200,top=200,width=320,height=568',
          );
          window.addEventListener('message', this.handlePopup);
        }
        this.setState({ isLoading: false });
      });
    });
  };

  render() {
    const { isLoading } = this.state;

    return (
      <TranslationsProvider
        translations={{
          de: () => import('../__translations__/de.json'),
        }}
        id="pension-products-connect-accounts-modal"
      >
        <div className="row">
          <div className={`col-xs-12 ${styles.modalWrapper}`}>
            <div className={styles.modalHeader}>
              <div className={styles.imgDesktop}>
                <Image alt="data-transfer" src="data_transfer_pp_xl.svg" />
              </div>
              <div className={styles.imgMobile}>
                <Image alt="data-transfer" src="data_transfer_pp_xs.svg" />
              </div>
              <span
                id="newsletterModalCrossIcon"
                className={styles.closeIcon}
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                aria-hidden="true"
                onClick={this.closeModal}
              >
                <Icon icon="cross" round={false} />
              </span>
            </div>
            <Paragraph>
              <Translate id="paragraph.1" />
            </Paragraph>
            <List
              className={styles.list}
              listItemClassname={styles.listItem}
              items={[
                {
                  id: 'details',
                  item: <Translate id="list.item.1" />,
                },
                {
                  id: 'closure',
                  item: <Translate id="list.item.2" />,
                },
                {
                  id: 'link',
                  item: <Translate id="list.item.3" />,
                },
              ]}
            />
            <div className={styles.paragraph}>
              <Paragraph>
                <Translate id="paragraph.2" />{' '}
                <ButtonLink
                  text={<Translate id="paragraph.3.link" />}
                  onClick={this.onDataProtectionLinkClick}
                />
                <Translate id="dot" />
              </Paragraph>
            </div>
            <div className={styles.buttonsWrapper}>
              <Button
                isPrimary
                onClick={this.connectAccounts}
                isDisabled={isLoading}
                isLoading={isLoading}
              >
                <Translate id="button.confirm" />
              </Button>
            </div>
          </div>
        </div>
      </TranslationsProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.pensionProducts.token,
  bacNumber: state.customer.bac_number,
});

const mapDispatchToProps = {
  changeUIState,
  fetchPensionCustomerToken,
  postPensionCustomerShareData,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConnectAccountsModal));
