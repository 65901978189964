const { getNamespaceName } = require('utils/IPUtils');

export const HOSTS = {
  pension: 'https://www.raisin-pension.de',
  invest: 'https://invest.raisin.com',
  ws: 'https://www.weltsparen.de',
  crypto: 'https://www.weltsparen.de/crypto',
  staging: (name, type) => {
    switch (type) {
      case 'invest':
        return `https://obs-api-${name}.fairr.online/.invest`;
      default:
        return `https://obs-api-${name}.fairr.online`;
    }
  },
};

export const getHost = (type = 'pension') => {
  const name = getNamespaceName();

  return name !== 'production' && name !== 'develop' ? HOSTS.staging(name, type) : HOSTS[type];
};

export const LINKS = {
  general: getHost(),
  ruerup: `${getHost()}/produkte/ruerup/`,
  fairruerup: {
    product: `${getHost()}/produkte/ruerup/rechner/`,
    document: `${getHost()}/assets/media/leitfaden-ruerup.pdf`,
  },
  fairriester: {
    product: `${getHost()}/produkte/riester/rechner/`,
    document: `${getHost()}/assets/media/leitfaden-riester.pdf`,
  },
  cockpit: `${getHost()}/cockpit/`,
  postbox: `${getHost()}/cockpit/kommunikation/dokumente/`,
  customerData: `${getHost()}/cockpit/meine-daten/`,
  connectAccounts: `${getHost()}/login/connect-accounts`,
  etfcCockpit: `${getHost('invest')}/cockpit/uebersicht`,
  cryptoCockpit: `${getHost('crypto')}/cockpit`,
  mycryptoCockpit: `${getHost('crypto')}/cockpit/uebersicht`,
};

export const getFinalRPPath = (link, raisid) => {
  const pathParts = link.split('#');
  const pathName = pathParts.shift();
  const hash = pathParts.length ? `#${pathParts.join('#')}` : '';

  return `${pathName}${pathName.includes('?') ? '&' : '?'}raisid=${raisid}${hash}`;
};

export const raisinPensionSubdomainRouter = async ({
  raisid,
  link,
  changeUIState,
  changePPTargetLink,
  ippPensionsBIPSRedirect,
}) => {
  if (ippPensionsBIPSRedirect) {
    return false;
  }

  if (raisid) {
    const finalPath = getFinalRPPath(link, raisid);

    window.location.replace(finalPath);

    return true;
  }

  changePPTargetLink(link);
  changeUIState({ isPensionProductsDataSharingModalOpen: true });

  return false;
};

const filterByType = (products = [], type) => products?.filter((product) => product.type === type);

const filterByName = (products = [], name) => products?.filter((product) => product.name === name);

const sumBalances = (sum, product) => sum + product.balance.value;

export const TYPES = {
  CRYPTO: 'crypto',
  INVEST: 'invest',
  FAIRR: 'fairr', // For both PENSION_TYPES
};

export const PENSION_TYPES = {
  ETF_RURUP: 'ETF Rürup',
  ETF_RIESTER: 'ETF Riester',
};

const getMigratedHost = () =>
  `https://${getNamespaceName() === 'production' ? 'weltsparen' : 'staging-weltsparen'}.de/pension`;

export const PENSION_PRODUCT_MIGRATED_COCKPIT_LINKS = {
  [TYPES.INVEST]: `${getMigratedHost()}/auth?next=/investieren/etf-configurator/cockpit/uebersicht`,
  [TYPES.FAIRR]: `${getMigratedHost()}/auth?next=/investieren/etf-ruerup/cockpit`,
  [TYPES.CRYPTO]: `${getMigratedHost()}/auth?next=/crypto/cockpit/uebersicht`,
};

export const hasEtfConfiguratorProducts = (pensionProducts) =>
  !!filterByType(pensionProducts?.products, TYPES.INVEST)?.length;

export const hasPPProducts = (pensionProducts) =>
  !!filterByType(pensionProducts?.products, TYPES.FAIRR)?.length;

export const hasCryptoPortfolio = (pensionProducts) =>
  !!filterByType(pensionProducts?.products, TYPES.CRYPTO)?.length;

export const hasETFRurupProduct = (pensionProducts) =>
  !!filterByName(filterByType(pensionProducts?.products, TYPES.FAIRR), PENSION_TYPES.ETF_RURUP)
    ?.length;

export const hasETFRiesterProduct = (pensionProducts) =>
  !!filterByName(filterByType(pensionProducts?.products, TYPES.FAIRR), PENSION_TYPES.ETF_RIESTER)
    ?.length;

export const getEtfConfiguratorTotalValue = (pensionProducts = []) => {
  const filteredProducts = filterByType(pensionProducts?.products, TYPES.INVEST);

  return {
    value: filteredProducts.reduce(sumBalances, 0) || 0,
    currency: filteredProducts[0]?.balance?.currency,
  };
};

export const getPensionProductsTotalInvestment = (pensionProducts) =>
  filterByType(pensionProducts?.products, TYPES.FAIRR).reduce(sumBalances, 0) || 0;

export const getPensionProductsInvestmentForETFRurup = (pensionProducts = []) => {
  const filteredProducts = filterByName(
    filterByType(pensionProducts?.products, TYPES.FAIRR),
    PENSION_TYPES.ETF_RURUP,
  );

  return {
    value: filteredProducts.reduce(sumBalances, 0) || 0,
    currency: filteredProducts[0]?.balance?.currency,
  };
};

export const getPensionProductsInvestmentForETFRiester = (pensionProducts = []) => {
  const filteredProducts = filterByName(
    filterByType(pensionProducts?.products, TYPES.FAIRR),
    PENSION_TYPES.ETF_RIESTER,
  );

  return {
    value: filteredProducts.reduce(sumBalances, 0) || 0,
    currency: filteredProducts[0]?.balance?.currency,
  };
};

export const getCryptoPortfolioTotalValue = (pensionProducts = []) => {
  const filteredProducts = filterByType(pensionProducts?.products, TYPES.CRYPTO);

  return {
    value: filteredProducts.reduce(sumBalances, 0) || 0,
    currency: filteredProducts[0]?.balance?.currency,
  };
};
