import React, { Component, Suspense } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { HashRouter, Redirect, Switch } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import 'ui-lib/src/styles/styles.scss';

import { HAS_INVESTMENT_PRODUCT_ACCOUNT, LINKS } from 'components/Layout/menu';
import RaisinRoute from 'components/RaisinRoute';
import {
  changeCurrency,
  fetchCustomerBranding,
  fetchCustomerProductAccess,
  fetchPomProducts,
} from 'store/customer/actions';
import { fetchFeatureFlags } from 'store/global/actions';
import { fetchDBSProductsAccess } from 'store/customer/actions/dbs';
import {
  changePPTargetLink,
  fetchPensionCustomerToken,
  postPensionCustomerShareSession,
} from 'store/pensionProducts/actions';
import { changeLocale, changeUIState, fetchFeatureSwitches } from 'store/ui/actions';
import { isDEUCustomer, isGBRCustomer, localeByCountry } from 'utils/CustomerUtils';
import { raisinPensionDeepLinkManager } from 'utils/DeepLinkManager';
import { getCurrencyForLocale, getLocaleFromLocalStorage } from 'utils/Locale';

import { redirectExternal } from './components/Header/data/utils';
import { EXTERNAL_LINKS } from './components/Header/Navigation/menuLinks';
import LoadingPlaceholder from './components/LoadingPlaceholder';
import Globals from './pages/Globals';
import { redirectProducts } from './utils/Routing';

const LoadingScreen = React.lazy(() => import('./pages/LoadingScreen'));
const IPCockpit = React.lazy(() => import('./pages/IPCockpit'));
const IPBuyEdit = React.lazy(() => import('./pages/IPBuy/BuyEdit'));
const IPBuySubmit = React.lazy(() => import('./pages/IPBuy/BuySubmit'));
const IPBuyConfirm = React.lazy(() => import('./pages/IPBuy/BuyConfirm'));
const IPSellEdit = React.lazy(() => import('./pages/IPSell/SellEdit'));
const IPSellSubmit = React.lazy(() => import('./pages/IPSell/SellSubmit'));
const IPSellConfirm = React.lazy(() => import('./pages/IPSell/SellConfirm'));
const IPSavingsPlanEdit = React.lazy(() => import('./pages/IPSavingsPlan/SavingsPlanEdit'));
const IPSavingsPlanSubmit = React.lazy(() => import('./pages/IPSavingsPlan/SavingsPlanSubmit'));
const IPSavingsPlanConfirm = React.lazy(() => import('./pages/IPSavingsPlan/SavingsPlanConfirm'));
const DepositAccountOrderEdit = React.lazy(() =>
  import('./pages/DepositAccountOrder/DepositAccountOrderEdit'),
);
const DepositAccountOrderSubmit = React.lazy(() =>
  import('./pages/DepositAccountOrder/DepositAccountOrderSubmit'),
);
const DepositAccountOrderConfirm = React.lazy(() =>
  import('./pages/DepositAccountOrder/DepositAccountOrderConfirm'),
);
const DepositAccountProlongationEdit = React.lazy(() =>
  import('./pages/DepositAccountProlongation/DepositAccountProlongationEdit'),
);
const DepositAccountProlongationSubmit = React.lazy(() =>
  import('./pages/DepositAccountProlongation/DepositAccountProlongationSubmit'),
);
const DepositAccountProlongationConfirm = React.lazy(() =>
  import('./pages/DepositAccountProlongation/DepositAccountProlongationConfirm'),
);
const DepositAccountOrderRevokeEdit = React.lazy(() =>
  import('./pages/DepositAccountOrder/DepositAccountOrderRevoke/DepositAccountOrderRevokeEdit'),
);
const DepositAccountOrderRevokeConfirm = React.lazy(() =>
  import('./pages/DepositAccountOrder/DepositAccountOrderRevoke/DepositAccountOrderRevokeConfirm'),
);
const OvernightPayInEdit = React.lazy(() =>
  import('./pages/Overnight/OvernightPayin/OvernightPayInEdit'),
);
const OvernightPayInSubmit = React.lazy(() =>
  import('./pages/Overnight/OvernightPayin/OvernightPayInSubmit'),
);
const OvernightPayInConfirm = React.lazy(() =>
  import('./pages/Overnight/OvernightPayin/OvernightPayInConfirm'),
);
const OvernightPayOutEdit = React.lazy(() =>
  import('./pages/Overnight/OvernightPayOut/OvernightPayOutEdit'),
);
const OvernightPayOutSubmit = React.lazy(() =>
  import('./pages/Overnight/OvernightPayOut/OvernightPayOutSubmit'),
);
const OvernightPayOutConfirm = React.lazy(() =>
  import('./pages/Overnight/OvernightPayOut/OvernightPayOutConfirm'),
);
const OvernightSavingsPlanEdit = React.lazy(() =>
  import('./pages/Overnight/SavingsPlan/SavingsPlanEdit'),
);
const OvernightSavingsPlanSubmit = React.lazy(() =>
  import('./pages/Overnight/SavingsPlan/SavingsPlanSubmit'),
);
const OvernightSavingsPlanConfirm = React.lazy(() =>
  import('./pages/Overnight/SavingsPlan/SavingsPlanConfirm'),
);
const SwitchMTan = React.lazy(() => import('./pages/Administration/SwitchMTan'));
const EarlyTerminationEdit = React.lazy(() =>
  import('./pages/EarlyTermination/EarlyTerminationEdit'),
);
const EarlyTerminationSubmit = React.lazy(() =>
  import('./pages/EarlyTermination/EarlyTerminationSubmit'),
);
const EarlyTerminationConfirm = React.lazy(() =>
  import('./pages/EarlyTermination/EarlyTerminationConfirm'),
);

const CustomerDataEdit = React.lazy(() => import('./pages/CustomerData/CustomerDataEdit'));
const CustomerDataSubmit = React.lazy(() => import('./pages/CustomerData/CustomerDataSubmit'));
const CustomerDataConfirm = React.lazy(() => import('./pages/CustomerData/CustomerDataConfirm'));
const MyInvestments = React.lazy(() => import('./pages/MyInvestments'));
const Products = React.lazy(() => import('./pages/Products'));
const FormsList = React.lazy(() => import('./pages/Administration/FormsList'));
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const CMSPage = React.lazy(() => import('./pages/CMSPage'));
const LockMTanEdit = React.lazy(() => import('./pages/LockMTan/LockMTanEdit'));
const LockMTanConfirm = React.lazy(() => import('./pages/LockMTan/LockMTanConfirm'));
const BlockAccount = React.lazy(() => import('./pages/BlockAccount'));
const DocumentRequiredInformation = React.lazy(() =>
  import('./pages/DocumentRequired/DocumentRequiredInformation'),
);
const DocumentRequiredConfirm = React.lazy(() =>
  import('./pages/DocumentRequired/DocumentRequiredConfirm'),
);
const IPLandingConfigurator = React.lazy(() => import('./pages/IPLandingConfigurator'));
const IPConfiguratorFundsOverview = React.lazy(() => import('./pages/IPConfiguratorFundsOverview'));

class Router extends Component {
  static propTypes = {
    fetchFeatureSwitches: PropTypes.func.isRequired,
    changeLocale: PropTypes.func.isRequired,
    changeCurrency: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    features: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    areFeaturesLoaded: PropTypes.bool.isRequired,
    fetchCustomerBranding: PropTypes.func.isRequired,
    fetchProductAccess: PropTypes.func.isRequired,
    fetchPomProducts: PropTypes.func.isRequired,
    fetchPensionCustomerToken: PropTypes.func.isRequired,
    postPensionCustomerShareSession: PropTypes.func.isRequired,
    changeUIState: PropTypes.func.isRequired,
    changePPTargetLink: PropTypes.func.isRequired,
    customer: PropTypes.object.isRequired,
    token: PropTypes.string,
    fetchFeatureFlags: PropTypes.func.isRequired,
    featureFlags: PropTypes.object.isRequired,
  };

  state = {
    allOBSDataLoaded: false,
  };

  constructor(props) {
    super(props);
    const customerLocale = getLocaleFromLocalStorage();
    const customerCurrency = getCurrencyForLocale(customerLocale);

    if (customerLocale && customerLocale !== props.locale) {
      props.changeLocale(customerLocale);
    }
    if (customerCurrency && customerCurrency !== props.customer.currency) {
      props.changeCurrency(customerCurrency);
    }
  }

  componentDidMount() {
    const {
      fetchPensionCustomerToken,
      postPensionCustomerShareSession,
      fetchFeatureFlags,
      fetchDbsAccess,
      customer,
      isFetchingFeatureFlags,
      featureFlags,
    } = this.props;

    fetchFeatureFlags();

    fetchDbsAccess(customer.bac_number);

    if (
      !this.props.token &&
      isDEUCustomer(customer) &&
      !isFetchingFeatureFlags &&
      featureFlags?.ippPensionsBIPSRedirect === false
    ) {
      fetchPensionCustomerToken().then(() => {
        // if token is present, customer agreed with data sharing
        if (this.props.token) {
          postPensionCustomerShareSession();
        }
      });
    }
  }

  componentDidUpdate() {
    const {
      customer,
      locale,
      casCustomerData,
      featureFlags,
      changeLocale: dispatchChangeLocale,
      changeCurrency: dispatchChangeCurrency,
      fetchProductAccess,
      fetchPomProducts: fetchPomProductsAccess,
      fetchFeatureSwitches: getFeatureSwitches,
      fetchCustomerBranding: getCustomerBranding,
    } = this.props;

    const { allOBSDataLoaded } = this.state;

    const hasB2cDeprecateObsFeatureFlag = featureFlags?.b2cDeprecateObs;
    const countryFromCas = casCustomerData?.address?.countryCode;
    const countryFromCasFormatted = localeByCountry[countryFromCas];

    const customerLocale = !hasB2cDeprecateObsFeatureFlag
      ? customer.locale
      : countryFromCasFormatted;

    if (!isEmpty(featureFlags) && !allOBSDataLoaded) {
      if (!hasB2cDeprecateObsFeatureFlag) {
        getFeatureSwitches();
        getCustomerBranding();
        fetchProductAccess();
      } else if (isDEUCustomer(customer)) {
        fetchPomProductsAccess();
      }

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ allOBSDataLoaded: true });
    }

    if (customerLocale && customerLocale !== locale) {
      dispatchChangeLocale(customerLocale);
      dispatchChangeCurrency(getCurrencyForLocale(customerLocale));
    }
  }

  render() {
    const {
      fetchPensionCustomerToken,
      postPensionCustomerShareSession,
      changeUIState,
      changePPTargetLink,
      customer: { productAccess },
      casCustomerData,
      features,
      featureFlags,
    } = this.props;

    localStorage.removeItem('productAccess');

    const canSeeIpCockpit = productAccess.includes(HAS_INVESTMENT_PRODUCT_ACCOUNT);
    const canSeeIpProducts = features.ip_view_products || false;
    const isFromGBR = isGBRCustomer({
      default_address: { country: casCustomerData?.address?.countryCode },
    });
    const isDataEditAllowed = window?.location?.href?.includes('address') ? !isFromGBR : true;

    return (
      <HashRouter>
        <div>
          <Suspense fallback={<LoadingPlaceholder height={300} width="100%" />}>
            <Switch>
              <RaisinRoute
                exact
                path="/postbox/documents"
                component={() => {
                  redirectExternal(EXTERNAL_LINKS.DOCUMENTS.HOME);
                }}
              />
              <RaisinRoute
                exact
                path="/postbox/index"
                component={() => {
                  redirectExternal(EXTERNAL_LINKS.POSTBOX.HOME);
                }}
              />
              <RaisinRoute
                exact
                path="/postbox/compose/*"
                component={() => {
                  redirectExternal(EXTERNAL_LINKS.POSTBOX.WRITE_MESSAGE);
                }}
              />
              <Redirect
                exact
                from="/InvestmentProducts"
                to={LINKS.PRODUCTS.INVESTMENT_PRODUCTS_PARENT}
              />
              <RaisinRoute
                isAllowed={canSeeIpProducts}
                path={LINKS.PRODUCTS.INVESTMENT_PRODUCTS_ROBO}
                component={() => {
                  window.location.assign(LINKS.PRODUCTS.INVESTMENT_PRODUCTS_ROBO);
                }}
                exact
                isIPRoute
              />
              <RaisinRoute
                isAllowed={canSeeIpProducts}
                path="/investieren/robo"
                component={() => {
                  window.location.assign(LINKS.PRODUCTS.INVESTMENT_PRODUCTS_ROBO);
                }}
                exact
                isIPRoute
              />
              <RaisinRoute
                isAllowed={canSeeIpProducts}
                path="/investieren/robo-advisor"
                component={() => {
                  window.location.assign(LINKS.PRODUCTS.INVESTMENT_PRODUCTS_ROBO);
                }}
                exact
                isIPRoute
              />
              <RaisinRoute
                isAllowed={canSeeIpProducts}
                path={LINKS.PRODUCTS.INVESTMENT_PRODUCTS_PARENT}
                component={() => {
                  window.location.assign(LINKS.PRODUCTS.INVESTMENT_PRODUCTS_PARENT);
                }}
                exact
                isIPRoute
              />
              <RaisinRoute
                isAllowed={canSeeIpProducts}
                path={LINKS.PRODUCTS.INVESTMENT_PRODUCTS_CONFIGURATOR}
                component={IPLandingConfigurator}
                exact
                isIPRoute
              />
              <RaisinRoute
                isAllowed={canSeeIpProducts}
                path={LINKS.PRODUCTS.INVESTMENT_PRODUCTS_CONFIGURATOR_FUNDS_OVERVIEW}
                component={IPConfiguratorFundsOverview}
                exact
                isIPRoute
              />
              <RaisinRoute
                isAllowed={canSeeIpCockpit}
                path="/InvestmentProducts/cockpit"
                component={IPCockpit}
                isIPRoute
              />
              <RaisinRoute isIPRoute path="/InvestmentProducts/buy/edit" component={IPBuyEdit} />
              <RaisinRoute
                isIPRoute
                path="/InvestmentProducts/buy/submit"
                component={IPBuySubmit}
              />
              <RaisinRoute
                isIPRoute
                path="/InvestmentProducts/buy/confirm"
                component={IPBuyConfirm}
              />
              <RaisinRoute isIPRoute path="/InvestmentProducts/sell/edit" component={IPSellEdit} />
              <RaisinRoute
                isIPRoute
                path="/InvestmentProducts/sell/submit"
                component={IPSellSubmit}
              />
              <RaisinRoute
                isIPRoute
                path="/InvestmentProducts/sell/confirm"
                component={IPSellConfirm}
              />
              <RaisinRoute
                path="/Products(/easyAccess|/overnight|/notice)?"
                component={({ match: { url } }) =>
                  redirectProducts({
                    url,
                    showB2cLoggedInOffers: featureFlags.b2cLoggedInOffers,
                    Products,
                  })
                }
              />
              <RaisinRoute
                path="/Identification"
                component={() => {
                  redirectExternal(EXTERNAL_LINKS.IDENTIFICATION.HOME);
                }}
                exact
              />
              <RaisinRoute
                path="/Identification/proofOfAddress"
                component={() => {
                  redirectExternal(EXTERNAL_LINKS.IDENTIFICATION.PROOF_OF_ADDRESS);
                }}
                exact
              />
              <RaisinRoute
                path="/Overnight/PayIn/edit/:deposit_number"
                component={OvernightPayInEdit}
              />
              <RaisinRoute path="/Overnight/PayIn/submit" component={OvernightPayInSubmit} />
              <RaisinRoute path="/Overnight/PayIn/confirm" component={OvernightPayInConfirm} />
              <RaisinRoute
                path="/Overnight/PayOut/edit/:depositNumber"
                component={OvernightPayOutEdit}
              />
              <RaisinRoute path="/Overnight/PayOut/submit" component={OvernightPayOutSubmit} />
              <RaisinRoute path="/Overnight/PayOut/confirm" component={OvernightPayOutConfirm} />
              <RaisinRoute
                path="/Overnight/SavingsPlan/edit/:depositNumber"
                component={OvernightSavingsPlanEdit}
              />
              <RaisinRoute
                path="/Overnight/SavingsPlan/submit"
                component={OvernightSavingsPlanSubmit}
              />
              <RaisinRoute
                path="/Overnight/SavingsPlan/confirm"
                component={OvernightSavingsPlanConfirm}
              />
              <RaisinRoute
                isAllowed={isDataEditAllowed}
                path="/MyData/:section/edit"
                component={CustomerDataEdit}
              />
              <RaisinRoute
                isAllowed={isDataEditAllowed}
                path="/MyData/:section/submit"
                component={CustomerDataSubmit}
              />
              <RaisinRoute
                isAllowed={isDataEditAllowed}
                path="/MyData/:section/confirm"
                component={CustomerDataConfirm}
              />
              <RaisinRoute path="/about/:section" component={CMSPage} />
              <RaisinRoute path="/Dashboard" component={Dashboard} />
              <RaisinRoute path="/Administration/FormsList" component={FormsList} />
              <RaisinRoute path="/Administration/SwitchMTan" component={SwitchMTan} />
              <RaisinRoute
                path="/EarlyTermination/:depositNumber/edit"
                component={EarlyTerminationEdit}
              />
              <RaisinRoute
                path="/EarlyTermination/:depositNumber/submit"
                component={EarlyTerminationSubmit}
              />
              <RaisinRoute
                path="/EarlyTermination/:depositNumber/confirm"
                component={EarlyTerminationConfirm}
              />
              <RaisinRoute path="/LockMTan/edit" component={LockMTanEdit} exact />
              <RaisinRoute path="/LockMTan/confirm" component={LockMTanConfirm} exact />
              <RaisinRoute path="/BlockAccount" component={BlockAccount} exact />
              <RaisinRoute
                path="/DocumentRequired/information/:depositNumber"
                component={DocumentRequiredInformation}
                exact
              />
              <RaisinRoute
                path="/DocumentRequired/confirm"
                component={DocumentRequiredConfirm}
                exact
              />
              {/* <RaisinRoute
                isAllowed={canReferAFriend}
                path="/ReferAFriend/leads"
                component={ReferAFriendLeads}
              />
              <RaisinRoute
                isAllowed={canReferAFriend}
                path="/ReferAFriend/confirm"
                component={ReferAFriendConfirm}
              /> */}
              <RaisinRoute
                isIPRoute
                path="/InvestmentProducts/savingsPlan/edit"
                component={IPSavingsPlanEdit}
              />
              <RaisinRoute
                isIPRoute
                path="/InvestmentProducts/savingsPlan/submit"
                component={IPSavingsPlanSubmit}
              />
              <RaisinRoute
                isIPRoute
                path="/InvestmentProducts/savingsPlan/confirm"
                component={IPSavingsPlanConfirm}
              />
              <RaisinRoute
                path="/MyInvestments/(TermDeposit|Overnight|Notice)"
                component={MyInvestments}
                exact
              />
              <RaisinRoute
                path="/DepositProducts/order/:product_id/edit"
                component={DepositAccountOrderEdit}
              />
              <RaisinRoute
                path="/DepositProducts/order/:product_id/submit"
                component={DepositAccountOrderSubmit}
              />
              <RaisinRoute
                path="/DepositProducts/order/:product_id/confirm"
                component={DepositAccountOrderConfirm}
              />
              <RaisinRoute
                path="/DepositProducts/prolongation/:offerId/:depositNumber/edit"
                component={DepositAccountProlongationEdit}
              />
              <RaisinRoute
                path="/DepositProducts/prolongation/:offerId/:depositNumber/submit"
                component={DepositAccountProlongationSubmit}
              />
              <RaisinRoute
                path="/DepositProducts/prolongation/:offerId/:depositNumber/confirm"
                component={DepositAccountProlongationConfirm}
              />
              <RaisinRoute
                path="/DepositProducts/order/:product_id/revoke/edit"
                component={DepositAccountOrderRevokeEdit}
              />
              <RaisinRoute
                path="/DepositProducts/order/:product_id/revoke/confirm"
                component={DepositAccountOrderRevokeConfirm}
              />
              <RaisinRoute
                hideMenu
                showHeader={false}
                showFooter={false}
                path={LINKS.LOADING_SCREEN}
                component={LoadingScreen}
              />
              {/* This must be the last route otherwise it would break the application */}
              <RaisinRoute
                component={(props) => {
                  const path = props.location.pathname + props.location.search;
                  // This redirects RP deeplinks for logged in customers.

                  if (isDEUCustomer(this.props.customer)) {
                    const showLoadingScreen = raisinPensionDeepLinkManager({
                      fetchPensionCustomerToken,
                      postPensionCustomerShareSession,
                      changeUIState,
                      changePPTargetLink,
                      path,
                    });

                    if (showLoadingScreen) {
                      window.location.assign(
                        `${window.location.origin}${window.location.pathname}#${LINKS.LOADING_SCREEN}`,
                      );
                    } else {
                      props.history.push('/Dashboard');
                    }
                  } else {
                    props.history.push('/Dashboard');
                  }

                  return null;
                }}
              />
            </Switch>
          </Suspense>
          <Globals />
        </div>
      </HashRouter>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.ui.locale,
  features: state.ui.features,
  areFeaturesLoaded: state.ui.areFeaturesLoaded,
  customer: state.customer,
  token: state.pensionProducts.token,
  raisid: state.pensionProducts.raisid,
  featureFlags: state.global.featureFlags,
  isFetchingFeatureFlags: state.global.isFetchingFeatureFlags,
  casCustomerData: state.customer.casCustomerData,
  pomProducts: state.customer.pomProducts,
});

const mapDispatchToProps = {
  fetchFeatureSwitches,
  changeLocale,
  changeCurrency,
  fetchPensionCustomerToken,
  fetchProductAccess: fetchCustomerProductAccess,
  fetchPomProducts,
  postPensionCustomerShareSession,
  fetchCustomerBranding,
  changeUIState,
  changePPTargetLink,
  fetchFeatureFlags,
  fetchDbsAccess: fetchDBSProductsAccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
