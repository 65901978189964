import { getFeatureFlagClient } from 'utils/FeatureFlag';

import { createImprovedAsyncAction } from '../../actionCreators';
import * as types from '../../actionTypes';

export const postLogout = () => {
  return createImprovedAsyncAction({
    type: types.POST_LOGOUT,
    path: 'logout',
    method: 'post',
    settings: { preventJwtRefresher: true, resetSessionRefresher: true },
  });
};

export const fetchFeatureFlags = () => {
  return { type: types.FETCH_FEATURE_FLAGS, promise: getFeatureFlagClient() };
};
