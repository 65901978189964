import { createImprovedAsyncAction } from '../../../store/actionCreators';
import { bearerToken } from './utils';

const commonOptions = ({ method, path, guestToken, customerId, userId }) => {
  const options = {
    method,
    path,
    settings: {
      withCredentials: false,
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };

  if (guestToken) {
    options.settings.headers.Authorization = bearerToken(guestToken);
    if (customerId && userId) {
      options.settings.headers['Customer-ID'] = customerId;
      options.settings.headers['User-ID'] = userId;
    }
  } else {
    options.settings.useJwt = true;
  }

  return options;
};

export const verifyOTP = async ({
  nonce,
  guestToken,
  verificationCode,
  verificationId,
  customerId,
  userId,
}) => {
  const options = commonOptions({
    method: 'put',
    path: 'customer.verifications.verifyOTP',
    guestToken,
    customerId,
    userId,
  });

  options.dynamicPath = { verificationId, nonce };
  options.params = { verification_code: verificationCode };

  return createImprovedAsyncAction(options).promise;
};

export const sendOTP = async ({
  verificationId,
  guestToken,
  verificationMessage,
  locale,
  customerId,
  userId,
}) => {
  const options = commonOptions({
    method: 'post',
    path: 'customer.verifications.sendOTP',
    guestToken,
    customerId,
    userId,
  });

  options.dynamicPath = { verificationId };
  options.params = { verification_message: verificationMessage };
  options.settings.params = { locale };

  return createImprovedAsyncAction(options).promise;
};

export const getUserDetails = async (verificationId, guestToken, customerId, userId) => {
  const options = commonOptions({
    method: 'get',
    path: 'customer.verifications.userDetails',
    guestToken,
    customerId,
    userId,
  });

  options.dynamicPath = { verificationId };

  return createImprovedAsyncAction(options).promise;
};

export const getUserMobile = async (verificationId, guestToken, customerId, userId) => {
  const details = await getUserDetails(verificationId, guestToken, customerId, userId);

  return details?.data?.targets?.[0]?.masked_value;
};
