import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TranslationsProvider, Translate } from 'components/I18n';
import Paragraph from 'ui-lib/src/components/Paragraph';
import Button from 'ui-lib/src/components/Button';
import Table from 'ui-lib/src/components/Table';
import { changeUIState } from 'store/ui/actions';
import { columns, rows } from './table';
import styles from './styles.scss';

export class NationalIdentifierModal extends PureComponent {
  static propTypes = {
    changeUIState: PropTypes.func.isRequired,
  };

  closeModal = () => this.props.changeUIState({ isNationalIdentifierModalOpen: false });

  render() {
    return (
      <TranslationsProvider
        translations={{
          de: () => import('./__translations__/de.json'),
        }}
        id="ip-national-identifier-modal"
      >
        <div className={styles.modal}>
          <div className="col-md-12">
            <Paragraph className={styles.modalHeader}>
              <Translate id="header" />
            </Paragraph>
            <Paragraph>
              <Translate id="paragraph.1" />
            </Paragraph>
            <Paragraph>
              <Translate id="paragraph.2" />
            </Paragraph>
            <Paragraph>
              <Translate id="paragraph.3" />
            </Paragraph>
            <Table dataSource={rows} columns={columns} pageSize={Infinity} striped />
            <div className={styles.closeButtonWrapper}>
              <Button isPrimary onClick={this.closeModal}>
                <Translate id="closeButton" />
              </Button>
            </div>
          </div>
        </div>
      </TranslationsProvider>
    );
  }
}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
  changeUIState: (state) => dispatch(changeUIState(state)),
});

export default connect(null, mapDispatchToProps)(NationalIdentifierModal);
