import { ajaxStatus } from '../../../../config';

export const setSavingsPlanData = (state, payload) => {
  if (payload.mTanForm) {
    return {
      ...state,
      ...payload,
    };
  }

  return {
    ...state,
    ...payload,
    form: {
      status: ajaxStatus.notPosted,
      error: null,
    },
    mTanForm: {
      status: ajaxStatus.notPosted,
      error: null,
    },
  };
};

export const fetchSavingsPlanData = (state, payload) => {
  const { data } = payload;
  const status = data.savings_plan;

  delete data.savings_plan;

  return {
    ...state,
    ...data,
    status,
    create_questions: data.create_questions.map((q) => q.toLowerCase()),
    edit_questions: data.edit_questions.map((q) => q.toLowerCase()),
    suspend_questions: data.suspend_questions.map((q) => q.toLowerCase()),
  };
};
