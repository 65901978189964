import { handle } from 'redux-pack';
import { trackEvent } from '@raisin/events-tracking';
import { initialState } from '../../selectors';
import * as types from '../../../actionTypes';
import { ajaxStatus } from '../../../../config';
import { setOrderData, resetOrderData } from './alterations';
import { EVENTS } from '../../../../eventsTracking';

export default (state = initialState, action) => {
  // NOSONAR
  const { type, payload } = action;

  switch (type) {
    case types.LOAD_ORDER_FORM:
      return {
        ...state,
        ...payload,
      };
    case types.POST_ORDER_DATA:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          form: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          session: {
            status: payload.response.status,
            error: payload.response.statusText,
          },
          form: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          session: {
            nonce: payload.data.nonce,
          },
          form: {
            status: ajaxStatus.success,
            error: null,
          },
          transient: payload.data,
        }),
      });
    case types.POST_ORDER_MTAN:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          mTanForm: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          session: {
            status: payload.response.status,
            error: payload.response.statusText,
            nonce: prevState.session.nonce,
          },
          mTanForm: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => {
          const { portfolio_id, order_number, amount } = payload.data;

          trackEvent(
            {
              ...EVENTS.PRODUCT_ORDER_FINISHED,
              customAttribute1: 'investment',
              customAttribute2: 'etf-robo',
              customAttribute3: portfolio_id.toString(),
              customAttribute4: order_number.toString(),
              customAttribute5: amount.toString(),
              customAttribute6: '1',
            },
            true,
          );

          return {
            ...prevState,
            mTanForm: {
              status: ajaxStatus.success,
              error: null,
            },
            transient: payload.data,
          };
        },
      });
    case types.SET_ORDER_DATA:
      return setOrderData(state, payload);
    case types.RESET_ORDER_DATA:
      return resetOrderData(state, payload);
    default:
      return state;
  }
};
