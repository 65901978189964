import React, { Component } from 'react';
import PropTypes from 'prop-types';
import constants from 'ui-lib/src/constants';
import Alert from 'ui-lib/src/components/Alert';
import Paragraph from 'ui-lib/src/components/Paragraph';
import { getDateTime } from 'ui-lib/src/utils/GeneralUtils';
import { Translate, LocalizeConsumer } from 'components/I18n';
import { ajaxStatus } from '../../config';
import { getFormErrorMessage } from '../../utils/ErrorHandling';
import styles from './styles.scss';

export default class MTanFormErrorNotice extends Component {
  static propTypes = {
    history: PropTypes.object,
    buyMTanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    sellMTanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    customerContactDataMtanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    customerPasswordMtanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    savingsPlanMTanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    referenceAccountMTanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    overnightPayInMTanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    overnightSavingsPlanMTanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    overnightPayOutMTanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    depositOrderMtanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    customerPayoutMtanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    earlyTerminationMTanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    phone_number_anonymized: PropTypes.string,
  };

  render() {
    const {
      buyMTanForm,
      sellMTanForm,
      customerContactDataMtanForm,
      customerPasswordMtanForm,
      savingsPlanMTanForm,
      referenceAccountMTanForm,
      overnightPayInMTanForm,
      overnightPayOutMTanForm,
      overnightSavingsPlanMTanForm,
      depositOrderMtanForm,
      customerPayoutMtanForm,
      earlyTerminationMTanForm,
      history: {
        location: { pathname },
      },
      phone_number_anonymized,
    } = this.props;
    const { alerts } = constants;
    const { date, time } = getDateTime();
    let mTanForm = null;

    if (pathname.startsWith('/InvestmentProducts/buy')) {
      mTanForm = buyMTanForm;
    } else if (pathname.startsWith('/InvestmentProducts/sell')) {
      mTanForm = sellMTanForm;
    } else if (pathname.startsWith('/MyData/address')) {
      mTanForm = customerContactDataMtanForm;
    } else if (pathname.startsWith('/MyData/password')) {
      mTanForm = customerPasswordMtanForm;
    } else if (pathname.startsWith('/InvestmentProducts/savingsPlan')) {
      mTanForm = savingsPlanMTanForm;
    } else if (pathname.startsWith('/ReferenceAccount')) {
      mTanForm = referenceAccountMTanForm;
    } else if (pathname.startsWith('/Overnight/PayIn')) {
      mTanForm = overnightPayInMTanForm;
    } else if (pathname.startsWith('/Overnight/PayOut')) {
      mTanForm = overnightPayOutMTanForm;
    } else if (pathname.startsWith('/Overnight/SavingsPlan')) {
      mTanForm = overnightSavingsPlanMTanForm;
    } else if (pathname.startsWith('/DepositProducts/order')) {
      mTanForm = depositOrderMtanForm;
    } else if (pathname.startsWith('/DepositProducts/prolongation')) {
      mTanForm = depositOrderMtanForm;
    } else if (pathname.startsWith('/Administration/CustomerPayout')) {
      mTanForm = customerPayoutMtanForm;
    } else if (pathname.startsWith('/EarlyTermination')) {
      mTanForm = earlyTerminationMTanForm;
    }

    if (mTanForm && mTanForm.status === ajaxStatus.failure) {
      return (
        <LocalizeConsumer>
          {(ctx) => (
            <Alert
              type={alerts.danger}
              key="alert-mTanFormError"
              className="mTanErrorNotice"
              showDefault
            >
              <Paragraph className={styles.alert}>
                <Translate
                  id={getFormErrorMessage(mTanForm)}
                  values={{
                    date: ctx.localizeDate(...date),
                    time,
                    phone_number_anonymized,
                  }}
                />
              </Paragraph>
            </Alert>
          )}
        </LocalizeConsumer>
      );
    }

    return null;
  }
}
