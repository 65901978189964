export const noticesConfig = {
  pages: {
    LOGIN: {
      name: 'LOGIN',
      notices: [
        'REQUEST_ERROR_NOTICE',
        'AUTHORIZATION_ERROR_NOTICE',
        'BLOCKED_ACCOUNT_ERROR_NOTICE',
      ],
    },
    DASHBOARD: {
      name: 'DASHBOARD',
      notices: [
        'MISSING_FUNDS_NOTICE',
        'EXPIRED_PID_NOTICE',
        'MISSING_KYC_NOTICE',
        'MISSING_POA_NOTICE',
        'MISSING_IDD_NOTICE',
        'ACCOUNT_OVERDRAFT_NOTICE',
      ],
    },
    PLP: {
      name: 'PLP',
      notices: ['REQUEST_ERROR_NOTICE'],
    },
    IP_COCKPIT: {
      name: 'IP_COCKPIT',
      notices: ['ORDER_FREEZE_EXPLAIN'],
    },
    PDP: {
      name: 'PDP',
      notices: ['REQUEST_ERROR_NOTICE', 'ELIGIBILITY_NOTICE'],
    },
    IP_ORDER_EDIT: {
      name: 'IP_ORDER_EDIT',
      notices: ['REQUEST_ERROR_NOTICE', 'FORM_ERROR_NOTICE'],
    },
    IP_ORDER_SUBMIT: {
      name: 'IP_ORDER_SUBMIT',
      notices: ['REQUEST_ERROR_NOTICE', 'MTAN_FORM_ERROR_NOTICE'],
    },
    IP_ORDER_CONFIRM: {
      name: 'IP_ORDER_CONFIRM',
      notices: [
        'REQUEST_ERROR_NOTICE',
        'CONFIRMATION_NOTICE',
        'WAITING_TIME_NOTICE',
        'MISSING_PID_NOTICE',
        'MISSING_FUNDS_NOTICE',
        'SAVINGS_PLAN_DEBIT_DAY_NOTICE',
        'EXPIRED_PID_NOTICE',
      ],
    },
    DEPOSIT_ACCOUNT_ORDER_EDIT: {
      name: 'DEPOSIT_ACCOUNT_ORDER_EDIT',
      notices: ['REQUEST_ERROR_NOTICE', 'FORM_ERROR_NOTICE'],
    },
    DEPOSIT_ACCOUNT_ORDER_SUBMIT: {
      name: 'DEPOSIT_ACCOUNT_ORDER_SUBMIT',
      notices: ['REQUEST_ERROR_NOTICE', 'MTAN_FORM_ERROR_NOTICE'],
    },
    DEPOSIT_ACCOUNT_ORDER_CONFIRM: {
      name: 'DEPOSIT_ACCOUNT_ORDER_CONFIRM',
      notices: [
        'REQUEST_ERROR_NOTICE',
        'CONFIRMATION_NOTICE',
        'WAITING_TIME_NOTICE',
        'MISSING_FUNDS_NOTICE',
        'SAVINGS_PLAN_DEBIT_DAY_NOTICE',
        'EXPIRED_PID_NOTICE',
        'MISSING_KYC_NOTICE',
        'MISSING_POA_NOTICE',
      ],
    },
    ORDER_REVOKE: {
      name: 'ORDER_REVOKE',
      notices: ['REQUEST_ERROR_NOTICE'],
    },
    PROLONGATION_EDIT: {
      name: 'PROLONGATION_EDIT',
      notices: ['REQUEST_ERROR_NOTICE', 'FORM_ERROR_NOTICE'],
    },
    PROLONGATION_SUBMIT: {
      name: 'PROLONGATION_SUBMIT',
      notices: ['REQUEST_ERROR_NOTICE', 'MTAN_FORM_ERROR_NOTICE'],
    },
    PROLONGATION_CONFIRM: {
      name: 'PROLONGATION_CONFIRM',
      notices: ['REQUEST_ERROR_NOTICE'],
    },
    BUY_EDIT: {
      name: 'BUY_EDIT',
      notices: ['REQUEST_ERROR_NOTICE', 'FORM_ERROR_NOTICE'],
    },
    BUY_SUBMIT: {
      name: 'BUY_SUBMIT',
      notices: ['REQUEST_ERROR_NOTICE', 'MTAN_FORM_ERROR_NOTICE'],
    },
    BUY_CONFIRM: {
      name: 'BUY_CONFIRM',
      notices: [
        'REQUEST_ERROR_NOTICE',
        'CONFIRMATION_NOTICE',
        'WAITING_TIME_NOTICE',
        'MISSING_PID_NOTICE',
        'MISSING_FUNDS_NOTICE',
      ],
    },
    SELL_EDIT: {
      name: 'SELL_EDIT',
      notices: ['REQUEST_ERROR_NOTICE', 'FORM_ERROR_NOTICE'],
    },
    SELL_SUBMIT: {
      name: 'SELL_SUBMIT',
      notices: ['REQUEST_ERROR_NOTICE', 'MTAN_FORM_ERROR_NOTICE'],
    },
    SELL_CONFIRM: {
      name: 'SELL_CONFIRM',
      notices: ['REQUEST_ERROR_NOTICE', 'CONFIRMATION_NOTICE', 'WAITING_TIME_NOTICE'],
    },
    COCKPIT: {
      name: 'COCKPIT',
      notices: ['REQUEST_ERROR_NOTICE', 'MISSING_PID_NOTICE', 'MISSING_FUNDS_NOTICE'],
    },
    REVOKE_DEPOSIT: {
      name: 'REVOKE_DEPOSIT',
      notices: [],
    },
    REVOKE_DEPOSIT_CONFIRM: {
      name: 'REVOKE_DEPOSIT_CONFIRM',
      notices: [],
    },
    CUSTOMER_BLOCK_ACCOUNT: {
      name: 'CUSTOMER_BLOCK_ACCOUNT',
      notices: ['REQUEST_ERROR_NOTICE', 'FORM_ERROR_NOTICE'],
    },
    CUSTOMER_CONTACT_DATA_EDIT: {
      name: 'CUSTOMER_CONTACT_DATA_EDIT',
      notices: ['REQUEST_ERROR_NOTICE', 'FORM_ERROR_NOTICE'],
    },
    CUSTOMER_CONTACT_DATA_SUBMIT: {
      name: 'CUSTOMER_CONTACT_DATA_SUBMIT',
      notices: ['REQUEST_ERROR_NOTICE', 'MTAN_FORM_ERROR_NOTICE'],
    },
    CUSTOMER_CONTACT_DATA_CONFIRM: {
      name: 'CUSTOMER_CONTACT_DATA_CONFIRM',
      notices: ['CONFIRMATION_NOTICE'],
    },
    CUSTOMER_PASSWORD_EDIT: {
      name: 'CUSTOMER_PASSWORD_EDIT',
      notices: ['REQUEST_ERROR_NOTICE', 'FORM_ERROR_NOTICE'],
    },
    CUSTOMER_PASSWORD_SUBMIT: {
      name: 'CUSTOMER_PASSWORD_SUBMIT',
      notices: ['REQUEST_ERROR_NOTICE', 'MTAN_FORM_ERROR_NOTICE'],
    },
    CUSTOMER_PASSWORD_CONFIRM: {
      name: 'CUSTOMER_PASSWORD_CONFIRM',
      notices: ['CONFIRMATION_NOTICE'],
    },
    REFER_A_FRIEND: {
      name: 'REFER_A_FRIEND',
      notices: ['REQUEST_ERROR_NOTICE', 'FORM_ERROR_NOTICE'],
    },
    TAX_EXEMPTION: {
      name: 'TAX_EXEMPTION',
      notices: ['REQUEST_ERROR_NOTICE', 'FORM_ERROR_NOTICE'],
    },
    SAVINGS_PLAN_EDIT: {
      name: 'SAVINGS_PLAN_EDIT',
      notices: ['REQUEST_ERROR_NOTICE', 'FORM_ERROR_NOTICE'],
    },
    SAVINGS_PLAN_SUBMIT: {
      name: 'SAVINGS_PLAN_SUBMIT',
      notices: ['REQUEST_ERROR_NOTICE', 'MTAN_FORM_ERROR_NOTICE'],
    },
    SAVINGS_PLAN_CONFIRM: {
      name: 'SAVINGS_PLAN_CONFIRM',
      notices: ['REQUEST_ERROR_NOTICE', 'CONFIRMATION_NOTICE', 'WAITING_TIME_NOTICE'],
    },
    REFERENCE_ACCOUNT_EDIT: {
      name: 'REFERENCE_ACCOUNT_EDIT',
      notices: ['REQUEST_ERROR_NOTICE', 'FORM_ERROR_NOTICE'],
    },
    REFERENCE_ACCOUNT_SUBMIT: {
      name: 'REFERENCE_ACCOUNT_SUBMIT',
      notices: ['REQUEST_ERROR_NOTICE', 'MTAN_FORM_ERROR_NOTICE'],
    },
    OVERNIGHT_PAY_IN_EDIT: {
      name: 'OVERNIGHT_PAY_IN_EDIT',
      notices: ['REQUEST_ERROR_NOTICE', 'FORM_ERROR_NOTICE'],
    },
    OVERNIGHT_PAY_IN_SUBMIT: {
      name: 'OVERNIGHT_PAY_IN_SUBMIT',
      notices: ['REQUEST_ERROR_NOTICE', 'MTAN_FORM_ERROR_NOTICE'],
    },
    OVERNIGHT_PAY_IN_CONFIRM: {
      name: 'OVERNIGHT_PAY_IN_CONFIRM',
      notices: ['REQUEST_ERROR_NOTICE', 'CONFIRMATION_NOTICE'],
    },
    OVERNIGHT_SAVINGS_PLAN_EDIT: {
      name: 'OVERNIGHT_SAVINGS_PLAN_EDIT',
      notices: ['REQUEST_ERROR_NOTICE', 'FORM_ERROR_NOTICE'],
    },
    OVERNIGHT_SAVINGS_PLAN_SUBMIT: {
      name: 'OVERNIGHT_SAVINGS_PLAN_SUBMIT',
      notices: ['REQUEST_ERROR_NOTICE', 'MTAN_FORM_ERROR_NOTICE'],
    },
    OVERNIGHT_SAVINGS_PLAN_CONFIRM: {
      name: 'OVERNIGHT_SAVINGS_PLAN_CONFIRM',
      notices: ['REQUEST_ERROR_NOTICE', 'CONFIRMATION_NOTICE'],
    },
    OVERNIGHT_PAY_OUT_EDIT: {
      name: 'OVERNIGHT_PAY_OUT_EDIT',
      notices: ['REQUEST_ERROR_NOTICE', 'FORM_ERROR_NOTICE'],
    },
    OVERNIGHT_PAY_OUT_SUBMIT: {
      name: 'OVERNIGHT_PAY_OUT_SUBMIT',
      notices: ['REQUEST_ERROR_NOTICE', 'MTAN_FORM_ERROR_NOTICE'],
    },
    OVERNIGHT_PAY_OUT_CONFIRM: {
      name: 'OVERNIGHT_PAY_OUT_CONFIRM',
      notices: ['REQUEST_ERROR_NOTICE', 'CONFIRMATION_NOTICE'],
    },
    CUSTOMER_PAYOUT_EDIT: {
      name: 'CUSTOMER_PAYOUT_EDIT',
      notices: ['REQUEST_ERROR_NOTICE', 'FORM_ERROR_NOTICE'],
    },
    CUSTOMER_PAYOUT_SUBMIT: {
      name: 'CUSTOMER_PAYOUT_SUBMIT',
      notices: ['REQUEST_ERROR_NOTICE', 'MTAN_FORM_ERROR_NOTICE'],
    },
    CUSTOMER_PAYOUT_CONFIRM: {
      name: 'CUSTOMER_PAYOUT_CONFIRM',
      notices: ['REQUEST_ERROR_NOTICE', 'CONFIRMATION_NOTICE'],
    },
    EARLY_TERMINATION_EDIT: {
      name: 'EARLY_TERMINATION_EDIT',
      notices: ['REQUEST_ERROR_NOTICE', 'FORM_ERROR_NOTICE'],
    },
    EARLY_TERMINATION_SUBMIT: {
      name: 'EARLY_TERMINATION_SUBMIT',
      notices: ['REQUEST_ERROR_NOTICE', 'MTAN_FORM_ERROR_NOTICE'],
    },
    EARLY_TERMINATION_CONFIRM: {
      name: 'EARLY_TERMINATION_CONFIRM',
      notices: ['REQUEST_ERROR_NOTICE'],
    },
    MY_INVESTMENTS: {
      name: 'MY_INVESTMENTS',
      notices: [
        'DISABLE_AUTOPROLONGATION_NOTICE',
        'MISSING_KYC_NOTICE',
        'MISSING_POA_NOTICE',
        'DISABLE_AUTORENEWAL_NOTICE',
        'EXPIRED_PID_NOTICE',
      ],
    },
    DEPOSIT_ACCOUNT_APPLICATION_FORM: {
      name: 'DEPOSIT_ACCOUNT_APPLICATION_FORM',
      notices: ['FILE_UPLOAD_NOTICE', 'FORM_ERROR_NOTICE'],
    },
    POSTBOX: {
      name: 'POSTBOX',
      notices: ['FILE_UPLOAD_NOTICE', 'FORM_ERROR_NOTICE'],
    },
    SET_PASSWORD: {
      name: 'SET_PASSWORD',
      notices: ['REQUEST_ERROR_NOTICE', 'FORM_ERROR_NOTICE'],
    },
  },
  notices: {
    REQUEST_ERROR_NOTICE: 'REQUEST_ERROR_NOTICE',
    AUTHORIZATION_ERROR_NOTICE: 'AUTHORIZATION_ERROR_NOTICE',
    BLOCKED_ACCOUNT_ERROR_NOTICE: 'BLOCKED_ACCOUNT_ERROR_NOTICE',
    ELIGIBILITY_NOTICE: 'ELIGIBILITY_NOTICE',
    FORM_ERROR_NOTICE: 'FORM_ERROR_NOTICE',
    MTAN_FORM_ERROR_NOTICE: 'MTAN_FORM_ERROR_NOTICE',
    CONFIRMATION_NOTICE: 'CONFIRMATION_NOTICE',
    WAITING_TIME_NOTICE: 'WAITING_TIME_NOTICE',
    MISSING_PID_NOTICE: 'MISSING_PID_NOTICE',
    EXPIRED_PID_NOTICE: 'EXPIRED_PID_NOTICE',
    MISSING_FUNDS_NOTICE: 'MISSING_FUNDS_NOTICE',
    DISABLE_AUTOPROLONGATION_NOTICE: 'DISABLE_AUTOPROLONGATION_NOTICE',
    DISABLE_AUTORENEWAL_NOTICE: 'DISABLE_AUTORENEWAL_NOTICE',
    SAVINGS_PLAN_DEBIT_DAY_NOTICE: 'SAVINGS_PLAN_DEBIT_DAY_NOTICE',
    FILE_UPLOAD_NOTICE: 'FILE_UPLOAD_NOTICE',
    MISSING_POA_NOTICE: 'MISSING_POA_NOTICE',
    MISSING_KYC_NOTICE: 'MISSING_KYC_NOTICE',
    ACCOUNT_OVERDRAFT_NOTICE: 'ACCOUNT_OVERDRAFT_NOTICE',
  },
};
