import { handle } from 'redux-pack';
import * as types from '../../../actionTypes';
import { ajaxStatus } from '../../../../config';
import { initialState } from '../../selectors';
import { setSellData } from './alterations';

export default (state = initialState, action) => {
  // NOSONAR
  const { type, payload } = action;

  switch (type) {
    case types.RESET_SELL_DATA:
      return {
        ...initialState.sell,
      };
    case types.LOAD_SELL_FORM: {
      return {
        ...state,
        ...payload,
      };
    }
    case types.SET_SELL_DATA:
      return setSellData(state, payload);
    case types.POST_SELL_DATA:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          form: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          form: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          session: {
            nonce: payload.data.nonce,
          },
          form: {
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });
    case types.POST_SELL_MTAN:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          mTanForm: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          session: {
            status: payload.response.status,
            error: payload.response.statusText,
            nonce: prevState.session.nonce,
          },
          mTanForm: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          mTanForm: {
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });
    default:
      return state;
  }
};
