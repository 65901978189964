import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';

export class Translate extends PureComponent {
  static propTypes = {
    raw: PropTypes.bool,
    intl: PropTypes.shape({
      formatHTMLMessage: PropTypes.func,
    }),
    values: PropTypes.object,
  };

  static renderHtmlTags = {
    b: (...chunks) => <strong>{chunks}</strong>,
    p: (...chunks) => <p>{chunks}</p>,
  };

  render() {
    if (this.props.raw) {
      return (
        <>
          {this.props.intl.formatHTMLMessage(
            { ...this.props },
            { ...this.props.values, ...Translate.renderHtmlTags },
          )}
        </>
      );
    }

    return <FormattedHTMLMessage {...this.props} />;
  }
}

export default injectIntl(Translate);
