import React, { Component } from 'react';
import PropTypes from 'prop-types';
import constants from 'ui-lib/src/constants';
import Alert from 'ui-lib/src/components/Alert';
import Paragraph from 'ui-lib/src/components/Paragraph';
import { getDateTime } from 'ui-lib/src/utils/GeneralUtils';
import { Translate, LocalizeConsumer } from 'components/I18n';
import styles from './styles.scss';

export default class ConfirmationNotice extends Component {
  static propTypes = {
    history: PropTypes.object,
    notices: PropTypes.object,
  };

  render() {
    const {
      notices,
      history: {
        location: { pathname },
      },
    } = this.props;
    const { date, time } = getDateTime();
    const { alerts } = constants;

    if (pathname.endsWith('confirm')) {
      return (
        <LocalizeConsumer>
          {(ctx) => (
            <Alert
              id="confirmation-notice"
              type={alerts.success}
              key="alert-confirm"
              className="confirmNotice"
              showDefault
            >
              <Paragraph className={styles.alert}>
                <Translate
                  id="order.success"
                  values={{
                    date: ctx.localizeDate(...date),
                    time,
                    mtan: notices['signature.value'],
                  }}
                />
              </Paragraph>
            </Alert>
          )}
        </LocalizeConsumer>
      );
    }

    return null;
  }
}
