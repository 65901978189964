import Keycloak from 'keycloak-js';
import { sendGtmTrackingEvent } from 'ui-lib/src/utils/GtmTracking';

import { identifyUserBy, trackEvent } from '@raisin/events-tracking';

import { getAuthBaseUrl, KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM } from 'utils/Auth/utils';
import { getLocaleFromLocalStorage } from 'utils/Locale';

import { EVENTS } from '../../eventsTracking';
import { getBasePath } from '../../components/Header/data/utils';

export const detectLoginType = (username) => {
  const validEmailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (validEmailRegex.test(username)) return 'email';

  return 'account_id';
};

export const getForgotPasswordLink = () => {
  const baseUrl = getAuthBaseUrl(window?.location?.hostname);
  const redirectUri = `${
    window?.location?.origin || 'https://www.weltsparen.de'
  }${getBasePath()}/savingglobal/#/Login`;

  const keycloak = new Keycloak({
    url: `${baseUrl}/auth/`,
    clientId: KEYCLOAK_CLIENT_ID,
    realm: KEYCLOAK_REALM,
  });

  keycloak.init();

  return keycloak
    .createLoginUrl({ redirectUri, locale: getLocaleFromLocalStorage() })
    .replaceAll('/protocol/openid-connect/auth', '/protocol/openid-connect/forgot-credentials');
};

export const sendLoginSucceededEvents = (response) => {
  if (response?.payload?.data?.customer) {
    const { bac_number } = response.payload.data.customer;

    sendGtmTrackingEvent({
      eventCategory: 'user-login',
      eventAction: `login-success`,
      eventLabel: `${bac_number}`,
    });
    identifyUserBy('bac_number', bac_number);
    trackEvent(
      {
        ...EVENTS.LOGIN_SUCCEEDED,
      },
      true,
    );
  }
};
