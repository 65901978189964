import React, { PureComponent } from 'react';
import Text from 'ui-lib/src/components/Text';
import { TranslationsProvider, Translate } from 'components/I18n';
import styles from './styles.scss';

export default class IPRiskWarningsModalContent extends PureComponent {
  render() {
    return (
      <TranslationsProvider
        translations={{
          de: () => import('./__translations__/de.json'),
        }}
        id="ip-risk-warnings-modal"
      >
        <div className={`container-fluid ${styles.modal}`}>
          <div className="col-md-12">
            <Text>
              <Translate id="IPRiskWarningsModal" />
            </Text>
          </div>
        </div>
      </TranslationsProvider>
    );
  }
}
