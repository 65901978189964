import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { stringify } from 'query-string';
import xss from 'xss';
// eslint-disable-next-line import/no-cycle
import SessionsRefresher from 'utils/Auth/SessionsRefresher';
import { isAuthConfigDone, retrieveAuthToken } from '../Auth';

import { obsRestEndpointUri } from './raisin-config';

const sessionsRefresher = new SessionsRefresher();

let headers = {
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache, no-store, must-revalidate',
};
const publicRequestHeaders = headers;

// S3 does not support CORS on OPTIONS method
// We use this caching policy to force IE/Firefox to NOT cache Ajax requests
if (process.env.NODE_ENV !== 'production') {
  headers = {
    ...headers,
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: -1,
  };
}

export const parseSettings = ({
  method,
  data,
  headers,
  validateStatus,
  withCredentials = true,
} = {}) => {
  const settings = {
    method,
    data: data ? JSON.stringify(data) : undefined,
    headers: { ...headers, ...(!withCredentials ? { ...publicRequestHeaders } : {}) },
  };

  if (validateStatus) {
    settings.validateStatus = validateStatus;
  }

  if (withCredentials) {
    settings.withCredentials = true;
  }

  return settings;
};

export const parseEndpoint = (endpoint, params, dynamicPath = {}) => {
  const querystring = !isEmpty(params) ? `?${stringify(params)}` : '';

  const finalEndpoint = Object.keys(dynamicPath).reduce((acc, item) => {
    return acc.replace(new RegExp(`{${item}}`), dynamicPath[item]);
  }, endpoint);

  return `${finalEndpoint}${querystring}`;
};

const sleep = async (sleepTime = 500) => {
  await new Promise((resolve) => setTimeout(resolve, sleepTime));
};

export const request = async (endpoint, { params, dynamicPath, ...rest } = {}) => {
  const settings = rest;

  const preventJwtHandling =
    settings.preventJwtRefresher || process.env.NODE_ENV === 'test' || !isAuthConfigDone();

  if (settings.resetSessionRefresher) {
    sessionsRefresher.reset();
  }

  if (!preventJwtHandling) {
    const user = sessionsRefresher.getUser();
    const token = user?.signInUserSession?.idToken?.jwtToken || retrieveAuthToken()?.access_token;

    if (settings.useJwt) {
      settings.headers = {
        ...settings.headers,
        Authorization: `Bearer ${token}`,
      };
      // sessionsRefresher.refreshJsessionid();
    }
  }

  if (process.env.NODE_ENV === 'local') {
    await sleep(500);
  }
  const response = await axios(
    parseEndpoint((await obsRestEndpointUri(settings.useJwt)) + endpoint, params, dynamicPath),
    parseSettings(settings),
  );

  if (dynamicPath && Object.keys(dynamicPath).length) {
    return { ...response, params: dynamicPath };
  }

  return response;
};

export default {
  get: (endpoint, params, settings, dynamicPath = {}) => {
    return request(endpoint, {
      method: 'get',
      headers,
      ...params,
      dynamicPath,
      ...settings,
    });
  },
  post: (endpoint, data, settings, dynamicPath = {}) => {
    const sanitizedData = cloneDeep(data, (value) => xss(value));

    return request(endpoint, {
      method: 'post',
      headers,
      data: sanitizedData,
      dynamicPath,
      ...settings,
    });
  },
  put: (endpoint, data, settings, dynamicPath = {}) => {
    const sanitizedData = cloneDeep(data, (value) => xss(value));

    return request(endpoint, {
      method: 'put',
      headers,
      data: sanitizedData,
      dynamicPath,
      ...settings,
    });
  },
  // Axios has a very well known problem on sending FormData. It removes it from request.
  // After research we decide to create this options to deal with this edge case.
  upload: (endpoint, data, _, dynamicPath = {}) => {
    const content = data.get('content');
    const sanitizedContent = xss(content);

    data.set('content', sanitizedContent);

    return new Promise((resolve, reject) => {
      try {
        const xhr = new XMLHttpRequest();

        xhr.open('POST', parseEndpoint(endpoint, data, dynamicPath), true);
        const csrfToken = sessionStorage.getItem('x-csrf-token');

        // add anti csrf token to headers to avoid csrf vulnerabilities
        xhr.setRequestHeader('x-csrf-token', csrfToken);
        xhr.onreadystatechange = () => {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            const { status } = xhr;

            if (status === 0 || (status >= 200 && status < 400)) {
              resolve({ status });
            } else {
              const error = { error: xhr.statusText };

              reject(error);
            }
          }
        };
        xhr.send(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
