import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { getCurrencyForLocale, getLocaleFromLocalStorage } from 'utils/Locale';
import LocalizeContext from '../LocalizeContext';

// eslint-disable-next-line no-underscore-dangle
const locale = getLocaleFromLocalStorage(); // this method will defualt to 'de';
const currency = getCurrencyForLocale(locale);

export class LocalizeProvider extends Component {
  static propTypes = {
    intl: PropTypes.object,
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);
    this.localizePercent = this.localizePercent.bind(this);
    this.localizeMoney = this.localizeMoney.bind(this);
    this.localizeDate = this.localizeDate.bind(this);
    this.localizeNumber = this.localizeNumber.bind(this);
    this.formatHTMLMessage = this.formatHTMLMessage.bind(this);
    this.formatDate = this.formatDate.bind(this);
  }

  localizePercent(value, options) {
    return this.props.intl.formatNumber(value, {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      ...options,
    });
  }

  localizeMoney(value, options = {}) {
    return this.props.intl.formatNumber(value, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      ...options,
    });
  }

  localizeDate(year, month, day, options) {
    const fallbackOptions = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      ...options,
    };
    const date = new Date(year, Number(month) - 1, day);

    return this.props.intl.formatDate(date, fallbackOptions);
  }

  formatDate(date, options) {
    return this.props.intl.formatDate(date, options);
  }

  localizeNumber(value, options) {
    return this.props.intl.formatNumber(value, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      ...options,
    });
  }

  formatHTMLMessage(messageDescriptor, values) {
    return this.props.intl.formatHTMLMessage(messageDescriptor, values);
  }

  render() {
    return (
      <LocalizeContext.Provider
        value={{
          localizeMoney: this.localizeMoney,
          localizePercent: this.localizePercent,
          localizeDate: this.localizeDate,
          localizeNumber: this.localizeNumber,
          formatHTMLMessage: this.formatHTMLMessage,
          formatDate: this.formatDate,
        }}
      >
        {this.props.children}
      </LocalizeContext.Provider>
    );
  }
}

export default injectIntl(LocalizeProvider);
