import { POST_LOGOUT, POST_CUSTOMER_LOGIN_CREDENTIALS } from './actionTypes';
import { appReducer } from './appReducer';

const rootReducer = (state, action) => {
  if (action.type === POST_LOGOUT || action.type === POST_CUSTOMER_LOGIN_CREDENTIALS) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
