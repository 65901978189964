const distributors = {
  COBASHORT: 'COBASHORT',
  COBAFULL: 'COBAFULL',
  JUSTETF: 'justetf',
  RAISIN_BUSINESS: 'Raisin_business',
  RAISIN: 'Raisin',
  PAYPAL: 'Paypal',
};

export const isJustEtfCustomer = (customer) => customer.distributor_id === distributors.JUSTETF;

export default distributors;
