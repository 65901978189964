export const HELP_CENTER_LINKS = {
  AUT: 'https://kundenservice.weltsparen.at/hc/de',
  DEU: 'https://kundenservice.weltsparen.de/hc/de',
  ESP: 'https://www.raisin.es/contacto/',
  EUR: 'https://help.raisin.com/hc/en-us/',
  IRL: 'https://help.raisin.ie/hc/en-gb/',
  NLD: 'https://www.raisin.nl/service-en-contact/',
  POL: 'https://pomoc.raisin.com/hc/pl-pl',
  FIN: '', // New Zendesk link
};
