export const LEGAL_LINKS = {
  DEU: [
    {
      id: 'footer-imprint',
      text: 'footer.imprint.link.siteInformation',
      title: 'Site Information',
      url: {
        external: '/impressum/',
      },
    },
    {
      id: 'footer-privacy',
      text: 'footer.imprint.link.privacyPolicy',
      title: 'Privacy Policy',
      url: {
        external: '/datenschutz/',
      },
    },
    {
      id: 'footer-security',
      text: 'footer.imprint.link.security',
      title: 'Security',
      url: {
        external: '/it-sicherheit/',
      },
    },
    {
      id: 'footer-terms',
      text: 'footer.imprint.link.termsAndConditions',
      title: 'Terms and Conditions',
      url: {
        external: '/agb/',
      },
    },
    {
      id: 'footer-userCentric',
      text: 'footer.imprint.link.privacySettings',
      title: 'Privacy Settings',
      url: '#uc-corner-modal-show',
    },
  ],

  AUT: [
    {
      id: 'footer-imprint',
      text: 'footer.imprint.link.siteInformation',
      title: 'Site Information',
      url: {
        external: '/impressum/',
      },
    },
    {
      id: 'footer-privacy',
      text: 'footer.imprint.link.privacyPolicy',
      title: 'Privacy Policy',
      url: {
        external: '/datenschutz/',
      },
    },
    {
      id: 'footer-security',
      text: 'footer.imprint.link.security',
      title: 'Security',
      url: {
        external: '/it-sicherheit/',
      },
    },
    {
      id: 'footer-terms',
      text: 'footer.imprint.link.termsAndConditions',
      title: 'Terms and Conditions',
      url: {
        external: '/agb/',
      },
    },
    {
      id: 'footer-userCentric',
      text: 'footer.imprint.link.privacySettings',
      title: 'Privacy Settings',
      url: '#uc-corner-modal-show',
    },
  ],

  EUR: [
    {
      id: 'footer-imprint',
      text: 'footer.imprint.link.siteInformation',
      title: 'Site Information',
      url: {
        external: '/site-information/',
      },
    },
    {
      id: 'footer-privacy',
      text: 'footer.imprint.link.privacyPolicy',
      title: 'Privacy Policy',
      url: {
        external: '/privacy-policy/',
      },
    },
    {
      id: 'footer-security',
      text: 'footer.imprint.link.security',
      title: 'Security',
      url: {
        external: '/security/',
      },
    },
    {
      id: 'footer-terms',
      text: 'footer.imprint.link.termsAndConditions',
      title: 'Terms and Conditions',
      url: {
        external: '/terms/',
      },
    },
    {
      id: 'footer-userCentric',
      text: 'footer.imprint.link.privacySettings',
      title: 'Privacy Settings',
      url: '#uc-corner-modal-show',
    },
  ],

  GBR: [
    {
      id: 'footer-privacy',
      text: 'footer.imprint.link.privacyPolicy',
      title: 'Privacy Policy',
      url: {
        external: '/privacy-policy/',
      },
    },
    {
      id: 'footer-cookie',
      text: 'footer.imprint.link.cookiePolicy',
      title: 'Cookie Policy',
      url: {
        external: '/cookie-policy/',
      },
    },
    {
      id: 'footer-security',
      text: 'footer.imprint.link.security',
      title: 'Security',
      url: {
        external: '/security/',
      },
    },
    {
      id: 'footer-terms',
      text: 'footer.imprint.link.termsAndConditions',
      title: 'Terms & conditions',
      url: {
        external: '/terms-and-conditions/',
      },
    },
    {
      id: 'footer-imprint',
      text: 'footer.imprint.link.siteInformation',
      title: 'Site Information',
      url: {
        external: '/disclaimer',
      },
    },
    {
      id: 'footer-userCentric',
      text: 'footer.imprint.link.privacySettings',
      title: 'Privacy Settings',
      url: '#uc-corner-modal-show',
    },
  ],

  IRL: [
    {
      id: 'footer-imprint',
      text: 'footer.imprint.link.siteInformation',
      title: 'Site Information',
      url: {
        external: '/site-information/',
      },
    },
    {
      id: 'footer-privacy',
      text: 'footer.imprint.link.privacyPolicy',
      title: 'Private Policy',
      url: {
        external: '/privacy-policy/',
      },
    },
    {
      id: 'footer-security',
      text: 'footer.imprint.link.security',
      title: 'Security',
      url: {
        external: '/security/',
      },
    },
    {
      id: 'footer-terms',
      text: 'footer.imprint.link.termsAndConditions',
      title: 'Terms and Conditions',
      url: {
        external: '/terms/',
      },
    },
    {
      id: 'footer-userCentric',
      text: 'footer.imprint.link.privacySettings',
      title: 'Privacy Settings',
      url: '#uc-corner-modal-show',
    },
  ],

  FRA: [
    {
      id: 'footer-imprint',
      text: 'footer.imprint.link.siteInformation',
      title: 'Site Information',
      url: {
        external: '/mentions-legales/',
      },
    },
    {
      id: 'footer-privacy',
      text: 'footer.imprint.link.privacyPolicy',
      title: 'Privacy Policy',
      url: {
        external: '/protection-des-donnees/',
      },
    },
    {
      id: 'footer-security',
      text: 'footer.imprint.link.security',
      title: 'Security',
      url: {
        external: '/securite-informatique/',
      },
    },
    {
      id: 'footer-terms',
      text: 'footer.imprint.link.termsAndConditions',
      title: 'Terms and Conditions',
      url: {
        external: '/conditions/',
      },
    },
    {
      id: 'footer-userCentric',
      text: 'footer.imprint.link.privacySettings',
      title: 'Privacy Settings',
      url: '#uc-corner-modal-show',
    },
  ],

  NLD: [
    {
      id: 'footer-imprint',
      text: 'footer.imprint.link.siteInformation',
      title: 'Site Information',
      url: {
        external: '/disclaimer/',
      },
    },
    {
      id: 'footer-privacy',
      text: 'footer.imprint.link.privacyPolicy',
      title: 'Privacy Policy',
      url: {
        external: '/privacybeleid/',
      },
    },
    {
      id: 'footer-security',
      text: 'footer.imprint.link.security',
      title: 'Security',
      url: {
        external: '/veiligheid/',
      },
    },
    {
      id: 'footer-terms',
      text: 'footer.imprint.link.termsAndConditions',
      title: 'Terms and Conditions',
      url: {
        external: '/algemene-voorwaarden/',
      },
    },
    {
      id: 'footer-userCentric',
      text: 'footer.imprint.link.privacySettings',
      title: 'Privacy Settings',
      url: '#uc-corner-modal-show',
    },
  ],

  ESP: [
    {
      id: 'footer-imprint',
      text: 'footer.imprint.link.siteInformation',
      title: 'Site Information',
      url: {
        external: '/aviso-legal/',
      },
    },
    {
      id: 'footer-privacy',
      text: 'footer.imprint.link.privacyPolicy',
      title: 'Privacy Policy',
      url: {
        external: '/privacidad/',
      },
    },
    {
      id: 'footer-cookie',
      text: 'footer.imprint.link.cookiePolicy',
      title: 'Cookie Policy',
      url: {
        external: '/privacidad/#paragraph-3',
      },
    },
    {
      id: 'footer-security',
      text: 'footer.imprint.link.security',
      title: 'Security',
      url: {
        external: '/seguridad/',
      },
    },
    {
      id: 'footer-terms',
      text: 'footer.imprint.link.termsAndConditions',
      title: 'Terms and Conditions',
      url: {
        external: '/terminos-condiciones/',
      },
    },
    {
      id: 'footer-userCentric',
      text: 'footer.imprint.link.privacySettings',
      title: 'Privacy Settings',
      url: '#uc-corner-modal-show',
    },
  ],

  POL: [
    {
      id: 'footer-imprint',
      text: 'footer.imprint.link.siteInformation',
      title: 'Site Information',
      url: {
        external: '/pl-pl/nota-prawna/',
      },
    },
    {
      id: 'footer-privacy',
      text: 'footer.imprint.link.privacyPolicy',
      title: 'Privacy Policy',
      url: {
        external: '/pl-pl/polityka-prywatnosci/',
      },
    },
    {
      id: 'footer-security',
      text: 'footer.imprint.link.security',
      title: 'Security',
      url: {
        external: '/pl-pl/bezpieczenstwo/',
      },
    },
    {
      id: 'footer-terms',
      text: 'footer.imprint.link.termsAndConditions',
      title: 'Terms and Conditions',
      url: {
        external: '/pl-pl/regulamin/',
      },
    },
    {
      id: 'footer-userCentric',
      text: 'footer.imprint.link.privacySettings',
      title: 'Privacy Settings',
      url: '#uc-corner-modal-show',
    },
  ],
  FIN: [
    {
      id: 'footer-imprint',
      text: 'footer.imprint.link.siteInformation',
      title: 'Site Information',
      url: {
        external: '/fi-fi/footer-imprint/',
      },
    },
    {
      id: 'footer-privacy',
      text: 'footer.imprint.link.privacyPolicy',
      title: 'Privacy Policy',
      url: {
        external: '/fi-fi/privacy-policy/',
      },
    },
    {
      id: 'footer-cookie',
      text: 'footer.imprint.link.cookiePolicy',
      title: 'Cookie Policy',
      url: {
        external: '/fi-fi/cookie-policy/',
      },
    },
    {
      id: 'footer-security',
      text: 'footer.imprint.link.security',
      title: 'Security',
      url: {
        external: '/fi-fi/security/',
      },
    },
    {
      id: 'footer-terms',
      text: 'footer.imprint.link.termsAndConditions',
      title: 'Terms & conditions',
      url: {
        external: '/fi-fi/terms-and-conditions/',
      },
    },
    {
      id: 'footer-userCentric',
      text: 'footer.imprint.link.privacySettings',
      title: 'Privacy Settings',
      url: '#uc-corner-modal-show',
    },
  ],
};

export const CREDITS = {
  AUT: [
    {
      logo: 'aut-tuv-raisin.svg',
      title: 'tuv',
      url: 'https://www.tuev-saar.de/zertifikat/tk45016/',
    },
  ],

  DEU: [
    {
      logo: 'climate-action.svg',
      title: 'climateAction',
      url: '',
    },
    {
      logo: 'deu-tuv-raisin.svg',
      title: 'tuv',
      url: 'https://www.tuev-saar.de/zertifikat/tk45015/',
    },
  ],

  EUR: [],

  IRL: [],

  GBR: [],

  NLD: [],

  FRA: [],

  ESP: [
    {
      logo: 'sello_confianza-online.svg',
      title: 'Confianza Online',
      url: 'https://www.confianzaonline.es/empresas/raisin.htm',
    },
  ],

  POL: [],
  FIN: [],
};
