const NUMBER_OF_RECORDS = 20 * 52;

// will return true if there was at least 1 month difference between two provided dates.
// we do not count by days, but simply compare the months.
export const isMonthDifferent = (d1, d2) => {
  return !(d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear());
};

// Calculate the amountDevelopment values
export const generateAmountDevelopment = (amount, amountSP, dates) => {
  if (!amountSP) {
    return null;
  }

  const amountDevelopmentData = [];
  let amountDevelopment = amount;

  dates.forEach((date, index) => {
    if (isMonthDifferent(new Date(date), new Date(dates[index - 1]))) {
      amountDevelopment += amountSP;
    }

    amountDevelopmentData.push([index, amountDevelopment]);
  });

  return amountDevelopmentData;
};

/*
 * Calculate the performance for the passed projection type:
 * If SP is active, the SP contribution is also calculated.
 */
export const generatePerformace = (performance, amount, amountSP, dates) => {
  const calculatedPerformance = [];
  let previousValue = null;

  dates.forEach((date, index) => {
    let currentValue = null;

    // for the calculation of the first value, the formula is slighly different
    if (index === 0) {
      currentValue = performance[0] * amount;
    } else {
      const performanceWithoutSp = previousValue * (performance[index] / performance[index - 1]);

      // if the month changed, we add a SP contribution
      if (amountSP && isMonthDifferent(new Date(date), new Date(dates[index - 1]))) {
        currentValue = performanceWithoutSp + amountSP;
      } else {
        currentValue = performanceWithoutSp;
      }
    }

    calculatedPerformance.push([index, Math.round(currentValue)]);
    previousValue = currentValue;
  });

  return calculatedPerformance;
};

// create an array with the number of weeks that we have data for
export const generateWeeks = (date) => date.slice(0, NUMBER_OF_RECORDS).map((_, index) => index);

// get the median value of an array
export const getMiddleValue = (array) => array[Math.floor(array.length / 2)];

// finds the y-axis tick and normalize it
export const getYAxisValueNormalized = (maxValue) => {
  const yAxisValue = maxValue / 3;
  const numberOfDigits = `${parseInt(yAxisValue, 10)}`.length;
  const yAxisValueNormalized =
    Math.ceil(yAxisValue / 10 ** (numberOfDigits - 2)) * 10 ** (numberOfDigits - 2);

  return yAxisValueNormalized;
};

// get the maximum value for the portfolio
export const getMaxValue = (performance) => {
  return performance[NUMBER_OF_RECORDS - 1][1];
};
