import { handle } from 'redux-pack';
import { initialState } from '../selectors';
import * as types from '../../actionTypes';


export default (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case types.FETCH_DASHBOARD_DATA:
      return handle(state, action, {
        success: (prevState) => ({
          ...prevState,
          data: payload.data,
        }),
      });
    case types.FETCH_PENSION_PRODUCTS_DASHBOARD_DATA:
      return handle(state, action, {
        success: (prevState) => ({
          ...prevState,
          pensionProducts: {
            products: payload.data.products,
            status: payload.status,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          pensionProducts: {
            status: payload.status,
          },
        }),
      });
    case types.DAS_FETCH_DEPOSITS_BALANCE:
      return handle(state, action, {
        success: (prevState) => ({
          ...prevState,
          dasDepositsBalance: payload?.data,
        }),
      });
    default:
      return state;
  }
};
