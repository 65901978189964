import React, { memo, useState } from 'react';

import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Input from 'ui-lib/src/components/Input';
import Label from 'ui-lib/src/components/Label';

import * as TRANSLATION_KEYS from './__translations__';
import { OTP_LENGTH } from './shared/constants';
import styles from './styles.scss';

export const OTPInput = memo(({ isDisabled, onChange, onEnter }) => {
  const { formatMessage } = useIntl();
  const [value, setValue] = useState();

  const handleKeyDown = (e) => {
    if (e.code === 'Enter') {
      onEnter(e);

      return true;
    }

    if ((e.ctrlKey || e.metaKey) && ['a', 'v'].includes(e.key)) return true; // allow select & paste

    if (['ArrowLeft', 'ArrowRight', 'Backspace'].includes(e.code)) return true; // allow avigation inside input

    if ((!value || value.length < OTP_LENGTH) && /[0-9]/.test(e.key)) return true; // allow digits

    e.preventDefault();

    return false;
  };

  const handleChange = (e) => {
    const val = e.target.value;

    setValue(val);
    onChange(val);
  };

  return (
    <div className={styles.otpInputWrapper}>
      <Label htmlFor="otp-code">mTAN</Label>
      <Input
        autoFocus
        id="otp-code"
        inputType="text"
        autoComplete="off"
        pattern="^[0-9]{6}$"
        value={value}
        placeholder={formatMessage({ id: TRANSLATION_KEYS.INPUT_PLACEHOLDER })}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        isDisabled={isDisabled}
      />
    </div>
  );
});

OTPInput.displayName = 'OTPInput';

OTPInput.propTypes = {
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired,
};
