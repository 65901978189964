import React from 'react';
import { Translate } from 'components/I18n';

export const formatMessage = (msg, values) => <Translate id={msg} values={values} />;

export const formatError = (msg) => <Translate id={msg} raw />;

export const OBS_HOST_APP = 'obs-beta';

export const isRunningLocally = () =>
  (!process.env.OBS_REST_ENDPOINT_URI || !process.env.KEYCLOAK_ENDPOINT_URI) &&
  process.env.NODE_ENV === 'local' &&
  !process.env.OBS_LOCAL;
