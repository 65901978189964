import React, { Component } from 'react';

import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from 'ui-lib/src/components/Button';
import Heading from 'ui-lib/src/components/Heading';
import Input from 'ui-lib/src/components/Input';
import Label from 'ui-lib/src/components/Label';
import Paragraph from 'ui-lib/src/components/Paragraph';
import { sendGtmTrackingEvent } from 'ui-lib/src/utils/GtmTracking';

import { Translate, TranslationsProvider } from 'components/I18n';
import { LINKS } from 'components/Layout/menu';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import Notices from 'components/Notices';
import { noticesConfig } from 'components/Notices/config';
import PasswordInput from 'components/PasswordInput';
import {
  authenticateWithKeycloak,
  fetchCustomerDataForToken,
  postPasswordInitial,
  setCustomerData,
} from 'store/customer/actions';
import { storeAuthConfigDone, storeAuthToken } from 'utils/Auth';
import { isFromCobaShort } from 'utils/CustomerUtils';
import { reportError } from 'utils/ErrorHandling';
import { getFeatureFlagClient } from 'utils/FeatureFlag';
import { formatMessage } from 'utils/React';
import Validator from 'utils/Validator';

import { trackEvent } from '@raisin/events-tracking';
import styles from './styles.scss';
import { getKeycloakAuth, getRedirectAfterLogin } from './utils';
import { EVENTS } from '../../eventsTracking';

export const VERIFICATION_ID_KEY = 'verification_id';

const passwordConfig = {
  min: 8,
  max: 64,
};

export const formikEnhancer = withFormik({
  mapPropsToValues: () => ({
    password: '',
    passwordConfirm: '',
  }),
  validationSchema: () => {
    return Validator.generateImprovedSchema([
      {
        name: 'password',
        validation: {
          type: 'string',
          min: passwordConfig.min,
          max: passwordConfig.max,
          isRequired: true,
        },
      },
      {
        name: 'passwordConfirm',
        validation: {
          type: 'string',
          equalsField: 'password',
          min: passwordConfig.min,
          max: passwordConfig.max,
          isRequired: true,
        },
      },
    ]);
  },
  handleSubmit: async (values, { props }) => {
    const { password, passwordConfirm } = values;
    const { history, postPasswordInitial, authenticateWithKeycloak, setCustomerData, bac } = props;
    const { token, ...queryParameters } = parse(history.location.search);

    sendGtmTrackingEvent({
      eventCategory: 'obs-set-password',
      eventAction: 'sp-submit',
      eventNonInteraction: false,
    });

    const res = await postPasswordInitial({ password, repeat_password: passwordConfirm, token });

    if (res.error) {
      return;
    }

    const client = await getFeatureFlagClient()
      .then((client) => {
        if (client) {
          return client;
        }
        reportError(`Initializing LaunchDarkly client incorrectly. LD client value:`, client);

        return false;
      })
      .catch((err) => reportError(`Failed iniatizing LaunchDarkly client.`, err));

    if (client?.allFlags()?.featureKeycloakTokenForRegistrationFlow) {
      const verificationId = client.allFlags().userManagementOTPDuringRegistration
        ? localStorage.getItem(VERIFICATION_ID_KEY)
        : null;
      const authTokenRes = await authenticateWithKeycloak({
        username: res.payload?.data?.email,
        password,
        KeycloakAuth: getKeycloakAuth(),
        verificationId,
      }).catch((error) => {
        const errorMsg = 'Error fetching JWT from Keycloak';

        reportError(errorMsg, error);
        sendGtmTrackingEvent({
          eventCategory: 'user-login',
          eventAction: `login-failure`,
          eventLabel: errorMsg,
        });
        trackEvent({ ...EVENTS.LOGIN_FAILED, customAttribute1: bac });
      });

      const authToken = authTokenRes?.payload?.token;

      if (authToken) {
        storeAuthToken(authToken);
        storeAuthConfigDone(true);
        setCustomerData({ isLoggedIn: true });
      }
    }

    getRedirectAfterLogin(queryParameters, history);
  },
});

export class SetPassword extends Component {
  state = {
    allDataLoaded: false,
  };

  static propTypes = {
    name: PropTypes.string,
    bac: PropTypes.string,
    distributorId: PropTypes.string,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      location: PropTypes.shape({
        search: PropTypes.string,
      }).isRequired,
    }).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    values: PropTypes.shape({
      password: PropTypes.string.isRequired,
      passwordConfirm: PropTypes.string.isRequired,
    }).isRequired,
    errors: PropTypes.shape({
      password: PropTypes.object,
      passwordConfirm: PropTypes.object,
    }).isRequired,
    touched: PropTypes.shape({
      password: PropTypes.bool,
      passwordConfirm: PropTypes.bool,
    }).isRequired,
    isPosting: PropTypes.bool.isRequired,
    fetchCustomerDataForToken: PropTypes.func.isRequired,
    postPasswordInitial: PropTypes.func.isRequired,
    authenticateWithKeycloak: PropTypes.func.isRequired,
    locale: PropTypes.string,
    isCompanyCustomer: PropTypes.bool,
    country: PropTypes.string,
    setCustomerData: PropTypes.func.isRequired,
  };

  redirectToLogin = () => {
    this.props.history.push(LINKS.LOGIN);
  };

  componentDidMount() {
    const { history, fetchCustomerDataForToken } = this.props;
    const { token, product } = parse(history.location.search);

    if (!token) {
      this.redirectToLogin();
    } else {
      fetchCustomerDataForToken(token).then(() => {
        this.setState({ allDataLoaded: true });
        const { bac, isCompanyCustomer } = this.props;

        sendGtmTrackingEvent({ event: 'bac_number_done', bacNumber: bac }, false);
        sendGtmTrackingEvent(
          {
            regOption: '2step',
            pageType: 'Registration Page',
            regStep: 3,
            business: isCompanyCustomer ? 1 : 0,
            retail: isCompanyCustomer ? 0 : 1,
            customer: bac,
          },
          false,
        );
        sendGtmTrackingEvent({
          eventCategory: 'obs-set-password',
          eventAction: 'sp-show',
          eventLabel: product,
          eventProperty: bac,
          eventNonInteraction: true,
        });
      });
    }
  }

  renderContent() {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleSubmit,
      name,
      distributorId,
      isPosting,
      locale,
      isCompanyCustomer,
    } = this.props;

    if (locale === undefined || isCompanyCustomer === undefined) {
      return (
        <div>
          <Notices page={noticesConfig.pages.SET_PASSWORD.name} />
          <div className={styles.center}>
            <Button isPrimary onClick={this.redirectToLogin}>
              <Translate id="toLogin" />
            </Button>
          </div>
        </div>
      );
    }

    return (
      <>
        <Heading level={2} className={styles.center}>
          <Translate id="heading" values={{ name }} />
        </Heading>
        <hr />
        <Notices page={noticesConfig.pages.SET_PASSWORD.name} />
        <div className="row">
          <div className="col-lg-8 col-lg-offset-2">
            <Paragraph className={styles.center}>
              {isFromCobaShort(distributorId) ? (
                <Translate id="intro.coba" />
              ) : (
                <Translate id="intro" />
              )}
            </Paragraph>
            <form className={styles.form} onSubmit={handleSubmit}>
              <div className="row">
                <Label
                  label={<Translate id="password.label" />}
                  htmlFor="password"
                  className="col-sm-4"
                />
                <div className="col-sm-8 no-padding">
                  <PasswordInput
                    id="password"
                    name="password"
                    onChange={handleChange}
                    error={errors.password && touched.password ? errors.password : null}
                    value={values.password}
                    formatError={formatMessage}
                    inputWrapperClassName={`col-sm-6 ${styles.inputWrapper}`}
                  />
                </div>
              </div>
              <div className={`row ${styles.formGroup}`}>
                <div className={`col-sm-5 col-sm-offset-4 ${styles.hint}`}>
                  <Translate id="password.hint" />
                </div>
              </div>
              <div className={`row ${styles.formGroup}`}>
                <Label
                  label={<Translate id="passwordConfirm.label" />}
                  htmlFor="passwordConfirm"
                  className="col-sm-4"
                />
                <div className="col-sm-4">
                  <Input
                    id="passwordConfirm"
                    name="passwordConfirm"
                    onChange={handleChange}
                    error={
                      errors.passwordConfirm && touched.passwordConfirm
                        ? errors.passwordConfirm
                        : null
                    }
                    inputType="password"
                    value={values.passwordConfirm}
                    formatError={formatMessage}
                  />
                </div>
              </div>
              <div className={`row ${styles.formGroup}`}>
                <div className={`col-sm-8 ${styles.buttonWrapper}`}>
                  <Button
                    id="setPassword"
                    isPrimary
                    isLoading={isPosting}
                    isDisabled={isPosting}
                    type="submit"
                  >
                    <Translate id="button" />
                  </Button>
                </div>
              </div>
              <div className={styles.hint}>
                <Translate id="form.hint" />
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { allDataLoaded } = this.state;

    if (!allDataLoaded) {
      return <LoadingPlaceholder height={300} width="100%" />;
    }

    return (
      <TranslationsProvider
        translations={{
          de: () => import('./__translations__/de.json'),
          'en-US': () => import('./__translations__/en-US.json'),
          'en-GB': () => import('./__translations__/en-GB.json'),
          'en-IE': () => import('./__translations__/en-IE.json'),
          'pl-PL': () => import('./__translations__/pl-PL.json'),
          'fi-FI': () => import('./__translations__/fi-FI.json'),
          es: () => import('./__translations__/es.json'),
          fr: () => import('./__translations__/fr.json'),
          nl: () => import('./__translations__/nl.json'),
        }}
        id="setPassword"
      >
        {this.renderContent()}
      </TranslationsProvider>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  name: state.customer.display_name,
  bac: state.customer.bac_number,
  distributorId: state.customer.distributor_id,
  isPosting: state.ui.isPosting,
  locale: state.customer.locale,
  isCompanyCustomer: state.customer.is_company_customer,
  country: state.customer.default_address.country,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
  fetchCustomerDataForToken: (token) => dispatch(fetchCustomerDataForToken(token)),
  postPasswordInitial: (data) => dispatch(postPasswordInitial(data)),
  authenticateWithKeycloak: (data) => dispatch(authenticateWithKeycloak(data)),
  setCustomerData: (data) => dispatch(setCustomerData(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(formikEnhancer(SetPassword)));
