import React, { Component, cloneElement } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LINKS } from 'components/Layout/menu';
import { reportError } from 'utils/ErrorHandling';
import {
  fetchAccounts,
  fetchCustomerData,
  fetchTamsTransactionAccount,
  fetchCASCustomerData,
  fetchCustomerValidityStatus,
} from 'store/customer/actions';
import { getFeatureFlagClient } from 'utils/FeatureFlag';
import { getRegionName, GBR_REGION } from 'utils/IPUtils';
import { isLoggedIn, getBACNumberFromJWT, getTransactionsAccountData } from 'utils/CustomerUtils';
import GtmTrackingSetup from 'utils/GtmTracking';
import { identifyUserBy } from '@raisin/events-tracking';

export class Authorization extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    default_address: PropTypes.object.isRequired,
    fetchCustomerData: PropTypes.func.isRequired,
    fetchTransactionAccount: PropTypes.func.isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
    fetchCustomerValidityStatus: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      allDataLoaded: false,
      b2cDeprecateObs: null,
    };
  }

  componentDidMount() {
    getFeatureFlagClient()
      .then((client) => {
        if (client) {
          this.setState(() => ({
            b2cDeprecateObs: client?.allFlags()?.b2cDeprecateObs,
          }));
        } else {
          reportError('Initializing LaunchDarkly client incorrectly. LD client value:', client);
        }
      })
      .catch((err) => reportError('Failed iniatizing LaunchDarkly client.', err));
  }

  componentDidUpdate() {
    const {
      fetchCustomerData: fetchCustomerDataFromOBS,
      fetchCASCustomerData: fetchCustomerDataFromCAS,
      fetchCustomerValidityStatus: getCustomerValidityStatus,
      fetchTransactionAccount,
      fetchAccountsDispatch,
      tamsTransactionAccount,
      history,
    } = this.props;

    const { allDataLoaded, b2cDeprecateObs } = this.state;

    if (!allDataLoaded && b2cDeprecateObs !== null) {
      const getCustomerData = !b2cDeprecateObs
        ? fetchCustomerDataFromOBS
        : fetchCustomerDataFromCAS;

      if (isLoggedIn()) {
        getTransactionsAccountData({
          fetchTransactionAccount,
          fetchAccountsDispatch,
          tamsTransactionAccount,
        });
        const bacNumber = getBACNumberFromJWT();

        getCustomerData(bacNumber, b2cDeprecateObs).then(() => {
          this.setState({ allDataLoaded: true });

          if (getRegionName() !== GBR_REGION) {
            getCustomerValidityStatus(bacNumber);
          }
          identifyUserBy('bac_number', bacNumber);
        });
      } else {
        history.push(LINKS.LOGIN);
      }
    }
  }

  render() {
    const { children } = this.props;
    const { allDataLoaded } = this.state;

    if (!allDataLoaded) return null;

    return (
      <>
        <GtmTrackingSetup />
        {cloneElement(children, { ...this.state })}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  default_address: state.customer.default_address,
  bac_number: state.customer.bac_number,
  tamsTransactionAccount: state.customer.tamsTransactionAccount,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAccountsDispatch: (bacNumber) => dispatch(fetchAccounts(bacNumber)),
  fetchTransactionAccount: (bac_number) => dispatch(fetchTamsTransactionAccount(bac_number)),
  fetchCASCustomerData: (bacNumber, b2cDeprecateObs) =>
    dispatch(fetchCASCustomerData(bacNumber, b2cDeprecateObs)),
  // eslint-disable-next-line no-unused-vars
  fetchCustomerData: (_bacNumber, _b2cDeprecateObs) => dispatch(fetchCustomerData()),
  fetchCustomerValidityStatus: (bac_number) => dispatch(fetchCustomerValidityStatus(bac_number)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Authorization));
