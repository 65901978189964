import { handle } from 'redux-pack';

import * as types from '../../actionTypes';
import { initialState } from '../selectors';

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_FEATURE_FLAGS:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          isFetchingFeatureFlags: true,
        }),
        success: (prevState) => {
          const flags = payload?.allFlags();

          return {
            ...prevState,
            featureFlags: flags,
          };
        },
        finish: (prevState) => ({
          ...prevState,
          isFetchingFeatureFlags: false,
        }),
      });
    default:
      return state;
  }
};
