import React from 'react';
import { Translate } from 'components/I18n';
import Link from 'ui-lib/src/components/Link';
import Image from 'components/Image';
import styles from './styles.scss';

const TrustpilotBar = ({ trustpilotData }) => {
  const TRUSTPILOT_URL = 'https://de.trustpilot.com/review/www.weltsparen.de';
  /**
   * NOTE:
   * If the trustpilotData is empty, to avoid any issue related how we calculate the ratings
   * what we do is set the value to 0, with this the default case will be load the 0 star image
   */
  const stars = trustpilotData?.score_value ? Math.round(trustpilotData?.score_value * 2) / 2 : 0;

  return (
    <div className={styles.background}>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.innerWrapper}>
            <Translate id="footer.trustpilot.message" />

            <div className={styles.starsContainer}>
              <Link className={styles.starsLink} href={TRUSTPILOT_URL} target="_blank">
                <Image className={styles.stars} src={`${stars}_stars.svg`} alt="tp stars" />
                <span className={styles.starsText} data-testid="trustpilot-stars" fontWeight={700}>
                  <Translate id="footer.trustpilot.stars.text" />
                </span>
              </Link>
            </div>

            <p className={styles.reviewsText}>
              <Translate
                id="footer.trustpilot.reviews.text"
                values={{ countOfReviews: trustpilotData?.count_of_reviews }}
              />
            </p>
          </div>

          <Link className={styles.logoLink} href={TRUSTPILOT_URL} target="_blank">
            <Image src="trustPilot-icon.svg" alt="trustpilot" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TrustpilotBar;
