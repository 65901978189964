import { createStore, applyMiddleware, compose } from 'redux';
import { middleware as reduxPackMiddleware } from 'redux-pack';
import { setCustomerData } from 'store/customer/actions';
import { cleanUpSessionData } from '../utils/CustomerUtils/sessionDataCleanUp';
import { responseStatus } from '../config';
import reducer from './reducer';
import { POST_LOGOUT } from './actionTypes';

const LOGIN_FAIL_CUSTOMERS_LIST = [
  'BAC_111_471_522_339',
  'BAC_111_854_902_225',
  'BAC_111_433_933_098',
  'BAC_111_446_599_287',
  'BAC_111_119_326_524',
  'BAC_111_359_030_172',
  'BAC_111_417_958_166',
  'BAC_111_331_410_529',
  'BAC_111_185_496_752',
  'BAC_111_372_859_567',
  'BAC_111_628_048_173',
  'BAC_111_735_090_847',
  'BAC_111_474_179_876',
  'BAC_111_151_814_545',
  'BAC_111_536_114_694',
  'BAC_111_709_147_251',
  'BAC_111_849_117_781',
  'BAC_111_330_520_573',
  'BAC_111_420_677_832',
  'BAC_111_338_597_333',
  'BAC_111_367_254_027',
  'BAC_111_755_996_581',
  'BAC_111_387_717_444',
  'BAC_111_372_884_514',
  'BAC_111_508_671_237',
  'BAC_111_345_081_091',
  'BAC_111_542_325_589',
];

const responseValidator = (store) => (next) => (action) => {
  const customer = store.getState()?.customer || {};
  const { bac_number } = customer;

  if (
    action.payload &&
    action.payload.response &&
    action.payload.response.status &&
    action.payload.response.status === responseStatus.loggedOut &&
    action.type !== POST_LOGOUT &&
    !LOGIN_FAIL_CUSTOMERS_LIST.includes(bac_number)
  ) {
    cleanUpSessionData();
    store.dispatch(setCustomerData({ isLoggedIn: false }));
    if (!window.location.hash.includes('Login')) {
      const isDev = process.env.NODE_ENV === 'local' && !process.env.OBS_LOCAL;

      window.location.href = isDev ? '/#/Login' : '/savingglobal/#/Login';
    }
  }
  next(action);
};

const configureStore = (initialState) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const enhancers = [applyMiddleware(reduxPackMiddleware, responseValidator)];
  const store = createStore(reducer, initialState, composeEnhancers(...enhancers));

  if (module.hot) {
    module.hot.accept('./reducer', () => {
      const nextReducer = require('./reducer').default;

      store.replaceReducer(nextReducer);
    });
  }

  return store;
};

export default configureStore();
