const getLoginMTANTrackEventProps = (action) => ({
  object: 'login-mTAN',
  portal: 'customer-portal',
  boundedContext: 'customer-advisory',
  zone: 'raisin-frontend',
  eventName: `login-mTAN-${action}`,
  description: `login-mTAN-${action} in raisin-frontend`,
  action,
});

export const LOGIN_MTAN_REQUESTED = getLoginMTANTrackEventProps('requested');

export const LOGIN_MTAN_RESENT = getLoginMTANTrackEventProps('resent');

export const LOGIN_MTAN_SUBMITTED = getLoginMTANTrackEventProps('submitted');

export const LOGIN_MTAN_VERIFIED = getLoginMTANTrackEventProps('verified');

export const LOGIN_MTAN_INVALID = getLoginMTANTrackEventProps('invalid');

export const LOGIN_MTAN_EXPIRED = getLoginMTANTrackEventProps('expired');

export const LOGIN_MTAN_BLOCKED = getLoginMTANTrackEventProps('blocked');

export const LOGIN_MTAN_FAILED = getLoginMTANTrackEventProps('failed');
