export const getEsMenus = ({ menuLinks }) => {
  return [
    {
      id: '2c1d8255-3a4c-4d78-bf4b-f0f450ce9fff',
      type: 'text',
      title: 'Mensajes',
      href: menuLinks.POSTBOX,
    },
    {
      id: '0e57aad3-f3fd-4f97-a438-3b96476dd20c',
      type: 'text',
      title: 'Documentos',
      href: menuLinks.DOCUMENTS,
    },
    {
      id: '5534dc4e-fd1e-4936-84f1-0fe185ec6e82',
      type: 'text',
      title: 'Movimientos',
      href: menuLinks.TRANSACTIONS,
    },
    {
      id: '8fbeb352-f366-470f-aaca-bf93e53f2c47',
      type: 'text',
      title: 'Retirar dinero',
      href: menuLinks.WITHDRAW,
    },
    {
      id: '394d205a-3fbc-472a-947d-3b5f78cb6e6a',
      type: 'text',
      title: 'Acceso y seguridad',
      href: menuLinks.USER_ACCOUNT,
    },
    {
      id: 'e81df68f-9700-45c1-b305-23beb9acc063',
      type: 'text',
      title: 'Mis datos',
      href: menuLinks.MY_DETAILS,
    },
    {
      id: '4681530d-9eed-4cbe-982d-5445410895d8',
      type: 'text',
      title: 'Notificaciones',
      href: menuLinks.NOTIFICATIONS,
    },
  ];
};

export const getEsMenusNonExportedCustomer = ({ menuLinks }) => {
  const navItems = [
    {
      id: '2c1d8255-3a4c-4d78-bf4b-f0f450ce9fff',
      type: 'text',
      title: 'Mensajes',
      href: menuLinks.POSTBOX,
    },
    {
      id: '0e57aad3-f3fd-4f97-a438-3b96476dd20c',
      type: 'text',
      title: 'Documentos',
      href: menuLinks.DOCUMENTS,
    },
    {
      id: '394d205a-3fbc-472a-947d-3b5f78cb6e6a',
      type: 'text',
      title: 'Acceso y seguridad',
      href: menuLinks.USER_ACCOUNT,
    },
  ];

  return navItems?.length ? navItems : undefined;
};
