import React, { memo } from 'react';

import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Translate } from 'components/I18n';

import * as TRANSLATION_KEYS from './__translations__';

export const OTPDescription = memo(({ description, date, mobile }) => {
  const { formatDate, formatTime } = useIntl();

  return (
    <div className="col-xs-12 col-md-8 col-lg-6">
      {description ? (
        description(mobile, date)
      ) : (
        <Translate
          id={TRANSLATION_KEYS.DEFAULT_DESCRIPTION}
          values={{ mobile, date: formatDate(date), time: formatTime(date) }}
        />
      )}
    </div>
  );
});

OTPDescription.propTypes = {
  mobile: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  description: PropTypes.string,
};
