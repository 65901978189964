import React from 'react';
import Input from 'ui-lib/src/components/Input';
import PropTypes from 'prop-types';
import { Translate } from 'components/I18n';
import classNames from 'classnames';
import styles from './styles.scss';
import { calculatePasswordStrength } from './utils';

const labelIds = {
  0: 'blank',
  1: 'blank',
  2: 'bad',
  3: 'medium',
  4: 'medium',
  5: 'strong',
};

export default class PasswordInput extends Input {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    name: PropTypes.string,
    id: PropTypes.string,
    formatError: PropTypes.func,
    error: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      strength: 0,
      newPassword: '',
    };
    this.renderBars = this.renderBars.bind(this);
    this.calculateStrength = this.calculateStrength.bind(this);
  }

  renderBars() {
    const { strength } = this.state;

    return [1, 2, 3, 4, 5].map((item) => {
      return item <= strength ? (
        <div
          key={item.toString()}
          className={classNames(styles.passwordStrBar, {
            [styles.barStrFilled]: strength <= 2,
            [styles.barStrFilled]: strength <= 4,
            [styles.barStrFilled]: strength <= 5,
          })}
        />
      ) : (
        <div key={item.toString()} className={styles.passwordStrBar} />
      );
    });
  }

  calculateStrength() {
    const result = calculatePasswordStrength(this.props);

    this.setState({ strength: result });
  }

  onChange = (e) => {
    this.calculateStrength();
    this.props.onChange(e);
  };

  render() {
    const { name, id, value, formatError, error } = this.props;

    return (
      <div className={styles.passwordInputWrapper}>
        <div className="col-sm-6">
          <Input
            name={name}
            id={id}
            onChange={this.onChange}
            value={value}
            inputType="password"
            formatError={formatError}
            error={error}
          />
          <div className={styles.passwordStrContainer}>{this.renderBars()}</div>
        </div>
        <div className="col-sm-6">
          <div className={styles.passwordStrLabel}>
            <Translate id={`passwordInput.label.${labelIds[this.state.strength]}`} />
          </div>
        </div>
      </div>
    );
  }
}
