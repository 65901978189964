import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import constants from 'ui-lib/src/constants';
import Alert from 'ui-lib/src/components/Alert';
import Paragraph from 'ui-lib/src/components/Paragraph';
import { HAS_INVESTMENT_PRODUCT_ACCOUNT } from 'components/Layout/menu';
import { Translate } from 'components/I18n';
import { changeUIState } from 'store/ui/actions';

export class EligibilityNotice extends Component {
  static propTypes = {
    isEligibleForInvestmentProducts: PropTypes.bool.isRequired,
    onChangeUIState: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.onOpenFaq = this.onOpenFaq.bind(this);
  }

  onOpenFaq(e) {
    const faq = e.currentTarget.dataset.faq.split(',');

    this.props.onChangeUIState({
      faqSelection: {
        section: Number(faq[0]),
        number: Number(faq[1]),
      },
    });
  }

  render() {
    const { isEligibleForInvestmentProducts, productAccess } = this.props;
    const { alerts } = constants;

    if (isEligibleForInvestmentProducts) return null;

    const nonEligibilityReason = productAccess.find((el) => el === HAS_INVESTMENT_PRODUCT_ACCOUNT)
      ? 'multiplePortfolios'
      : 'regulation';

    return (
      <Alert type={alerts.info} key="alert-eligibility" className="eligibilityNotice" showDefault>
        <Paragraph>
          <strong>
            <Translate id={`eligibilityNotice.${nonEligibilityReason}.title`} />
          </strong>
        </Paragraph>
        <Paragraph>
          <Translate id={`eligibilityNotice.${nonEligibilityReason}.text`} />
        </Paragraph>
      </Alert>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onChangeUIState: (data) => dispatch(changeUIState(data)),
});

export default connect(null, mapDispatchToProps)(EligibilityNotice);
