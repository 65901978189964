export const ICONS = {
  AUT: [
    {
      name: 'facebook.svg',
      url: 'https://www.facebook.com/WeltSparen',
      title: 'Facebook',
    },
    {
      name: 'linkedin.svg',
      url: 'https://www.linkedin.com/company/join-raisin/',
      title: 'LinkedIn',
    },
    {
      name: 'twitter.svg',
      url: 'https://twitter.com/WeltSparen',
      title: 'Twitter',
    },
  ],

  IRL: [],

  DEU: [
    {
      name: 'facebook.svg',
      url: 'https://www.facebook.com/WeltSparen',
      title: 'Facebook',
    },
    {
      name: 'linkedin.svg',
      url: 'https://www.linkedin.com/company/join-raisin/',
      title: 'LinkedIn',
    },
    {
      name: 'twitter.svg',
      url: 'https://twitter.com/WeltSparen',
      title: 'Twitter',
    },
    {
      name: 'youtube.svg',
      url: 'https://www.youtube.com/user/WeltSparen',
      title: 'YouTube',
    },
    {
      name: 'instagram.svg',
      url: 'https://www.instagram.com/weltsparen/?hl=de',
      title: 'Instagram',
    },
    {
      name: 'xing.svg',
      url: 'https://www.xing.com/en',
      title: 'Xing',
    },
  ],

  EUR: [
    {
      name: 'facebook.svg',
      url: 'https://www.facebook.com/Raisin/',
      title: 'Facebook',
    },
    {
      name: 'linkedin.svg',
      url: 'https://www.linkedin.com/company/join-raisin/',
      title: 'LinkedIn',
    },
    {
      name: 'twitter.svg',
      url: 'https://twitter.com/Raisin_EN',
      title: 'Twitter',
    },
  ],

  GBR: [
    {
      name: 'facebook.svg',
      url: 'https://www.facebook.com/RaisinUK/',
      title: 'Facebook',
    },
    {
      name: 'linkedin.svg',
      url: 'https://www.linkedin.com/showcase/raisin-uk/',
      title: 'LinkedIn',
    },
    {
      name: 'twitter.svg',
      url: 'https://twitter.com/raisin_uk',
      title: 'Twitter',
    },
    {
      name: 'youtube.svg',
      url: 'https://www.youtube.com/channel/UCYHX-gaJ-KZwx4KsrmiP15Q',
      title: 'YouTube',
    },
    {
      name: 'instagram.svg',
      url: 'https://www.instagram.com/raisin_uk/',
      title: 'Instagram',
    },
  ],

  NLD: [
    {
      name: 'facebook.svg',
      url: 'https://www.facebook.com/RaisinNederland/',
      title: 'Facebook',
    },
    {
      name: 'linkedin.svg',
      url: 'https://www.linkedin.com/company/join-raisin/',
      title: 'LinkedIn',
    },
    {
      name: 'twitter.svg',
      url: 'https://twitter.com/raisin_nl',
      title: 'Twitter',
    },
  ],

  FRA: [
    {
      name: 'facebook.svg',
      url: 'https://www.facebook.com/RaisinFrance/',
      title: 'Facebook',
    },
    {
      name: 'linkedin.svg',
      url: 'https://www.linkedin.com/company/join-raisin/',
      title: 'LinkedIn',
    },
    {
      name: 'twitter.svg',
      url: 'https://twitter.com/Raisin_FR',
      title: 'Twitter',
    },
  ],

  ESP: [
    {
      name: 'facebook.svg',
      url: 'https://www.facebook.com/RaisinEspana/',
      title: 'Facebook',
    },
    {
      name: 'linkedin.svg',
      url: 'https://www.linkedin.com/showcase/raisin-spain/',
      title: 'LinkedIn',
    },
    {
      name: 'twitter.svg',
      url: 'https://twitter.com/Raisin_ES',
      title: 'Twitter',
    },
  ],

  POL: [],
  FIN: [],
};
