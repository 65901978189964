import * as yup from 'yup';
import IBAN from 'iban';
import { currencyAmountToNumber } from 'ui-lib/src/utils/GeneralUtils';
import { countries } from 'utils/CustomerUtils';
import { parsePhoneNumberFromString as parseMobile } from 'libphonenumber-js/mobile';

export default class Validator {
  static generateSchema(fields, formValidation) {
    const schemaRules = {};

    fields.forEach((field) => {
      const { validation = {} } = field;

      if (field.nested) {
        schemaRules[field.name] = this.generateSchema(field.nested);
      } else if (Object.keys(validation).length) {
        schemaRules[field.name] = yup[validation.type]();

        if (validation.required) {
          schemaRules[field.name] = schemaRules[field.name].required();
        }
        if (validation.oneOf) {
          schemaRules[field.name] = schemaRules[field.name].oneOf(validation.oneOf);
        }
        if (validation.notOneOf) {
          schemaRules[field.name] = schemaRules[field.name].notOneOf(validation.notOneOf);
        }
        if (validation.min) {
          schemaRules[field.name] = schemaRules[field.name].min(validation.min);
        }
        if (validation.max) {
          schemaRules[field.name] = schemaRules[field.name].max(validation.max);
        }
        if (validation.requiredWhen) {
          schemaRules[field.name] = schemaRules[field.name].when(
            validation.requiredWhen.field,
            (requiredField, schema) => {
              if (
                validation.requiredWhen.oneOf &&
                validation.requiredWhen.oneOf.includes(requiredField)
              ) {
                return schema.required();
              }
              if (
                validation.requiredWhen.include &&
                requiredField.includes(validation.requiredWhen.include)
              ) {
                return schema.required();
              }

              return schema;
            },
          );
        }
        if (validation.requiredWhenMulti) {
          // eslint-disable-next-line array-callback-return
          validation.requiredWhenMulti.map((requiredWhenField) => {
            schemaRules[field.name] = schemaRules[field.name].when(
              requiredWhenField.field,
              (requiredField, schema) => {
                if (requiredWhenField.oneOf.includes(requiredField)) {
                  if (schema.type === 'array') {
                    return schema
                      .required({
                        path: field.name,
                        type: 'requiredWhen',
                      })
                      .min(1);
                  }

                  return schema.required({
                    path: field.name,
                    type: 'requiredWhen',
                  });
                }

                return schema;
              },
            );
          });
        }
        if (validation.matches) {
          schemaRules[field.name] = schemaRules[field.name].matches(validation.matches, {
            message: { path: validation.path, type: 'invalid' },
          });
        }
        if (validation.customRule) {
          schemaRules[field.name] = schemaRules[field.name].test(
            validation.customRule.errorCode,
            null,
            (value, schema) => validation.customRule.validation(value, schema.parent),
          );
        }
        if (field.name === 'tax_id_number') {
          schemaRules[field.name] = Validator.validateGermanTaxID();
        }
        if (field.name === 'reference_account_iban') {
          schemaRules[field.name] = Validator.validateIban();
        }
      } else if (field.schema) {
        schemaRules[field.name] = field.schema;
      }
    });

    let schema = yup.object().shape(schemaRules);

    if (formValidation) {
      // validation to ensure at least one of the given fields is present
      if (formValidation.atLeastOneOf) {
        schema = schema.test('at-least-one-of', null, (value) => {
          // if at least one field is present, then early return
          if (formValidation.atLeastOneOf.find((field) => value[field])) {
            return true;
          }

          // if none of the given fields is present, then return an error
          return new yup.ValidationError('at-least-one-of', null, 'atLeastOneOf');
        });
      }
    }

    return schema;
  }

  static generateImprovedSchema(fields, formValidation) {
    const schemaRules = {};

    fields.forEach((field) => {
      const { validation = {} } = field;

      if (field.nested) {
        schemaRules[field.name] = this.generateImprovedSchema(field.nested);
      } else if (Object.keys(validation).length) {
        schemaRules[field.name] = yup[validation.type]();

        if (validation.isRequired) {
          schemaRules[field.name] = schemaRules[field.name].required({
            path: field.name,
            type: 'required',
          });
        }
        if (validation.oneOf) {
          schemaRules[field.name] = schemaRules[field.name].oneOf(validation.oneOf, {
            path: field.name,
            type: 'oneOf',
          });
        }
        if (validation.notOneOf) {
          schemaRules[field.name] = schemaRules[field.name].notOneOf(validation.notOneOf, {
            path: field.name,
            type: 'notOneOf',
          });
        }
        if (validation.min) {
          schemaRules[field.name] = schemaRules[field.name].min(validation.min, {
            path: field.name,
            type: 'min',
          });
        }
        if (validation.max) {
          schemaRules[field.name] = schemaRules[field.name].max(validation.max, {
            path: field.name,
            type: 'max',
          });
        }
        if (validation.requiredWhen) {
          schemaRules[field.name] = schemaRules[field.name].when(
            validation.requiredWhen.field,
            (requiredField, schema) => {
              if (validation.requiredWhen.oneOf.includes(requiredField)) {
                return schema.required({
                  path: field.name,
                  type: 'requiredWhen',
                });
              }

              return schema;
            },
          );
        }
        if (validation.matches) {
          schemaRules[field.name] = schemaRules[field.name].matches(validation.matches, {
            message: {
              path: field.name,
              type: 'invalid',
            },
          });
        }
        if (validation.equalsField) {
          schemaRules[field.name] = schemaRules[field.name].test(
            'equalsField',
            { path: field.name, type: 'equalsField' },
            // eslint-disable-next-line func-names
            function (value) {
              return this.parent[validation.equalsField] === value;
            },
          );
        }
        if (field.name === 'tax_id_number') {
          schemaRules[field.name] = Validator.validateTaxID(validation);
        }
        if (field.name === 'reference_account_iban') {
          schemaRules[field.name] = Validator.validateImprovedIban('reference_account_iban');
        }
      } else if (field.schema) {
        schemaRules[field.name] = field.schema;
      }
    });

    let schema = yup.object().shape(schemaRules);

    if (formValidation) {
      // validation to ensure at least one of the given fields is present
      if (formValidation.atLeastOneOf) {
        schema = schema.test('at-least-one-of', null, (value) => {
          // if at least one field is present, then early return
          if (formValidation.atLeastOneOf.find((field) => value[field])) {
            return true;
          }

          // if none of the given fields is present, then return an error
          return new yup.ValidationError('at-least-one-of', null, 'atLeastOneOf');
        });
      }
    }

    return schema;
  }

  static amountInputSchema(validationObj) {
    const { min, max, currentAmount = null, isRequired, country, starlingLimit } = validationObj;

    if (!country) {
      throw new Error('No country was provided to amountInputSchema');
    }

    const exists = (value) => value !== null && value !== undefined;

    const validateInputFormat = (amount) => {
      return !Number.isNaN(currencyAmountToNumber(amount, country));
    };

    const validateMin = (amount, min) => {
      if (exists(amount)) {
        return exists(min) ? currencyAmountToNumber(amount, country) >= min : true;
      }

      return true;
    };

    const validateMax = (amount, max) => {
      if (exists(amount)) {
        return exists(max) ? currencyAmountToNumber(amount, country) <= max : true;
      }

      return true;
    };

    const validateEquality = (amount) =>
      currentAmount === null ? true : currencyAmountToNumber(amount, country) !== currentAmount;

    const validateRequired = (amount) => (isRequired ? exists(amount) : true);

    return yup
      .string()
      .test('invalid', 'invalid', validateInputFormat)
      .test('required', 'required', (value) => validateRequired(value))
      .test('min', 'min', (value) => validateMin(value, min))
      .test('max', 'max', (value) => validateMax(value, max))
      .test('starlingLimit', 'starlingLimit', (value) => validateMax(value, starlingLimit))
      .test('equal-to', 'equal-to', (value) => validateEquality(value));
  }

  // To be used together with NewAmountInput
  static amountInputSchemaNew(validationObj) {
    const {
      min,
      max,
      currentAmount = null,
      isRequired,
      starlingLimit,
      allowZero,
      withdrawalAgainstBalance,
    } = validationObj;

    const exists = (value) => value !== null && value !== undefined && !(!allowZero && value === 0);
    const existsWithArray = (values) => values.every(exists);

    const validateInputFormat = (amount) => {
      return !Number.isNaN(amount);
    };

    const validateMin = (amount, min, allowZero = false) => {
      if (exists(amount)) {
        if (allowZero && amount === 0) {
          return true;
        }

        return exists(min) ? amount >= min : true;
      }

      return true;
    };

    const validateMax = (amount, max) => {
      if (exists(amount)) {
        return exists(max) ? amount <= max : true;
      }

      return true;
    };

    const validateEquality = (amount) => (currentAmount === null ? true : amount !== currentAmount);

    const validateRequired = (amount) => (isRequired ? exists(amount) : true);

    const validateWithdrawalAgainstBalance = (value, withdrawalAgainstBalance, balance) => {
      if (existsWithArray([value, withdrawalAgainstBalance, balance])) {
        return balance === value || balance - value >= withdrawalAgainstBalance;
      }

      return true;
    };

    return yup
      .number()
      .test('invalid', 'invalid', validateInputFormat)
      .test('required', 'required', (value) => validateRequired(value))
      .test('min', 'min', (value) => validateMin(value, min, allowZero))
      .test('max', 'max', (value) => validateMax(value, max))
      .test('starlingLimit', 'starlingLimit', (value) => validateMax(value, starlingLimit))
      .test('equal-to', 'equal-to', (value) => validateEquality(value))
      .test('withdrawalAgainstBalance', 'withdrawalAgainstBalance', (value) =>
        validateWithdrawalAgainstBalance(value, withdrawalAgainstBalance, max),
      );
  }

  static validateGermanTaxID() {
    return yup.string().test('invalid', 'Not a valid German Tax ID', (taxID) => {
      const taxIDLength = 11;

      if (!taxID) {
        return false;
      }

      // Taxnumber has to have exactly 11 digits.
      if (taxID.length !== taxIDLength) {
        return false;
      }

      // First digit cannot be 0.
      if (taxID[0] === '0') {
        return false;
      }

      /*
       * Within the first ten digits:
       *  - one digit appears exactly twice or thrice
       *  - one or two digits appear zero times
       *  - and all other digits appear exactly once
       */
      const taxIDArray = taxID.split('').slice(0, -1);
      const valueCount = taxIDArray.reduce((obj, item) => {
        // eslint-disable-next-line
        obj[item] = obj[item] ? ++obj[item] : 1;

        return obj;
      }, {});
      const valueCountLength = Object.keys(valueCount).length;

      if (valueCountLength !== 8 && valueCountLength !== 9) {
        return false;
      }

      // 11th digit has to match the checkum.
      let sum = 0;
      let product = 10;

      // eslint-disable-next-line
      for (let i = 0; i < taxIDLength - 1; i++) {
        sum = (+taxIDArray[i] + product) % 10;
        if (sum === 0) {
          sum = 10;
        }
        product = (sum * 2) % 11;
      }

      let checksum = 11 - product;

      if (checksum === 10) {
        checksum = 0;
      }

      if (+taxID[taxIDLength - 1] !== checksum) {
        return false;
      }

      return true;
    });
  }

  static validateTaxID(validation) {
    const { path, country, regex } = validation;

    if (!country) {
      throw new Error('No country was provided to validateTaxID');
    }

    if (country !== 'DEU') {
      return yup.string().test('invalid', { path, type: 'invalid' }, (taxID) => {
        if (!validation.isRequired) {
          return true;
        }

        if (!taxID) {
          return false;
        }
        if (regex && !taxID.match(regex)) {
          return false;
        }

        return true;
      });
    }

    return yup.string().test('invalid', { path, type: 'invalid' }, (taxID) => {
      const taxIDLength = 11;

      if (!validation.isRequired) {
        return true;
      }

      if (!taxID) {
        return false;
      }

      // Taxnumber has to have exactly 11 digits.
      if (taxID.length !== taxIDLength) {
        return false;
      }

      // First digit cannot be 0.
      if (taxID[0] === '0') {
        return false;
      }

      /*
       * Within the first ten digits:
       *  - one digit appears exactly twice or thrice
       *  - one or two digits appear zero times
       *  - and all other digits appear exactly once
       */
      const taxIDArray = taxID.split('').slice(0, -1);
      const valueCount = taxIDArray.reduce((obj, item) => {
        // eslint-disable-next-line
        obj[item] = obj[item] ? ++obj[item] : 1;

        return obj;
      }, {});
      const valueCountLength = Object.keys(valueCount).length;

      if (valueCountLength !== 8 && valueCountLength !== 9) {
        return false;
      }

      // 11th digit has to match the checkum.
      let sum = 0;
      let product = 10;

      // eslint-disable-next-line
      for (let i = 0; i < taxIDLength - 1; i++) {
        sum = (+taxIDArray[i] + product) % 10;
        if (sum === 0) {
          sum = 10;
        }
        product = (sum * 2) % 11;
      }

      let checksum = 11 - product;

      if (checksum === 10) {
        checksum = 0;
      }

      if (+taxID[taxIDLength - 1] !== checksum) {
        return false;
      }

      return true;
    });
  }

  static getAppropriatenessSchema() {
    return yup.object().test('invalid', 'Customer is inexperienced', (appropriateness) => {
      const experience = Object.keys(appropriateness).filter((key) => appropriateness[key]);

      const case1 = [
        'equity_domestic_experience',
        'funds_eu_experience',
        'funds_non_eu_experience',
        'equity_foreign_experience',
        'certificates_advanced_experience',
        'closed_end_funds_experience',
        'asset_investments_experience',
        'derivatives_experience',
      ];

      const case2 = ['fixed_income_domestic_experience', 'fixed_income_foreign_experience'];
      const case3 = ['fixed_income_domestic_experience', 'certificates_simple_experience'];
      const case4 = ['fixed_income_foreign_experience', 'certificates_simple_experience'];
      const case5 = ['fixed_income_foreign_experience', 'forex_experience'];
      const case6 = ['appropriateness_warning_accepted'];

      const func = (value) => experience.includes(value);

      return (
        case1.some(func) ||
        case2.every(func) ||
        case3.every(func) ||
        case4.every(func) ||
        case5.every(func) ||
        case6.every(func)
      );
    });
  }

  static validateIban() {
    return yup.string().test('invalid', 'Iban is not valid', (iban) => {
      const cleanIban = iban.replace(/\s/g, '');
      // see https://github.com/arhs/iban.js/issues/35
      const formattedIban = IBAN.electronicFormat(cleanIban);

      return formattedIban === cleanIban && IBAN.isValid(cleanIban);
    });
  }

  static validateImprovedIban(fieldName) {
    return yup.string().test('invalid', { path: fieldName, type: 'invalid' }, (iban) => {
      const cleanIban = iban.replace(/\s/g, '');
      // see https://github.com/arhs/iban.js/issues/35
      const formattedIban = IBAN.electronicFormat(cleanIban);

      return formattedIban === cleanIban && IBAN.isValid(cleanIban);
    });
  }

  static isIbanValid(fieldName) {
    return yup
      .string()
      .test('invalid', { path: fieldName, type: 'invalid' }, (iban) => IBAN.isValid(iban));
  }

  static validateMobileNumber(config) {
    const validateRequired = (num) => (config.required ? !!num : true);

    const validateNumber = (num) => {
      const parsedNum = parseMobile(num);

      return !!parsedNum && parsedNum.isValid();
    };

    const allowedCountries = [
      'fr',
      'it',
      'gb',
      'nl',
      'bg',
      'be',
      'fi',
      'es',
      'dk',
      'ee',
      'gr',
      'ie',
      'lv',
      'lt',
      'lu',
      'mt',
      'pl',
      'pt',
      'ro',
      'se',
      'sk',
      'si',
      'cz',
      'hu',
      'cy',
      'li',
      'no',
      'ch',
      'is',
      'hr',
    ];

    if (config.customerCountry) {
      if (config.customerCountry === countries.DEU || config.customerCountry === countries.AUT) {
        allowedCountries.push('de', 'at');
      }
    }
    const validateCountry = (num) => {
      const parsedNum = parseMobile(num);

      if (!parsedNum) return false;
      const { country } = parsedNum;

      if (country) {
        return allowedCountries.includes(country.toLowerCase());
      }

      return false;
    };

    const validTypes = ['MOBILE', 'FIXED_LINE_OR_MOBILE'];
    const validateNumberType = (num) => {
      const parsedNum = parseMobile(num);

      return !!parsedNum && validTypes.includes(parsedNum.getType());
    };

    return yup
      .string()
      .default('')
      .test('required', 'required', validateRequired)
      .test('invalid', 'invalid', validateNumber)
      .test('invalid-country', 'invalid-country', validateCountry)
      .test('invalid-type', 'invalid-type', validateNumberType);
  }

  static validateRiskChartInputFields({
    amountValue,
    amountSPValue,
    maxAmount,
    minAmount,
    maxAmountSP,
    minAmountSP,
  }) {
    // Validation scenarios
    const isAmountValueZero = amountValue === 0;
    const isAmountSPValueZero = amountSPValue === 0;
    const isAmountValueBetween0and50 = amountValue > 0 && amountValue < minAmountSP;
    const isAmountValueBetween50and500 = amountValue >= minAmountSP && amountValue < minAmount;
    const isMaxAmountError = amountValue > maxAmount;
    const isMaxAmountSPError = amountSPValue > maxAmountSP;
    const isMinAmountSPError = amountSPValue > 0 && amountSPValue < minAmountSP;
    const isAmountValueValid = amountValue >= minAmount && amountValue <= maxAmount;
    const isAmountSPValueValid = amountSPValue >= minAmountSP && amountSPValue <= maxAmountSP;

    const errors = {};
    const merge = (properties) => Object.assign(errors, properties);

    if (
      (isAmountValueZero && isAmountSPValueZero) ||
      (isMinAmountSPError && (isAmountValueBetween0and50 || isAmountValueBetween50and500)) ||
      (isAmountValueBetween0and50 && isAmountSPValueZero)
    )
      return { amount: 'empty', amountSP: 'empty', inputFields: 'input.multiple.error' };

    if (isAmountValueBetween0and50 && isAmountSPValueValid)
      return { amount: 'empty', amountSP: 'empty', inputFields: 'input.in.combination.error' };

    if ((isAmountValueZero && isMinAmountSPError) || (isAmountValueValid && isMinAmountSPError))
      merge({ amountSP: 'min' });

    if (isAmountValueBetween50and500 && isAmountSPValueZero) merge({ amount: 'min' });

    if (isMaxAmountError) merge({ amount: 'max' });
    if (isMaxAmountSPError) merge({ amountSP: 'max' });

    return errors;
  }
}
