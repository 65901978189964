import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Alert from 'ui-lib/src/components/Alert';
import ButtonLink from 'ui-lib/src/components/ButtonLink';
import Paragraph from 'ui-lib/src/components/Paragraph';
import constants from 'ui-lib/src/constants';
import { sendGtmTrackingEvent } from 'ui-lib/src/utils/GtmTracking';

import { Translate } from 'components/I18n';
import { pageCategoryMap } from 'utils/GtmTracking';

import { redirectExternal } from '../Header/data/utils';
import { EXTERNAL_LINKS } from '../Header/Navigation/menuLinks';

export default class MissingPIDNotice extends Component {
  static propTypes = {
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
    notices: PropTypes.object,
    bacNumber: PropTypes.string.isRequired,
  };

  handleClick = () => {
    const { page, bacNumber } = this.props;

    if (page in pageCategoryMap) {
      sendGtmTrackingEvent({
        eventCategory: pageCategoryMap[page],
        eventAction: 'linkto-identification',
        eventLabel: 'onboarding-progress',
        eventProperty: bacNumber,
        eventNonInteraction: false,
      });
    }
    redirectExternal(EXTERNAL_LINKS.IDENTIFICATION.HOME);
  };

  render() {
    const { notices, className } = this.props;
    const { alerts } = constants;

    if (notices && notices['invalid.document.pid']) {
      return (
        <Alert
          type={alerts.info}
          key="alert-missingIdentification"
          className={`missingIdentificationNotice ${className}`}
          cta={
            <ButtonLink
              onClick={this.handleClick}
              text={<Translate id="alert.button.toIdentification" />}
              suffixIcon="chevron-right"
            />
          }
          showDefault
        >
          <Paragraph>
            <strong>
              <Translate id="alert.heading.missingIdentification" />
            </strong>
          </Paragraph>
          <Paragraph>
            <Translate id="alert.paragraph.missingIdentification" />
          </Paragraph>
        </Alert>
      );
    }

    return null;
  }
}
