import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { TranslationsProvider } from 'components/I18n';

import AUTHORIZATION_ERROR_NOTICE from './AuthorizationErrorNotice';
import BLOCKED_ACCOUNT_ERROR_NOTICE from './BlockedAccountErrorNotice';
import { noticesConfig } from './config';
import CONFIRMATION_NOTICE from './ConfirmationNotice';
import DISABLE_AUTOPROLONGATION_NOTICE from './DisableAutoProlongation';
import DISABLE_AUTORENEWAL_NOTICE from './DisableAutoRenewal';
import ELIGIBILITY_NOTICE from './EligibilityNotice';
import EXPIRED_PID_NOTICE from './ExpiredPIDNotice';
import FILE_UPLOAD_NOTICE from './FileUploadErrorNotice';
import FORM_ERROR_NOTICE from './FormErrorNotice';
import MISSING_FUNDS_NOTICE from './MissingFundsNotice';
import MISSING_IDD_NOTICE from './MissingIDDNotice';
import MISSING_KYC_NOTICE from './MissingKYCNotice';
import MISSING_PID_NOTICE from './MissingPIDNotice';
import MISSING_POA_NOTICE from './MissingPOANotice';
import MTAN_FORM_ERROR_NOTICE from './MTanFormErrorNotice';
import ORDER_FREEZE_EXPLAIN from './OrderFreezeExplain';
import ACCOUNT_OVERDRAFT_NOTICE from './OverdraftNotice';
import REQUEST_ERROR_NOTICE from './RequestErrorNotice';
import SAVINGS_PLAN_DEBIT_DAY_NOTICE from './SavingsPlanDebitDayNotice';
import WAITING_TIME_NOTICE from './WaitingTimeNotice';

const NoticeComponents = {
  AUTHORIZATION_ERROR_NOTICE,
  REQUEST_ERROR_NOTICE,
  BLOCKED_ACCOUNT_ERROR_NOTICE,
  ELIGIBILITY_NOTICE,
  FORM_ERROR_NOTICE,
  MTAN_FORM_ERROR_NOTICE,
  CONFIRMATION_NOTICE,
  WAITING_TIME_NOTICE,
  MISSING_PID_NOTICE,
  EXPIRED_PID_NOTICE,
  MISSING_FUNDS_NOTICE,
  DISABLE_AUTOPROLONGATION_NOTICE,
  DISABLE_AUTORENEWAL_NOTICE,
  SAVINGS_PLAN_DEBIT_DAY_NOTICE,
  FILE_UPLOAD_NOTICE,
  MISSING_POA_NOTICE,
  MISSING_KYC_NOTICE,
  MISSING_IDD_NOTICE,
  ACCOUNT_OVERDRAFT_NOTICE,
  ORDER_FREEZE_EXPLAIN,
};

export class Notices extends Component {
  static propTypes = {
    page: PropTypes.oneOf(
      Object.keys(noticesConfig.pages).map((page) => noticesConfig.pages[page].name),
    ),
    history: PropTypes.object,
    isEligibleForInvestmentProducts: PropTypes.bool,
    orderForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    buyForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    sellForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    savingsPlanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    referenceAccountInitialForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    referenceAccountMTanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    referAFriendForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    depositOrderForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    orderMTanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    buyMTanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    sellMTanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    customerMtanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    contactDataForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    passwordForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    savingsPlanMTanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    customerContactDataMtanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    customerPasswordMtanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    overnightPayInForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    overnightPayInMTanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    overnightPayOutForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    overnightPayOutMTanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    overnightSavingsPlanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    overnightSavingsPlanMTanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    depositOrderMtanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    depositAccountOrderRevokeForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    mailDataForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    nationalIdentifiersForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    customerPayoutMtanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    earlyTerminationForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    earlyTerminationMTanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    blockAccountForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    applicationDocumentForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    setPasswordForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    loginForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    phone_number_anonymized: PropTypes.string,
    notices: PropTypes.object,
    tamsTransactionAccount: PropTypes.shape({
      bic: PropTypes.string.isRequired,
      iban: PropTypes.string.isRequired,
      sortCode: PropTypes.string,
      accountNumber: PropTypes.string,
    }),
    region: PropTypes.string.isRequired,
    requestError: PropTypes.object,
    isBlocked: PropTypes.bool,
    bacNumber: PropTypes.string.isRequired,
    className: PropTypes.string,
    customerState: PropTypes.string.isRequired,
    defaultAddress: PropTypes.object.isRequired,
    customerDocuments: PropTypes.object.isRequired,
    isDWMLegacyMigrationInProgress: PropTypes.bool,
  };

  render() {
    const pageNotices = noticesConfig.pages[this.props.page].notices;
    const notices = pageNotices.map((type) =>
      React.createElement(NoticeComponents[type], { ...this.props, key: type }),
    );

    return (
      <TranslationsProvider
        translations={{
          de: () => import('./__translations__/de.json'),
          'de-AT': () => import('./__translations__/de-AT.json'),
          'en-US': () => import('./__translations__/en-US.json'),
          'en-IE': () => import('./__translations__/en-IE.json'),
          'en-GB': () => import('./__translations__/en-GB.json'),
          nl: () => import('./__translations__/nl.json'),
          fr: () => import('./__translations__/fr.json'),
          es: () => import('./__translations__/es.json'),
          'pl-PL': () => import('./__translations__/pl-PL.json'),
          'fi-FI': () => import('./__translations__/fi-FI.json'),
        }}
        id="notices"
      >
        <>{notices}</>
      </TranslationsProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  applicationDocumentForm: state.customer.applicationDocumentForm,
  bacNumber: state.customer.bac_number,
  blockAccountForm: state.customer.blockAccountForm,
  buyForm: state.investmentProducts.buy.form,
  buyMTanForm: state.investmentProducts.buy.mTanForm,
  contactDataForm: state.customer.contactDataForm,
  customerContactDataMtanForm: state.customer.customerContactDataMtanForm,
  customerDocuments: state.customer.documents,
  customerMtanForm: state.customer.mTanForm,
  customerPasswordMtanForm: state.customer.customerPasswordMtanForm,
  customerPayoutMtanForm: state.customer.customerPayoutMtanForm,
  customerState: state.customer.state,
  defaultAddress: state.customer.default_address,
  depositAccountOrderRevokeForm: state.customer.depositAccountOrderRevokeForm,
  depositOrderForm: state.deposits.form,
  depositOrderMtanForm: state.deposits.mTanForm,
  earlyTerminationForm: state.deposits.earlyTerminationForm,
  earlyTerminationMTanForm: state.deposits.earlyTerminationMTanForm,
  isBlocked: state.ui.isBlocked,
  isEligibleForInvestmentProducts: state.customer.isEligibleForInvestmentProducts,
  loginForm: state.customer.loginForm,
  mailDataForm: state.customer.mailDataForm,
  notices: state.ui.notices,
  orderForm: state.investmentProducts.order.form,
  orderMTanForm: state.investmentProducts.order.mTanForm,
  overnightPayInForm: state.deposits.overnightPayInForm,
  overnightPayInMTanForm: state.deposits.overnightPayInMTanForm,
  overnightPayOutForm: state.deposits.overnightPayOutForm,
  overnightPayOutMTanForm: state.deposits.overnightPayOutMTanForm,
  overnightSavingsPlanForm: state.deposits.overnightSavingsPlanForm,
  overnightSavingsPlanMTanForm: state.deposits.overnightSavingsPlanMTanForm,
  passwordForm: state.customer.passwordForm,
  phone_number_anonymized: state.customer.phone_number_anonymized,
  productAccess: state.customer.productAccess,
  referAFriendForm: state.customer.referAFriendForm,
  referenceAccountInitialForm: state.customer.referenceAccountInitialForm,
  referenceAccountMTanForm: state.customer.referenceAccountMTanForm,
  region: state.customer.region,
  requestError: state.ui.requestError,
  savingsPlanForm: state.investmentProducts.savingsPlan.form,
  savingsPlanMTanForm: state.investmentProducts.savingsPlan.mTanForm,
  sellForm: state.investmentProducts.sell.form,
  sellMTanForm: state.investmentProducts.sell.mTanForm,
  setPasswordForm: state.customer.setPasswordForm,
  isDWMLegacyMigrationInProgress: state.global?.featureFlags?.ippDWMLegacyMigrationInProgress,
  tamsTransactionAccount: state.customer.tamsTransactionAccount,
  casCustomerData: state.customer.casCustomerData,
  dbsDeposits: state.customer.dbsDeposits,
});

export default withRouter(connect(mapStateToProps)(Notices));
