import React, { useContext, useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import Button from 'ui-lib/src/components/Button';
import ButtonLink from 'ui-lib/src/components/ButtonLink';

import { trackEvent } from '@raisin/events-tracking';

import { Translate } from 'components/I18n';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import * as TRANSLATION_KEYS from './__translations__';
import { OTPDescription } from './OTPDescription';
import { OTPInput } from './OTPInput';
import { OTPContext } from './OTPProvider';
import { getUserMobile, sendOTP, verifyOTP } from './shared/api';
import { OTP_INVALID_ERROR_CODE, OTP_TRACKED_EVENTS } from './shared/constants';
import { OTPPropTypes } from './shared/types';
import {
  isExpiredOTPError,
  isInvalidOTPError,
  isUserBlockedError,
  isVerifiedOTPResponse,
} from './shared/utils';

export const OTPContent = ({
  locale,
  verificationMessage,
  verificationId,
  guestToken,
  customerId,
  userId,
  description,
  onUserVerified,
  onUserVerificationFailed,
  trackedEvents,
}) => {
  const { formatMessage } = useIntl();
  const [maskedMobile, setMaskedMobile] = useState('');
  const [nonce, setNonce] = useState('');
  const [date, setDate] = useState(new Date());
  const [isValidPattern, setIsValidPattern] = useState(false);
  const [verificationCode, setVerificationCode] = useState(null);
  const [isServiceDown, setIsServiceDown] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const {
    isBlocked,
    setError,
    setIsBlocked,
    setInfo,
    isValidated,
    setIsValidated,
    isLoading,
    setIsLoading,
  } = useContext(OTPContext);

  const trackOTPEvent = (event) => {
    if (!trackedEvents?.[event]) return;
    trackEvent({ ...trackedEvents[event], customAttribute1: customerId }, true, true);
  };

  const clearAllAlerts = () => {
    setError(null);
    setInfo(null);
    setIsBlocked(false);
  };

  const handleOTPInputChange = (value) => {
    clearAllAlerts();
    setVerificationCode(value);
    setIsValidPattern(/^\d{6}$/g.test(value));
  };

  const handleError = (err) => {
    // Extract API error object from axios error wrapper
    const error = err?.response?.data;

    const isUserBlocked = isUserBlockedError(error);

    if (isUserBlocked) {
      setIsBlocked(true);
      trackOTPEvent(OTP_TRACKED_EVENTS.BLOCKED);
    } else {
      setIsBlocked(false);
      if (isInvalidOTPError(error)) {
        trackOTPEvent(OTP_TRACKED_EVENTS.INVALID);
        setError(formatMessage({ id: TRANSLATION_KEYS.INVALID_OTP_ERR_DESC }));
      } else if (isExpiredOTPError(error)) {
        trackOTPEvent(OTP_TRACKED_EVENTS.EXPIRED);
        setError(formatMessage({ id: TRANSLATION_KEYS.EXPIRED_OTP_ERR_DESC }));
      } else {
        trackOTPEvent(OTP_TRACKED_EVENTS.FAILED);
        setError(formatMessage({ id: TRANSLATION_KEYS.GENERIC_ERR_DESC }));
        setIsServiceDown(true);
      }
    }

    onUserVerificationFailed?.();
  };

  const fetchMaskedMobile = async () => {
    const mobile = await getUserMobile(verificationId, guestToken, customerId, userId);

    if (!mobile) throw new Error();

    setMaskedMobile(mobile);
  };

  const fetchOTPNonce = async () => {
    const requestParams = {
      verificationId,
      guestToken,
      verificationMessage,
      locale,
      customerId,
      userId,
    };
    const response = await sendOTP(requestParams);
    const nonceResponse = response?.data?.nonce;

    if (!nonceResponse) throw new Error();

    setNonce(nonceResponse);
    setIsServiceDown(false);
  };

  const handleSubmit = async () => {
    if (!isValidPattern || !verificationCode || !verificationId || !nonce) return;

    try {
      setIsVerifying(true);
      const requestParams = {
        verificationId,
        verificationCode,
        guestToken,
        nonce,
        customerId,
        userId,
      };

      trackOTPEvent(OTP_TRACKED_EVENTS.SUBMITTED);
      const response = await verifyOTP(requestParams);

      if (!isVerifiedOTPResponse(response.data)) {
        // Wrap error object to match axios error structure
        handleError({ response: { data: { frontend_error_code: OTP_INVALID_ERROR_CODE } } });

        return;
      }
      clearAllAlerts();
      onUserVerified();
      trackOTPEvent(OTP_TRACKED_EVENTS.VERIFIED);
      setIsValidated(true);
    } catch (error) {
      handleError(error);
    } finally {
      setIsVerifying(false);
    }
  };

  const handleResend = async () => {
    setIsResending(true);

    try {
      if (!maskedMobile) {
        await fetchMaskedMobile();
      }
      await fetchOTPNonce();
      trackOTPEvent(OTP_TRACKED_EVENTS.RESENT);
      setDate(new Date());
      setInfo(formatMessage({ id: TRANSLATION_KEYS.MTAN_RESENT_DESC }, { mobile: maskedMobile }));
    } catch (error) {
      setNonce('');
      handleError(error);
    } finally {
      setIsResending(false);
    }
  };

  useEffect(() => {
    if (!verificationId) return;
    (async () => {
      try {
        await fetchMaskedMobile();
        await fetchOTPNonce();
        trackOTPEvent(OTP_TRACKED_EVENTS.REQUESTED);
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  if (isValidated) return null;

  if (isLoading || !verificationId) return <LoadingPlaceholder height={300} width="100%" />;

  if (isServiceDown)
    return (
      <ButtonLink
        isDisabled={isVerifying || isResending}
        isLoading={isResending}
        onClick={handleResend}
      >
        <Translate id={TRANSLATION_KEYS.RESEND_BTN_LBL} />
      </ButtonLink>
    );

  return (
    <>
      <OTPDescription description={description} date={date} mobile={maskedMobile} />
      <OTPInput
        onChange={handleOTPInputChange}
        isDisabled={isVerifying || isResending || isBlocked}
        onEnter={handleSubmit}
      />

      <ButtonLink
        isDisabled={isVerifying || isResending}
        isLoading={isResending}
        onClick={handleResend}
      >
        <Translate id={TRANSLATION_KEYS.RESEND_BTN_LBL} />
      </ButtonLink>

      <Button
        isDisabled={!isValidPattern || isVerifying || isResending || isBlocked}
        isLoading={isVerifying}
        isPrimary
        onClick={handleSubmit}
      >
        <Translate id={TRANSLATION_KEYS.SUBMIT_BTN_LBL} />
      </Button>
    </>
  );
};

OTPContent.propTypes = OTPPropTypes;

export default OTPContent;
