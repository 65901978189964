import * as types from '../../../actionTypes';
import { createImprovedAsyncAction } from '../../../actionCreators';
import { getLocaleFromLocalStorage } from '../../../../utils/Locale';

export const fetchDBSCustomerDeposits = (bac) => {
  return createImprovedAsyncAction({
    type: types.DBS_FETCH_CUSTOMER_DEPOSITS,
    path: 'dbs.customerDeposits',
    settings: {
      params: {
        customer_id: bac,
      },
      headers: {
        'Accept-Language': 'en' || getLocaleFromLocalStorage(), // TODO: this is a temp solution as the BE needs to fix the problem
      },
      useJwt: true,
    },
  });
};

/**
 * Fetch the given deposit's data by Id.
 * @param {string} deposit_id
 * @param {string} bac
 */
export const fetchDBSDeposit = (deposit_id, bac) => {
  return createImprovedAsyncAction({
    type: types.DBS_FETCH_DEPOSIT,
    path: 'dbs.deposit',
    dynamicPath: { deposit_id },
    settings: {
      params: {
        customer_id: bac,
      },
      headers: {
        'Accept-Language': 'en' || getLocaleFromLocalStorage(),
      },
      useJwt: true,
    },
  });
};

/**
 * Merges customer deposits with DTS data
 * @param {Array} payload
 */
export const mergeDBSDepositsWithDTS = (payload) => {
  return {
    type: types.DBS_MERGE_CUSTOMER_DEPOSITS_WITH_DTS,
    payload,
  };
};
/**
 * Checks the deposit's replatformed status.
 * @param {string} deposit_id
 * @returns true if the deposit is replatformed to BIPS.
 */
export const isDepositBIPSified = (deposit_id) => {
  return createImprovedAsyncAction({
    type: 'types.FETCH_DEPOSIT_STATUS',
    path: 'depositStatus',
    dynamicPath: { deposit_id },
  }).promise;
};

/**
 * Gets the list of product access for the customer on from DBS
 * @param {string} customer_id
 * @returns {Promise<{
  "overnight_account": boolean,
  "notice_account": boolean,
  "term_deposit": boolean
}>}
 */

export const fetchDBSProductsAccess = (bac) => {
  return createImprovedAsyncAction({
    type: types.FETCH_DBS_PRODUCTS_ACCESS,
    path: 'dbs.productsAccess',
    settings: {
      params: {
        customer_id: bac,
      },
      headers: {
        'Accept-Language': 'en' || getLocaleFromLocalStorage(), // TODO: this is a temp solution as the BE needs to fix the problem
      },
      useJwt: true,
    },
  });
};

/**
 * Gets the list of product types for the customer from DBS
 * @param {string} distributor_id
 * @param {string} distribution_channel_id
 *
 * @returns {Promise<{
  "overnight_account": boolean,
  "notice_account": boolean,
  "term_deposit": boolean
}>}
 */

export const fetchDBSProductsTypes = (distributorId, distributionChannelId) => {
  return createImprovedAsyncAction({
    type: types.FETCH_DBS_PRODUCTS_TYPES,
    path: 'dbs.productsTypes',
    settings: {
      params: {
        distributor_id: distributorId,
        distribution_channel_id: distributionChannelId,
      },
      headers: {
        'Accept-Language': 'en' || getLocaleFromLocalStorage(),
      },
      useJwt: true,
    },
  });
};


/**
 * Disable AutoRenewal for a BIPSIfied Deposit.
 *
 * @param {string} deposit_id
 * @param {{ "product_id": null, "renewal_interest_treatment": null}} data
 */
export const postDisableAutoRenewal = (deposit_id, data) => {
  return createImprovedAsyncAction({
    type: types.POST_DISABLE_AUTO_RENEWAL,
    path: 'dbs.autoRenewal.disable',
    dynamicPath: { deposit_id },
    params: data,
    method: 'post',
    settings: {
      useJwt: true,
    },
  });
};
