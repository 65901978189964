import * as types from '../../actionTypes';
import { createImprovedAsyncAction } from '../../actionCreators';

export const fetchPensionCustomerToken = () => {
  return createImprovedAsyncAction({
    type: types.FETCH_PENSION_CUSTOMER_TOKEN,
    path: 'pension.token',
  });
};

export const postPensionCustomerShareData = () => {
  return createImprovedAsyncAction({
    type: types.POST_PENSION_CUSTOMER_SHARE_DATA,
    path: 'pension.shareData',
    method: 'post',
  });
};

export const postPensionCustomerShareSession = () => {
  return createImprovedAsyncAction({
    type: types.POST_PENSION_CUSTOMER_SHARE_SESSION,
    path: 'pension.shareSession',
    method: 'post',
  });
};

export const changePensionProductType = (type) => ({
  type: types.CHANGE_PENSION_PRODUCT_TYPE,
  payload: type,
});

export const changePPTargetLink = (link) => ({
  type: types.CHANGE_PP_TARGET_LINK,
  payload: link,
});
