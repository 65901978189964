import { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { postLogout } from 'store/global/actions';
import { cleanUpSessionData } from '../../utils/CustomerUtils/sessionDataCleanUp';
import { getFeatureFlagClient } from '../../utils/FeatureFlag';
import { goToDeProdPensionLogoutPage, goToLoginPage } from '../../utils/Routing';
import { reportError } from '../../utils/ErrorHandling';
import { getRefreshToken } from '../../utils/Auth/utils';
import { getKeycloakAuth } from '../SetPassword/utils';

export const Logout = ({ postLogout, isLoggedIn, location }) => {
  const fromPension = new URLSearchParams(location.search).get('fromPension');
  const germanyBranch = window.location.host.endsWith('weltsparen.de');
  const featureFlagClientPromiseRef = useRef();

  const shouldUseFForRedirectionAfterLogout = !fromPension && germanyBranch;

  useEffect(() => {
    featureFlagClientPromiseRef.current = getFeatureFlagClient().catch((err) => {
      reportError(`Failed initializing LaunchDarkly client.`, err);
    });
  }, []);

  useEffect(() => {
    async function logout() {
      const refreshToken = getRefreshToken();
      let client;

      try {
        client = await featureFlagClientPromiseRef.current;
      } catch (error) {
        reportError('Failed to fetch feature flags while logging out', error);
      }

      try {
        const keycloakAuth = getKeycloakAuth();

        await keycloakAuth.keycloakLogout(refreshToken);
      } catch (err) {
        reportError(`Failed to logout in KC`, err);
      }

      cleanUpSessionData();

      const userManagementCallObsLogout = client?.allFlags?.().userManagementCallObsLogout;

      if (userManagementCallObsLogout) {
        await postLogout();
      }

      if (!shouldUseFForRedirectionAfterLogout) {
        goToLoginPage();
      } else {
        const pensionLogout = client?.allFlags?.().userManagementPensionLogout;

        if (pensionLogout) {
          goToDeProdPensionLogoutPage();
        } else {
          goToLoginPage();
        }
      }
    }

    if (isLoggedIn) {
      logout();
    } else {
      goToLoginPage();
    }
  }, []);

  return null;
};

Logout.propTypes = {
  postLogout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.customer.isLoggedIn,
});

const mapDispatchToProps = {
  postLogout,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout));
