import { combineReducers } from 'redux';
import charts from './charts/reducer';
import customer from './customer/reducer';
import ui from './ui/reducers';
import investmentProducts from './investmentProducts/reducers';
import deposits from './deposits/reducers';
import staticContent from './staticContent/reducers';
import dashboard from './dashboard/reducers';
import global from './global/reducers';
import pensionProducts from './pensionProducts/reducers';
import etfConfiguratorFunds from './etfConfiguratorFunds/reducers';

export const appReducer = combineReducers({
  charts,
  customer,
  dashboard,
  deposits,
  investmentProducts,
  staticContent,
  ui,
  global,
  pensionProducts,
  etfConfiguratorFunds,
});
