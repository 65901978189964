import { LINKS } from 'components/Layout/menu';
import { isRunningLocally } from 'utils/React';
import { hasCookieForMobile, getJwtForMobile } from 'utils/CustomerUtils';
import { retrieveAuthConfig, storeAuthToken } from 'utils/Auth/index';
import { KeycloakAuthFactory } from 'utils/Auth/client';
import { reportError } from 'utils/ErrorHandling';
import * as types from '../../store/actionTypes';
// eslint-disable-next-line import/no-cycle
import { createImprovedAsyncAction } from '../../store/actionCreators';

const ONE_MINUTE = 1000 * 60;

export default class SessionsRefresher {
  constructor(refreshInterval = ONE_MINUTE) {
    this.user = null;
    this.lastJwtRefresh = null;
    this.lastJsessionidRefresh = null;
    this.refreshInterval = refreshInterval;
    this.refresh = window.setInterval(this.refreshJwt, ONE_MINUTE * 5);
  }

  logUserOut = () => {
    storeAuthToken(null);
    clearInterval(this.refresh);
    window.location.assign(`${window.location.origin}${window.location.pathname}#${LINKS.LOGOUT}`);
  };

  getUser = () => {
    return this.user;
  };

  setUser = (user) => {
    this.user = user;
  };

  // eslint-disable-next-line consistent-return
  refreshJwt = async () => {
    if (isRunningLocally()) return {};

    if (hasCookieForMobile()) {
      const jwtToken = getJwtForMobile();

      return { signInUserSession: { idToken: { jwtToken } } };
    }

    try {
      if (
        !this.lastJwtRefresh ||
        Date.now() - this.lastJwtRefresh > this.refreshInterval ||
        !this.user
      ) {
        const config = retrieveAuthConfig();

        if (config?.identity_provider === 'KEYCLOAK') {
          KeycloakAuthFactory.getInstance()
            ?.updateToken(30)
            .then((isRefreshed) => {
              if (isRefreshed) {
                this.lastJwtRefresh = Date.now();
              }
            });
        }
      }
    } catch (e) {
      reportError('Unable to refresh Token', e);
    }
  };

  refreshJsessionid = () => {
    if (isRunningLocally()) return false;

    if (
      !this.lastJsessionidRefresh ||
      Date.now() - this.lastJsessionidRefresh > this.refreshInterval
    ) {
      const { promise } = createImprovedAsyncAction({
        type: types.FETCH_CUSTOMER_DATA,
        path: 'customer.data',
      });

      promise.catch(() => this.logUserOut());
      this.lastJsessionidRefresh = Date.now();
    }

    return true;
  };

  reset = () => {
    clearInterval(this.refresh);
    this.refresh = null;
    this.user = null;
    this.lastJwtRefresh = null;
    this.lastJsessionidRefresh = null;
  };
}
