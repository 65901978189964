import { getBasePath } from './utils';

export const getMenuLinks = () => {
  const basePath = getBasePath();
  const getCustomerPortalUrl = (url) => `${basePath}${url}`;

  return {
    POSTBOX: getCustomerPortalUrl('/postbox/messages'),
    DOCUMENTS: getCustomerPortalUrl('/documents'),
    PAYMENT_METHODS: getCustomerPortalUrl('/payment-methods'),
    TRANSACTIONS: getCustomerPortalUrl('/account-transactions/'),
    WITHDRAW: getCustomerPortalUrl('/account-withdrawal'),
    USER_ACCOUNT: getCustomerPortalUrl('/user-account'),
    MY_DETAILS: getCustomerPortalUrl('/my-details'),
    NOTIFICATIONS: getCustomerPortalUrl('/notifications'),
    EXEMPTION_ORDER_OVERVIEW: getCustomerPortalUrl('/exemption-order/overview'),
  };
};
