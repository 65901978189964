import React, { createContext, useState } from 'react';

import PropTypes from 'prop-types';

export const OTPContext = createContext();

export const OTPProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [error, setError] = useState();
  const [info, setInfo] = useState();
  

  return (
    <OTPContext.Provider
      value={{
        isLoading,
        setIsLoading,
        isValidated,
        setIsValidated,
        isBlocked,
        setIsBlocked,
        error,
        setError,
        info,
        setInfo,
      }}
    >
      {children}
    </OTPContext.Provider>
  );
};

OTPProvider.propTypes = {
  children: PropTypes.node,
};
