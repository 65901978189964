/* eslint-disable import/first */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import 'ui-lib/src/styles/local-styles.scss';
import { HashRouter, Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import LoginOrchestrator from 'utils/LoginOrchestrator';
import { changeLocale } from 'store/ui/actions';
import { changeCurrency } from 'store/customer/actions';
import PublicRoute from 'components/PublicRoute';
import 'ui-lib/src/styles/styles.scss';
import { LINKS } from 'components/Layout/menu';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import { initializeTracking } from '@raisin/events-tracking';
import { initializeAPM } from '@raisin/fe-apm';
import Authorization from './Authorization';
import store from './store';
import { fetchFeatureFlags } from './store/global/actions';
import Router from './Router';
import { reportError } from './utils/ErrorHandling';
import Login from './pages/Login';
import { getCurrencyForLocale, getLocaleFromLocalStorage } from './utils/Locale';
import SetPassword from './pages/SetPassword';
import Logout from './pages/Logout';
import packageData from '../package.json';

require('velocity-animate');
require('velocity-animate/velocity.ui');

// eslint-disable-next-line no-underscore-dangle
window.__frontend__version__ = process.env.frontend_version;
// eslint-disable-next-line no-underscore-dangle
window.__uilib__version__ = process.env.uilib_version;

require('./App.scss');

initializeAPM({ zoneName: 'obs', version: packageData.version });

export default class App extends Component {
  static propTypes = {
    locale: PropTypes.string,
  };

  constructor(props) {
    super(props);
    const customerLocale = getLocaleFromLocalStorage();

    if (customerLocale && customerLocale !== props.locale) {
      store.dispatch(changeLocale(customerLocale));
      store.dispatch(changeCurrency(getCurrencyForLocale(customerLocale)));
    }

    const fetchFeatureFlagsAction = fetchFeatureFlags();

    this.state = {
      isFeatureFlagsInitialLoading: true,
    };
    store.dispatch(fetchFeatureFlagsAction);
    fetchFeatureFlagsAction.promise
      .then(() => this.setState({ isFeatureFlagsInitialLoading: false }))
      .catch((err) => reportError('Failed initializing LaunchDarkly client.', err));

    initializeTracking(customerLocale);
  }

  componentDidMount = () => {
    window.onerror = function onerror(msg, url, lineNo, columnNo, error) {
      reportError(msg, {
        ...error,
        url,
        lineNo,
        columnNo,
      });

      return false;
    };
  };

  render() {
    if (this.state.isFeatureFlagsInitialLoading) {
      return <LoadingPlaceholder height={300} width="100%" />;
    }

    return (
      <Provider store={store}>
        <HashRouter>
          <LoginOrchestrator>
            <Switch>
              <PublicRoute path={LINKS.SET_PASSWORD} component={SetPassword} exact />
              <PublicRoute path={LINKS.LOGIN} component={Login} exact />
              <Route path={LINKS.LOGOUT} component={Logout} exact />
              <Route
                render={() => (
                  <Authorization>
                    <Router />
                  </Authorization>
                )}
              />
            </Switch>
          </LoginOrchestrator>
        </HashRouter>
      </Provider>
    );
  }
}

ReactDOM.render(<App />, document.getElementById('react'));
