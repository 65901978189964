import React from 'react';
import { Translate } from 'components/I18n';

export const columns = [
  {
    title: <Translate id="header.1" />,
    className: 'col-xs-2',
    dataIndex: 'country',
    mobile: {
      order: 1,
      className: 'col-xs-12 no-padding',
    },
  },
  {
    title: <Translate id="header.2" />,
    className: 'col-xs-6',
    dataIndex: 'priority1',
    mobile: {
      order: 2,
      className: 'col-xs-12 no-padding',
    },
  },
  {
    title: <Translate id="header.3" />,
    className: 'col-xs-4',
    dataIndex: 'priority2',
    mobile: {
      order: 3,
      className: 'col-xs-12 no-padding',
    },
  },
];

export const rows = [
  {
    country: <Translate id="austria" />,
    priority1: <Translate id="concat" />,
    priority2: '–',
  },
  {
    country: <Translate id="belgium" />,
    priority1: <Translate id="belgium.priority.1" />,
    priority2: <Translate id="concat" />,
  },
  {
    country: <Translate id="bulgary" />,
    priority1: <Translate id="bulgary.priority.1" />,
    priority2: <Translate id="concat" />,
  },
  { country: <Translate id="germany" />, priority1: <Translate id="concat" />, priority2: '–' },
  {
    country: <Translate id="denmark" />,
    priority1: <Translate id="denamrk.priority.1" />,
    priority2: <Translate id="concat" />,
  },
  {
    country: <Translate id="estonia" />,
    priority1: <Translate id="estonia.priority.1" />,
    priority2: <Translate id="concat" />,
  },
  {
    country: <Translate id="finland" />,
    priority1: <Translate id="finland.priority.1" />,
    priority2: <Translate id="concat" />,
  },
  { country: <Translate id="france" />, priority1: <Translate id="concat" />, priority2: '–' },
  {
    country: <Translate id="greece" />,
    priority1: <Translate id="greece.priority.1" />,
    priority2: <Translate id="concat" />,
  },
  { country: <Translate id="ireland" />, priority1: <Translate id="concat" />, priority2: '–' },
  {
    country: <Translate id="iceland" />,
    priority1: <Translate id="iceland.priority.1" />,
    priority2: '–',
  },
  {
    country: <Translate id="italy" />,
    priority1: <Translate id="italy.priority.1" />,
    priority2: '–',
  },
  {
    country: <Translate id="croatia" />,
    priority1: <Translate id="croatia.priority.1" />,
    priority2: <Translate id="concat" />,
  },
  {
    country: <Translate id="liechtenstein" />,
    priority1: <Translate id="national.pass" />,
    priority2: <Translate id="liechtenstein.priority.2" />,
  },
  {
    country: <Translate id="lithuania" />,
    priority1: <Translate id="lithuania.priority.1" />,
    priority2: <Translate id="national.pass" />,
  },
  { country: <Translate id="luxembourg" />, priority1: <Translate id="concat" />, priority2: '–' },
  {
    country: <Translate id="latvia" />,
    priority1: <Translate id="latvia.priority.1" />,
    priority2: <Translate id="concat" />,
  },
  {
    country: <Translate id="malta" />,
    priority1: <Translate id="malta.priority.1" />,
    priority2: <Translate id="national.pass" />,
  },
  {
    country: <Translate id="netherlands" />,
    priority1: <Translate id="national.pass" />,
    priority2: <Translate id="netherlands.priority.2" />,
  },
  {
    country: <Translate id="norway" />,
    priority1: <Translate id="norway.priority.1" />,
    priority2: <Translate id="concat" />,
  },
  {
    country: <Translate id="poland" />,
    priority1: <Translate id="poland.priority.1" />,
    priority2: <Translate id="poland.priority.2" />,
  },
  {
    country: <Translate id="portugal" />,
    priority1: <Translate id="portugal.priority.1" />,
    priority2: <Translate id="national.pass" />,
  },
  {
    country: <Translate id="romania" />,
    priority1: <Translate id="romania.priority.1" />,
    priority2: <Translate id="national.pass" />,
  },
  {
    country: <Translate id="sweden" />,
    priority1: <Translate id="sweden.priority.1" />,
    priority2: <Translate id="concat" />,
  },
  {
    country: <Translate id="slovenia" />,
    priority1: <Translate id="slovenia.priority.1" />,
    priority2: <Translate id="concat" />,
  },
  {
    country: <Translate id="slovakia" />,
    priority1: <Translate id="slovakia.priority.1" />,
    priority2: <Translate id="national.pass" />,
  },
  {
    country: <Translate id="spain" />,
    priority1: <Translate id="spain.priority.1" />,
    priority2: '-',
  },
  {
    country: <Translate id="czech_republic" />,
    priority1: <Translate id="czech_republic.priority.1" />,
    priority2: <Translate id="czech_republic.priority.2" />,
  },
  { country: <Translate id="hungary" />, priority1: <Translate id="concat" />, priority2: '–' },
  {
    country: <Translate id="united_kingdom" />,
    priority1: <Translate id="united_kingdom.priority.1" />,
    priority2: <Translate id="concat" />,
  },
  {
    country: <Translate id="cyprus" />,
    priority1: <Translate id="national.pass" />,
    priority2: <Translate id="concat" />,
  },
  { country: <Translate id="rest" />, priority1: <Translate id="national.pass" />, priority2: '–' },
];
