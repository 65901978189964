import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import constants from 'ui-lib/src/constants';
import Alert from 'ui-lib/src/components/Alert';
import Paragraph from 'ui-lib/src/components/Paragraph';
import { Translate } from 'components/I18n';
import styles from './styles.scss';

export const NOTICE_NAME = 'deposit.autoprolongation.disabled';
export default class DisableAutoProlongation extends PureComponent {
  static propTypes = {
    notices: PropTypes.object,
  };

  render() {
    const { notices } = this.props;
    const { alerts } = constants;

    if (notices[NOTICE_NAME]) {
      return (
        <Alert
          id="disable-autoprolongation-notice"
          type={alerts.success}
          key="alert-disable-autoprolongation"
          className="selenium-autoProlongationDisabledNotification"
          showDefault
        >
          <Paragraph className={styles.alert}>
            <Translate
              id="autoprolongation.disable"
              values={{
                bankName: notices[NOTICE_NAME],
              }}
            />
          </Paragraph>
        </Alert>
      );
    }

    return null;
  }
}
