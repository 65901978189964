import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import Layout from 'components/Layout';

export default class RaisinRoute extends Component {
  static propTypes = {
    isAllowed: PropTypes.bool,
    showHeader: PropTypes.bool,
    showFooter: PropTypes.bool,
    hideMenu: PropTypes.bool,
    isIPRoute: PropTypes.bool,
  };

  static defaultProps = {
    isAllowed: true,
    showHeader: true,
    showFooter: true,
    hideMenu: false,
    isIPRoute: false,
  };

  render() {
    const { isAllowed, showHeader, showFooter, hideMenu, isIPRoute } = this.props;

    return (
      <Layout
        isIPRoute={isIPRoute}
        hideMenu={hideMenu}
        showHeader={showHeader}
        showFooter={showFooter}
      >
        {isAllowed ? <Route {...this.props} /> : <Redirect to="/" />}
      </Layout>
    );
  }
}
