import { handle } from 'redux-pack';
import { FETCH_PORTFOLIOS, FETCH_PORTFOLIO, RESET_PORTFOLIOS } from '../../../actionTypes';

export default (state = {}, action) => {
  // NOSONAR
  const { type, payload } = action;

  switch (type) {
    case FETCH_PORTFOLIOS:
      return handle(state, action, {
        success: () => {
          return {
            ...payload,
          };
        },
      });
    case FETCH_PORTFOLIO:
      return handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            [payload.data.id]: payload.data,
          };
        },
      });
    case RESET_PORTFOLIOS:
      return [];
    default:
      return state;
  }
};
