import { hasAuthToken } from 'utils/Auth';

import { getAccount, getAvailableBalance, getTotalBalance } from './account';
import { getActionItems } from './actionItems';
import { getCustomerMenus, getNonExportedCustomerMenus } from './customerMenus/index';
import { getMenuLinks } from './links';
import { getLogoItems } from './logos';
import { getNavItems } from './menus';
import { getTranslation } from './utils';

const getLogoutMenu = (pathname, is_company_customer, distributor_id, branding) => {
  return {
    type: 'public',
    logoItems: getLogoItems(pathname, is_company_customer, distributor_id, branding),
  };
};

const getDataSource = ({
  isCustomerActive,
  customer,
  location,
  pensionProductsData,
  isFeatureFlagLoading,
  isDWMLegacyMigrationCompleted,
  hasDWMPortfolio,
  hasMigratedRobo,
  tamsTransactionAccount,
  tamsError,
  userManagementPensionLogout,
  shouldShowUpdatedProductURLs,
  userManagementCallObsLogout,
  shouldShowPaymentMethodScreens,
  hasB2cDeprecateObsFeatureFlag,
  pomProducts,
  ippPensionsBIPSRedirect,
}) => {
  const {
    isLoggedIn,
    company_customer_name,
    display_name,
    is_company_customer,
    branding,
    distributor_id,
    is_pending_activation,
    first_name,
    last_name,
    state,
  } = customer;
  const { pathname } = location;

  if (!isLoggedIn && !hasAuthToken()) {
    return getLogoutMenu(pathname, is_company_customer, distributor_id, branding);
  }

  const displayName = is_company_customer ? company_customer_name : display_name;

  // Temp check to make sure all data exists before rendering the nav items
  if (displayName?.length === 0) {
    return getLogoutMenu(pathname, is_company_customer, distributor_id, branding);
  }

  const menuLinks = getMenuLinks();

  return {
    type: 'private',
    logoItems: getLogoItems(pathname, is_company_customer, distributor_id, branding),
    navItems: getNavItems({
      customer,
      pensionProductsData,
      isFeatureFlagLoading,
      isDWMLegacyMigrationCompleted,
      hasDWMPortfolio,
      hasMigratedRobo,
      shouldShowUpdatedProductURLs,
      hasB2cDeprecateObsFeatureFlag,
      pomProducts,
      ippPensionsBIPSRedirect,
    }),
    customer: {
      displayName: `${first_name} ${last_name}`,
      fullName: displayName,
      accountIds: getAccount({ customer, isCustomerActive, tamsTransactionAccount }),
      totalBalance: getTotalBalance(customer, tamsTransactionAccount),
      availableBalance: getAvailableBalance(customer, tamsTransactionAccount),
      subMenus: is_pending_activation
        ? getNonExportedCustomerMenus({ customer, menuLinks })
        : getCustomerMenus({
            customer,
            menuLinks,
            shouldShowPaymentMethodScreens,
          }),
      actionItems: getActionItems({
        userManagementPensionLogout,
        userManagementCallObsLogout,
      }),
      state,
      balanceIsNotAvailable: tamsError?.isMaintenance || tamsError?.error,
      notAvailableMessage: {
        title: getTranslation('header.account.information.maintenance.title'),
        tooltipText: tamsError?.isMaintenance
          ? getTranslation('header.account.information.maintenance.tooltip')
          : getTranslation('header.account.information.fetchBalance.error'),
      },
    },
  };
};

export default getDataSource;
