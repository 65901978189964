import { isDistributorCustomer } from 'utils/CustomerUtils';
import {
  hasCryptoPortfolio,
  hasEtfConfiguratorProducts,
  hasPPProducts,
  LINKS as PENSION_PRODUCTS_LINKS,
  PENSION_PRODUCT_MIGRATED_COCKPIT_LINKS,
} from 'utils/PensionProducts';

import { getUrl } from '../utils';

export const HAS_FIXED_DEPOSIT_ACCESS = 'HAS_FIXED_DEPOSIT_ACCESS';
export const HAS_FIXED_DEPOSIT_ACCOUNT = 'HAS_FIXED_DEPOSIT_ACCOUNT';
export const HAS_FLEX_DEPOSIT_ACCESS = 'HAS_FLEX_DEPOSIT_ACCESS';
export const HAS_FLEX_DEPOSIT_ACCOUNT = 'HAS_FLEX_DEPOSIT_ACCOUNT';
export const HAS_INVESTMENT_PRODUCT_ACCESS = 'HAS_INVESTMENT_PRODUCT_ACCESS';
export const HAS_INVESTMENT_PRODUCT_ACCOUNT = 'HAS_INVESTMENT_PRODUCT_ACCOUNT';
export const HAS_NOTICE_DEPOSIT_ACCESS = 'HAS_NOTICE_DEPOSIT_ACCESS';
export const HAS_NOTICE_DEPOSIT_ACCOUNT = 'HAS_NOTICE_DEPOSIT_ACCOUNT';
export const HAS_OVERNIGHT_DEPOSIT_ACCESS = 'HAS_OVERNIGHT_DEPOSIT_ACCESS';
export const HAS_OVERNIGHT_DEPOSIT_ACCOUNT = 'HAS_OVERNIGHT_DEPOSIT_ACCOUNT';

const getMenuBasedOnProductAccess = (menu) => {
  const resultMenu = [];

  menu.forEach((menuItem) => {
    if (menuItem.itemProductAccess) {
      const resultMenuItem = { ...menuItem };

      delete resultMenuItem.itemProductAccess;
      resultMenu.push(resultMenuItem);
    }
  });

  return resultMenu;
};

const generateOffersMenu = (
  productAccess,
  hasB2cDeprecateObsFeatureFlag,
  pomProducts,
  shouldShowUpdatedProductURLs,
) => ({
    id: 'offers',
    title: `Angebote`,
    href: '',
    subMenus: {
      layout: 'even',
      main: getMenuBasedOnProductAccess([
        {
          id: 'overnightDeposit-offers',
          type: 'advanced',
          title: `Tagesgeld`,
          href: shouldShowUpdatedProductURLs
            ? '/products/overnight/'
            : getUrl(`/#/Products/overnight`),
          description: `Flexible Anlage ohne festgelegte Laufzeit und mit variablem Zins`,
          icon: 'overnightMoneyIcon',
          iconColor: '#9CD3E4',
          groupName: 'Sparen',
          itemProductAccess: productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCESS) ||
          hasB2cDeprecateObsFeatureFlag,
        },
        {
          id: 'fixedDeposit-offers',
          type: 'advanced',
          title: 'Festgeld',
          href: shouldShowUpdatedProductURLs ? '/products/' : getUrl(`/#/Products`),
          description: 'Termineinlage mit fester Laufzeit und festem Zins',
          icon: 'fixedDepositIcon',
          iconColor: '#61AFD2',
          groupName: 'Sparen',
          itemProductAccess:
            productAccess.includes(HAS_FIXED_DEPOSIT_ACCESS) ||
            productAccess.includes(HAS_FLEX_DEPOSIT_ACCESS) ||
            hasB2cDeprecateObsFeatureFlag,
        },
        {
          id: 'noticeDeposit-offers',
          type: 'advanced',
          title: `Kündigungsgeld`,
          href: shouldShowUpdatedProductURLs ? '/products/notice/' : getUrl(`/#/Products/notice`),
          description: `Einlage ohne feste Laufzeit mit variablem Zins und Kündigungsfrist`,
          icon: 'terminationPaymentIcon',
          iconColor: '#317EA6',
          groupName: 'Sparen',
          itemProductAccess: productAccess.includes(HAS_NOTICE_DEPOSIT_ACCESS) ||
          hasB2cDeprecateObsFeatureFlag,
        },
        {
          id: 'geldmarkt-portfolio',
          type: 'advanced',
          title: 'Geldmarkt-Portfolio',
          href: '/geldmarkt/',
          description:
            'ETF-Portfolio mit variablem Zins ohne feste Laufzeit, unbegrenzte Anlagehöhe',
          icon: 'trendingUpIcon',
          iconColor: '#FFA101',
          groupName: 'Sparen',
          itemProductAccess: productAccess.includes(HAS_INVESTMENT_PRODUCT_ACCESS) ||
          pomProducts?.etf_configurator,
        },
      ]),
      sidebar: getMenuBasedOnProductAccess([
        {
          id: 'DWM-offers',
          type: 'advanced',
          title: 'Vermögensverwaltung',
          href: '/vermoegensverwaltung/',
          description: `Automatisiert in ein von Experten entwickeltes, globales Portfolio investieren`,
          icon: 'etfRoboIcon',
          iconColor: '#FBC02D',
          groupName: `Investieren`,
          itemProductAccess: productAccess.includes(HAS_INVESTMENT_PRODUCT_ACCESS) ||
          pomProducts?.dwm,
        },
        {
          id: 'etf-configurator-offers',
          type: 'advanced',
          title: 'ETF Configurator',
          href: '/investieren/configurator',
          description: `Automatisiert in ein selbst zusammen-gestelltes Portfolio investieren`,
          icon: 'etfConfiguratorIcon',
          iconColor: '#FFA300',
          groupName: `Investieren`,
          itemProductAccess: productAccess.includes(HAS_INVESTMENT_PRODUCT_ACCESS) ||
          pomProducts?.etf_configurator,
        },
        {
          id: 'private-equity-offers',
          type: 'advanced',
          title: `Private Equity`,
          href: `/private-equity/`,
          description: `Chancen auf Überrenditen mit Unternehmensbeteiligungen nutzen`,
          icon: 'privateEquityIcon',
          iconColor: '#FE8303',
          groupName: `Investieren`,
          itemProductAccess: productAccess.includes(HAS_INVESTMENT_PRODUCT_ACCESS) ||
          pomProducts?.private_equity,
        },
        {
          id: 'crypto-offers',
          type: 'advanced',
          title: `Crypto`,
          href: `/crypto/`,
          description: `In ein diversifiziertes Portfolio aus Kryptowährungen investieren`,
          icon: 'cryptoIcon',
          iconColor: '#C72323',
          groupName: `Investieren`,
          itemProductAccess: productAccess.includes(HAS_INVESTMENT_PRODUCT_ACCESS) ||
          pomProducts?.crypto,
        },
        {
          id: '8f49f12f-8b42-4404-a9e1-a8af52d340a5',
          type: 'advanced',
          title: `ETF Rürup`,
          href: 'https://www.raisin-pension.de/',
          description: `Mit Steuervorteilen für das Alter vorsorgen`,
          icon: 'etfRurupIcon',
          iconColor: '#AEC962',
          groupName: `Investieren`,
          itemProductAccess: productAccess.includes(HAS_INVESTMENT_PRODUCT_ACCESS) ||
          pomProducts?.pension,
        },
      ]),
    },
  });

export const getDeMenus = ({
  customer,
  pensionProductsData,
  isDWMLegacyMigrationCompleted,
  hasDWMPortfolio,
  hasMigratedRobo,
  shouldShowUpdatedProductURLs,
  hasB2cDeprecateObsFeatureFlag,
  pomProducts,
  ippPensionsBIPSRedirect,
}) => {
  const { productAccess, is_company_customer } = customer;

  const myInvestments = {
    id: 'myInvestments',
    title: 'Meine Anlagen',
    href: '',
    subMenus: {
      layout: 'even',
      top: [
        {
          id: 'dashboard-myInvestments',
          type: 'basic',
          title: 'Übersicht',
          href: getUrl(`/#/dashboard`),
          icon: 'overviewIcon',
          iconColor: '#0E6CC5',
        },
      ],
      main: getMenuBasedOnProductAccess([
        {
          id: 'overnight-deposit',
          type: 'basic',
          title: 'Tagesgeld',
          href: getUrl(`/#/MyInvestments/Overnight`),
          icon: 'overnightMoneyIcon',
          iconColor: '#9CD3E4',
          groupName: 'Sparen',
          itemProductAccess: productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCOUNT),
        },
        {
          id: 'fixed-deposit',
          type: 'basic',
          title: 'Festgeld',
          href: getUrl(`/#/MyInvestments/TermDeposit`),
          icon: 'fixedDepositIcon',
          iconColor: '#61AFD2',
          groupName: 'Sparen',
          itemProductAccess:
            productAccess.includes(HAS_FIXED_DEPOSIT_ACCOUNT) ||
            productAccess.includes(HAS_FLEX_DEPOSIT_ACCOUNT),
        },
        {
          id: 'noticeDeposit-offers',
          type: 'basic',
          title: `Kündigungsgeld`,
          href: getUrl(`/#/MyInvestments/Notice`),
          icon: 'terminationPaymentIcon',
          iconColor: '#317EA6',
          groupName: 'Sparen',
          itemProductAccess: productAccess.includes(HAS_NOTICE_DEPOSIT_ACCOUNT),
        },
      ]),
      sidebar: getMenuBasedOnProductAccess([
        {
          id: 'investment-products-DWM',
          type: 'basic',
          title: hasMigratedRobo ? 'ETF Robo' : 'Vermögensverwaltung',
          href: `/investments/wealth-management/dashboard?ref=header`,
          icon: 'etfRoboIcon',
          iconColor: '#FBC229',
          groupName: `Investieren`,
          itemProductAccess: hasDWMPortfolio || hasMigratedRobo,
        },
        {
          id: 'investment-products-robo',
          type: 'basic',
          title: `ETF Robo`,
          href: getUrl(`/#/InvestmentProducts/cockpit`),
          icon: 'etfRoboIcon',
          iconColor: '#FBC229',
          groupName: `Investieren`,
          itemProductAccess:
            !isDWMLegacyMigrationCompleted &&
            (productAccess.includes(HAS_INVESTMENT_PRODUCT_ACCOUNT) ||
              hasB2cDeprecateObsFeatureFlag),
        },
        {
          id: 'investment-products-configurator',
          type: 'basic',
          title: `ETF Configurator`,
          href: ippPensionsBIPSRedirect
            ? PENSION_PRODUCT_MIGRATED_COCKPIT_LINKS.invest
            : PENSION_PRODUCTS_LINKS.etfcCockpit,
          icon: 'etfConfiguratorIcon',
          iconColor: '#FFA300',
          groupName: `Investieren`,
          itemProductAccess: hasEtfConfiguratorProducts(pensionProductsData),
        },
        {
          id: 'crypto',
          type: 'basic',
          title: 'Crypto',
          href: ippPensionsBIPSRedirect
            ? PENSION_PRODUCT_MIGRATED_COCKPIT_LINKS.crypto
            : '/crypto/cockpit/',
          icon: 'cryptoIcon',
          iconColor: '#C72323',
          groupName: `Investieren`,
          itemProductAccess: hasCryptoPortfolio(pensionProductsData),
        },
        {
          id: 'raisin-pension',
          type: 'basic',
          title: 'Pension',
          href: ippPensionsBIPSRedirect
            ? PENSION_PRODUCT_MIGRATED_COCKPIT_LINKS.fairr
            : PENSION_PRODUCTS_LINKS.cockpit,
          icon: 'etfRurupIcon',
          iconColor: '#AEC962',
          groupName: `Investieren`,
          itemProductAccess: hasPPProducts(pensionProductsData),
        },
      ]),
    },
  };

  const offers = generateOffersMenu(
    productAccess,
    hasB2cDeprecateObsFeatureFlag,
    pomProducts,
    shouldShowUpdatedProductURLs,
  );

  const finance = {
    id: 'finance',
    title: `Finanzwissen`,
    href: '',
    subMenus: {
      main: [
        {
          id: 'savings-finance',
          type: 'advanced',
          title: 'Sparen',
          href: '/sparen/',
          description:
            'Höhere Zinsen als bei der Hausbank erhalten, mit nationaler Einlagensicherung',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/bltf266390c18780027/Sparen.svg',
          iconColor: '#0E6CC5',
        },
        {
          id: 'investments-finance',
          type: 'advanced',
          title: 'Geldanlage',
          href: '/geldanlage/',
          description:
            'Wertschwankungen in Kauf nehmen, um langfristig höhere Renditen zu realisieren',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt928de307dda49550/Geldanlage.svg',
          iconColor: '#0E6CC5',
        },
        {
          id: 'tax-information-finance',
          type: 'advanced',
          title: 'Steuerinformationen',
          href: `${is_company_customer ? '/geschaeftskunden/steuern/' : '/steuern/'}`,
          description: 'Informationen zur steuerlichen Behandlung von Geldanlagen',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt36b69e61c2b0a979/Steuerinformation.svg',
          iconColor: '#0E6CC5',
        },
        {
          id: 'insurance-deposit-finance',
          type: 'advanced',
          title: 'Einlagensicherung',
          href: `${
            is_company_customer ? '/geschaeftskunden/einlagensicherung/' : '/einlagensicherung/'
          }`,
          description: 'Alles zum harmonisierten Einlagensicherungssystem in Europa',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/bltdc24d5d258f982e7/Einlagensicherung.svg',
          iconColor: '#0E6CC5',
        },
      ],
      sidebar: [
        {
          id: 'investmentAbroad-finance',
          type: 'image',
          title: `Geldanlage im Ausland`,
          href: '/geldanlage/ausland/',
          imageUrl:
            'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt2583321398710b00/Image_Ausland.png',
          description: `Geldanlage im Ausland`,
        },
        {
          id: 'differenceFundsEtfs-finance',
          type: 'arrow',
          title: 'Unterschied Fonds ETFs',
          href: '/geldanlage/etf/unterschied-fonds-etf/',
        },
        {
          id: 'b8401a9d-2cfb-4280-b7ad-d54ee8c31d7e',
          type: 'arrow',
          title: 'Steuern sparen',
          href: '/steuer/steuern-sparen/',
        },
        {
          id: '5330f3cf-1ecb-4689-b50f-83ca4fa184e8',
          type: 'arrow',
          title: 'Was bedeutet AWV-Meldepflicht?',
          href: '/faq/bedeutet-awv-meldepflicht/',
        },
        {
          id: 'e776ab43-cce8-4cb1-9660-f10521d72350',
          type: 'arrow',
          title: 'Was ist die Quellensteuer?',
          href: '/steuer/quellensteuer/',
        },
      ],
    },
  };

  const help = {
    id: '2e11a01f-4216-4fea-b05f-2fd46cf3a8b4',
    title: 'Hilfe',
    href: '',
    subMenus: {
      main: [
        {
          id: 'a9cbf2ad-81bc-4f83-8196-b67b414f9b0f',
          type: 'text',
          title: 'So funktioniert’s',
          href: '/so-funktionierts/',
        },
        {
          id: '9a91930e-db2a-45be-8eab-30a081e40e03',
          type: 'text',
          title: 'Banken',
          href: '/bank/',
        },
        {
          id: 'c3f65470-a71c-4079-adae-a644c03c00a2',
          type: 'text',
          title: 'Häufig gestellte Fragen',
          href: 'https://kundenservice.weltsparen.de/hc/de/',
        },
        {
          id: '457a0585-4847-4c2c-bbad-620101e9512b',
          type: 'text',
          title: 'Kundenservice',
          href: '/kontakt/',
        },
      ],
    },
  };

  let navItems = [myInvestments, offers];

  if (!isDistributorCustomer(customer.distributor_id)) {
    navItems = [...navItems, finance];
  }

  return [...navItems, help];
};
