import { handle } from 'redux-pack';
import * as types from '../../../actionTypes';
import { ajaxStatus } from '../../../../config';
import { initialState } from '../../selectors';
import { setBuyData } from './alterations';

export default (state, action) => {
  // NOSONAR
  const { type, payload } = action;

  switch (type) {
    case types.RESET_BUY_DATA:
      return {
        ...initialState.buy,
      };
    case types.LOAD_BUY_FORM: {
      return {
        ...state,
        ...payload,
      };
    }
    case types.SET_BUY_DATA:
      return setBuyData(state, payload);
    case types.POST_BUY_DATA:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          form: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          form: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          session: {
            nonce: payload.data.nonce,
          },
          form: {
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });
    case types.POST_BUY_MTAN:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          mTanForm: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          session: {
            status: payload.response.status,
            error: payload.response.statusText,
            nonce: prevState.session.nonce,
          },
          mTanForm: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          mTanForm: {
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });
    default:
      return state;
  }
};
