import { isDistributorCustomer } from 'utils/CustomerUtils';

import { getUrl } from '../utils';

export const HAS_FIXED_DEPOSIT_ACCESS = 'HAS_FIXED_DEPOSIT_ACCESS';
export const HAS_FIXED_DEPOSIT_ACCOUNT = 'HAS_FIXED_DEPOSIT_ACCOUNT';
export const HAS_FLEX_DEPOSIT_ACCESS = 'HAS_FLEX_DEPOSIT_ACCESS';
export const HAS_FLEX_DEPOSIT_ACCOUNT = 'HAS_FLEX_DEPOSIT_ACCOUNT';
export const HAS_INVESTMENT_PRODUCT_ACCESS = 'HAS_INVESTMENT_PRODUCT_ACCESS';
export const HAS_INVESTMENT_PRODUCT_ACCOUNT = 'HAS_INVESTMENT_PRODUCT_ACCOUNT';
export const HAS_NOTICE_DEPOSIT_ACCESS = 'HAS_NOTICE_DEPOSIT_ACCESS';
export const HAS_NOTICE_DEPOSIT_ACCOUNT = 'HAS_NOTICE_DEPOSIT_ACCOUNT';
export const HAS_OVERNIGHT_DEPOSIT_ACCESS = 'HAS_OVERNIGHT_DEPOSIT_ACCESS';
export const HAS_OVERNIGHT_DEPOSIT_ACCOUNT = 'HAS_OVERNIGHT_DEPOSIT_ACCOUNT';

export const getAuMenus = ({
  customer,
  shouldShowUpdatedProductURLs,
  hasB2cDeprecateObsFeatureFlag,
}) => {
  const { productAccess } = customer;

  const myInvestments = {
    id: 'myInvestments',
    title: `Meine Anlagen`,
    href: '',
    subMenus: {
      layout: 'even',
      top: [
        {
          id: 'dashboard-myInvestments',
          type: 'basic',
          title: `Übersicht`,
          href: getUrl(`/#/Dashboard`),
          icon: 'overviewIcon',
          iconColor: '#0E6CC5',
        },
      ],
      main: [
        productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCOUNT) && {
          id: 'overnight-deposit',
          type: 'basic',
          title: `Tagesgeld`,
          href: getUrl(`/#/MyInvestments/Overnight`),
          icon: 'overnightMoneyIcon',
          iconColor: '#9CD3E4',
        },
        (productAccess.includes(HAS_FIXED_DEPOSIT_ACCOUNT) ||
          productAccess.includes(HAS_FLEX_DEPOSIT_ACCOUNT)) && {
          id: 'fixed-deposit',
          type: 'basic',
          title: `Festgeld`,
          href: getUrl(`/#/MyInvestments/TermDeposit`),
          icon: 'fixedDepositIcon',
          iconColor: '#61AFD2',
        },
        productAccess.includes(HAS_NOTICE_DEPOSIT_ACCOUNT) && {
          id: 'noticeDeposit-offers',
          type: 'basic',
          title: `Kündigungsgeld`,
          href: getUrl(`/#/MyInvestments/Notice`),
          icon: 'terminationPaymentIcon',
          iconColor: '#317EA6',
        },
      ],
    },
  };

  const offers = {
    id: 'offers',
    title: `Angebote`,
    href: '',
    subMenus: {
      main: [
        (productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCESS) || hasB2cDeprecateObsFeatureFlag) && {
          id: 'overnightDeposit-offers',
          type: 'advanced',
          title: `Tagesgeld`,
          href: shouldShowUpdatedProductURLs
            ? '/products/overnight/'
            : getUrl(`/#/Products/overnight`),
          description: `Flexible Anlage ohne festgelegte Laufzeit und mit variablem Zins`,
          icon: 'overnightMoneyIcon',
          iconColor: '#9CD3E4',
        },
        (productAccess.includes(HAS_FIXED_DEPOSIT_ACCESS) ||
          productAccess.includes(HAS_FLEX_DEPOSIT_ACCESS) ||
          hasB2cDeprecateObsFeatureFlag) && {
          id: 'fixedDeposit-offers',
          type: 'advanced',
          title: `Festgeld`,
          href: shouldShowUpdatedProductURLs ? '/products/' : getUrl(`/#/Products`),
          description: 'Termineinlage mit fester Laufzeit und festem Zins',
          icon: 'fixedDepositIcon',
          iconColor: '#61AFD2',
        },
      ],
    },
  };

  const finance = {
    id: 'finance',
    title: `Finanzwissen`,
    href: '',
    subMenus: {
      main: [
        {
          id: 'e78780a1-cc15-45d9-95ca-53c52e905cc5',
          type: 'advanced',
          title: 'Sparen',
          href: '/sparen/',
          description: 'Erspartes Geld für höhere Zinsen risikofrei einlegen',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt47d6d4d59810852a/Sparen.svg',
          iconColor: '#0E6CC5',
        },
        {
          id: '2a134210-7e96-4614-b940-318c010b7a0a',
          type: 'advanced',
          title: 'Geldanlage',
          href: '/geldanlage/',
          description:
            'Wertschwankungen in Kauf nehmen, um langfristig höhere Renditen zu realisieren',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blta8cc8459997e3a6c/Geldanlage.svg',
          iconColor: '#0E6CC5',
        },
        {
          id: '5d4de868-91cd-42cc-9358-08173aa5986f',
          type: 'advanced',
          title: 'Einlagensicherung',
          href: '/einlagensicherung/',
          description: 'Alles Wichtige rund um die Einlagensicherungssysteme in Europa',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt8e96e15c429c928b/Einlagensicherung.svg',
          iconColor: '#0E6CC5',
        },
      ],
      sidebar: [
        {
          id: 'differenceFundsEtfs-finance',
          type: 'arrow',
          title: 'Geld sparen',
          href: '/sparen/geld-sparen/',
        },
        {
          id: 'b8401a9d-2cfb-4280-b7ad-d54ee8c31d7e',
          type: 'arrow',
          title: 'Monatlich sparen',
          href: '/sparen/monatlich-sparen/',
        },
        {
          id: '5330f3cf-1ecb-4689-b50f-83ca4fa184e8',
          type: 'arrow',
          title: 'Sparbuch',
          href: '/sparen/sparbuch/',
        },
        {
          id: 'e776ab43-cce8-4cb1-9660-f10521d72350',
          type: 'arrow',
          title: 'Sparkonto',
          href: '/sparen/sparkonto/',
        },
      ],
    },
  };

  const help = {
    id: '2e11a01f-4216-4fea-b05f-2fd46cf3a8b4',
    title: 'Hilfe',
    href: '',
    subMenus: {
      main: [
        {
          id: 'a9cbf2ad-81bc-4f83-8196-b67b414f9b0f',
          type: 'text',
          title: 'So funktioniert’s',
          href: '/so-funktionierts/',
        },
        {
          id: '9a91930e-db2a-45be-8eab-30a081e40e03',
          type: 'text',
          title: 'Banken',
          href: '/bank/',
        },
        {
          id: 'c3f65470-a71c-4079-adae-a644c03c00a2',
          type: 'text',
          title: 'Häufig gestellte Fragen',
          href: 'https://kundenservice.weltsparen.at/hc/de/',
        },
        {
          id: '457a0585-4847-4c2c-bbad-620101e9512b',
          type: 'text',
          title: 'Kundenservice',
          href: '/kontakt/',
        },
      ],
    },
  };

  let navItems = [myInvestments, offers];

  if (!isDistributorCustomer(customer.distributor_id)) {
    navItems = [...navItems, finance];
  }

  return [...navItems, help];
};
