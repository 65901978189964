import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { sendGtmTrackingEvent } from 'ui-lib/src/utils/GtmTracking';
import { Translate, TranslationsProvider } from 'components/I18n';
import { isJustEtfPortfolio } from 'utils/IPUtils';
import { LINKS } from 'components/Layout/menu';
import styles from './styles.scss';

export class RoboAwardHeader extends Component {
  static propTypes = {
    bacNumber: PropTypes.string.isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    portfolio: PropTypes.shape({
      portfolio_provider: PropTypes.string,
    }).isRequired,
    eventEndDate: PropTypes.instanceOf(Date).isRequired,
  };

  isCampaignActive = () => new Date() < this.props.eventEndDate;

  isOnAllowedPath = () => {
    const {
      history: {
        location: { pathname },
      },
    } = this.props;

    const allowedPaths = [LINKS.MY_INVESTMENTS.COCKPIT, LINKS.PRODUCTS.INVESTMENT_PRODUCTS_DWM];

    return allowedPaths.includes(pathname);
  };

  isJustEtfCockpit = () => {
    const {
      history: {
        location: { pathname },
      },
      portfolio: { portfolio_provider },
    } = this.props;

    return portfolio_provider
      ? pathname === LINKS.MY_INVESTMENTS.COCKPIT && isJustEtfPortfolio(portfolio_provider)
      : true;
  };

  isCockpit = () => this.props.history.location.pathname === LINKS.MY_INVESTMENTS.COCKPIT;

  shouldDisplayHeader = () =>
    this.isCampaignActive() && this.isOnAllowedPath() && !this.isJustEtfCockpit();

  renderContent = () => (
    <TranslationsProvider
      translations={{
        de: () => import('./__translations__/de.json'),
      }}
      id="robo-award"
    >
      <div className={styles.roboAwardHeader}>
        <strong className="semi-bold">
          <Translate id="roboAward.text" />
        </strong>
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a
          className={styles.link}
          href="https://www.surveymonkey.de/r/etpaward2020"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            sendGtmTrackingEvent({
              eventCategory: this.isCockpit() ? 'obs-ip-cockpit' : 'obs-ip-landing',
              eventAction: 'linkto-etp-awards',
              eventLabel: 'etp-awards',
              eventProperty: this.props.bacNumber,
              eventNonInteraction: false,
            });
          }}
        >
          <Translate id="roboAward.link" />
        </a>
      </div>
    </TranslationsProvider>
  );

  render = () => (this.shouldDisplayHeader() ? this.renderContent() : null);
}

export default withRouter(RoboAwardHeader);
