/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'ui-lib/src/components/Link';
import Heading from 'ui-lib/src/components/Heading';
import { Translate } from 'components/I18n';
import Image from 'components/Image';
import { localeByCountry } from 'utils/CustomerUtils';
import { trackEvent } from '@raisin/events-tracking';
import { ANDROID_APP_URL, IOS_APP_URL } from './storeUrls';
import styles from './styles.scss';
import { EVENTS } from '../../../eventsTracking';

const MobileBanner = ({ country }) => (
  <div className={styles.background}>
    <div className="container">
      <div className={styles.wrapper}>
        <div className={styles.icon}>
          <Image src="app-icon-80.svg" alt="Mobile Banner Icon" />
        </div>

        <div className={styles.content}>
          <div className={styles.textWrapper}>
            <Heading className={styles.heading}>
              <Translate id="footer.mobileBanner.title" />
            </Heading>

            <p className={styles.text}>
              <Translate id="footer.mobileBanner.text" />
            </p>
          </div>

          <div className={styles.imagesWrapper}>
            <Link
              className={styles.badgeLink}
              href={ANDROID_APP_URL[country]}
              target="_blank"
              text={
                <Image src={`googleplay_badge_${localeByCountry[country]}.svg`} alt="Google Play" />
              }
              onClick={() => {
                trackEvent(EVENTS.ANDROID_APP_ICON_BADGE_TAPPED);
              }}
            />
            <Link
              className={styles.badgeLink}
              href={IOS_APP_URL[country]}
              target="_blank"
              text={
                <Image src={`appstore_badge_${localeByCountry[country]}.svg`} alt="App Store" />
              }
              onClick={() => {
                trackEvent(EVENTS.IOS_APP_ICON_BADGE_TAPPED);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

MobileBanner.propTypes = {
  country: PropTypes.string.isRequired,
};

export default MobileBanner;
