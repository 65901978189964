import React, { useEffect, useState } from 'react';
import { Translate } from 'components/I18n';
import Link from 'ui-lib/src/components/Link';
import { reportError } from 'utils/ErrorHandling';
import { getFeatureFlagClient } from 'utils/FeatureFlag';
import { getForgotPasswordLink } from './utils';
import styles from './styles.scss';

export const ResetYourPassword = () => {
  const [featureFlagClient, setFeatureFlagClient] = useState();

  useEffect(() => {
    getFeatureFlagClient()
      .then((client) => {
        if (client) {
          setFeatureFlagClient(client);

          return;
        }
        reportError(`Initializing LaunchDarkly client incorrectly. LD client value:`, client);
      })
      .catch((err) => reportError(`Failed iniatizing LaunchDarkly client.`, err));
  }, []);

  const flags = featureFlagClient?.allFlags();

  return flags?.baseServicesShowResetYourPassword ? (
    <div className="row">
      <div className={`${styles.forgotPassword}`}>
        <Link className={styles.forgotPassword} href={getForgotPasswordLink()}>
          <Translate id="forgotPassword" />
        </Link>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ResetYourPassword;
