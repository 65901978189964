import React, { useEffect, useMemo, useState } from 'react';

import { lowerCase } from 'lodash';
import { connect } from 'react-redux';
import Alert from 'ui-lib/src/components/Alert';
import Link from 'ui-lib/src/components/Link';
import Paragraph from 'ui-lib/src/components/Paragraph';

import { trackEvent } from '@raisin/events-tracking';

import { Translate } from 'components/I18n';
import { fetchCASCustomerData, fetchKSSCustomerKYCStatus } from 'store/customer/actions';
import { CUSTOMER_ACCOUNT_STATUS, isGBRCustomer, KYC_STATUS } from 'utils/CustomerUtils';

import { EVENTS } from '../../eventsTracking';
import { getExternalUrl } from '../Header/data/utils';
import { EXTERNAL_LINKS } from '../Header/Navigation/menuLinks';
import styles from './styles.scss';

export const MissingPOANotice = ({
  bacNumber,
  casCustomerData,
  kssCustomerKYCStatus,
  fetchKSSCustomerKYCStatus,
  fetchCASCustomerData,
}) => {
  const [isPendingPOA, setIsPendingPOA] = useState(false);

  const isEligibleToPOA = useMemo(() => {
    const isActive = casCustomerData?.status === CUSTOMER_ACCOUNT_STATUS.ACTIVE;
    const isBritish = isGBRCustomer({
      default_address: { country: casCustomerData?.address?.countryCode },
    });

    return !(isActive || isBritish);
  }, [casCustomerData?.status, casCustomerData?.address?.countryCode]);

  useEffect(() => {
    if (bacNumber && !casCustomerData) {
      fetchCASCustomerData(bacNumber);
    }
  }, [bacNumber, casCustomerData]);

  useEffect(() => {
    if (!isEligibleToPOA) return;

    fetchKSSCustomerKYCStatus(bacNumber);
  }, [isEligibleToPOA, fetchKSSCustomerKYCStatus, bacNumber]);

  useEffect(() => {
    if (!isEligibleToPOA) return;

    const pendingPOA =
      lowerCase(kssCustomerKYCStatus?.kyc_state) ===
      lowerCase(KYC_STATUS.PENDING_ADDITIONAL_DOCUMENTATION);

    setIsPendingPOA(pendingPOA);
  }, [kssCustomerKYCStatus, isEligibleToPOA]);

  if (!isPendingPOA || !isEligibleToPOA) return null;

  return (
    <Alert
      id="alert-missingProofOfAddress"
      className={styles.alertPOA}
      type="warning"
      key="alert-missingProofOfAddress"
      cta={
        <Link
          onClick={() => trackEvent(EVENTS.PROOF_OF_ADDRESS_PAGE_VIEWED)}
          href={getExternalUrl(EXTERNAL_LINKS.IDENTIFICATION.PROOF_OF_ADDRESS)}
          suffixIcon="chevron-right"
        >
          <Translate id="alert.button.missingProofOfAddress" />
        </Link>
      }
      showDefault
    >
      <Paragraph>
        <strong>
          <Translate id="alert.heading.missingProofOfAddress" />
        </strong>
      </Paragraph>
      <Paragraph>
        <Translate id="alert.paragraph.missingProofOfAddress" />
      </Paragraph>
    </Alert>
  );
};

const mapStateToProps = (state) => {
  return {
    casCustomerData: state.customer.casCustomerData,
    kssCustomerKYCStatus: state.customer.kssCustomerKYCStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchCASCustomerData: (bacNumber) => dispatch(fetchCASCustomerData(bacNumber)),
  fetchKSSCustomerKYCStatus: (bacNumber) => dispatch(fetchKSSCustomerKYCStatus(bacNumber)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MissingPOANotice);
