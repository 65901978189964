import React, { Component } from 'react';
import PropTypes from 'prop-types';
import constants from 'ui-lib/src/constants';
import Alert from 'ui-lib/src/components/Alert';
import Text from 'ui-lib/src/components/Text';
import Paragraph from 'ui-lib/src/components/Paragraph';
import { Translate } from 'components/I18n';
import { ajaxStatus } from '../../config';
import styles from './styles.scss';

export default class AuthorizationErrorNotice extends Component {
  static propTypes = {
    loginForm: PropTypes.object,
    requestError: PropTypes.object,
  };

  render() {
    const { loginForm, requestError } = this.props;
    const { alerts } = constants;

    if (loginForm.status === ajaxStatus.failure && !requestError?.title_i18n) {
      return (
        <Alert
          id="authorizationErrorNotice"
          type={alerts.danger}
          key="alert-authorizationError"
          className={styles.authorizationErrorNotice}
          showDefault
        >
          <Paragraph>
            <strong>
              <Translate id={ `${loginForm.error.code  }.label` } />
            </strong>
          </Paragraph>
          <Text>
            <Translate id={ loginForm.error.code } />
          </Text>
        </Alert>
      );
    }

    return null;
  }
}
