import get from 'lodash/get';
import axios from 'axios';
import { PROVIDERS } from 'utils/IPUtils';
import { isRunningLocally } from 'utils/React';
// eslint-disable-next-line import/no-cycle
import RequestUtils from '../utils/RequestUtils';
import { getIntegrationServiceBaseUrl } from '../utils/IntegrationService';
import config from '../config';

const handleLocalEnvRequest = (configuration) => {
  const { type, path, params = {}, settings = {}, dynamicPath = {} } = configuration;
  const endpointConfig = config.local;
  let endpoint = get(endpointConfig, path);

  if (path === 'products.investment_products') {
    if (params.params.provider === PROVIDERS.justEtf) {
      endpoint = get(endpointConfig, 'products.investment_products_justetf');
    }
  } else if (path === 'products.investmentProduct') {
    endpoint = get(endpointConfig, `products.singlePortfolios.${dynamicPath.id}`);
  }

  if (!endpoint) {
    throw new Error(`Endpoint ${path} not found under the local env.`);
  }

  return {
    type,
    promise: RequestUtils.get(endpoint, params, settings, dynamicPath),
  };
};

const handleProductionEnvRequest = (configuration) => {
  const { type, path, params: paramsOrData = {}, settings = {}, dynamicPath = {} } = configuration;
  const { method = 'get' } = configuration;
  const endpointConfig = config.production;
  const endpoint = get(endpointConfig, path);

  if (!endpoint) {
    throw new Error(`Endpoint ${path} not found under the production env.`);
  }

  return {
    type,
    promise: RequestUtils[method](endpoint, paramsOrData, settings, dynamicPath),
  };
};

export const createMailSubscriptionAction = (configuration) => {
  const { type, method = 'get', path, data = {}, headers = {} } = configuration;
  const endpoint = get(config.production, path);

  return {
    type,
    promise: axios({
      url: getIntegrationServiceBaseUrl() + endpoint,
      method,
      data,
      withCredentials: true,
      crossDomain: true,
      headers,
    }),
  };
};

export const createImprovedAsyncAction = (configuration) => {
  if (isRunningLocally()) {
    return handleLocalEnvRequest(configuration);
  }

  return handleProductionEnvRequest(configuration);
};

export const createAsyncAction = (type, path, method = 'get', params = {}, settings = {}) => {
  let env = process.env.NODE_ENV;

  env =
    env === 'test' || process.env.OBS_LOCAL || process.env.OBS_REST_ENDPOINT_URI
      ? 'production'
      : env;
  const endpointConfig = config[env];
  const endpoint = get(endpointConfig, path);

  if (!endpoint) {
    throw new Error(`Path ${path} not found under the ${env}`);
  }

  if (!process.env.OBS_REST_ENDPOINT_URI && env === 'local' && !process.env.OBS_LOCAL) {
    // eslint-disable-next-line no-param-reassign
    method = 'get'; // AWS mock response comes always with get
  }

  return {
    type,
    promise: RequestUtils[method](endpoint, params, settings),
  };
};
