import React, { Component } from 'react';
import PropTypes from 'prop-types';
import constants from 'ui-lib/src/constants';
import Alert from 'ui-lib/src/components/Alert';
import Text from 'ui-lib/src/components/Text';
import { Translate } from 'components/I18n';

export default class RequestErrorNotice extends Component {
  static propTypes = {
    requestError: PropTypes.object,
  };

  render() {
    const { requestError } = this.props;
    const { alerts } = constants;

    if (
      requestError &&
      (requestError.status === 400 || requestError.status > 403) &&
      requestError.status < 599
    ) {
      return (
        <Alert
          type={alerts.danger}
          key="alert-requestError"
          className="requestErrorNotice"
          showDefault
        >
          <Text>
            <Translate id="error.getRequest.failure" />
          </Text>
        </Alert>
      );
    }

    return null;
  }
}
