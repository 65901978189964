import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Link from 'ui-lib/src/components/Link';
import Heading from 'ui-lib/src/components/Heading';
import { Translate } from 'components/I18n';
import Image from 'components/Image';
import { ICONS } from './icons';
import styles from './styles.scss';

const SocialMedia = memo(({ country }) =>
  ICONS[country].length ? (
    <div className={styles.wrapper}>
      <Heading className={styles.heading}>
        <Translate id="footer.socialMedia.title" />
      </Heading>

      <div className={styles.container}>
        {ICONS[country].map((icon) => (
          <Link
            key={icon.title}
            className={styles.icons}
            href={icon.url}
            type="text"
            target="_blank"
          >
            <Image src={icon.name} alt={icon.title} />
          </Link>
        ))}
      </div>
    </div>
  ) : null,
);

SocialMedia.propTypes = {
  country: PropTypes.string.isRequired,
};

export default SocialMedia;
