export const calculatePasswordStrength = ({ value }) => {
  if (value.length <= 1) {
    return 0;
  }
  if (value.length <= 6) {
    return 1;
  }
  let intScore = Math.min(value.length * 2, 32);

  if (value.match(/[a-z]/)) {
    intScore += 1;
  }
  if (value.match(/[A-Z]/)) {
    intScore += 5;
  }
  if (value.match(/\d+/)) {
    intScore += 5;
  }
  if (value.match(/(\d.*\d.*\d)/)) {
    intScore += 5;
  }
  if (value.match(/^\s*\d+\s*$/)) {
    intScore = 11;
  }
  if (value.match(/[!,@#$%^&*?_~]/)) {
    intScore += 10;
  }
  if (value.match(/([!,@#$%^&*?_~].*[!,@#$%^&*?_~])/)) {
    intScore += 10;
  }
  if (value.match(/[a-z]/) && value.match(/[A-Z]/)) {
    intScore += 2;
  }
  if (value.match(/\d/) && value.match(/\D/)) {
    intScore += 2;
  }

  if (
    value.match(/[a-z]/) &&
    value.match(/[A-Z]/) &&
    value.match(/\d/) &&
    value.match(/[!,@#$%^&*?_~]/)
  ) {
    intScore += 2;
  }

  intScore *= 100 / 48;
  if (intScore > 100) {
    intScore = 100;
  }

  let result;

  switch (true) {
    case intScore >= 0 && intScore <= 10:
      result = 1;
      break;
    case intScore > 10 && intScore <= 40:
      result = 2;
      break;
    case intScore > 40 && intScore <= 60:
      result = 3;
      break;
    case intScore > 60 && intScore <= 90:
      result = 4;
      break;
    case intScore > 90 && intScore <= 100:
      result = 5;
      break;
    default:
      result = 0;
  }

  return result;
};
