export const getFormErrorMessage = (form) => {
  let notice;

  if (form?.error?.response?.data?.notices) {
    // eslint-disable-next-line
    notice = Object.keys(form.error.response.data.notices)[0];
  }

  return form?.error?.response?.data?.title_i18n || notice || 'error.generic';
};

export const reportError = (message, error) => {
  if (process.env.NODE_ENV === 'production') {
    // TODO - send errors to new APM tool
  } else {
    // eslint-disable-next-line
    console.error(message, error);
  }
};
