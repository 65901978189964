import * as types from '../actionTypes';

export const initialState = {
  fundsList: [],
  fundsListFilters: {
    select: '',
    sustainabilityToggle: false,
    searchValue: '',
    checkboxes: {
      Anleihen: true,
      Aktien: true,
      Sonstige: true,
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ETF_C_FUNDS_LIST:
      return {
        ...state,
        fundsList: action.payload,
      };
    case types.TOGGLE_ETF_C_FUNDS_OVERVIEW_FILTER_CHECKBOX:
      return {
        ...state,
        fundsListFilters: {
          ...state.fundsListFilters,
          checkboxes: { ...state.fundsListFilters.checkboxes, ...action.payload },
        },
      };

    case types.CHANGE_ETF_C_FUNDS_OVERVIEW_FILTER_SELECT:
      return {
        ...state,
        fundsListFilters: {
          ...state.fundsListFilters,
          select: action.payload,
        },
      };

    case types.RESET_ETF_C_FUNDS_OVERVIEW_FILTER_SELECT:
      return {
        ...state,
        fundsListFilters: {
          ...state.fundsListFilters,
          select: initialState.fundsListFilters.select,
        },
      };

    case types.CHANGE_ETF_C_FUNDS_OVERVIEW_FILTER_TOGGLE:
      return {
        ...state,
        fundsListFilters: {
          ...state.fundsListFilters,
          sustainabilityToggle: action.payload,
        },
      };

    case types.SET_ETF_C_FUNDS_OVERVIEW_SEARCH_VALUE:
      return {
        ...state,
        fundsListFilters: {
          ...state.fundsListFilters,
          searchValue: action.payload,
        },
      };
    default:
      return state;
  }
};
