export const getNldMenus = ({ menuLinks }) => {
  const subMenus = [
    {
      id: '5534dc4e-fd1e-4936-84f1-0fe185ec6e52',
      type: 'text',
      title: 'Berichten',
      href: menuLinks.POSTBOX,
    },
    {
      id: '5534dc4e-fd1e-4934-84f1-0fe185ec6e52',
      type: 'text',
      title: 'Documenten',
      href: menuLinks.DOCUMENTS,
    },
    {
      id: '5534dc4e-fd1e-4936-84f1-0fe185ec6e82',
      type: 'text',
      title: 'Transacties (Raisin-rekening)',
      href: menuLinks.TRANSACTIONS,
    },
    {
      id: '8fbeb352-f366-470f-aaca-bf93e53f2c47',
      type: 'text',
      title: 'Uitbetalen naar tegenrekening',
      href: menuLinks.WITHDRAW,
    },
    {
      id: '394d205a-3fbc-472a-947d-3b5f78cb6e6a',
      type: 'text',
      title: 'Toegang en veiligheid',
      href: menuLinks.USER_ACCOUNT,
    },
    {
      id: 'e81df68f-9700-45c1-b305-23beb9acc063',
      type: 'text',
      title: 'Mijn gegevens',
      href: menuLinks.MY_DETAILS,
    },
    {
      id: '4681530d-9eed-4cbe-982d-5445410895d8',
      type: 'text',
      title: 'Communicatievoorkeuren',
      href: menuLinks.NOTIFICATIONS,
    },
  ];

  return subMenus;
};

export const getNldNonExportedMenus = ({ menuLinks }) => {
  const navItems = [
    {
      id: '5534dc4e-fd1e-4936-84f1-0fe185ec6e52',
      type: 'text',
      title: 'Berichten',
      href: menuLinks.POSTBOX,
    },
    {
      id: '5534dc4e-fd1e-4934-84f1-0fe185ec6e52',
      type: 'text',
      title: 'Documenten',
      href: menuLinks.DOCUMENTS,
    },
    {
      id: '394d205a-3fbc-472a-947d-3b5f78cb6e6a',
      type: 'text',
      title: 'Toegang en veiligheid',
      href: menuLinks.USER_ACCOUNT,
    },
  ];

  return navItems;
};
