import { states } from 'utils/CustomerUtils';

import { getUrl } from '../utils';

export const HAS_FIXED_DEPOSIT_ACCESS = 'HAS_FIXED_DEPOSIT_ACCESS';
export const HAS_FIXED_DEPOSIT_ACCOUNT = 'HAS_FIXED_DEPOSIT_ACCOUNT';
export const HAS_FLEX_DEPOSIT_ACCESS = 'HAS_FLEX_DEPOSIT_ACCESS';
export const HAS_FLEX_DEPOSIT_ACCOUNT = 'HAS_FLEX_DEPOSIT_ACCOUNT';
export const HAS_INVESTMENT_PRODUCT_ACCESS = 'HAS_INVESTMENT_PRODUCT_ACCESS';
export const HAS_INVESTMENT_PRODUCT_ACCOUNT = 'HAS_INVESTMENT_PRODUCT_ACCOUNT';
export const HAS_NOTICE_DEPOSIT_ACCESS = 'HAS_NOTICE_DEPOSIT_ACCESS';
export const HAS_NOTICE_DEPOSIT_ACCOUNT = 'HAS_NOTICE_DEPOSIT_ACCOUNT';
export const HAS_OVERNIGHT_DEPOSIT_ACCESS = 'HAS_OVERNIGHT_DEPOSIT_ACCESS';
export const HAS_OVERNIGHT_DEPOSIT_ACCOUNT = 'HAS_OVERNIGHT_DEPOSIT_ACCOUNT';

export const getIrlMenus = ({
  customer,
  shouldShowUpdatedProductURLs,
  hasB2cDeprecateObsFeatureFlag,
}) => {
  const { productAccess, state } = customer;
  const isPendingActivation = state === states.ACTIVATION_PENDING;

  const myInvestments = {
    id: 'myInvestments',
    title: 'My savings',
    href: '',
    subMenus: {
      layout: 'even',
      top: [
        {
          id: 'dashboard-myInvestments',
          type: 'basic',
          title: 'My dashboard',
          href: getUrl(`/#/dashboard`),
          icon: 'overviewIcon',
          iconColor: '#0E6CC5',
        },
      ],
      main: [
        (productAccess.includes(HAS_FIXED_DEPOSIT_ACCOUNT) ||
          productAccess.includes(HAS_FLEX_DEPOSIT_ACCOUNT)) && {
          id: 'fixed-deposit',
          type: 'basic',
          title: 'Deposit accounts',
          href: getUrl(`/#/MyInvestments/TermDeposit`),
          icon: 'fixedDepositIcon',
          iconColor: '#61AFD2',
        },
        productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCOUNT) && {
          id: 'overnight-deposit',
          type: 'basic',
          title: 'Demand deposit accounts',
          href: getUrl(`/#/MyInvestments/Overnight`),
          icon: 'overnightMoneyIcon',
          iconColor: '#9CD3E4',
        },
      ],
    },
  };

  const offers = {
    id: 'offers',
    title: 'Savings accounts',
    href: '',
    subMenus: {
      layout: 'even',
      main: [
        (productAccess.includes(HAS_FIXED_DEPOSIT_ACCESS) ||
          productAccess.includes(HAS_FLEX_DEPOSIT_ACCESS) ||
          hasB2cDeprecateObsFeatureFlag) && {
          id: 'fixedDeposit-offers',
          type: 'advanced',
          title: 'Deposit accounts',
          href: shouldShowUpdatedProductURLs ? '/products/' : getUrl(`/#/Products`),
          description: 'Earn a fixed interest and withdraw your money at the end of the term',
          icon: 'fixedDepositIcon',
          iconColor: '#61AFD2',
        },
        (productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCESS) || hasB2cDeprecateObsFeatureFlag) && {
          id: 'overnightDeposit-offers',
          type: 'advanced',
          title: 'Demand deposit accounts',
          href: shouldShowUpdatedProductURLs
            ? '/products/overnight/'
            : getUrl(`/#/Products/overnight`),
          description:
            'Earn a variable interest on your savings and access your money whenever you need',
          icon: 'overnightMoneyIcon',
          iconColor: '#9CD3E4',
        },
      ],
    },
  };

  const information = {
    id: 'information',
    title: 'Information',
    href: '',
    subMenus: {
      main: [
        {
          id: 'e78780a1-cc15-45d9-95ca-53c52e905cc5',
          type: 'advanced',
          title: 'Deposit protection',
          href: '/deposit-guarantee/',
          description: 'Everything you need to know about the Deposit Guarantee Scheme',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt7ec22c5392125d5b/Deposit_protection.svg',
          iconColor: '#0E6CC5',
        },
        {
          id: '2a134210-7e96-4614-b940-318c010b7a0a',
          type: 'advanced',
          title: 'Banks',
          href: '/bank/',
          description: 'Find out more about our partner banks',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt9d8d32dc1f3a6166/Banks.svg',
          iconColor: '#0E6CC5',
        },
        {
          id: 'd63e6408-738b-4d6f-927a-bc2274804d69',
          type: 'advanced',
          title: 'Savings guides',
          href: '/savings/',
          description: 'Everything you need to successfully navigate the world of saving',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt9878b0f3ba0d44ff/Savings_guides.svg',
          iconColor: '#0E6CC5',
        },
        {
          id: '5d4de868-91cd-42cc-9358-08173aa5986f',
          type: 'advanced',
          title: 'Banking guides',
          href: '/banking/',
          description:
            'Gain a better understanding of financial services with our useful banking guides',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt54dedccdfc38141b/Banking_guides.svg',
          iconColor: '#0E6CC5',
        },
        {
          id: '5d4de868-91cd-42cc-9358-08173ba5386f',
          type: 'advanced',
          title: 'Taxes guides',
          href: '/taxes/',
          description:
            'Learn about different types of taxes, including tax relief and inheritance tax',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt8a15c57942be166c/Taxes_guides.svg',
          iconColor: '#0E6CC5',
        },
      ],
    },
  };

  const help = {
    id: '2e11a01f-4216-4fea-b05f-2fd46cf3a8b4',
    title: 'Help',
    href: '',
    subMenus: {
      main: [
        isPendingActivation && {
          id: 'a9cbf2ad-81bc-4f83-8196-b67b414f9b0f',
          type: 'text',
          title: 'Get started',
          href: '/how-to-register/',
        },
        {
          id: '9a91930e-db2a-45be-8eab-30a081e40e03',
          type: 'text',
          title: 'FAQs',
          href: 'https://help.raisin.ie/hc/en-gb/',
        },
        {
          id: 'c3f65470-a71c-4079-adae-a644c03c00a2',
          type: 'text',
          title: 'Contact us',
          href: '/contact/',
        },
      ],
    },
  };

  const navItems = [myInvestments, offers, information, help];

  return navItems;
};
