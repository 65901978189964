import { featureFlagClientInit, FeatureFlagUserBuilder } from '@raisin/feature-flag-react-client';
import { getNamespaceName, getRegionName } from 'utils/IPUtils';
import store from 'store';
import uuid from "uuid/v4";

export const LAUNCHDARKLY_CLIENT_SIDE_IDS = {
  develop: '61a9f77ca7a8d31143a37089',
  staging: '61a9f4a34e7888116e020694',
  onboarding: '61a9f4afc6a5f10dd1217229',
  production: '61a9f4530be7e9119fa0857e',
};

const getLaunchDarklyClientSideId = () => {
  const namespace = getNamespaceName();

  return LAUNCHDARKLY_CLIENT_SIDE_IDS[namespace] || LAUNCHDARKLY_CLIENT_SIDE_IDS.develop;
};

export const getFeatureFlagClient = async () => {
  const customer = store.getState()?.customer || {};
  const {
    bac_number,
    default_address,
    is_company_customer,
    is_pending_activation,
    distributor_id,
  } = customer;

  const hasCustomer = !!bac_number;
  const type = is_company_customer ? 'business' : 'retail';
  const status = is_pending_activation ? 'non-exported' : 'exported';

  const clientSideId = getLaunchDarklyClientSideId();
  const user = new FeatureFlagUserBuilder(bac_number)
    .investorRegion(getRegionName())
    .investorDomain(default_address?.country || '')
    .investorType(hasCustomer ? type : '')
    .investorStatus(hasCustomer ? status : '')
    .distributor(distributor_id || '')
    .build();

  const options = {
    streaming: true,
  };

  const client = await featureFlagClientInit(clientSideId, user, options);

  return client;
};

export const getFeatureFlagClientBy = async (key, anonymous = false) => {
  const clientSideId = getLaunchDarklyClientSideId();
  const userBuilder = new FeatureFlagUserBuilder(key)
    .investorRegion(getRegionName());


  if (anonymous) {
    userBuilder.anonymous(anonymous);
  }

  const options = {
    streaming: true,
  };

  const client = await featureFlagClientInit(clientSideId, userBuilder.build(), options);

  return client;
};

const ANONYMOUS_USER_FF_KEY = 'anonymous-user-ff-key';

/**
 This is workaround to give a random uuid when a user is not logged in yet
 */


export const getFeatureFlagUserKey = () => {
  let userKey = localStorage.getItem(ANONYMOUS_USER_FF_KEY);

  if (userKey) {
    return userKey;
  }
  userKey = uuid();
  localStorage.setItem(ANONYMOUS_USER_FF_KEY, userKey);

  return userKey;
};
