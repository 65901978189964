import isEmpty from 'lodash/isEmpty';
import constants from 'ui-lib/src/constants';
import {
  generateAmountDevelopment,
  generatePerformace,
  getMiddleValue,
  generateWeeks,
  getYAxisValueNormalized,
  getMaxValue,
} from './utils';

const basePortfolioID = 'WI04';

export const initialState = {
  pointsOnMap: [],
  performanceData: {},
  historicData: {},
};

export const getPointsOnMap = (state) => {
  const { portfolio_id } = state.investmentProducts.investment;
  const portfolio = state.investmentProducts.portfolios[portfolio_id];

  if (isEmpty(portfolio)) {
    return initialState.pointsOnMap;
  }

  return portfolio.composition.map((asset) => ({
    name: asset.description_short,
    value: asset.allocation_size * 100,
    type: asset.asset_class,
    geo: { area: asset.geography },
  }));
};

export const getPerformanceData = (state, portfolioParam) => {
  const portfolioId = portfolioParam || state.investmentProducts.investment.portfolio_id;
  let amountSP = state.investmentProducts.order.savingsPlan.amount.value;
  let { amount } = state.investmentProducts.order;

  if (amount === null) {
    amount = state.investmentProducts.order.initialAmount;
  }
  if (amountSP === null) {
    amountSP = state.investmentProducts.order.initialSPAmount;
  }

  // get the selected portfolio
  const portfolio = state.investmentProducts.portfolios[portfolioId];
  const portfolios = Object.values(state.investmentProducts.portfolios)
    .filter(({ id }) => id)
    .sort((a, b) => a.value - b.value);

  // gets the WI04 portfolio
  const calculationBasePortfolio = portfolios.find(({ id }) => id === constants.maxPortfolio);

  if (isEmpty(portfolio) || isEmpty(calculationBasePortfolio)) {
    return initialState.performanceData;
  }

  const { extreme_bad_projections, bad_projections, good_projections, median_projections, dates } =
    portfolio.projection_data;
  const baseGoodPerformance = generatePerformace(
    calculationBasePortfolio.projection_data.good_projections,
    amount,
    amountSP,
    dates,
  );
  const maxValue = getMaxValue(baseGoodPerformance);
  const yAxisValueNormalized = getYAxisValueNormalized(maxValue);
  const tickValuesY = [1, 2, 3].map((index) => yAxisValueNormalized * index);

  tickValuesY.push(amount);

  return {
    tickValuesY,
    amount,
    amountDevelopment: generateAmountDevelopment(amount, amountSP, dates),
    max: maxValue,
    middle: getMiddleValue(generateWeeks(dates)),
    years: generateWeeks(dates),
    good: generatePerformace(good_projections, amount, amountSP, dates),
    median: generatePerformace(median_projections, amount, amountSP, dates),
    bad: generatePerformace(bad_projections, amount, amountSP, dates),
    extremeBad: generatePerformace(extreme_bad_projections, amount, amountSP, dates),
  };
};

export const getPerformanceDataForCustomerPortfolio = (state) => {
  const portfolioId = state.investmentProducts.investment.portfolio_id;
  const amount = state.investmentProducts.investment.value_snapshot.ip_account_balance.value;
  const isSavingsPlanActive = state.investmentProducts.savingsPlan.status.active;
  const amountSP = isSavingsPlanActive
    ? state.investmentProducts.savingsPlan.status.amount.value
    : 0;
  const portfolio = state.investmentProducts.portfolios[portfolioId];

  if (isEmpty(portfolio)) {
    return initialState.performanceData;
  }

  const { extreme_bad_projections, bad_projections, good_projections, median_projections, dates } =
    portfolio.projection_data;
  const goodPerformance = generatePerformace(good_projections, amount, amountSP, dates);
  const maxValue = getMaxValue(goodPerformance);
  const yAxisValueNormalized = getYAxisValueNormalized(maxValue);
  const tickValuesY = [1, 2, 3].map((index) => yAxisValueNormalized * index);

  tickValuesY.push(amount);

  return {
    tickValuesY,
    amount,
    amountDevelopment: generateAmountDevelopment(amount, amountSP, dates),
    max: maxValue,
    middle: getMiddleValue(generateWeeks(dates)),
    years: generateWeeks(dates),
    good: goodPerformance,
    median: generatePerformace(median_projections, amount, amountSP, dates),
    bad: generatePerformace(bad_projections, amount, amountSP, dates),
    extremeBad: generatePerformace(extreme_bad_projections, amount, amountSP, dates),
  };
};

export const getHistoricData = (state, portfolioParam) => {
  const portfolio_id = portfolioParam || state.investmentProducts.investment.portfolio_id;
  const portfolio = state.investmentProducts.portfolios[portfolio_id];
  const basePortfolio = state.investmentProducts.portfolios[basePortfolioID];

  if (isEmpty(portfolio) || !portfolio.historic_data) {
    return initialState.historicData;
  }

  const { dates, values } = portfolio.historic_data;
  const getMiddleValue = (array) => array[Math.round(array.length / 2)];
  const calculatedValues = portfolio.historic_data.values.map((value, index) => {
    const date = portfolio.historic_data.dates[index];

    return {
      name: date,
      value: [date, value],
    };
  });

  const baseValues = basePortfolio.historic_data.values.map((value, index) => {
    const date = basePortfolio.historic_data.dates[index];

    return {
      name: date,
      value: [date, value],
    };
  });

  return {
    baseValues,
    values: calculatedValues,
    min: !isEmpty(values) ? values[0] : null,
    middle: getMiddleValue(dates),
  };
};
