export const ANDROID_APP_URL = {
  AUT: 'https://play.google.com/store/apps/details?id=com.raisin.app&utm_medium=qr&utm_source=weltsparen_at',
  DEU: 'https://play.google.com/store/apps/details?id=com.raisin.app&utm_medium=qr&utm_source=weltsparen_de',
  ESP: 'https://play.google.com/store/apps/details?id=com.raisin.app&utm_medium=qr&utm_source=raisin_es',
  EUR: 'https://play.google.com/store/apps/details?id=com.raisin.app&utm_medium=qr&utm_source=raisin_com',
  FRA: 'https://play.google.com/store/apps/details?id=com.raisin.app&utm_medium=qr&utm_source=raisin_fr',
  GBR: 'https://play.google.com/store/apps/details?id=com.raisin.app&utm_medium=qr&utm_source=raisin_uk',
  NLD: 'https://play.google.com/store/apps/details?id=com.raisin.app&utm_medium=qr&utm_source=raisin_nl',
  POL: 'https://play.google.com/store/apps/details?id=com.raisin.app&utm_medium=qr&utm_source=raisin_pl',
  FIN: 'https://play.google.com/store/apps/details?id=com.raisin.app&utm_medium=qr&utm_source=raisin_fi',

};

export const IOS_APP_URL = {
  AUT: 'https://apps.apple.com/at/app/id1515520813?ct=app_dwnl&pt=qr&utm_source=weltsparen_at',
  DEU: 'https://apps.apple.com/de/app/id1515520813?ct=app_dwnl&pt=qr&utm_source=weltsparen_de',
  ESP: 'https://apps.apple.com/es/app/id1515520813?ct=app_dwnl&pt=qr&utm_source=raisin_es',
  EUR: 'https://apps.apple.com/app/id1515520813?ct=app_dwnl&pt=qr&utm_source=raisin_com',
  FRA: 'https://apps.apple.com/fr/app/id1515520813?ct=app_dwnl&pt=qr&utm_source=raisin_fr',
  GBR: 'https://apps.apple.com/gb/app/id1515520813?ct=app_dwnl&pt=qr&utm_source=raisin_uk',
  NLD: 'https://apps.apple.com/nl/app/id1515520813?ct=app_dwnl&pt=qr&utm_source=raisin_nl',
  POL: 'https://apps.apple.com/nl/app/id1515520813?ct=app_dwnl&pt=qr&utm_source=raisin_pl',
  FIN: 'https://apps.apple.com/nl/app/id1515520813?ct=app_dwnl&pt=qr&utm_source=raisin_fi',
};
