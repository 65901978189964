import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TranslationsProvider, Translate } from 'components/I18n';
import List from 'ui-lib/src/components/List';
import Paragraph from 'ui-lib/src/components/Paragraph';
import Icon from 'ui-lib/src/components/Icon';
import Button from 'ui-lib/src/components/Button';
import ButtonLink from 'ui-lib/src/components/ButtonLink';
import { sendGtmTrackingEvent } from 'ui-lib/src/utils/GtmTracking';
import Image from 'components/Image';
import {
  fetchPensionCustomerToken,
  postPensionCustomerShareData,
  postPensionCustomerShareSession,
} from 'store/pensionProducts/actions';
import { changeUIState } from 'store/ui/actions';
import { LINKS as MENU_LINKS } from 'components/Layout/menu';
import { getFinalRPPath } from 'utils/PensionProducts';
import styles from '../styles.scss';

export class DataSharingModal extends PureComponent {
  static propTypes = {
    changeUIState: PropTypes.func.isRequired,
    fetchPensionCustomerToken: PropTypes.func.isRequired,
    postPensionCustomerShareData: PropTypes.func.isRequired,
    postPensionCustomerShareSession: PropTypes.func.isRequired,
    token: PropTypes.string,
    type: PropTypes.string,
    bacNumber: PropTypes.string.isRequired,
    ppTargetLink: PropTypes.string,
  };

  state = {
    isLoading: false,
  };

  closeModal = (e) => {
    const { bacNumber } = this.props;

    if (e) {
      e.preventDefault();
      sendGtmTrackingEvent({
        eventCategory: 'obs-pp-plp',
        eventAction: 'close-data-sharing-modal',
        eventLabel: 'data-sharing-cancel',
        eventProperty: bacNumber,
        eventNonInteraction: false,
      });
    }
    this.props.changeUIState({ isPensionProductsDataSharingModalOpen: false });
  };

  onDataProtectionLinkClick = () => {
    const { bacNumber } = this.props;

    sendGtmTrackingEvent({
      eventCategory: 'obs-pp-plp',
      eventAction: 'linkto-obs-about-privacy',
      eventLabel: 'data-sharing-privacy',
      eventProperty: bacNumber,
      eventNonInteraction: false,
    });
    window.open(`#/${MENU_LINKS.ABOUT.PRIVACY}`, '_blank');
  };

  acceptShareData = () => {
    const {
      postPensionCustomerShareData,
      fetchPensionCustomerToken,
      bacNumber,
      type,
      postPensionCustomerShareSession,
    } = this.props;

    sendGtmTrackingEvent({
      eventCategory: 'obs-pp-plp',
      eventAction: `linkto-external-fairr-order-${type}`,
      eventLabel: 'data-sharing-accept',
      eventProperty: bacNumber,
      eventNonInteraction: false,
    });
    this.setState({ isLoading: true });

    const popup = window.open(
      `${window.location.origin}${window.location.pathname}#${MENU_LINKS.LOADING_SCREEN}`,
      '_blank',
    );

    return postPensionCustomerShareData().then(() => {
      return fetchPensionCustomerToken().then(() => {
        const { token, ppTargetLink } = this.props;

        this.closeModal();
        if (token) {
          postPensionCustomerShareSession().then((res) => {
            const raisid = res.payload.data.token || 'invalid';

            popup.location.href = getFinalRPPath(ppTargetLink, raisid);
          });
        } else {
          // if token is missing, there was an error. We redirect to RP passing the raisid as null
          popup.location.href = getFinalRPPath(ppTargetLink, null);
        }

        return popup;
      });
    });
  };

  render() {
    const { isLoading } = this.state;

    return (
      <TranslationsProvider
        translations={{
          de: () => import('../__translations__/de.json'),
        }}
        id="pension-products-data-sharing-modal"
      >
        <div className="row">
          <div className={`col-xs-12 ${styles.modalWrapper}`}>
            <div className={styles.modalHeader}>
              <div className={styles.imgDesktop}>
                <Image alt="data-transfer" src="data_transfer_pp_xl.svg" />
              </div>
              <div className={styles.imgMobile}>
                <Image alt="data-transfer" src="data_transfer_pp_xs.svg" />
              </div>
              <span
                id="newsletterModalCrossIcon"
                className={styles.closeIcon}
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                aria-hidden="true"
                onClick={this.closeModal}
              >
                <Icon icon="cross" round={false} />
              </span>
            </div>
            <Paragraph>
              <Translate id="paragraph.1" />
            </Paragraph>
            <List
              className={styles.list}
              listItemClassname={styles.listItem}
              items={[
                {
                  id: 'details',
                  item: <Translate id="list.item.1" />,
                },
                {
                  id: 'closure',
                  item: <Translate id="list.item.2" />,
                },
                {
                  id: 'link',
                  item: <Translate id="list.item.3" />,
                },
              ]}
            />
            <div className={styles.paragraph}>
              <Paragraph>
                <Translate id="paragraph.2" />{' '}
                <ButtonLink
                  text={<Translate id="paragraph.3.link" />}
                  onClick={this.onDataProtectionLinkClick}
                />
                <Translate id="dot" />
              </Paragraph>
            </div>
            <div className={styles.buttonsWrapper}>
              <Button
                isPrimary
                onClick={this.acceptShareData}
                isDisabled={isLoading}
                isLoading={isLoading}
              >
                <Translate id="button.confirm" />
              </Button>
            </div>
          </div>
        </div>
      </TranslationsProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.pensionProducts.token,
  type: state.pensionProducts.type,
  bacNumber: state.customer.bac_number,
  ppTargetLink: state.pensionProducts.targetLink,
});

const mapDispatchToProps = {
  changeUIState,
  fetchPensionCustomerToken,
  postPensionCustomerShareData,
  postPensionCustomerShareSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(DataSharingModal);
