import React from 'react';
import { getBasePath, getExternalUrl } from '../../components/Header/data/utils';
import { EXTERNAL_LINKS } from '../../components/Header/Navigation/menuLinks';

const mapRFRouteToCPRoute = {
  '/Products': '/products',
  '/Products/overnight': '/products/overnight',
  '/Products/notice': '/products/notice',
};

export const redirectProducts = ({ url, showB2cLoggedInOffers, Products }) => {
  if (showB2cLoggedInOffers && url !== '/Products/easyAccess') {
    return window.location.assign(getExternalUrl(mapRFRouteToCPRoute[url] || '/products'));
  }

  return <Products />;
};

export const redirectToReferAFriend = () => {
  window.location.assign(`${getBasePath()}/refer-a-friend`);
};

export const getLocaleBasePath = (locale) => {
  switch (locale.toLowerCase()) {
    case 'pl-pl':
      return 'pl-pl';
    case 'fi-fi':
      return 'fi-fi';
    case 'en':
    case 'en-us':
      return 'en';
    default:
      return '';
  }
};

export const getRelativePathWithLocalePrefix = (path) => {
  const localeBasePath = getLocaleBasePath(document.documentElement.lang);

  return localeBasePath !== '' ? `/${localeBasePath}${path}` : path;
};

export const goTo = (path) => {
  const relativePath = getRelativePathWithLocalePrefix(path);

  window?.location?.assign(relativePath);
};

export const goToLoginPage = () => {
  goTo(EXTERNAL_LINKS.GLOBAL.LOGIN);
};

export const goToDeProdPensionLogoutPage = () => {
  window?.location?.assign(EXTERNAL_LINKS.DEU.PENSION_LOGOUT);
};
