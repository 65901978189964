import { LINKS } from 'components/Layout/menu';
import { countries, getCountryByLocalStorageLocale } from 'utils/CustomerUtils';
import { trackEvent } from '@raisin/events-tracking';
import { EVENTS } from '../../eventsTracking';

const LOGOS = {
  raisin: 'Logo_Raisin_homepage.svg',
  raisinBank: 'Logo_Raisinbank_homepage.svg',
  weltsparen: 'Logo_WeltSparen_homepage.svg',
  weltsparenBusiness: 'Logo_WeltSparenBusiness_homepage.svg',
};

export const BURGER_MENU_SVGS = {
  false: 'menu_24.svg',
  true: 'close_24.svg',
};

export const getLogo = (pathname, isBusinessCustomer) => {
  const country = getCountryByLocalStorageLocale();

  if (country === countries.AUT) {
    return LOGOS.raisinBank;
  }

  if (pathname === LINKS.SET_PASSWORD && (country === countries.DEU || country === countries.AUT)) {
    return LOGOS.weltsparen;
  }

  if (isBusinessCustomer) {
    return LOGOS.weltsparenBusiness;
  }

  return LOGOS.raisin;
};

export const trackHeaderNavigation = (object) => {
  const eventName = `${object}-tapped`;

  trackEvent({
    ...EVENTS.MAIN_NAV_LINK_TAPPED,
    eventName,
    object,
    description: `${eventName} on the header-navigation inside raisin-frontend`,
  });
};
