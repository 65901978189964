import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isGBRCustomer, states, getAccountHolderName } from 'utils/CustomerUtils';
import { formatIban } from 'utils/Iban';
import constants from 'ui-lib/src/constants';
import Alert from 'ui-lib/src/components/Alert';
import Paragraph from 'ui-lib/src/components/Paragraph';
import { Translate, LocalizeConsumer } from 'components/I18n';
import styles from './styles.scss';

export default class MissingFundsNotice extends Component {
  static propTypes = {
    tamsTransactionAccount: PropTypes.shape({
      bic: PropTypes.string.isRequired,
      iban: PropTypes.string.isRequired,
      sortCode: PropTypes.string,
      accountNumber: PropTypes.string,
    }),
    defaultAddress: PropTypes.shape({
      country: PropTypes.string.isRequired,
    }).isRequired,
    casCustomerData: PropTypes.object.isRequired,
  };

  gbrCustomerAccountDetails = (name, accNumber, sortCode) => (
    <>
      <div className={styles.alertIntro}>
        <Paragraph>
          <Translate id="alert.missingFunds.advice" />
        </Paragraph>
      </div>
      <Paragraph className={styles.alert}>
        <Translate
          id="alert.missingFunds.accountHolder"
          values={{
            accountHolder: name,
          }}
        />
      </Paragraph>
      <Paragraph className={styles.alert}>
        <Translate id="alert.missingFunds.accNumber" values={{ account_number: accNumber }} />
      </Paragraph>
      <Paragraph className={styles.alert}>
        <Translate id="alert.missingFunds.sortCode" values={{ sort_code: sortCode }} />
      </Paragraph>
    </>
  );

  gbrActivationPending = () => (
    <div className={styles.alertIntro}>
      <Paragraph>
        <Translate id="alert.missingFunds.notifylater" />
      </Paragraph>
    </div>
  );

  gbrCustomerRender = (customerState, tamsTransactionAccount, accountHolderName) => {
    const isPendingActivation = customerState === states.ACTIVATION_PENDING;
    const { accountNumber: accNumber, sortCode: code } = tamsTransactionAccount;

    return isPendingActivation
      ? this.gbrActivationPending()
      : this.gbrCustomerAccountDetails(accountHolderName, accNumber, code);
  };

  render() {
    const { tamsTransactionAccount, defaultAddress, customerState, casCustomerData } = this.props;
    const { alerts } = constants;
    const accountHolderName = getAccountHolderName(casCustomerData);

    if (tamsTransactionAccount?.balance?.available < 0) {
      const { iban = '', bic } = tamsTransactionAccount;

      if (!tamsTransactionAccount?.iban && !isGBRCustomer({ default_address: defaultAddress })) {
        return <></>;
      }

      return (
        <LocalizeConsumer>
          {(ctx) => (
            <Alert
              type={alerts.info}
              key="alert-missingFunds"
              className="missingFundsNotice"
              showDefault
            >
              <div className={styles.alertIntro}>
                <Paragraph>
                  <Translate
                    id="alert.missingFunds.intro"
                    values={{
                      amount: ctx.localizeNumber(
                        Math.abs(tamsTransactionAccount?.balance?.available || 0),
                        {
                          minimumFractionDigits: 2,
                        },
                      ),
                    }}
                  />
                </Paragraph>
              </div>
              {isGBRCustomer({ default_address: defaultAddress }) ? (
                this.gbrCustomerRender(customerState, tamsTransactionAccount, accountHolderName)
              ) : (
                <>
                  <Paragraph className={styles.alert}>
                    <Translate
                      id="alert.missingFunds.accountHolder"
                      values={{
                        accountHolder: accountHolderName,
                      }}
                    />
                  </Paragraph>
                  <Paragraph className={styles.alert}>
                    <Translate id="alert.missingFunds.iban" values={{ iban: formatIban(iban) }} />
                  </Paragraph>
                  <Paragraph className={styles.alert}>
                    <Translate id="alert.missingFunds.bic" values={{ bic }} />
                  </Paragraph>
                </>
              )}
            </Alert>
          )}
        </LocalizeConsumer>
      );
    }

    return null;
  }
}
