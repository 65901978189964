import { reportError } from 'utils/ErrorHandling';
import { getFeatureFlagClient } from 'utils/FeatureFlag';
import { raisinPensionSubdomainRouter, getHost } from 'utils/PensionProducts';

const RAISIN_INVEST_BASE_URL = getHost('invest');

let hasRedirected = false;

export const DEEP_LINK_STORAGE_KEY = 'obs-deepLink';
const getCurrentBaseUrl = () => `${window.location.protocol}//${window.location.host}/`;

export const saveDeepLink = (url) => {
  sessionStorage.setItem(DEEP_LINK_STORAGE_KEY, url);
};

export const createRedirectionLinkFromParams = (refParam, hostParam) => {
  if (!refParam) return null;

  let redirectionUrl;

  switch (hostParam) {
    case 'invest':
      redirectionUrl = new URL(RAISIN_INVEST_BASE_URL);
      break;
    default:
      redirectionUrl = new URL(getCurrentBaseUrl());
      break;
  }

  const refUrl = new URL(refParam, window.location.origin);

  redirectionUrl.pathname = refUrl.pathname;
  redirectionUrl.search = refUrl.search;

  return redirectionUrl.toString();
};

const getRedirectionLink = (path) => {
  const ownUrl = new URL(path, getCurrentBaseUrl());
  const isRPRoute = ownUrl.pathname === '/RP' || ownUrl.pathname.startsWith('/RP/');
  const refParam = ownUrl.searchParams.get('ref');
  const hostParam = ownUrl.searchParams.get('host');

  const link = isRPRoute ? createRedirectionLinkFromParams(refParam, hostParam) : null;

  return { link, hostParam };
};

export const raisinPensionDeepLinkManager = ({
  fetchPensionCustomerToken,
  postPensionCustomerShareSession,
  changeUIState,
  changePPTargetLink,
  path,
  clearDeepLink,
  shouldClearDeeplink = false,
}) => {
  if (hasRedirected) return true;

  const { link, hostParam } = getRedirectionLink(path);

  if (link == null) return false;

  const chooseWhetherToShareSession = async () => {
    const ldClient = await getFeatureFlagClient();

    if (ldClient == null || typeof ldClient !== 'object') {
      throw new Error('LaunchDarkly failed to initialize');
    }

    if (ldClient.allFlags().ippPensionsBIPSRedirect === true) {
      return false;
    }

    if (ldClient.allFlags().IppPensionDataSharingBannerOff) {
      return true;
    }

    // if token is present, customer agreed with data sharing
    return !!(await fetchPensionCustomerToken())?.payload?.data?.token || hostParam === 'invest';
  };

  chooseWhetherToShareSession()
    .then((shouldShareSession) => {
      const ippPensionsBIPSRedirect = !shouldShareSession;

      if (shouldShareSession) {
        postPensionCustomerShareSession()
          .then((sessionRes) => {
            const raisid = sessionRes?.payload?.data?.token || 'invalid';

            shouldClearDeeplink && clearDeepLink();
            hasRedirected = raisinPensionSubdomainRouter({
              raisid,
              link,
              changeUIState,
              changePPTargetLink,
              ippPensionsBIPSRedirect,
            });
          })
          .catch((error) => reportError('An error happened while sharing the user session', error));
      } else {
        shouldClearDeeplink && clearDeepLink();
        hasRedirected = raisinPensionSubdomainRouter({
          raisid: null,
          link,
          changeUIState,
          changePPTargetLink,
          ippPensionsBIPSRedirect,
        });
      }
    })
    .catch((error) => reportError('An error happened while redirecting the user', error));

  return true;
};

export default class DeepLinkManager {
  constructor(history) {
    this.history = history;
  }

  getDeepLink = () => {
    return this.hasDeepLink() ? sessionStorage.getItem(DEEP_LINK_STORAGE_KEY) : null;
  };

  redirectToDeepLinkOrIndex = (
    fetchPensionCustomerToken,
    postPensionCustomerShareSession,
    changeUIState,
    changePPTargetLink,
  ) => {
    const deepLink = this.getDeepLink();

    if (deepLink) {
      const deepLinkManagerIssuedRedirect = raisinPensionDeepLinkManager({
        fetchPensionCustomerToken,
        postPensionCustomerShareSession,
        changeUIState,
        changePPTargetLink,
        path: deepLink,
        clearDeepLink: this.clearDeepLink,
        shouldClearDeeplink: true,
      });

      if (deepLinkManagerIssuedRedirect) return;

      // this code is reached only if the deeplink was not a raisinPension deeplink
      const raisinFrontendRoute = deepLink.includes('/savingglobal/');

      if (raisinFrontendRoute) {
        // this should correspond to props.location.pathname e.g /pl-pl/savingglobal/?locale=pl-pl#/Dashboard -> /Dashboard
        const pathname = deepLink.split('#').slice(-1).join();

        this.history.push(pathname);
      } else {
        window.location.assign(deepLink);
      }
      this.clearDeepLink();
    } else {
      this.history.push('/');
    }
  };

  clearDeepLink = () => {
    sessionStorage.setItem(DEEP_LINK_STORAGE_KEY, '');
  };

  hasDeepLink = () => {
    const deepLink = sessionStorage.getItem(DEEP_LINK_STORAGE_KEY);

    return !!deepLink && deepLink !== '';
  };
}
