import store from 'store2';

import {
  deleteCookieForSession,
  AUTH_TIME_DIFF_KEY,
  removeAutoLogoutTimestamp,
  deleteAuthToken,
} from '.';
import { storeAuthConfigDone } from '../Auth';

export const cleanUpUmtData = () => {
  deleteCookieForSession();
  removeAutoLogoutTimestamp();
  deleteAuthToken();
  storeAuthConfigDone(false);
  store.local.remove(AUTH_TIME_DIFF_KEY);
};

export const cleanUpSessionData = () => {
  cleanUpUmtData();

  [
    '/transaction_account',
    '/customer',
    '/customer/product/access',
    '/branding',
    '/deposits/types',
    '/threads/unread-count',
    '/accounts',
    '/pension/customer/products',
    '/products',
    '/product_instances',
    Object.keys(localStorage).find((item) => item.includes('/accounts/TRA')),
    Object.keys(localStorage).find((item) => item.includes('/customers/')),
  ].forEach((item) => {
    store.local.remove(item || '');
  });
};
