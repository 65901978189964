import { isDistributorCustomer } from 'utils/CustomerUtils';

import { getUrl } from '../utils';

export const HAS_FIXED_DEPOSIT_ACCESS = 'HAS_FIXED_DEPOSIT_ACCESS';
export const HAS_FIXED_DEPOSIT_ACCOUNT = 'HAS_FIXED_DEPOSIT_ACCOUNT';
export const HAS_FLEX_DEPOSIT_ACCESS = 'HAS_FLEX_DEPOSIT_ACCESS';
export const HAS_FLEX_DEPOSIT_ACCOUNT = 'HAS_FLEX_DEPOSIT_ACCOUNT';
export const HAS_INVESTMENT_PRODUCT_ACCESS = 'HAS_INVESTMENT_PRODUCT_ACCESS';
export const HAS_INVESTMENT_PRODUCT_ACCOUNT = 'HAS_INVESTMENT_PRODUCT_ACCOUNT';
export const HAS_NOTICE_DEPOSIT_ACCESS = 'HAS_NOTICE_DEPOSIT_ACCESS';
export const HAS_NOTICE_DEPOSIT_ACCOUNT = 'HAS_NOTICE_DEPOSIT_ACCOUNT';
export const HAS_OVERNIGHT_DEPOSIT_ACCESS = 'HAS_OVERNIGHT_DEPOSIT_ACCESS';
export const HAS_OVERNIGHT_DEPOSIT_ACCOUNT = 'HAS_OVERNIGHT_DEPOSIT_ACCOUNT';

const getMyInvestments = (productAccess) => {
  return {
    id: 'myInvestments',
    title: `My savings`,
    href: '',
    subMenus: {
      layout: 'even',
      top: [
        {
          id: 'dashboard-myInvestments',
          type: 'basic',
          title: `Dashboard`,
          href: getUrl(`/#/Dashboard`),
          icon: 'overviewIcon',
          iconColor: '#0E6CC5',
        },
      ],
      main: [
        (productAccess.includes(HAS_FIXED_DEPOSIT_ACCOUNT) ||
          productAccess.includes(HAS_FLEX_DEPOSIT_ACCOUNT)) && {
          id: 'fixed-deposit',
          type: 'basic',
          title: `Fixed rate bonds`,
          href: getUrl(`/#/MyInvestments/TermDeposit`),
          icon: 'fixedDepositIcon',
          iconColor: '#61AFD2',
        },
        productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCOUNT) && {
          id: 'overnight-deposit',
          type: 'basic',
          title: `Easy access savings accounts`,
          href: getUrl(`/#/MyInvestments/Overnight`),
          icon: 'overnightMoneyIcon',
          iconColor: '#9CD3E4',
        },
        productAccess.includes(HAS_NOTICE_DEPOSIT_ACCOUNT) && {
          id: 'notice-deposit',
          type: 'basic',
          title: `Notice accounts`,
          href: getUrl(`/#/MyInvestments/Notice`),
          icon: 'terminationPaymentIcon',
          iconColor: '#317EA6',
        },
      ],
    },
  };
};

const getOffers = (productAccess, shouldShowUpdatedProductURLs, hasB2cDeprecateObsFeatureFlag) => {
  return {
    id: 'offers',
    title: `Savings accounts`,
    href: '',
    subMenus: {
      layout: 'even',
      main: [
        (productAccess.includes(HAS_FIXED_DEPOSIT_ACCESS) ||
          productAccess.includes(HAS_FLEX_DEPOSIT_ACCESS) ||
          hasB2cDeprecateObsFeatureFlag) && {
          id: 'fixedDeposit-offers',
          type: 'advanced',
          title: 'Fixed rate bonds',
          href: shouldShowUpdatedProductURLs ? '/products/' : getUrl(`/#/Products`),
          description: 'View all savings accounts',
          icon: 'fixedDepositIcon',
          iconColor: '#61AFD2',
        },
        (productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCESS) || hasB2cDeprecateObsFeatureFlag) && {
          id: 'overnightDeposit-offers',
          type: 'advanced',
          title: `Easy access savings`,
          href: shouldShowUpdatedProductURLs
            ? '/products/overnight/'
            : getUrl(`/#/Products/overnight`),
          description:
            'An easy access savings account lets you top up and withdraw your cash at your convenience',
          icon: 'overnightMoneyIcon',
          iconColor: '#9CD3E4',
        },
        (productAccess.includes(HAS_NOTICE_DEPOSIT_ACCESS) || hasB2cDeprecateObsFeatureFlag) && {
          id: 'noticeDeposit-offers',
          type: 'advanced',
          title: `Notice accounts`,
          href: shouldShowUpdatedProductURLs ? '/products/notice/' : getUrl(`/#/Products/notice`),
          description:
            'Receive a variable rate of interest and give notice when you want to withdraw your savings',
          icon: 'terminationPaymentIcon',
          iconColor: '#317EA6',
        },
      ],
      bottom: [
        {
          id: 'efe277ff-d6e0-451a-8130-dbc8278f4862',
          type: 'arrow',
          title: 'Under 1 year fixed rate bonds',
          href: '/savings-accounts/fixed-rate-bonds/up-to-12-months/',
        },
        {
          id: '85f37705-e429-45ac-87f4-d99344561b38',
          type: 'arrow',
          title: '1 year fixed rate bonds',
          href: '/savings-accounts/fixed-rate-bonds/1-year/',
        },
        {
          id: 'efe277ff-d6e0-562b-8131-dbc8278f4862',
          type: 'arrow',
          title: '2 year fixed rate bonds',
          href: '/savings-accounts/fixed-rate-bonds/2-year/',
        },
        {
          id: 'c9b48d2d-f0cc-535f-951e-0f02d46ab21f',
          type: 'arrow',
          title: '3 year fixed rate bonds',
          href: '/savings-accounts/fixed-rate-bonds/3-year/',
        },
        {
          id: '86e38805-e430-45bd-87f5-d99344561b38',
          type: 'arrow',
          title: '5 year fixed rate bonds',
          href: '/savings-accounts/fixed-rate-bonds/5-year/',
        },
        {
          id: '85f37805-e429-45bc-87f4-d00344561b38',
          type: 'arrow',
          title: 'Exclusive offers',
          href: '/savings-accounts/raisin-exclusive/',
        },
      ],
    },
  };
};

const getHelpGuide = () => {
  return {
    id: 'helpGuide',
    title: 'Help & Guides',
    href: '',
    subMenus: {
      main: [
        {
          id: 'savings-finance',
          type: 'advanced',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt68453dc5db1e4d3a/FAQs.svg',
          iconColor: '#0E6CC5',
          title: 'FAQs',
          href: 'https://help.raisin.co.uk/hc/en-us/',
        },
        {
          id: 'investments-finance',
          type: 'advanced',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/bltc5ba40b6cbd767f5/Using_Raisin_UK.svg',
          iconColor: '#0E6CC5',
          title: 'Using Raisin UK',
          href: '/guides/',
        },
        {
          id: 'pension-finance',
          type: 'advanced',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt66cab0f0096dc752/Deposit_Protection.svg',
          iconColor: '#0E6CC5',
          title: 'Deposit Protection',
          href: '/deposit-protection/',
        },
        {
          id: 'saving-guides',
          type: 'advanced',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt33bcb9b7e9a3becc/Savings_guides.svg',
          iconColor: '#0E6CC5',
          title: 'Savings guides',
          href: '/savings/',
        },
        {
          id: 'bankding-guide',
          type: 'advanced',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt330aeed4f1b92062/Banking_guides.svg',
          iconColor: '#0E6CC5',
          title: 'Banking guides',
          href: '/banking/',
        },
      ],
    },
  };
};

const getAboutUs = () => {
  return {
    id: '2e11a01f-4216-4fea-b05f-2fd46cf3a8b4',
    title: 'About',
    href: '',
    subMenus: {
      main: [
        {
          id: 'c3f65470-a71c-4079-adae-a644c03c00a2',
          type: 'text',
          title: 'About us',
          href: '/about-us/',
        },
        {
          id: 'a9cbf2ad-81bc-4f83-8196-b67b414f9b0f',
          type: 'text',
          title: 'Contact us',
          href: '/contact/',
        },
        {
          id: '457a0585-4847-4c2c-bbad-620101e9512b',
          type: 'text',
          title: 'Our partner banks',
          href: '/bank/',
        },
        {
          id: '9a91930e-db2a-45be-8eab-30a081e40e03',
          type: 'text',
          title: 'Newsroom',
          href: '/newsroom/',
        },
      ],
    },
  };
};

export const getGbrMenus = ({
  customer,
  shouldShowUpdatedProductURLs,
  hasB2cDeprecateObsFeatureFlag,
}) => {
  const { productAccess, distributor_id } = customer;

  getMyInvestments(productAccess);
  getOffers(productAccess, shouldShowUpdatedProductURLs, hasB2cDeprecateObsFeatureFlag);
  getHelpGuide();
  getAboutUs();

  let navItems = [
    getMyInvestments(productAccess),
    getOffers(productAccess, shouldShowUpdatedProductURLs, hasB2cDeprecateObsFeatureFlag),
    getHelpGuide(),
  ];

  if (!isDistributorCustomer(distributor_id)) {
    navItems = [...navItems, getAboutUs()];
  }

  return [...navItems];
};
