import axios from 'axios';
import { getIntegrationServiceBaseUrl } from 'utils/IntegrationService';
import { trackEvent } from '@raisin/events-tracking';
import { EVENTS } from '../../eventsTracking';

export const getTrustpilotRatings = async (setTrustpilotRatings) => {
  const baseUrlIS = getIntegrationServiceBaseUrl();

  try {
    const { data } = await axios.get(`${baseUrlIS}/trustpilot/rating`);

    setTrustpilotRatings(data);
  } catch (error) {
    setTrustpilotRatings({
      count_of_reviews: 0,
      score_value: 0,
    });
  }
};

export const trackFooterNavigation = (object) => {
  const eventName = `${object}-tapped`;

  trackEvent({
    ...EVENTS.FOOTER_LEGAL_MENU_LINK_TAPPED,
    eventName,
    object,
    description: `${eventName} on the footer-navigation inside raisin-frontend`,
  });
};
