/* eslint-disable import/no-named-default */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { default as UILink } from 'ui-lib/src/components/Link';
import Heading from 'ui-lib/src/components/Heading';
import { Translate } from 'components/I18n';
import Image from 'components/Image';
import { countries } from 'utils/CustomerUtils';
import { PHONE_AND_EMAIL } from './phoneAndEmail';
import { HELP_CENTER_LINKS } from './helpCenterLinks';
import { NL_SECURITY_CERTIFICATE } from './links';
import styles from './styles.scss';

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const ContactDetails = ({ country, isBusinessCustomer, isCustomerLoggedIn }) => {
  const customerType = isBusinessCustomer ? 'business' : 'retail';
  const { email } = PHONE_AND_EMAIL[country][customerType];
  const countriesWithNewFooter = Object.keys(HELP_CENTER_LINKS).filter((key) => key !== 'NLD');

  return (
    <div className={styles.wrapper}>
      {country !== countries.NLD && (
        <Heading className={styles.heading}>
          <ConditionalWrapper
            condition={countriesWithNewFooter.includes(country)}
            wrapper={(children) => <UILink href={HELP_CENTER_LINKS[country]}>{children}</UILink>}
          >
            <Translate id="footer.contactDetails.title" />
          </ConditionalWrapper>
        </Heading>
      )}

      <div className={styles.container}>
        {country === countries.FRA && (
          <div className={styles.detailsContainer}>
            {isCustomerLoggedIn ? (
              <Link to="/PostBox/Compose/new" className={styles.anchor} id="footer-mailto">
                <Image src="envelope_24.svg" alt="mail" />
                <Translate id={`footer.contactDetails.${customerType}.email`} />
              </Link>
            ) : (
              <UILink
                className={styles.anchor}
                href={`mailto:${email}`}
                id="footer-mailto"
                type="text"
              >
                <Image src="envelope_24.svg" alt="mail" />
                <Translate id={`footer.contactDetails.${customerType}.email`} />
              </UILink>
            )}
          </div>
        )}
        {country === countries.NLD && (
          <div className={styles.nldLinkContainer}>
            <UILink
              href={NL_SECURITY_CERTIFICATE.url}
              target="_blank"
              id={NL_SECURITY_CERTIFICATE.title}
              data-testid="nl-security-certificate"
            >
              <Image
                src={NL_SECURITY_CERTIFICATE.logo}
                alt={NL_SECURITY_CERTIFICATE.title}
                loading="lazy"
              />
            </UILink>
          </div>
        )}
      </div>
    </div>
  );
};

ContactDetails.propTypes = {
  country: PropTypes.string.isRequired,
  isBusinessCustomer: PropTypes.bool.isRequired,
  isCustomerLoggedIn: PropTypes.bool.isRequired,
};

export default ContactDetails;
