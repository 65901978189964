export const initialState = {
  data: {
    "total_investment": {
      "currency": "EUR",
      "value": 0,
  },
  "fixed_deposits": {
    "deposits": [],
    "investment": {
        "currency": "EUR",
        "value": 0,
    },
    "has_foreign_currency": false,
},
"flex_deposits": {
  "deposits": [],
  "investment": {
      "currency": "EUR",
      "value": 0,
  },
  "has_foreign_currency": false,
},
"overnight_deposits": {
"deposits": [],
  "investment": {
      "currency": "EUR",
      "value": 0,
  },
  "has_foreign_currency": false,
},
"term_and_flex_deposits": {
  "deposits": [],
  "investment": {
      "currency": "EUR",
      "value": 0,
  },
  "has_foreign_currency": false,
},
"fixed_deposits_by_bank": {},
"flex_deposits_by_bank": {},
"overnight_deposits_by_bank": {},
"investment_products_by_portfolio": {},
"notices": {},
  },
  pensionProducts: {},
};
