import { ajaxStatus } from '../../../config';

export const initialState = {
  prolongation: {
    products: [],
  },
  product: {
    id: '',
    availability_notice: '',
    has_fixed_investment_amount: false,
    is_in_foreign_currency: false,
    min_placement_amount: 0,
    max_placement_amount: 0,
    is_overnight: false,
    is_notice_account: false,
    currency: 'EUR',
    nominal_interest_rate: 0,
    effective_interest_rate: 0,
    has_fully_online_process: false,
    is_flexgeld: false,
    is_top_offer: false,
    has_auto_prolongation: false,
    number_of_terms: 12,
    autoprolongation_number_of_terms: null,
    premature_termination: false,
    interest_payout_scheme: 'MATURITY',
    early_termination_interest_rate: 0,
    early_termination_effective_interest_rate: 0,
    opening_document: 'NONE',
    prolongation_window: 28,
    maturity_window: 5,
    prolongation_document: 'NONE',
    prolongation_capitalisation: 'CUSTOMER_CHOICE',
    prolongation_enabled: false,
    rate_change: false,
    rate_change_date: '',
    rate_change_value: 0,
    partner_bank: {
      id: '',
    },
    legal_statements: [],
    prolongation_legal_statements: [],
    additional_order_info: [],
  },
  products: [],
  banks: [],
  amount: 0,
  legalTermsValues: {},
  questionsValues: {},
  form: {
    status: ajaxStatus.notPosted,
    error: null,
    values: {
      mininum_amount: 0,
      maximum_amount: 0,
    },
  },
  deposit_number: '',
  mTanForm: {
    status: ajaxStatus.notPosted,
    error: null,
  },
  overnightPayInForm: {
    values: {
      maximum_amount: 0,
      current_balance: 0,
    },
    status: ajaxStatus.notPosted,
    error: null,
  },
  overnightPayInMTanForm: {
    status: ajaxStatus.notPosted,
    error: null,
  },
  overnightSavingsPlanForm: {
    status: ajaxStatus.notPosted,
    error: null,
  },
  overnightSavingsPlanMTanForm: {
    status: ajaxStatus.notPosted,
    error: null,
  },
  earlyTerminationForm: {
    status: ajaxStatus.notPosted,
    error: null,
  },
  earlyTerminationMTanForm: {
    status: ajaxStatus.notPosted,
    error: null,
  },
  overnightPayOutForm: {
    values: {
      minimum_amount: 0,
    },
    status: ajaxStatus.notPosted,
    error: null,
  },
  overnightPayOutMTanForm: {
    status: ajaxStatus.notPosted,
    error: null,
  },
  overnight: {
    payIn: {
      amount: '',
      payment_purpose: '',
      deposit_number: '',
      money_routing_id: '',
      bank_name: '',
    },
    payOut: {
      amount: '',
      deposit: {
        deposit_number: '',
        partner_bank_bic: '',
        initial_investment: 0,
        partner_bank_name: '',
        product_offer: {
          currency: '',
        },
      },
    },
    transactions: {},
  },
  session: {
    error: null,
    status: null,
    nonce: null,
    signature_chalenge: {
      alternate_delivery_links: {
        voice_call: '',
      },
    },
  },
  transient_data: {},
  topOffers: {},
  depositAccountApplicationForm: {
    delivery_type: '',
    partner_bank_name: '',
  },
  notices: {},
  signature_challenge_confirm: {
    signature: '',
  },
  earlyTermination: {
    balance: {
      currency: '',
      value: 0,
    },
    description_document: {
      url_path: '',
    },
    interest_rate: 0,
    maturity_date: '',
    partner_bank_name: '',
    partner_bank_withholding_tax_process_type: '',
    tax_info: {
      tax_rate: 0,
      tax_reduced_rate: 0,
      tax_template_path: '',
      tax_requirements: [
        {
          status: '',
          earliest_submission_date: '',
          lastest_submission_date: '',
          document: {
            sent_date: '',
            document_link: '',
          },
        },
      ],
    },
    placement_date: '',
    term_months: 0,
    termination_interest_rate: 0,
    payout_period_after_termination: null,
    is_notice_account: false,
    is_tra_document_missing: false,
  },
};
