import { ajaxStatus } from '../../../../config';
import { initialState } from '../../selectors';

const setFurtherInformationOrderData = (state, payload) => {
  return {
    ...state,
    ...payload,
    furtherInformation: {
      ...payload.furtherInformation,
    },
    form: {
      status: ajaxStatus.notPosted,
      error: null,
    },
    mTanForm: {
      status: ajaxStatus.notPosted,
      error: null,
    },
  };
};

export const setOrderData = (state, payload) => {
  if (payload.mTanForm) {
    return {
      ...state,
      ...payload,
    };
  }
  if (payload.furtherInformation) {
    return setFurtherInformationOrderData(state, payload);
  }

  return {
    ...state,
    ...payload,
    form: {
      status: ajaxStatus.notPosted,
      error: null,
    },
    mTanForm: {
      status: ajaxStatus.notPosted,
      error: null,
    },
  };
};

export const resetOrderData = (state, fields) => {
  const newState = { ...state };

  fields.forEach((field) => {
    newState[field] = initialState.order[field];
  });

  return newState;
};
