export const getDeuMenus = ({
  menuLinks,
  shouldShowTaxExemptionScreen,
  shouldShowPaymentMethodScreens,
}) => {
  return [
    {
      id: '2c1d8255-3a4c-4d78-bf4b-f0f450ce9fff',
      type: 'text',
      title: 'Nachrichten',
      href: menuLinks.POSTBOX,
    },
    {
      id: '0e57aad3-f3fd-4f97-a438-3b96476dd20c',
      type: 'text',
      title: 'Dokumente',
      href: menuLinks.DOCUMENTS,
    },
    {
      id: '5534dc4e-fd1e-4936-84f1-0fe185ec6e82',
      type: 'text',
      title: 'Umsätze',
      href: menuLinks.TRANSACTIONS,
    },
    {
      id: '8fbeb352-f366-470f-aaca-bf93e53f2c47',
      type: 'text',
      title: 'Auszahlungen',
      href: menuLinks.WITHDRAW,
    },
    ...(shouldShowPaymentMethodScreens
      ? [
          {
            id: '2564ebee-677e-42bf-b080-11ae38b23164',
            type: 'text',
            title: 'Zahlungsmethoden',
            href: menuLinks.PAYMENT_METHODS,
          },
        ]
      : []),
    {
      id: '394d205a-3fbc-472a-947d-3b5f78cb6e6a',
      type: 'text',
      title: 'Zugang und Sicherheit',
      href: menuLinks.USER_ACCOUNT,
    },
    {
      id: 'e81df68f-9700-45c1-b305-23beb9acc063',
      type: 'text',
      title: 'Meine Kundendaten',
      href: menuLinks.MY_DETAILS,
    },
    ...(shouldShowTaxExemptionScreen
      ? [
          {
            id: '006ddaba-3ada-40f9-a71c-6813e2a07b88',
            type: 'text',
            title: 'Freistellungsauftrag',
            href: menuLinks.EXEMPTION_ORDER_OVERVIEW,
          },
        ]
      : []),
    {
      id: '4681530d-9eed-4cbe-982d-5445410895d8',
      type: 'text',
      title: 'Benachrichtigungen',
      href: menuLinks.NOTIFICATIONS,
    },
  ];
};

export const getDeuMenusNonExportedCustomer = ({ menuLinks }) => {
  const navItems = [
    {
      id: '2c1d8255-3a4c-4d78-bf4b-f0f450ce9fff',
      type: 'text',
      title: 'Nachrichten',
      href: menuLinks.POSTBOX,
    },
    {
      id: '0e57aad3-f3fd-4f97-a438-3b96476dd20c',
      type: 'text',
      title: 'Dokumente',
      href: menuLinks.DOCUMENTS,
    },
    {
      id: '394d205a-3fbc-472a-947d-3b5f78cb6e6a',
      type: 'text',
      title: 'Zugang und Sicherheit',
      href: menuLinks.USER_ACCOUNT,
    },
  ];

  return navItems?.length ? navItems : undefined;
};
