import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TranslationsProvider } from 'components/I18n';
import ErrorBoundary from 'components/ErrorBoundary';
import Footer from 'components/Footer';
import Header from 'components/Header';
import styles from './styles.scss';

export class PublicLayout extends Component {
  static propTypes = {
    children: PropTypes.node,
    is_company_customer: PropTypes.bool,
  };

  static defaultProps = {
    is_company_customer: false,
  };

  render() {
    return (
      <TranslationsProvider
        translations={{
          de: () => import('./__translations__/de.json'),
          es: () => import('./__translations__/es.json'),
          fr: () => import('./__translations__/fr.json'),
          nl: () => import('./__translations__/nl.json'),
          'en-US': () => import('./__translations__/en-US.json'),
          'en-GB': () => import('./__translations__/en-GB.json'),
          'en-IE': () => import('./__translations__/en-IE.json'),
        }}
        id="public-layout"
      >
        <div className={styles.obsLoginWrapper}>
          <ErrorBoundary>
            <Header />
          </ErrorBoundary>
          <div className={`container ${styles.content}`}>{this.props.children}</div>
          <ErrorBoundary>
            <Footer />
          </ErrorBoundary>
        </div>
      </TranslationsProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  is_company_customer: state.customer.is_company_customer,
});

export default connect(mapStateToProps, null)(PublicLayout);
