import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ErrorBoundary from 'ui-lib/src/components/ErrorBoundary';
import { reportError } from '../../utils/ErrorHandling';

export class OBSErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    Fallback: PropTypes.node,
    staticContext: PropTypes.node,
    match: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.onError = this.onError.bind(this);
  }

  onError(e) {
    reportError(e.message, { ...e, ...this.props.match });
  }

  render() {
    const { staticContext, ...props } = this.props;

    return (
      <ErrorBoundary {...props} onError={this.onError}>
        {this.props.children}
      </ErrorBoundary>
    );
  }
}

export default withRouter(OBSErrorBoundary);
