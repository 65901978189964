import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Alert from 'ui-lib/src/components/Alert';
import ButtonLink from 'ui-lib/src/components/ButtonLink';
import Paragraph from 'ui-lib/src/components/Paragraph';
import constants from 'ui-lib/src/constants';
import { sendGtmTrackingEvent } from 'ui-lib/src/utils/GtmTracking';

import { Translate } from 'components/I18n';

import { redirectExternal } from '../Header/data/utils';
import { EXTERNAL_LINKS } from '../Header/Navigation/menuLinks';
import styles from './styles.scss';

export default class ExpiredPIDNotice extends Component {
  static propTypes = {
    history: PropTypes.object,
    notices: PropTypes.object,
    bacNumber: PropTypes.string,
  };

  getPageName = () => {
    const { pathname } = this.props.history.location;

    if (pathname.includes('Dashboard')) return 'dashboard';
    if (pathname.includes('InvestmentProducts')) return 'ip-order-3';
    if (pathname.includes('DepositProducts')) return 'deposit-order-3';
    if (pathname.includes('MyInvestments')) return 'customer-deposits';

    return undefined;
  };

  onClick = () => {
    const { bacNumber } = this.props;

    const page = this.getPageName();

    if (page) {
      sendGtmTrackingEvent({
        eventCategory: `obs-${page}`,
        eventAction: 'linkto-identification',
        eventLabel: 'notice-reident-cta',
        eventProperty: bacNumber,
        eventNonInteraction: false,
      });
    }

    redirectExternal(EXTERNAL_LINKS.IDENTIFICATION.HOME);
  };

  render() {
    const { notices = {}, dbsDeposits = {} } = this.props;
    const { alerts } = constants;

    const isKycSubmitted = Object.values(dbsDeposits)?.some(
      (deposit) =>
        deposit?.customer_jobs?.kyc?.status === 'submitted' &&
        deposit?.customer_jobs?.kyc?.required,
    );

    if (isKycSubmitted) return null;

    const depositWithKycJob = Object.values(dbsDeposits)?.find(
      (deposit) =>
        deposit?.customer_jobs?.kyc?.status === 'expired' && deposit?.customer_jobs?.kyc?.required,
    );
    const obsKycNotice = notices['customer.reidentification.needed'];

    const shouldShowNotice = depositWithKycJob || obsKycNotice;

    const requiringBank =
      depositWithKycJob?.product?.deposit_taking_bank?.name || obsKycNotice?.bank_name;

    if (shouldShowNotice) {
      return (
        <Alert
          type={alerts.info}
          key="alert-expiredIdentification"
          className="expiredIdentificationNotice"
          cta={
            <ButtonLink
              onClick={this.onClick}
              text={<Translate id="alert.button.toIdentification" />}
              suffixIcon="chevron-right"
            />
          }
          showDefault
        >
          <Paragraph>
            <strong>
              <Translate id="alert.heading.expiredIdent" />
            </strong>
          </Paragraph>
          <Paragraph className={styles.alert}>
            <Translate id="alert.paragraph.expiredIdent" values={{ bankName: requiringBank }} />
          </Paragraph>
        </Alert>
      );
    }

    return null;
  }
}
