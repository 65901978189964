import { handle } from 'redux-pack';
import * as types from '../../../actionTypes';

export default (state, action) => {
  // NOSONAR
  const { type, payload } = action;

  switch (type) {
    case types.PIA_FETCH_PRODUCTS_INSTANCES:
      return handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            productInstances: payload?.data?.productInstances,
          };
        },
      });
      case types.PIA_FETCH_PRODUCT_INSTANCE_DETAILS:
        return handle(state, action, {
          success: (prevState) => {
            return {
              ...prevState,
              productInstanceDetails: payload?.data,
            };
          },
        });
    default:
      return state;
  }
};
