import { handle } from 'redux-pack';
import { parseDepositOfferData } from 'ui-lib/src/components/OffersTable/utils';
import { initialState } from '../selectors';
import * as types from '../../actionTypes';
import { ajaxStatus } from '../../../config';

export default (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case types.FETCH_DEPOSITS_PRODUCTS:
      return handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            products: parseDepositOfferData(payload.data),
          };
        },
      });
    case types.FETCH_DEPOSIT_FOR_PROLONGATION:
      return handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            prolongation: { products: parseDepositOfferData(payload.data) },
          };
        },
      });
    case types.FETCH_DEPOSITS_PRODUCT:
      return handle(state, action, {
        success: (prevState) => {
          const parsedData = parseDepositOfferData(payload.data);

          return {
            ...prevState,
            product: parsedData.length > 0 ? parsedData[0] : null,
          };
        },
      });
    case types.RESET_DEPOSITS_PRODUCT:
      return {
        ...state,
        product: initialState.product,
        prolongation: initialState.prolongation,
        form: initialState.form,
        mTanForm: initialState.mTanForm,
        amount: 0,
        questionsValues: {},
        legalTermsValues: {},
        notices: {},
        signature_challenge_confirm: {
          signature: '',
        },
      };
    case types.POST_DEPOSIT_ORDER_DATA:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          form: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          session: {
            status: payload.response.status,
            error: payload.response.statusText,
          },
          form: {
            status: ajaxStatus.failure,
            error: payload,
            values: {
              bank_name: prevState.product.partner_bank.name,
              maximum_amount: prevState.product.max_placement_amount,
              minimum_amount: prevState.product.min_placement_amount,
              available_funds:
                payload.response.data.additional_details != null
                  ? payload.response.data.additional_details.available_funds
                  : '',
            },
          },
        }),
        success: (prevState) => ({
          ...prevState,
          session: {
            nonce: payload.data.signature_challenge.nonce,
          },
          form: {
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });
    case types.POST_DEPOSIT_PROLONGATION_DATA:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          form: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          session: {
            status: payload.response.status,
            error: payload.response.statusText,
          },
          form: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          session: {
            nonce: payload.data.signature_challenge.nonce,
          },
          form: {
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });
    case types.POST_DEPOSIT_ORDER_MTAN:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          mTanForm: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          session: {
            status: payload.response.status,
            error: payload.response.statusText,
            nonce: prevState.session.nonce,
          },
          mTanForm: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => {
          if (payload.data.notices['insufficient.funds']) {
            payload.data.notices['insufficient.funds'].value =
              payload.data.notices['insufficient.funds'].amount.value;
          }

          return {
            ...prevState,
            mTanForm: {
              status: ajaxStatus.success,
              error: null,
            },
            notices: payload.data.notices,
            deposit_number: payload.data.deposit_number,
          };
        },
      });
    case types.POST_DEPOSIT_PROLONGATION_MTAN:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          mTanForm: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          session: {
            status: payload.response.status,
            error: payload.response.statusText,
            nonce: prevState.session.nonce,
          },
          mTanForm: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          mTanForm: {
            status: ajaxStatus.success,
            error: null,
          },
          signature_challenge_confirm: {
            signature: payload.data.signature_challenge_confirm.signature,
          },
          notices: payload.data.notices,
        }),
      });
    case types.POST_OVERNIGHT_PAYIN_DATA:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          overnightPayInForm: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          session: {
            status: payload.response.status,
            error: payload.response.statusText,
          },
          overnightPayInForm: {
            values: {
              maximum_amount: payload.response.data.additional_details?.maximum_amount || 0,
              available_funds: payload.response.data.additional_details?.available_funds || '',
              bank_name: payload.response.data.additional_details?.bank_name || '',
            },
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          session: {
            nonce: payload.data.signature_challenge.nonce,
          },
          overnightPayInForm: {
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });
    case types.RESET_OVERNIGHT_PAYIN_NOTICE: {
      return {
        ...state,
        overnightPayInForm: {
          status: ajaxStatus.notPosted,
          error: null,
        },
        overnight: {
          payIn: {
            amount: '',
          },
        },
      };
    }
    case types.RESET_OVERNIGHT_PAYOUT_NOTICE: {
      return {
        ...state,
        overnightPayOutForm: {
          status: ajaxStatus.notPosted,
          error: null,
        },
        overnight: {
          payOut: {
            amount: '',
          },
        },
      };
    }
    case types.POST_OVERNIGHT_PAYIN_MTAN:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          overnightPayInMTanForm: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          session: {
            status: payload.response.status,
            error: payload.response.statusText,
            nonce: prevState.session.nonce,
          },
          overnightPayInMTanForm: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          overnightPayInMTanForm: {
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });
    case types.POST_OVERNIGHT_SAVINGS_PLAN_DATA:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          overnightSavingsPlanForm: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          session: {
            status: payload.response.status,
            error: payload.response.statusText,
          },
          overnightSavingsPlanForm: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          session: {
            nonce: payload.data.signature_challenge.nonce,
          },
          overnightSavingsPlanForm: {
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });
    case types.POST_OVERNIGHT_SAVINGS_PLAN_MTAN:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          overnightSavingsPlanMTanForm: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          session: {
            status: payload.response.status,
            error: payload.response.statusText,
            nonce: prevState.session.nonce,
          },
          overnightSavingsPlanMTanForm: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          overnightSavingsPlanMTanForm: {
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });
    case types.POST_OVERNIGHT_PAYOUT_DATA:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          overnightPayOutForm: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          session: {
            status: payload.response.status,
            error: payload.response.statusText,
          },
          overnightPayOutForm: {
            values: {
              minimum_amount:
                payload.response.data.additional_details != null
                  ? payload.response.data.additional_details.minimum_amount
                  : 0,
            },
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          session: {
            nonce: payload.data.signature_challenge.nonce,
          },
          overnightPayOutForm: {
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });
    case types.POST_OVERNIGHT_PAYOUT_MTAN:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          overnightPayOutMTanForm: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          session: {
            status: payload.response.status,
            error: payload.response.statusText,
            nonce: prevState.session.nonce,
          },
          overnightPayOutMTanForm: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          overnightPayOutMTanForm: {
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });
    case types.SET_DEPOSIT_DATA: {
      return {
        ...state,
        ...payload,
      };
    }
    case types.SET_OVERNIGHT_PAYIN_DATA: {
      return {
        ...state,
        overnight: {
          payIn: {
            ...state.overnight.payIn,
            ...payload,
          },
        },
      };
    }
    case types.SET_OVERNIGHT_PAYOUT_DATA: {
      return {
        ...state,
        overnight: {
          payOut: {
            ...state.overnight.payOut,
            ...payload,
          },
        },
      };
    }
    case types.FETCH_OVERNIGHT_TRANSACTIONS: {
      return handle(state, action, {
        success: (prevState) => ({
          ...prevState,
          overnight: {
            ...prevState.overnight,
            transactions: {
              ...prevState.overnight.transactions,
              [payload.data.deposit_number]: payload.data.transactions,
            },
          },
        }),
      });
    }
    case types.FETCH_DEPOSITS_PRODUCTS_TOP_OFFERS: {
      return handle(state, action, {
        success: (prevState) => ({
          ...prevState,
          topOffers: {
            ...payload.data,
          },
        }),
      });
    }

    case types.FETCH_EARLY_TERMINATION_DATA: {
      return handle(state, action, {
        success: (prevState) => ({
          ...prevState,
          earlyTermination: {
            ...payload.data,
          },
        }),
      });
    }

    case types.FETCH_DEPOSIT_ACCOUNT_APPLICATION_FORM: {
      return handle(state, action, {
        success: (prevState) => ({
          ...prevState,
          depositAccountApplicationForm: payload.data,
        }),
      });
    }

    case types.POST_EARLY_TERMINATION_DATA:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          earlyTerminationForm: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          earlyTerminationForm: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          session: {
            nonce: payload.data.signature_challenge.nonce,
          },
          earlyTermination: {
            ...payload.data.early_termination_schema,
          },
          earlyTerminationForm: {
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });

    case types.POST_EARLY_TERMINATION_MTAN:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          earlyTerminationMTanForm: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          earlyTerminationMTanForm: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          earlyTermination: {
            ...payload.data.early_termination_schema,
          },
          notices: { 'signature.value': payload.data.signature_challenge_confirm.signature },
          earlyTerminationMTanForm: {
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });

    case types.RESET_EARLY_TERMINATION_FORM:
      return {
        ...state,
        earlyTerminationForm: initialState.earlyTerminationForm,
        earlyTerminationMTanForm: initialState.earlyTerminationMTanForm,
      };

    default:
      return state;
  }
};
