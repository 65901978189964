import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Alert from 'ui-lib/src/components/Alert';
import Button from 'ui-lib/src/components/Button';
import Paragraph from 'ui-lib/src/components/Paragraph';
import constants from 'ui-lib/src/constants';

import { isGBRCustomer } from 'utils/CustomerUtils';
import { Translate } from 'components/I18n';
import Image from 'components/Image';

import { redirectExternal } from '../Header/data/utils';
import { EXTERNAL_LINKS } from '../Header/Navigation/menuLinks';
import styles from './styles.scss';

export default class AccountOverdraftNotice extends Component {
  static propTypes = {
    tamsTransactionAccount: PropTypes.object.isRequired,
  };

  handleClick = () => redirectExternal(EXTERNAL_LINKS.RESOLVE_OVERDRAFT);

  render() {
    const { tamsTransactionAccount, defaultAddress } = this.props;
    const { alerts } = constants;

    const hasNegativeBalance =
      tamsTransactionAccount?.balance?.current && tamsTransactionAccount?.balance?.current < 0;

    if (hasNegativeBalance && !isGBRCustomer({ default_address: defaultAddress })) {
      return (
        <Alert
          id="alert-account-overdraft"
          className={styles.alertOverdraft}
          type={alerts.danger}
          key="alert-account-overdraft"
          showDefault
          cta={
            <div className={styles.overdraftImage}>
              <Image width="235" height="100" src="overdraft.svg" alt="transactions" />
            </div>
          }
        >
          <Paragraph>
            <strong>
              <Translate id="alert.accountOverdraft.heading" />
            </strong>
          </Paragraph>
          <Paragraph>
            <Translate id="alert.accountOverdraft.paragraph" />
          </Paragraph>

          <div className={styles.overdraftButton}>
            <Button isSecondary onClick={this.handleClick}>
              <Translate id="alert.accountOverdraft.button" />
            </Button>
          </div>
        </Alert>
      );
    }

    return null;
  }
}
