import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { countries } from 'utils/CustomerUtils';
import ContactDetails from '../ContactDetails';
import SocialMedia from '../SocialMedia';
import styles from './styles.scss';

const ContactInformation = memo(
  ({ country, isBusinessCustomer, isCustomerLoggedIn, isTrustPilotAvailable }) => {
    const hasSecuritySeal = country === countries.NLD;

    return (
      <div className={isTrustPilotAvailable || hasSecuritySeal ? '' : styles.background}>
        <div className="container">
          <div className={styles.wrapper}>
            {country !== countries.GBR ? (
              <ContactDetails
                country={country}
                isBusinessCustomer={isBusinessCustomer}
                isCustomerLoggedIn={isCustomerLoggedIn}
              />
            ) : null}
            <SocialMedia country={country} />
          </div>
        </div>
      </div>
    );
  },
);

ContactInformation.propTypes = {
  country: PropTypes.string.isRequired,
  isBusinessCustomer: PropTypes.bool.isRequired,
  isCustomerLoggedIn: PropTypes.bool.isRequired,
  isTrustPilotAvailable: PropTypes.bool.isRequired,
};

export default ContactInformation;
