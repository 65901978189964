import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import truncate from 'lodash/truncate';
import { TranslationsProvider, Translate } from 'components/I18n';
import Paragraph from 'ui-lib/src/components/Paragraph';
import LabeledIcon from 'ui-lib/src/components/LabeledIcon';
import Heading from 'ui-lib/src/components/Heading';
import Icon from 'ui-lib/src/components/Icon';
import Button from 'ui-lib/src/components/Button';
import Alert from 'ui-lib/src/components/Alert';
import constants from 'ui-lib/src/constants';
import ButtonLink from 'ui-lib/src/components/ButtonLink';
import { LINKS } from 'components/Layout/menu';
import { changeUIState } from 'store/ui/actions';
import { fetchCustomerData, setCustomerData, postCustomerDocuments } from 'store/customer/actions';
import styles from './styles.scss';

export const UPLOAD_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export class PendingUploadModalContent extends Component {
  static propTypes = {
    uploadedDocuments: PropTypes.shape({
      identification: PropTypes.shape({
        list: PropTypes.array.isRequired,
      }).isRequired,
      proof_of_address: PropTypes.shape({
        list: PropTypes.array.isRequired,
      }).isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    changeUIState: PropTypes.func.isRequired,
    fetchCustomerData: PropTypes.func.isRequired,
    setCustomerData: PropTypes.func.isRequired,
    postCustomerDocuments: PropTypes.func.isRequired,
    filenameMaxLength: PropTypes.number,
    isPosting: PropTypes.bool,
  };

  static defaultProps = {
    filenameMaxLength: 100,
  };

  state = {
    uploadStatus: '',
  };

  sendFiles(type) {
    const { uploadedDocuments, postCustomerDocuments } = this.props;
    const { list } = uploadedDocuments[type];

    return new Promise(async (resolve, reject) => {
      if (!list.length) {
        resolve({ payload: { status: UPLOAD_STATUS.SUCCESS } });
      }

      const { pdfBlob } = uploadedDocuments[type];
      const formData = new FormData();
      const filename = `${Date.now()}.pdf`;

      formData.append('upload_file', pdfBlob, filename);

      try {
        resolve(postCustomerDocuments({ type, formData }));
      } catch (error) {
        reject(error);
      }
    });
  }

  submitFiles = () => {
    const { fetchCustomerData } = this.props;

    Promise.all([this.sendFiles('identification'), this.sendFiles('proof_of_address')]).then(
      (values) => {
        const hasError = values
          .filter((value) => value != null)
          .some((res) => {
            return res.payload.status >= 400 && res.payload.status <= 599;
          });

        this.setState({ uploadStatus: UPLOAD_STATUS.ERROR });

        if (!hasError) {
          this.setState({ uploadStatus: UPLOAD_STATUS.SUCCESS }, () => {
            fetchCustomerData();
            this.clearFileList();
          });
        }
      },
    );
  };

  clearFileList = () => {
    this.props.changeUIState({
      documents: {
        identification: {
          list: [],
          pdfBlob: '',
        },
        proof_of_address: {
          list: [],
          pdfBlob: '',
        },
      },
    });
  };

  navigateAway = (e) => {
    const {
      changeUIState,
      history: { push },
    } = this.props;

    e.preventDefault();
    this.clearFileList();
    changeUIState({ isPendingUploadModalOpen: false }).then(() => {
      push(LINKS.MY_INVESTMENTS.TERM_DEPOSIT);
    });
  };

  renderListItem = (item) => (
    <li key={item.id}>
      <Icon round={false} icon="file-empty" />
      {truncate(item.filename, { length: this.props.filenameMaxLength })}
    </li>
  );

  render() {
    const { danger, success } = constants.alerts;
    const { identification, proof_of_address } = this.props.uploadedDocuments;
    const { uploadStatus } = this.state;
    const { isPosting } = this.props;

    const list = [...identification.list, ...proof_of_address.list].map((item, index) => {
      return {
        id: index + 1,
        filename: item.filename,
      };
    });

    return (
      <TranslationsProvider
        translations={{
          de: () => import('../__translations__/de.json'),
          fr: () => import('../__translations__/fr.json'),
          es: () => import('../__translations__/es.json'),
          nl: () => import('../__translations__/nl.json'),
          'en-US': () => import('../__translations__/en.json'),
        }}
        id="identification-eur-modal"
      >
        <div className={styles.container}>
          <Heading level={3}>
            <Translate id="upload.popup.heading" />
          </Heading>
          <Paragraph>
            <Translate id="upload.popup.instructions" />
          </Paragraph>
          {uploadStatus ? (
            <Alert type={uploadStatus === UPLOAD_STATUS.SUCCESS ? success : danger} showDefault>
              <Translate
                id={
                  uploadStatus === UPLOAD_STATUS.SUCCESS
                    ? 'upload.alert.success'
                    : 'upload.alert.error'
                }
              />
            </Alert>
          ) : null}
          <ul className={styles.documentList}>{list.map((item) => this.renderListItem(item))}</ul>
          <div className={styles.controls}>
            <ButtonLink
              id="backToOverviewLink-modal"
              className={styles.backLink}
              onClick={this.navigateAway}
            >
              <LabeledIcon label={<Translate id="order.amount.back" />} prefixIcon="chevron-left" />
            </ButtonLink>
            {!uploadStatus || uploadStatus !== UPLOAD_STATUS.SUCCESS ? (
              <Button
                isLoading={isPosting}
                isDisabled={isPosting}
                isPrimary
                onClick={this.submitFiles}
                id="submitFilesButton"
              >
                <Translate id="upload.id.button.upload" />
              </Button>
            ) : null}
          </div>
        </div>
      </TranslationsProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  uploadedDocuments: state.ui.documents,
  isPosting: state.ui.isPosting,
});

const mapDispatchToProps = (dispatch) => ({
  changeUIState: (data) => dispatch(changeUIState(data)),
  postCustomerDocuments: (data) => dispatch(postCustomerDocuments(data)),
  fetchCustomerData: (data) => dispatch(fetchCustomerData(data)),
  setCustomerData: (data) => dispatch(setCustomerData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PendingUploadModalContent));
