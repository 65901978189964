export const OTP_LENGTH = 6;
export const OTP_VERIFICATION_MESSAGE_DE = 'WeltSparen';
export const OTP_VERIFICATION_MESSAGE_EN = 'Raisin';
export const OTP_USER_BLOCKED_ERROR_CODE = 'userBlockedTemporarily';
export const OTP_INVALID_ERROR_CODE = 'invalidOTP';
export const OTP_EXPIRED_ERROR_CODE = 'expiredOTP';
export const OTP_STATE_VALIDATED = 'VALIDATED';
export const OTP_STATE_NEW = 'NEW';

export const OTP_TRACKED_EVENTS = {
  REQUESTED: 'requested',
  RESENT: 'resent',
  SUBMITTED: 'submitted',
  VERIFIED: 'verified',
  EXPIRED: 'expired',
  INVALID: 'invalid',
  BLOCKED: 'blocked',
  FAILED: 'failed',
};
