import {
  isAUTCustomer,
  isDEUCustomer,
  isESPCustomer,
  isFRACustomer,
  isGBRCustomer,
  isIRLCustomer,
  isNLDCustomer,
  isPOLCustomer,
  isFINCustomer,
} from 'utils/CustomerUtils';

import { getComMenus, getComMenusNonExportedCustomer } from './comMenus';
import { getDeuMenus, getDeuMenusNonExportedCustomer } from './deMenus';
import { getEsMenus, getEsMenusNonExportedCustomer } from './esMenus';
import { getFrMenus, getFrMenusNonExportedCustomer } from './frMenus';
import { getGbrMenus, getGbrMenusNonExportedCustomer } from './gbrMenus';
import { getIrlMenus, getIrlMenusNonExportedCustomer } from './irlMenus';
import { getNldMenus, getNldNonExportedMenus } from './nlMenus';
import { getPolMenus, getPolMenusNonExportedCustomer } from './polMenus';
import { getFinMenus, getFinMenusNonExportedCustomer } from './finMenus';

export const getCustomerMenus = ({ shouldShowPaymentMethodScreens, ...props }) => {
  const { customer } = props;

  switch (true) {
    case isAUTCustomer(customer):
      return getDeuMenus(props);
    case isDEUCustomer(customer):
      return getDeuMenus({
        ...props,
        shouldShowPaymentMethodScreens,
        shouldShowTaxExemptionScreen: true,
      });
    case isNLDCustomer(customer):
      return getNldMenus(props);
    case isGBRCustomer(customer):
      return getGbrMenus(props);
    case isESPCustomer(customer):
      return getEsMenus(props);
    case isIRLCustomer(customer):
      return getIrlMenus(props);
    case isFRACustomer(customer):
      return getFrMenus(props);
    case isPOLCustomer(customer):
      return getPolMenus(props);
    case isFINCustomer(customer):
      return getFinMenus(props);      
    default:
      return getComMenus(props);
  }
};

export const getNonExportedCustomerMenus = (props) => {
  const { customer } = props;

  switch (true) {
    case isAUTCustomer(customer):
    case isDEUCustomer(customer):
      return getDeuMenusNonExportedCustomer(props);
    case isNLDCustomer(customer):
      return getNldNonExportedMenus(props);
    case isGBRCustomer(customer):
      return getGbrMenusNonExportedCustomer(props);
    case isESPCustomer(customer):
      return getEsMenusNonExportedCustomer(props);
    case isIRLCustomer(customer):
      return getIrlMenusNonExportedCustomer(props);
    case isFRACustomer(customer):
      return getFrMenusNonExportedCustomer(props);
    case isPOLCustomer(customer):
      return getPolMenusNonExportedCustomer(props);
    case isFINCustomer(customer):
        return getFinMenusNonExportedCustomer(props);    
    default:
      return getComMenusNonExportedCustomer(props);
  }
};
