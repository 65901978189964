import { handle } from 'redux-pack';
import * as types from '../../../actionTypes';
import { ajaxStatus } from '../../../../config';
import { initialState } from '../../selectors';
import { setSavingsPlanData, fetchSavingsPlanData } from './alterations';

export default (state, action) => {
  // NOSONAR
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_SAVINGS_PLAN_STATUS:
      return handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            status: payload.data,
          };
        },
      });
    case types.RESET_SAVINGS_PLAN_DATA:
      return {
        ...initialState.savingsPlan,
      };
    case types.LOAD_SAVINGS_PLAN_FORM: {
      return {
        ...state,
        ...payload,
      };
    }
    case types.SET_SAVINGS_PLAN_DATA:
      return setSavingsPlanData(state, payload);
    case types.FETCH_SAVINGS_PLAN_DATA:
      return handle(state, action, {
        success: (prevState) => fetchSavingsPlanData(prevState, payload),
      });
    case types.POST_SAVINGS_PLAN_DATA:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          form: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          form: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          transient: {
            responses: payload.data.responses,
          },
          session: {
            nonce: payload.data.signature_challenge.nonce,
          },
          form: {
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });
    case types.POST_SAVINGS_PLAN_MTAN:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          mTanForm: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          session: {
            status: payload.response.status,
            error: payload.response.statusText,
            nonce: prevState.session.nonce,
          },
          mTanForm: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          transient: {
            responses: payload.data.responses,
          },
          mTanForm: {
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });
    default:
      return state;
  }
};
