// =================================================== //
//                      CONSTANTS
// =================================================== //
const PORTAL = {
  CUSTOMER_PORTAL: 'customer-portal',
};

const ACTIONS = {
  STARTED: 'started',
  FINISHED: 'finished',
  SUCCEEDED: 'succeeded',
  TAPPED: 'tapped',
  VIEWED: 'viewed',
  FAILED: 'failed',
};

const CONTEXT = {
  DEPOSIT_BROKERAGE: 'deposit-brokerage',
  INVESTOR_ADVISORY: 'investor-advisory',
  SCA: 'strong-customer-auth',
  PRODUCT_ORDER: 'product-order',
};

const ZONE = {
  RAISIN_FE: 'raisin-frontend',
};

const CUSTOMER_GROUP = {
  BUSINESS: 'business',
  RETAIL: 'retail',
};

const OBJECT_PRODUCT_ORDER = CONTEXT.PRODUCT_ORDER;
// =================================================== //
//                     EVENTS BASE
// =================================================== //
const DEPOSIT_FLOWS_SCA_EVENT_BASE = {
  action: ACTIONS.TAPPED,
  boundedContext: CONTEXT.SCA,
  customAttribute1: 'deposit',
  customAttribute6: '1',
  customerGroup: CUSTOMER_GROUP.RETAIL,
  object: 'submit',
  portal: PORTAL.CUSTOMER_PORTAL,
  zone: ZONE.RAISIN_FE,
};

// =================================================== //
//                      EVENTS
// =================================================== //
export const EVENTS = {
  LOGIN_SUCCEEDED: {
    eventName: 'login-succeeded',
    object: 'login',
    action: ACTIONS.SUCCEEDED,
    description: 'login-succeeded inside customer-portal',
    portal: PORTAL.CUSTOMER_PORTAL,
    boundedContext: CONTEXT.SCA,
    zone: ZONE.RAISIN_FE,
  },
  LOGIN_FAILED: {
    eventName: 'login-failed',
    object: 'login',
    action: ACTIONS.FAILED,
    description: 'login-failed in raisin-frontend',
    portal: PORTAL.CUSTOMER_PORTAL,
    boundedContext: CONTEXT.SCA,
    zone: ZONE.RAISIN_FE,
  },
  LOGOUT_SUCCEEDED: {
    eventName: 'logout-succeeded',
    object: 'logout',
    action: ACTIONS.SUCCEEDED,
    description: 'logout-succeeded on the header-footer inside raisin-frontend',
    portal: PORTAL.CUSTOMER_PORTAL,
    boundedContext: CONTEXT.SCA,
    zone: ZONE.RAISIN_FE,
    component: 'header-footer',
  },
  PRODUCT_ORDER_STARTED: ({ productType, shortCode }) => ({
    eventName: 'product-order-started',
    description: 'product-order-started in raisin-frontend',
    object: OBJECT_PRODUCT_ORDER,
    action: ACTIONS.STARTED,
    portal: PORTAL.CUSTOMER_PORTAL,
    boundedContext: CONTEXT.INVESTOR_ADVISORY,
    zone: ZONE.RAISIN_FE,
    context: CONTEXT.PRODUCT_ORDER,
    customAttribute1: 'deposit',
    customAttribute2: productType,
    customAttribute3: shortCode,
  }),
  PRODUCT_ORDER_FINISHED: {
    eventName: 'product-order-finished',
    description: 'product-order-finished in raisin-frontend',
    object: OBJECT_PRODUCT_ORDER,
    action: ACTIONS.FINISHED,
    portal: PORTAL.CUSTOMER_PORTAL,
    boundedContext: CONTEXT.INVESTOR_ADVISORY,
    zone: ZONE.RAISIN_FE,
    context: CONTEXT.PRODUCT_ORDER,
    customAttribute1: 'dynamic - deposit/investment',
    customAttribute2: 'dynamic - overnight/term-deposit/etf-robo',
    customAttribute3: 'dynamic - id',
    customAttribute4: 'dynamic - deposit number',
    customAttribute5: 'dynamic - deposit amount',
    customAttribute6: '1',
  },
  PRODUCT_TYPE_VIEWED: {
    eventName: 'product-type-viewed',
    object: 'product',
    action: ACTIONS.VIEWED,
    portal: PORTAL.CUSTOMER_PORTAL,
    description: 'product-type-viewed in raisin-frontend',
    boundedContext: CONTEXT.INVESTOR_ADVISORY,
    zone: ZONE.RAISIN_FE,
    context: CONTEXT.PRODUCT_ORDER,
    customAttribute1: 'deposit',
    customAttribute2: 'dynamic - term-deposit/overnight/notice-account/etf-robo/etf-configurator',
  },
  MAIN_NAV_LINK_TAPPED: {
    eventName: 'dynamic - the permalink + action',
    object: 'dynamic - the permalink',
    action: ACTIONS.TAPPED,
    description: 'dynamic - eventName on the header-navigation inside raisin-frontend',
    portal: PORTAL.CUSTOMER_PORTAL,
    boundedContext: CONTEXT.INVESTOR_ADVISORY,
    zone: ZONE.RAISIN_FE,
    component: 'header-navigation',
  },
  IBAN_COPIED: {
    eventName: 'iban-copied-tapped',
    object: 'iban-copied',
    action: ACTIONS.TAPPED,
    description: 'iban-copied-tapped on the header-navigation inside raisin-frontend',
    portal: PORTAL.CUSTOMER_PORTAL,
    boundedContext: CONTEXT.INVESTOR_ADVISORY,
    zone: ZONE.RAISIN_FE,
    component: 'header-navigation',
  },
  FOOTER_LEGAL_MENU_LINK_TAPPED: {
    eventName: 'dynamic - the permalink + action',
    object: 'dynamic - the permalink',
    action: ACTIONS.TAPPED,
    description: 'dynamic - eventName on the footer-navigation inside raisin-frontend',
    portal: PORTAL.CUSTOMER_PORTAL,
    boundedContext: CONTEXT.INVESTOR_ADVISORY,
    zone: ZONE.RAISIN_FE,
    component: 'footer-navigation',
  },
  IOS_APP_ICON_BADGE_TAPPED: {
    eventName: 'ios-app-icon-badge-tapped',
    object: 'ios-app-icon-badge',
    action: ACTIONS.TAPPED,
    description: 'ios-app-icon-badge-tapped on the footer-navigation inside raisin-frontend',
    portal: PORTAL.CUSTOMER_PORTAL,
    boundedContext: CONTEXT.INVESTOR_ADVISORY,
    zone: ZONE.RAISIN_FE,
    component: 'footer-navigation',
  },
  ANDROID_APP_ICON_BADGE_TAPPED: {
    eventName: 'android-app-icon-badge-tapped',
    object: 'android-app-icon-badge',
    action: ACTIONS.TAPPED,
    description: 'android-app-icon-badge-tapped on the footer-navigation inside raisin-frontend',
    portal: PORTAL.CUSTOMER_PORTAL,
    boundedContext: CONTEXT.INVESTOR_ADVISORY,
    zone: ZONE.RAISIN_FE,
    component: 'footer-navigation',
  },
  PROMOTIONAL_BANNER_CTA_TAPPED: (productType) => ({
    eventName: 'promotional-banner-link-tapped',
    object: 'product-type',
    action: ACTIONS.TAPPED,
    description: 'Clicked the link on the promotional banner',
    portal: PORTAL.CUSTOMER_PORTAL,
    boundedContext: CONTEXT.INVESTOR_ADVISORY,
    zone: ZONE.RAISIN_FE,
    customerGroup: CUSTOMER_GROUP.RETAIL,
    customAttribute2: productType,
  }),
  PRODUCT_PAY_IN: (productType, productId, depositId, amount, interestRate, status) => ({
    ...DEPOSIT_FLOWS_SCA_EVENT_BASE,
    component: 'OvernightPayInSubmit',
    eventName: 'product-topped-up',
    description:
      'Customer has clicked submit on the SCA screen to authorized their top-up deposit order and the order has been successfully placed',
    customAttribute2: String(productType),
    customAttribute3: String(productId),
    customAttribute4: String(depositId),
    customAttribute5: String(amount),
    customAttribute7: String(interestRate),
    customAttribute8: String(status),
  }),
  PRODUCT_PAY_OUT: (productType, productId, depositId, amount, interestRate, status) => ({
    ...DEPOSIT_FLOWS_SCA_EVENT_BASE,
    component: 'OvernightPayOutSubmit',
    eventName: 'product-withdrawn-from',
    description:
      'Customer has clicked submit on the SCA screen to  authorized their withdrawal deposit order and the order has been successfully placed',
    customAttribute2: String(productType),
    customAttribute3: String(productId),
    customAttribute4: String(depositId),
    customAttribute5: String(amount),
    customAttribute7: String(interestRate),
    customAttribute8: String(status),
  }),
  PRODUCT_MANUALLY_RENEWED: (productType, productId, depositId, amount, interestRate, status) => ({
    ...DEPOSIT_FLOWS_SCA_EVENT_BASE,
    component: 'DepositAccountProlongationSubmit',
    eventName: 'product-manually-renewed',
    description:
      'Customer has clicked submit on the SCA screen to  authorized their renewal deposit order and the order has been successfully placed',
    customAttribute2: String(productType),
    customAttribute3: String(productId),
    customAttribute4: String(depositId),
    customAttribute5: String(amount),
    customAttribute7: String(interestRate),
    customAttribute8: String(status),
  }),
  PRODUCT_RENEWAL_AUTOMATIC_OPTED_OUT: (
    productType,
    productId,
    depositId,
    amount,
    interestRate,
    status,
  ) => ({
    ...DEPOSIT_FLOWS_SCA_EVENT_BASE,
    component: 'TermDepositCard',
    eventName: 'product-renewal-automatic-opted-out',
    description:
      'Customer has clicked the cancel button on the deposits list page to opt-out of automatic renewal',
    customAttribute2: String(productType),
    customAttribute3: String(productId),
    customAttribute4: String(depositId),
    customAttribute5: String(amount),
    customAttribute7: String(interestRate),
    customAttribute8: String(status),
  }),
  PRODUCT_TERMINATED: (productType, productId, depositId, amount, interestRate, status) => ({
    ...DEPOSIT_FLOWS_SCA_EVENT_BASE,
    component: 'EarlyTerminationSubmit',
    eventName: 'product-terminated',
    description:
      'Customer has clicked submit on the SCA screen to  authorized their termination deposit order and the order has been successfully placed',
    customAttribute2: String(productType),
    customAttribute3: String(productId),
    customAttribute4: String(depositId),
    customAttribute5: String(amount),
    customAttribute7: String(interestRate),
    customAttribute8: String(status),
  }),
  PROOF_OF_ADDRESS_PAGE_VIEWED: {
    eventName: 'proof-of-address-alert-tapped',
    object: 'proof-of-address-page',
    action: 'tapped',
    description: 'proof-of-address-alert-tapped in cp-identification',
    portal: 'customer-portal',
    boundedContext: 'kyc',
    zone: 'cp-identification',
  },
};
