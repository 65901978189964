import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { configuratorNamespace as ipConfiguratorNamespace } from '../../pages/IPLandingConfigurator/utils';

export const buildEvent = ({ name, category, action, label, value, property, nonInteraction }) => ({
  event: `${name || 'gtmEvent'}`,
  eventCategory: `${category || ''}`,
  eventAction: `${action || ''}`,
  eventLabel: `${label || ''}`,
  eventValue: Number(value) || '',
  eventProperty: `${property || ''}`,
  eventNonInteraction: `${nonInteraction}` !== 'false',
});

export const pushTrackingData = (data, isEvent = true) => {
  if (window.dataLayer) {
    if (isEvent) {
      window.dataLayer.push(buildEvent(data));
    } else {
      window.dataLayer.push(data);
    }
  }
};

export class GtmTrackingSetup extends Component {
  static propTypes = {
    customer: PropTypes.shape({
      bac_number: PropTypes.string.isRequired,
      is_company_customer: PropTypes.bool.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    const { customer } = this.props;

    if (customer.bac_number && window.dataLayer) {
      pushTrackingData(
        {
          customer: customer.bac_number,
          retail: customer.is_company_customer ? 0 : 1,
          business: customer.is_company_customer ? 1 : 0,
        },
        false,
      );
    }
  }

  render() {
    return <></>;
  }
}

const mapStateToProps = (state) => ({
  customer: state.customer,
});

export const namespaceCategoryMap = {
  Dashboard: 'obs-dashboard',
  'Product-Listing-Page': 'obs-ip-plp-robo',
  'Product-Details-Page': 'obs-ip-pdp',
  Cockpit: 'obs-ip-cockpit',
  'Buy-Flow': 'obs-ip-payin',
  'Sell-Flow': 'obs-ip-payout',
  'SavingsPlan-Flow': 'obs-ip-savingsplan',
  'Product-Order-Flow': 'obs-ip-order-1',
  'Product-Order-Flow-2': 'obs-ip-order-2',
  'Product-Order-Flow-3': 'obs-ip-order-3',
  'Deposit-Product-Order-3': 'obs-product-order-3',
  [ipConfiguratorNamespace]: 'obs-ip-plp-configurator',
};

export const pageCategoryMap = {
  COCKPIT: 'obs-ip-cockpit',
};

export const customerType = (isCompanyCustomer) => (isCompanyCustomer ? 'business' : 'retail');

export default connect(mapStateToProps, null)(GtmTrackingSetup);
