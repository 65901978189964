import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TranslationsProvider, Translate, LocalizeConsumer } from 'components/I18n';
import Heading from 'ui-lib/src/components/Heading';
import Paragraph from 'ui-lib/src/components/Paragraph';
import Button from 'ui-lib/src/components/Button';
import { getCustomerContacts } from 'utils/CustomerUtils';
import styles from './styles.scss';

export class IPBonusModelContent extends PureComponent {
  static propTypes = {
    redirectToOffers: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    isCompanyCustomer: PropTypes.bool.isRequired,
  };

  renderTable = (ctx, bonuses) => {
    const rows = [
      {
        investment: <Translate id="table.row.1" />,
        bonus: ctx.localizeMoney(bonuses[0], { currencyDisplay: 'code' }),
      },
      {
        investment: <Translate id="table.row.2" />,
        bonus: ctx.localizeMoney(bonuses[1], { currencyDisplay: 'code' }),
      },
      {
        investment: <Translate id="table.row.3" />,
        bonus: ctx.localizeMoney(bonuses[2], { currencyDisplay: 'code' }),
      },
      {
        investment: <Translate id="table.row.4" />,
        bonus: ctx.localizeMoney(bonuses[3], { currencyDisplay: 'code' }),
      },
    ];

    return (
      <div className={styles.overflow}>
        <table className={styles.stripedTable}>
          <tbody>
            <tr className={styles.darkGray}>
              <td>
                <Translate id="amount" />
              </td>
              <td>
                <Translate id="bonus" />
              </td>
            </tr>
            {rows.map(({ investment, bonus }) => (
              <tr>
                <td>{investment}</td>
                <td>
                  <strong>{bonus}</strong>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    const { locale, isCompanyCustomer } = this.props;
    const { email } = getCustomerContacts(locale, isCompanyCustomer);

    return (
      <TranslationsProvider
        translations={{
          de: () => import('./__translations__/de.json'),
        }}
        id="ip-bonus-modal"
      >
        <div className={styles.modal}>
          <div className={styles.bg}>
            <Heading className={styles.header}>
              <Translate id="header.1" />
              <br />
              <Translate id="header.2" />
            </Heading>
            <Paragraph className={styles.subHeader}>
              <Translate id="subheader" />
            </Paragraph>
          </div>
          <div className={`no-padding col-md-offset-2 col-md-8 col-xs-12 ${styles.content}`}>
            <Paragraph className={styles.paragraph}>
              <Translate id="text.1" />
            </Paragraph>
            <Paragraph className={styles.paragraph}>
              <strong>
                <Translate id="text.2" />
              </strong>
            </Paragraph>
            <Paragraph className={styles.paragraph}>
              <Translate id="text.3" />
            </Paragraph>
            <LocalizeConsumer>{(ctx) => this.renderTable(ctx, [5, 10, 25, 50])}</LocalizeConsumer>
            <Paragraph>
              <Translate id="text.4" values={{ email }} />
            </Paragraph>
            <Paragraph>
              <Translate id="text.5" />
            </Paragraph>
            <Paragraph>
              <Translate id="text.6" />
            </Paragraph>
            <div className={styles.buttonWrapper}>
              <Button isPrimary onClick={this.props.redirectToOffers}>
                <Translate id="button" />
              </Button>
            </div>
            <Paragraph>
              <Translate id="text.7" />
            </Paragraph>
            <LocalizeConsumer>{(ctx) => this.renderTable(ctx, [10, 25, 50, 100])}</LocalizeConsumer>
          </div>
        </div>
      </TranslationsProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.customer.locale,
  isCompanyCustomer: state.customer.is_company_customer,
});

export default connect(mapStateToProps)(IPBonusModelContent);
