import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'components/I18n';
import { isGBRCustomer } from 'utils/CustomerUtils';
import styles from './styles.scss';

const Disclaimer = memo(({ customer }) => (
  <div className="container">
    <div className={styles.wrapper}>
      <p>
        <Translate id="footer.disclaimer.text" />
      </p>
      {isGBRCustomer(customer) && (
        <p>
          <Translate id="footer.disclaimer.text.uk" />
        </p>
      )}
    </div>
  </div>
));

Disclaimer.propTypes = {
  customer: PropTypes.object.isRequired,
};

export default Disclaimer;
