import React, { Component } from 'react';
import PropTypes from 'prop-types';
import constants from 'ui-lib/src/constants';
import Alert from 'ui-lib/src/components/Alert';
import Paragraph from 'ui-lib/src/components/Paragraph';
import { Translate, LocalizeConsumer } from 'components/I18n';
import { ajaxStatus } from '../../config';
import { getFormErrorMessage } from '../../utils/ErrorHandling';
import styles from './styles.scss';

export default class FormErrorNotice extends Component {
  static propTypes = {
    history: PropTypes.object,
    buyForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    sellForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    contactDataForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    passwordForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    referAFriendForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    savingsPlanForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    overnightPayInForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    overnightPayOutForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    depositOrderForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    mailDataForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    blockAccountForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    earlyTerminationForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    applicationDocumentForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
    setPasswordForm: PropTypes.shape({
      status: PropTypes.string,
      error: PropTypes.object,
    }),
  };

  renderErrorMessageWithValues(form, messageId) {
    return (
      <LocalizeConsumer>
        {(ctx) => (
          <Translate
            id={messageId}
            values={{
              bank_name: form.values.bank_name,
              maximum_amount: ctx.localizeMoney(form.values.maximum_amount, {
                currencyDisplay: 'code',
                minimumFractionDigits: 2,
              }),
              minimum_amount: ctx.localizeMoney(form.values.minimum_amount, {
                currencyDisplay: 'code',
                minimumFractionDigits: 2,
              }),
              available_funds: ctx.localizeMoney(form.values.available_funds, {
                currencyDisplay: 'code',
                minimumFractionDigits: 2,
              }),
            }}
          />
        )}
      </LocalizeConsumer>
    );
  }

  // Additional info is composed by messages coming from CMS
  // therefore no need for translations. Displaying msg as is.
  renderErrorMessageFromAdditionalInfo({
    error: {
      response: {
        data: { additional_details },
      },
    },
  }) {
    const keys = Object.keys(additional_details);

    return (
      <ul className={styles.messages}>
        {Object.values(additional_details).map((msg, index) => (
          <li key={keys[index]}>{msg}</li>
        ))}
      </ul>
    );
  }

  getErrorMessage(form) {
    const messageId = getFormErrorMessage(form);

    /* When error content is coming from CMS during Deposit Order or Prolongation */
    if (
      form.error &&
      form.error.response.data.title_i18n === 'errors.validation' &&
      form.error.response.data.additional_details
    ) {
      return this.renderErrorMessageFromAdditionalInfo(form);
    } else if (
      /* Regular error with values */
      form.values &&
      (form.values.bank_name ||
        form.values.maximum_amount ||
        form.values.minimum_amount ||
        form.values.available_funds)
    ) {
      return this.renderErrorMessageWithValues(form, messageId);
    }

    return <Translate id={messageId} />;
  }

  render() {
    const {
      buyForm,
      sellForm,
      contactDataForm,
      passwordForm,
      referAFriendForm,
      savingsPlanForm,
      overnightPayInForm,
      overnightPayOutForm,
      depositOrderForm,
      mailDataForm,
      earlyTerminationForm,
      blockAccountForm,
      applicationDocumentForm,
      setPasswordForm,
      history: {
        location: { pathname },
      },
    } = this.props;
    const { alerts } = constants;
    const values = {};
    const forms = [];

    if (pathname.startsWith('/InvestmentProducts/buy')) {
      forms.push(buyForm);
    } else if (pathname.startsWith('/InvestmentProducts/sell')) {
      forms.push(sellForm);
    } else if (pathname.startsWith('/MyData/address')) {
      forms.push(contactDataForm);
    } else if (pathname.startsWith('/MyData/password')) {
      forms.push(passwordForm);
    } else if (pathname.startsWith('/ReferAFriend/leads')) {
      forms.push(referAFriendForm);
    } else if (pathname.startsWith('/InvestmentProducts/savingsPlan')) {
      forms.push(savingsPlanForm);
    } else if (pathname.startsWith('/Overnight/PayIn')) {
      const form = overnightPayInForm;

      forms.push(form);
      values.maximum_amount = form.values != null ? form.values.maximum_amount : 0;
      values.available_funds = form.values != null ? form.values.available_funds : 0;
    } else if (pathname.startsWith('/Overnight/PayOut')) {
      const form = overnightPayOutForm;

      forms.push(form);
      values.minimum_amount = form.values != null ? form.values.minimum_amount : 0;
    } else if (pathname.startsWith('/DepositProducts/order')) {
      const form = depositOrderForm;

      forms.push(form);
      values.bank_name = form.values != null ? form.values.bank_name : '';
      values.maximum_amount = form.values != null ? form.values.maximum_amount : 0;
      values.available_funds = form.values != null ? form.values.available_funds : 0;
    } else if (pathname.startsWith('/PostBox/Compose/new')) {
      forms.push(mailDataForm);
    } else if (pathname.startsWith('/BlockAccount')) {
      forms.push(blockAccountForm);
    } else if (pathname.startsWith('/EarlyTermination')) {
      forms.push(earlyTerminationForm);
    } else if (pathname.startsWith('/DocumentRequired/information')) {
      forms.push(applicationDocumentForm);
    } else if (pathname.startsWith('/SetPassword')) {
      forms.push(setPasswordForm);
    }

    return forms.map((form) => {
      if (form && form.status === ajaxStatus.failure) {
        return (
          <Alert type={alerts.danger} key="alert-formError" className="errorNotice" showDefault>
            <Paragraph className={styles.alert}>{this.getErrorMessage(form)}</Paragraph>
          </Alert>
        );
      }

      return null;
    });
  }
}
