export const CONSENT_TYPE = {
  SAVINGS: 'savings',
  INVESTMENTS: 'investments',
  PENSIONS: 'pensions',
  CUSTOMER_SERVICE: 'cs',
  IMPORTANT_INFORMATION: 'important_information',
};

export const isMailSubscribed = (mailCategories) => {
  return !!mailCategories
    .filter(({ id }) => id !== CONSENT_TYPE.CUSTOMER_SERVICE)
    .find(({ valid }) => valid);
};
