import React from 'react';
import Heading from 'ui-lib/src/components/Heading';
import Paragraph from 'ui-lib/src/components/Paragraph';
import { connect } from 'react-redux';
import { changeUIState } from 'store/ui/actions';
import Button from 'ui-lib/src/components/Button';
import constants from 'ui-lib/src/constants';
import AccordionWrapper from 'ui-lib/src/components/AccordionWrapper';
import PropTypes from 'prop-types';
import { TranslationsProvider, Translate } from 'components/I18n';
import styles from './styles.scss';

const renderNonMobile = () => {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9].map((id) => (
    <div>
      <Heading level={6}>
        <Translate id={`ip.snapshot.modal.section.${id}.title`} />
      </Heading>
      <Paragraph>
        <Translate id={`ip.snapshot.modal.section.${id}.text`} />
      </Paragraph>
    </div>
  ));
};

const renderMobile = () => {
  return (
    <AccordionWrapper
      key={Date.now()}
      items={[1, 2, 3, 4, 5, 6, 7, 8, 9].map((id) => ({
        header: (
          <Heading level={6}>
            <Translate id={`ip.snapshot.modal.section.${id}.title`} />
          </Heading>
        ),
        content: (
          <Paragraph>
            <Translate id={`ip.snapshot.modal.section.${id}.text`} />
          </Paragraph>
        ),
      }))}
    />
  );
};

const IPSnapshotModal = ({ changeUIState, width }) => {
  const isMobile = width < constants.breakpoints.sm;

  return (
    <TranslationsProvider
      translations={{
        de: () => import('./__translations__/de.json'),
      }}
      id="ip-snapshot-modal"
    >
      <div className={`container-fluid ${styles.modal}`}>
        <div className="col-xs-12">
          <Heading level={4}>
            <Translate id="ip.snapshot.modal.title" />
          </Heading>
          {isMobile ? renderMobile(changeUIState) : renderNonMobile(changeUIState)}
          <div className={styles.buttonWrapper}>
            <Button isPrimary onClick={() => changeUIState({ isIPSnapshotModalOpen: false })}>
              <Translate id="ip.snapshot.modal.cta" />
            </Button>
          </div>
        </div>
      </div>
    </TranslationsProvider>
  );
};

IPSnapshotModal.propTypes = {
  changeUIState: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  changeUIState,
};

export default connect(null, mapDispatchToProps)(IPSnapshotModal);
