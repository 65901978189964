import React from 'react';

import { TranslationsProvider } from 'components/I18n';

import { OTPAlert } from './OTPAlert';
import { OTPContent } from './OTPContent';
import { OTPProvider } from './OTPProvider';
import { OTPPropTypes } from './shared/types';
import styles from './styles.scss';

export { getVerificationMessage } from './shared/utils';

export const OTP = (props) => {
  return (
    <div className={styles.otpWrapper}>
      <TranslationsProvider
        translations={{
          de: () => import('./__translations__/de.json'),
          es: () => import('./__translations__/es.json'),
          fr: () => import('./__translations__/fr.json'),
          nl: () => import('./__translations__/nl.json'),
          'pl-PL': () => import('./__translations__/pl-PL.json'),
          'fi-FI': () => import('./__translations__/fi-FI.json'),
          'en-US': () => import('./__translations__/en-US.json'),
          'en-GB': () => import('./__translations__/en-GB.json'),
          'en-IE': () => import('./__translations__/en-IE.json'),
        }}
        id="OTP"
      >
        <OTPProvider>
          <OTPAlert />
          <OTPContent {...props} />
        </OTPProvider>
      </TranslationsProvider>
    </div>
  );
};

OTP.propTypes = OTPPropTypes;
