import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';

export default class LoadingPlaceholder extends PureComponent {
  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  };

  render() {
    const { height, width } = this.props;

    return (
      <div className={styles.loadingPlaceholder} style={{ height, width }}>
        <i className="fa fa-loading fa-spin" />
      </div>
    );
  }
}
