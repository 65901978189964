import React, { useEffect, useMemo } from 'react';

import { connect } from 'react-redux';
import Alert from 'ui-lib/src/components/Alert';
import Link from 'ui-lib/src/components/Link';
import Paragraph from 'ui-lib/src/components/Paragraph';

import { Translate } from 'components/I18n';
import {
  fetchCASCustomerData,
  fetchKCICustomerKYCStatus,
  fetchKSSCustomerKYCStatus,
} from 'store/customer/actions';
import {
  CUSTOMER_ACCOUNT_STATUS,
  isGBRCustomer,
  KYC_EXTERNAL_VERIFICATION_STATUS,
  KYC_STATUS,
} from 'utils/CustomerUtils';

import { getExternalUrl } from '../Header/data/utils';
import { EXTERNAL_LINKS } from '../Header/Navigation/menuLinks';
import styles from './styles.scss';

const MissingKYCNotice = ({
  bacNumber,
  casCustomerData,
  kssCustomerKYCStatus,
  fetchKSSCustomerKYCStatus,
  fetchCASCustomerData,
}) => {
  const isBritishCustomer = useMemo(
    () => isGBRCustomer({ default_address: { country: casCustomerData?.address?.countryCode } }),
    [casCustomerData?.address?.countryCode],
  );

  const isActiveCustomer = useMemo(() => {
    return casCustomerData?.status === CUSTOMER_ACCOUNT_STATUS.ACTIVE;
  }, [casCustomerData]);

  useEffect(() => {
    if (bacNumber && !casCustomerData) {
      fetchCASCustomerData(bacNumber);
    }
  }, [bacNumber, casCustomerData]);

  useEffect(() => {
    if (!casCustomerData || isActiveCustomer || isBritishCustomer) return;

    fetchKSSCustomerKYCStatus(bacNumber);
  }, [isBritishCustomer, casCustomerData, bacNumber, isActiveCustomer, fetchKSSCustomerKYCStatus]);

  const notificationType = useMemo(() => {
    if (isBritishCustomer) return KYC_EXTERNAL_VERIFICATION_STATUS.INITIATED;

    if (!kssCustomerKYCStatus) return undefined;

    const state = kssCustomerKYCStatus?.kyc_state;

    switch (state) {
      case KYC_STATUS.REJECTED:
        return KYC_EXTERNAL_VERIFICATION_STATUS.REJECTED;
      case KYC_STATUS.PENDING_EXTERNAL_VERIFICATION:
        return KYC_EXTERNAL_VERIFICATION_STATUS.INITIATED;
      case KYC_STATUS.PENDING_INTERNAL_VERIFICATION:
      case KYC_STATUS.APPROVED:
        return KYC_EXTERNAL_VERIFICATION_STATUS.APPROVED;
      default:
        return undefined;
    }
  }, [isBritishCustomer, kssCustomerKYCStatus]);

  const notification = useMemo(() => {
    if (!casCustomerData || isActiveCustomer || !notificationType) return undefined;

    const messageTypeBaseId = `alert.missingKYC.${notificationType.toLowerCase()}`;

    return {
      button:
        !isBritishCustomer && notificationType !== KYC_EXTERNAL_VERIFICATION_STATUS.APPROVED
          ? `${messageTypeBaseId}.button`
          : undefined,
      title: `${messageTypeBaseId}.heading`,
      body: `${messageTypeBaseId}.paragraph`,
      type: notificationType !== KYC_EXTERNAL_VERIFICATION_STATUS.APPROVED ? 'warning' : 'info',
    };
  }, [isBritishCustomer, casCustomerData, notificationType, isActiveCustomer]);

  if (!notification) return null;

  return (
    <Alert
      id="alert-missingKYC"
      className={styles.alertKYC}
      type={notification.type}
      key="alert-missingKYC"
      cta={
        notification.button && (
          <Link
            href={getExternalUrl(EXTERNAL_LINKS.IDENTIFICATION.HOME)}
            suffixIcon="chevron-right"
          >
            <Translate id={notification.button} />
          </Link>
        )
      }
      showDefault
    >
      <Paragraph>
        <strong>
          <Translate id={notification.title} />
        </strong>
      </Paragraph>
      <Paragraph>
        <Translate id={notification.body} />
      </Paragraph>
    </Alert>
  );
};

const mapStateToProps = (state) => {
  return {
    casCustomerData: state.customer.casCustomerData,
    kssCustomerKYCStatus: state.customer.kssCustomerKYCStatus,
    kciCustomerKYCStatus: state.customer.kciCustomerKYCStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchCASCustomerData: (bacNumber) => dispatch(fetchCASCustomerData(bacNumber)),
  fetchKSSCustomerKYCStatus: (bacNumber) => dispatch(fetchKSSCustomerKYCStatus(bacNumber)),
  fetchKCICustomerKYCStatus: (bacNumber) => dispatch(fetchKCICustomerKYCStatus(bacNumber)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MissingKYCNotice);
