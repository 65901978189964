import { withFormik } from 'formik';
import jwtDecode from 'jwt-decode';
import { sendGtmTrackingEvent } from 'ui-lib/src/utils/GtmTracking';

import {
  storeAuthConfigDone,
  storeAuthConfig,
  storeAuthToken,
  trimUrl,
} from 'utils/Auth';
import { KeycloakAuthFactory } from 'utils/Auth/client';
import { reportError } from 'utils/ErrorHandling';
import { getFeatureFlagClient, getFeatureFlagClientBy } from 'utils/FeatureFlag';

import { trackEvent } from '@raisin/events-tracking';
import { getAuthBaseUrl } from '../../utils/Auth/utils';
import { detectLoginType, sendLoginSucceededEvents } from './utils';
import { EVENTS } from '../../eventsTracking';

export const withLoginForm = withFormik({
  mapPropsToValues: ({ login }) => ({
    username: login ? login.username : '',
    password: login ? login.password : '',
    verificationId: login ? login.verificationId : '',
  }),
  validate: (values) => {
    // TODO make use of generateImprovedSchema
    return ['username', 'password'].reduce((acc, field) => {
      if (!values[field]) {
        acc[field] = { path: field, type: 'required' };
      }

      return acc;
    }, {});
  },
  handleSubmit: async (
    values,
    {
      props: {
        authenticateWithKeycloak,
        fetchCustomerProductAccess,
        fetchAuthConfiguration,
        postCustomerLoginCredentials,
        toggleLoginOTPVerification,
      },
    },
  ) => {
    let bacNumber;
    const authenticateCustomerWithKeycloak = async (clientId, realm) => {
      const KeycloakAuth = KeycloakAuthFactory.getInstance();
      const keycloakUrl = getAuthBaseUrl(window?.location?.hostname);
      const realmPath = `${trimUrl(keycloakUrl)}/auth/realms/${realm}`;
      const tokenEndpoint = `${realmPath}/protocol/openid-connect/token`;
      const logoutEndpoint = `${realmPath}/protocol/openid-connect/logout`;

      KeycloakAuth.setConfig({
        keycloakUrl,
        clientId,
        realm,
        realmPath,
        tokenEndpoint,
        logoutEndpoint,
      });

      try {
        const result = await authenticateWithKeycloak({
          username: values.username,
          password: values.password,
          KeycloakAuth,
          verificationId: values.verificationId,
        });

        const { status, token, headers } = result.payload;

        /**
         * Check keycloak status code
         * if (202): user has not used the OTP for the last 90 days, then show OTP component to verify himself
         * else: continue the login process normally
         */
        if (status === 202) {
          const verificationId = headers.get('Verification-ID');
          const guestToken = headers.get('Guest-Token');
          const customerId = headers.get('Customer-ID');
          const userId = headers.get('User-ID');

          toggleLoginOTPVerification({ verificationId, guestToken, customerId, userId });

          return;
        }

        if (token?.access_token) {
          const credentials = { token: token.access_token };

          // TODO - START: Delete after clear bank migration
          const decoded = jwtDecode(token.access_token);

          [bacNumber] = decoded.bac_number;

          if ((await getFeatureFlagClientBy(bacNumber)).allFlags().userManagementCBLogin === true) {
            return;
          }

          // TODO - END: Delete after clear bank migration

          storeAuthToken(token);
          storeAuthConfigDone(true);

          postCustomerLoginCredentials(credentials).then((res) => {
            sendLoginSucceededEvents(res);
            fetchCustomerProductAccess();
          });
        }
      } catch (error) {
        storeAuthConfigDone(false);
        storeAuthConfig(null);

        const errorMsg = 'Error fetching JWT from Keycloak';

        reportError(errorMsg, error);
        sendGtmTrackingEvent({
          eventCategory: 'user-login',
          eventAction: `login-failure`,
          eventLabel: errorMsg,
        });
        trackEvent({ ...EVENTS.LOGIN_FAILED, customAttribute1: bacNumber });
      }
    };

    sendGtmTrackingEvent({
      eventCategory: 'user-login',
      eventAction: `send-login-credentials`,
      eventLabel: detectLoginType(values.username),
    });

    const client = await getFeatureFlagClient()
      .then((client) => {
        if (client) {
          return client;
        }
        reportError(`Initializing LaunchDarkly client incorrectly. LD client value:`, client);

        return false;
      })
      .catch((err) => reportError(`Failed initializing LaunchDarkly client.`, err));

    try {
      // eslint-disable-next-line max-len
      if (client?.allFlags()?.userManagementKeycloakAsTheOnlyAuthWayEnabled) {
        storeAuthConfig({
          identity_provider: 'KEYCLOAK',
          distributor_name: 'Raisin',
        });

        await authenticateCustomerWithKeycloak('login', 'global');

        return;
      }

      const authenticationConfigurationResponse = await fetchAuthConfiguration(
        btoa(values.username),
      );

      if (authenticationConfigurationResponse.error) {
        reportError(
          `Error fetching user credentials from backend: ${authenticationConfigurationResponse.payload}`,
        );
        sendGtmTrackingEvent({
          eventCategory: 'user-login',
          eventAction: `login-failure`,
          eventLabel: 'CognitoResponse: Error fetching user credentials from backend',
        });
        trackEvent({ ...EVENTS.LOGIN_FAILED, customAttribute1: bacNumber });
      }
      const authenticationConfiguration = authenticationConfigurationResponse.payload.data;

      storeAuthConfig(authenticationConfiguration);

      const {
        identity_provider,
        // Keycloak
        keycloak_client = '',
        keycloak_realm = '',
      } = authenticationConfiguration;
      const credsWithUsernameAndPassword = {
        ...values,
      };

      if (
        authenticationConfiguration &&
        identity_provider === 'KEYCLOAK' &&
        keycloak_client &&
        keycloak_realm
      ) {
        await authenticateCustomerWithKeycloak(keycloak_client, keycloak_realm);
      } else if (identity_provider === 'OBS') {
        postCustomerLoginCredentials(credsWithUsernameAndPassword)
          .then((res) => {
            sendLoginSucceededEvents(res);
            fetchCustomerProductAccess();
          })
          .catch((error) => {
            storeAuthConfigDone(false);
            storeAuthConfig(null);

            const errorMsg = 'Error with legacy OBS login';

            reportError(errorMsg, error);
            sendGtmTrackingEvent({
              eventCategory: 'user-login',
              eventAction: `login-failure`,
              eventLabel: errorMsg,
            });
            trackEvent({ ...EVENTS.LOGIN_FAILED, customAttribute1: bacNumber });
          });
      }
    } catch (e) {
      storeAuthConfigDone(false);
      storeAuthConfig(null);
      reportError(`Error fetching user credentials from backend.`, e);
      sendGtmTrackingEvent({
        eventCategory: 'user-login',
        eventAction: `login-failure`,
        eventLabel: 'Error fetching user credentials from backend',
      });
      trackEvent({ ...EVENTS.LOGIN_FAILED, customAttribute1: bacNumber });
    }
  },
  displayName: 'login-form', // helps with React DevTools
});
