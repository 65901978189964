import { stringify } from 'query-string';
import {
  PRIVATE_EQUITY,
  PRIVATE_EQUITY_ORDER_FLOW_PATH,
  COMING_FROM_REGISTRATION_PARAM,
} from 'utils/IPUtils';
import { KeycloakAuthFactory } from 'utils/Auth/client';
import { trimUrl } from 'utils/Auth';
import { KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM, getAuthBaseUrl } from '../../utils/Auth/utils';
import { openConfiguratorPage } from '../IPLandingConfigurator/utils';

const depositProductIdPattern = '^[A-Z]{3}\\d{3}$';
const etfC = 'ETF-C';

const targetPageHash = {
  td_prot: 'Products',
  on_prot: 'Products/overnight',
  fairr_plp: 'PensionProducts',
  ea_prot: 'Products/easyAccess',
};

export const getRedirectAfterLogin = (
  { product, amount, target_page, portfolio, utm_source },
  history,
) => {
  let hash = 'Dashboard';
  let params = '';

  if (product) {
    if (product === etfC) {
      openConfiguratorPage(portfolio, utm_source);

      return;
    } else if (product === PRIVATE_EQUITY) {
      window.location.assign(PRIVATE_EQUITY_ORDER_FLOW_PATH + COMING_FROM_REGISTRATION_PARAM);

      return;
    } else if (product.match(depositProductIdPattern)) {
      hash = `DepositProducts/order/${product}/edit`;
      params = stringify({ amount });
    }
  } else if (target_page) {
    hash = targetPageHash[target_page] || 'Dashboard';
  }

  const targetLocation = params ? `${hash}?${params}` : hash;

  // if there is a deeplink, pathname will be different and DeeplinkManager will take care of the redirect
  if (history.location.pathname === '/SetPassword') {
    history.push(targetLocation);
  }
};

export const getKeycloakAuth = () => {
  const keycloakAuth = KeycloakAuthFactory.getInstance();

  const keycloakUrl = getAuthBaseUrl(window?.location?.hostname);
  const realmPath = `${trimUrl(keycloakUrl)}/auth/realms/${KEYCLOAK_REALM}`;
  const tokenEndpoint = `${realmPath}/protocol/openid-connect/token`;
  const logoutEndpoint = `${realmPath}/protocol/openid-connect/logout`;

  keycloakAuth.setConfig({
    keycloakUrl,
    clientId: KEYCLOAK_CLIENT_ID,
    realm: KEYCLOAK_REALM,
    realmPath,
    tokenEndpoint,
    logoutEndpoint,
  });

  return keycloakAuth;
};
